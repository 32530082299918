
@import "../../../styles/variables";

.chartType {
  background: $color-white;
    ul {
      margin-right: 8px;
      font-family: $Roboto-Regular;
      font-size: 14px;
      color: $color-gray;
      text-align: center;
      line-height: 18px;
      z-index: 1;
      li{
        background: $color-white;
      }
      & :hover {
          background: none;
      }
      border:none;
       > li {
      border: 1px solid rgba(0, 0, 0, 0.3);
        min-height: 24px;
        padding: 2px 10px;
        span:first-child {
          margin-right: 10px;
        }
      }
      > li:focus {
        border: 2px solid #2FDAFF !important;
      }
      div {
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 6px !important;
          margin-top: 1px;
          z-index: 1;
          li {
              border-top: none;
              z-index: 1;
          }
          li:hover {
            z-index: 99;
            background: #E3EDF5 !important;
          }
      }
    }
  }