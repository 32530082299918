@import '../../../../../styles/variables';

.ratingContainer {
    .showMore {
        padding-top: 5px;
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-blue;
        line-height: 18px;
        border: transparent;
        background: transparent;
        cursor: pointer;
    }

    .downWardUpwardArrow {
        float: right;
        padding-left: 30px;
    }

    .ratingTableContainer {
        padding: 10px 0px 10px 0px;
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-black;
        line-height: 16px;
        thead{
            tr{
                th:last-child{
                    div{
                        text-align: left !important;
                        padding-left: 12px !important;
                    }
                }
            }
        }
        tbody{
            tr{
                td:nth-last-child(2) {
                    div{
                        span {
                            margin-right: 0% !important;
                        }
                    }
                }
                td:last-child{
                    div {
                        text-align: left !important;
                    }
                }
            }
        }
    }
}