@import "../../styles/variables";
.fundCompareContainer {
    margin-left: 20px;
    font-family: $Roboto-Regular;;
    font-style: normal;
    background-color: white;
    padding: 0px 0px 128px 20px;
    .quoteCard{        
        background-color: white;
        width: 220px;
        margin-left: 1px;
        margin-top: 12px;
        margin-right: 25px;
        display: inline-block;
        vertical-align: top;
    }
    [cardType='openCard'] {
        margin-top: 12px;
    }
    [cardType='collapsedCard'] {
        margin-top: 40px;
    }
    
    .downloadIcon{
        position: relative;                    
        width: 14px;
        height: 18px;
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        color: $color-blue;
        line-height: 18px;
    }
    .downLoadBtnContainer
    {
        text-align: right;
        margin: -24px 20px 0px 0px;
    }
    .delayed-quote-info {
        font-size: 12px;
        line-height: 16px;
        position: sticky;
        top: 0px;
        display: flex;
        justify-content: flex-end;
        padding: 6px 19px 0px 0px;
        background-color: $color-white;
    }
    .date-info {
        display: inline-block;
        margin-right: 10px;
        color: $color-gray;
    }
    .refresh-icon {
        display: inline-block;
    }
    .scrollContainer{
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: scroll;
        position:sticky;
        top: 0px;
        align-items: center;
        background-color: #ffff;
        padding-left: 20px;
        margin-left: -20px;
        cursor: pointer;
        padding-bottom: 30px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .scrollContainer::-webkit-scrollbar {
      display: none;
    }

    .borderLine{
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;
    }
    ::-webkit-scrollbar {
        width: 0px;
    }
    .comparisonWithQuote{
		width: 280px;
        display: inline-flex;
        position: absolute;
        top: 12px;
    }    
    .modelTabs {
        max-width: 100%;
        position: sticky;
        top: 141px;
        background-color: white;
        z-index: 99999;
        // border-top: 1px solid rgba(0, 0, 0, 0.1);
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        overflow: auto;
        display: flex;
        background-image: linear-gradient(to right, white, white),
            linear-gradient(to right, white, white),
            linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),
            linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0));   
        background-position: left center, right center, left center, right center;
	    background-repeat: no-repeat;
	    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
	    background-attachment: local, local, scroll, scroll;
    }
    .isSectionDisplay {
        display: 'contents';
    }
    .isSectionHide {
        display: none;
    }
    .PerformanceOnComparePage{
        position: sticky;
    }
    .modelCompareTableSection{
        position: relative;
        //top: 265px;
    }
    .modelTabs::-webkit-scrollbar {
        display: none;
        width: 0 !important;
    }
    .modelTabs::-webkit-scrollbar-thumb {
        border-right: 0px #B2B2B2 solid !important;
    }
    .modelTable {
        width: 100%;
    }
    .primaryBenchmark {
        font-family: $Roboto-Regular;
        font-size: 14px;
        line-height: 18px;
        padding-top: 10px;
        color: $color-black;
    }
    .tableTitle{
        display: block;
        width:100%;
        height: 26px;
        margin: 30px 10px 20px 0px;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color:rgba(0,0,0,1);
        cursor: default;

        .downloadGlossary {
            display: flex;
            float: right;

            .glossary {
                margin-right: 20px;
                margin-top: -25px;

                div[id=outerModalPopUp]>div:nth-child(1) {
                    min-height: 500px;
                    width: 700px;
                    div[id=modalPopUpContentContainer] {
                        max-height: 500px;
                        height: 435px;
                    }
                }
            }
        }
    }
    .tableTitle:focus {
        outline: none;
    }
    .tableTitle:focus-visible {
        outline: 1px solid #2fdaff;
    }
    @media (min-width: 596px) and (max-width: 1023px), only screen and (max-device-width:1023px) {
        .downloadGlossary {
            .glossary {
                div[id=outerModalPopUp]>div:nth-child(1) {
                    min-height: 600px;
                    width: 700px;
                    div[id=modalPopUpContentContainer] {
                        max-height: 600px;
                        height: 535px;
                    }
    
                }
            }
        }
        .quoteCard{
            margin-top: 14px;
        }
        .delayed-quote-info {
            padding: 10px 20px 0px 0px;
        }
        [cardType='openCard'] {
            margin-top: 14px;
        }
        [cardType='collapsedCard'] {
            margin-top: 38px;
        }
        .modelTabs{
            max-width: 98%;
        }
    }
    @media (min-width: 375px) and (max-width: 595px), only screen and (max-device-width: 595px) {
        .downloadGlossary {
            .glossary {
                margin-right: -20px !important;
                margin-top: -26px !important;

                div[id=outerModalPopUp] {
                top: 0px;
                >div:nth-child(1) {
                    max-height: 100%;
                    min-height: 100%;
                div[id=modalPopUpContentContainer] {
                        max-height: 85vh !important;
                        height: 85vh !important;
                    }
                }
            }
            }
        }
        .hide {
            display: none;
          }
        .delayed-quote-info {
            margin-left: -10px;
            justify-content: start;
            padding: 10px 0px 0px 0px;
        }
        [cardType='collapsedCard'] {
            margin-top: 38px;
        }
        .modelTabs{
            max-width: 97%;
        }
    }
}
