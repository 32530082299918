@import "../../../styles/variables";
@import "../../../styles/Screener/Screener.module.scss";
.ScreenerContainer {
    margin-top: 20px;
    .quoteCard{        
        background-color: white;
        width: 228px;
        margin-left: 10px;
        margin-top: 10px;
        display: inline-block;
    }
    .allModels{
        margin-bottom: 4px;
    }
    .downloadCompareButtonWrapperDiv{    
        .downloadCompareButtonDiv{
            position: relative;
            float: right;
            margin-top: -40px;
            margin-right: 10px;
            
        }
    }

    .headerActionMenu{
        width: 5%;
        justify-content: center;
        ul{
            li{
                padding: 15px;
            }
            li:first-child{
                padding-bottom: 7.5px;
            }
            li:nth-child(2){
                padding-top: 7.5px;
            }
        }
    }

    .loader{
        width: 0;
        height: 0;
        display: inline-block;
        font-size: 8px;
        margin-right: 10px;
    }

    .openSaveOverlay{
        text-align: right;
        display: inline;
        display: inline-flex;
        align-items: center;

        .glossary {
            display: inline-block;
        }
        .dropdown {
            display: inline-block;
        }
        div[id=outerModalPopUp]>div:nth-child(1) {
            min-height: 500px;
            width: 700px;
            div[id=modalPopUpContentContainer] {
                max-height: 500px;
                height: 435px;
            }

        }
    }
    [name="risk-select"]{
        width:88px;
    }
    [name="income-select"]{
        width:108px;
    }
    [name="technicalIndicators-select"]{
        width:187px;
    }
    [name="operational-select"]{
        width:133px;
    }
    [name="performance-select"]{
        width:142px;
    }
    [name="ratings-select"]{
        width:109px;
    }
    [name="portfolio-characteristics-select"]{
        width:213px;
    }

    [name="popular-select"]{
        width:145px;
    }

    [name="basics-select"]{
        width:103px;
    }
    [name="performance-select"]{
        width:142px;
    }
    [name="assetAllocation-select"]{
        width:163px;
    }
    [name="esgFundRating-select"]{
        width:165px;
    }
    [name="esgCarbonMetrics-select"]{
        width:186px;
    }
    .screenerFiltersContainer{
        height: 263px;
        cursor: pointer;
        .selectScreenerFilter:nth-child(13){
          padding: 15px 15px 15px 10px !important;
       }
      }
    @media (max-width: 595px), only screen and (max-device-width:595px) {
        .screenerTitle{ 
            width: calc(100% - 40px);
        }
        .displayHidden {
          display: none;
        }
        .buttons {
            width: 96%;
            margin-bottom: 10px;   
        }
      }
      .screenerTitle{
        
        .discoverFunds{
            font-size: 28px;
            color: #000000;
            line-height: 34px;
            margin-bottom: 5px;
        }
    }

    .topResultsSection{
        display: inline-block;
        width: calc(100% - 200px);
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 34px;
        margin-top: 30px;
        margin-bottom: 15px;
        .topResults{
            margin-right: 5px;
            font-family: $Roboto-Light;
        }
        .topResultsCriteria{
            font-family: $Roboto-Regular;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #666666;  
      }      
    }
    .resetFundScreenFilters{
        width: 206px;
        display: inline-block;
        float: right;
    }
    .screenerResultCount{
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #666666;
      padding-right: 20px;
    }
    .resetFilterLnk{
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: right;
      padding-right: 15px;
    }
    
    .scrollContainer{
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: scroll;
        align-items: center;
        background-color: #ffff;
        z-index: 99999;
        width: 100%;
        padding-bottom: 5px;;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .scrollContainer::-webkit-scrollbar {
        display: none;
      }
    .fundScreenTabs {
        width: 99%;
    }
      @media (max-width: 595px), only screen and (max-device-width:595px) {
        .topResultsSection{ 
            width: 100%;
            .topResults{
              display: block;
              margin-bottom: 6px;
            }
        }  
      }
      @media (min-width: 595px), only screen and (min-device-width:595px) {
        .displayHidden {
          display: '';
        }
        .screenerTitle:first-child{
            width: calc(100% - 274px);
          }
      }
      @media (min-width: 1455px), only screen and (min-device-width:1455px) {
        .screenerTitle{ 
            width: 1247px;
        }
      }
      @media (min-width: 1500px) and (min-device-width:1500px) {
        .downloadCompareButtonWrapperDiv{    
            .downloadCompareButtonDiv{
              margin-top: 25px;
            }
        }
      }
      @media (min-width: 596px) and (max-width: 1023px), only screen and (max-device-width:1023px) {
        .allModels {
            margin-bottom: 14px !important;   
        }
        .downloadCompareButtonWrapperDiv{    
            .downloadCompareButtonDiv{
              margin-top: -50px;
            }
        }
        .fundScreenTabs {
            width: 98%;
        }
      }
      @media (min-width: 375px) and (max-width: 595px), only screen and (max-device-width:595px) {
        .allModels {
            margin-bottom: 20px !important;   
        }
        .buttons {
            width: 100%;
            margin-bottom: 4px;
        }
        .downloadCompareButtonWrapperDiv{    
            .downloadCompareButtonDiv{
                float:none;
                margin-top: 0px !important;
            }
        }
        .fundScreenTabs {
            width: 97%;
        }
      }
    }
@media screen and (max-width: 595px) {

    .ScreenerContainer {
        .openSaveOverlay {
            display: inline !important;
    
            div[id=outerModalPopUp] {
                top: 0px;
            >div:nth-child(1) {
                    max-height: 100%;
                    min-height: 100%;
                div[id=modalPopUpContentContainer] {
                        max-height: 85vh;
                        height: 85vh;
                    }
    
                }
            }
        }
        }
}
@media (min-width:596px) and (max-width: 1023px) {
    .screenerTitle:first-child{
        width: calc(100% - 300px) !important;
      }

      .ScreenerContainer {
        .openSaveOverlay {
            div[id=outerModalPopUp]>div:nth-child(1) {
                min-height: 600px;
                width: 700px;
                div[id=modalPopUpContentContainer] {
                    max-height: 600px;
                    height: 535px;
                }

            }
        }
    }
}