@import '../../../styles/variables';
.linkButton {
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.loaderDiv{
    width: 20px;
}