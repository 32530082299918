@import '../../../../styles/variables';

.OptionsChainContainer {

  .header {
    font-family: $Roboto-Light;
    font-size: 28px;
    color: $color-black;
    line-height: 34px;
    margin-bottom: 20px;
  }

  .adjustmentHeader{
    background: $color-white;
    border: 1px solid $color-dark-orange-4;
    border-left-width: 9px;
    border-radius: 3px;
    margin-bottom: 30px;
    display: flex;
    .iconNoData{
      color: $color-dark-orange-3;
      margin: 11px 8px 9px 9px;
      font-size: 24px;
      line-height: 24px;
    }
    .adjustmentMsg{
      padding: 12px 0px 10px 0px;
      font-family: $Roboto-Regular;
      font-size: 16px;
      color: $color-black;
      line-height: 22px;
      .adjustmentBoldMsg{
        font-weight: bolder;
      }
    }
  }

  .optionsDropdowns {
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 15px;
    background: $color-white-opacity;

    .dropdown {
      display: inline-block;
      margin-right: 10px;

      >ul {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-black;
        text-align: center;
        line-height: 18px;
        width: 140px;
        margin: 0px;
        position: relative;

        >li {
          border-radius: 3px;
          max-height: 34px;
          padding: 8px 10px;

          span:first-child {
            margin-right: 7px;
            top: 0px
          }
        }

        >div {
          box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);
          border-radius: 3px !important;
          margin-top: 5px;
          position: absolute;
          width: 140px;

          >li {
            border-top: 0px;
            background: $color-white;
          }
          li:hover {
            z-index: 99;
            background: $color-white-opacity !important;
          }
        }
      }

    }

    .optionsButtonContainer {
      display: none;
      width: 100%;
    }

    .standard {
      margin-right: 4px;
    }

    .Button {
      background: $color-white;
      border-radius: 3px 0 0 3px;
      border: 1px solid $color-blue;
      width: 50%;
      color: black;
      font-family: $Roboto-Regular;
      font-size: 12px;
      line-height: 16px;
      padding: 6px 0px;
    }

    .activeOptionsTab {
      color: $color-white;
      background: $color-blue;
    }

    .icon {
      width: 14px;
      height: 13px;
    }

    .linkContainer {
      margin-top: -4px;
      display: inline-block;
    }

  }
}

@media(min-width: 596px) and (max-width:1023px) {
  .OptionsChainContainer {

    .optionsDropdowns {

      .dropdown {
        ul {
          width: 155px;
        }

        div {
          width: 155px !important;
        }
      }
    }
  }

}

@media(min-width: 375px) and (max-width:595px) {
  .OptionsChainContainer {

    .adjustmentHeader{
      .iconNoData{
        margin: 33px 8px 33px 9px;
      }
    }

    .optionsDropdowns {
      display: block;
      padding: 15px 12px;

      .dropdown {
        width: 100%;
        ul {
          width: 100%;
        }

        div {
          width: 100% !important;
        }
      }

      .optionsButtonContainer {
        display: inline-block !important;
        padding-top: 3px;
        margin-bottom: 15px;
      }

      .callsAndPuts {
        display: none;
      }

      .standard {
        margin-right: -10px;
      }

      .linkContainer {
        margin-top: 15px;
        display: block;

        >button {
          padding: 0px;
        }
      }

    }
  }

}