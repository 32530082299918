@import "../../../styles/variables";

.noData {
    position: absolute;
    background: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    padding: 10px 14px 10px 15px !important;
    display: flexbox;
    align-self: center;
    align-content: center;
    z-index: 2;
}

.box {
    position: absolute;
    background: $color-white;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 10px 15px 0px 15px !important;
    z-index: 2;

    .internalBox {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
        color: $color-black;
        display: flex;
        align-items: center;
        margin-bottom: 10px !important;

        .icon {
            width: 8px;
            height: 8px;
            margin-right: 5px !important;
            margin-top: 2px !important;
        }

        .key {
            font-weight: 500;
            text-align: start;
            white-space: nowrap;
            width: 162px;
        }

        .value {
            font-weight: 400;
            margin-left: 19px !important;
            width: 45px;
            text-align: end;
        }
    }
}

@media (min-width: 375px) and (max-width: 595px) {
    .box {
        padding: 6px 10px 0px 10px !important;

        .internalBox {
            font-size: 10px;
            line-height: 14px;
            margin-bottom: 6px !important;

            .icon {
                width: 6px;
                height: 6px;
                margin-right: 5px !important;
                margin-top: 2px !important;
            }

            .key {
                font-weight: 500;
                text-align: start;
                white-space: nowrap;
                width: 110px;
            }

            .value {
                font-weight: 400;
                margin-left: 19px !important;
                width: 45px;
                text-align: end;
            }
        }
    }
}
