@import "../../../../../styles/variables";

.transactionsIndividualsContainer {
    margin: 30px 0px;

    .filtersContainer {
        padding: 0;
        margin: 0;
        display: flex;

        .item {
            display: inline-flex;
            flex-direction: row;
            margin-right: 8px;

            .button {
                border: 1px solid rgba(0, 0, 0, 0.30);
                color: $color-gray;
                background: $color-white;
                border-radius: 6px;
                font-family: $Roboto-Regular;
                font-size: 14px;
                cursor: pointer;
                text-align: center;
                line-height: 18px;
                height: 26px;
                width: 47px;
            }
        }

        .selected>.button {
            border: none;
            color: $color-blue;
            background-color: rgba(47, 174, 255, 0.25);
        }
    }

    .transactionTypes {
        display: inline-flex;
        margin: 20px 0px;
    }

}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .filtersContainer {
        margin: 0px 0px 11px 0px !important;
        .dropdownContainer {
            margin-right: 52%;

            ul {
                margin-left: 0;
                min-width: 50%;
            }

            ul,
            li {
                span {
                    padding-left: 10px;
                    padding-right: 12px;
                }
            }
        }
        .transactionFilters {
            min-width: 50%;
        }
    }
    .transactionTypes {
        margin: 5px 0px !important;

        .firstType {
            width: 160px;
        }
    }
}