@import "../../../../../../styles/variables";

.fundHightlightContainer{
    border-radius: 3px;
    width:100%;

    .fundHighlightContent{
        >div >div {
            max-width: 60%;
        }
    }
    .fundHighlightHeader{
        padding:11px 0 0 15px;
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
    }

    .fundHighlightContent{
        padding:7px 15px 5px 15px;
        color: $color-black;
    }

    .fundHighlightIPadContainer{
        float:left;
        width:100%;
        margin-bottom: 15px;
        .fundHighlightIPadDivLeft{
            float:left;
            width:49%;
        }
        .fundHighlightIPadDivRight{
            float:left;
            width:49%;
            margin-left:10px;
        }
    }
    .aiOverviewContainer{
        margin-bottom:0px;
    }
    .fundHighlightsButton{
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-blue;
        line-height: 18px;
        cursor: pointer;
        padding:0;
        border:none;
        background: none;
        margin-top: 15px;
    }
    .red {
        color: $color-red;
    }
    .green {
        color: $color-green;
    }
    .analystNoteLink {
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-blue;
        line-height: 18px;
        cursor: pointer;
        margin: 0px 0px 13px 15px;
        display: flex;
        width: fit-content;
        .pdfIcon{
            font-family: $Roboto-Light;
            margin-right: 6px;
            font-size: 18px;
        }
        .link {
            font-weight: 500;
        }
    }
    .linkButton {
        margin-bottom: 15px;
        margin-left: 15px;
    }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .fundHighlightsButton{
        padding: 0px 15px 15px 0px !important;
 }
 .fundHighlightContent{
        >div >div {
            max-width: none !important;
        }
    }
}