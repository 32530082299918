@import "../../../styles/variables";

.carbonRiskScoreContainer {
    .heading {
    height: 34px;
    left: 20px;
    font-family: $Roboto-Regular; 
    font-size: 28px;
    line-height: 34px;
    font-weight: 300;
    color: #000000;
    margin-bottom: 25px;
    }
}
.NXICarbonRiskScoreContainer {
    .heading {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        border-bottom: 1px solid $color-light-gray-5;
        padding-bottom: 10px;
    }
}