@import "../../../styles/variables";

.AUMRiskScoreContainer {

    .header {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 34px;
        color: $color-black;
        margin-bottom: 20px;
    }

    .types {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $color-gray;
        display: flex;
        align-items: center;
        margin-top: -10px;

        .type{
            margin-right: 20px;
            margin-bottom: 20px;

            .icon {
                width: 8px;
                height: 8px;
                margin-right: 5px;
                margin-bottom: 2.5px;
            }
        }
    }
}

.NXIAUMRiskScoreContainer {
    .header {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 28px;
        border-bottom: 1px solid $color-light-gray-5;
        padding-bottom: 10px;
    }

    .lowerSection {
        font-size: 12px;
        line-height: 18px;
        color: $color-black-solid;
        margin-top: 10px;
        padding-bottom: 20px;

        .categoryLabels {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 2px;

            .chartLabels {
                font-family: $Roboto-Regular;
                font-style: normal;
                font-weight: 400;
                margin-right: 20px;
                margin-bottom: 2px;
                display: flex;

                .circle {
                    width: 10px;
                    height: 10px;
                    border-radius: 25%;
                    margin-right: 5px;
                    align-self: center;
                }

                .icon {
                    width: 8px;
                    height: 8px;
                    margin-right: 5px;
                    margin-bottom: 2.5px;
                }
            }
        }
    }
}