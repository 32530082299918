@import "../../../../styles/variables";

.chartContainer {
    // width: 100%;
    // height: 232px !important;
    svg[class="ct-chart-donut"] {
        // min-height: 118px;
        g[class="ct-series ct-series-a"] {
            path {
                stroke: $color-bar-graph-blue-dark;
                stroke-width: 10px !important;
            }
            path[data-value="no-composition"] {
                stroke: $color-light-gray-5 !important;
            }
        }
        g[class="ct-series ct-series-b"] {
            path {
                stroke: $color-dividend-performance-area-blue;
                stroke-width: 10px !important;
            }
            path[data-value="no-composition"] {
                stroke: $color-light-gray-5 !important;
            }
        }
        g[class="ct-series ct-series-c"] {
            path {
                stroke: $color-dark-orange;
                stroke-width: 10px !important;
            }
            path[data-value="no-composition"] {
                stroke: $color-light-gray-5 !important;
            }
        }
        g[class="ct-series ct-series-d"] {
            path {
                stroke: $color-light-green;
                stroke-width: 10px !important;
            }
            path[data-value="no-composition"] {
                stroke: $color-light-gray-5 !important;
            }
        }
    }
}