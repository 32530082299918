@import "../../../styles/variables";

.searchForm {
     /*Slider Code*/
     .mainInner {
         width: 97%;
         padding-left: 20px;
         position: relative;
         margin-right: 20px;
     }
     .searchStyle {
         width: 100%;
         margin: 0 auto;
     }
    .sliderComponent {
            float: right;
            padding-right: 19px;
            padding-top: 20px;
            font-size: 12px;
            font-family: $Roboto-Regular;
            .marginLeft{
                margin-right: 10px;
            }
      }
    /*Slider Code ends*/
    
      .searchIconWrapper {
        display: flex;
        position: relative;
      }
      .searchInput{
        padding-left: 36px;
        width: 100%;
        height: 47px;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 26px;
        border: none;
        color: #999999;
        border-radius: 3px;
        box-shadow: 1px 2px 5px 2px #0000001A;
      }
        ::placeholder{
          font-size: 20px;
          color: #999999;
          font-style: normal;
          font-weight: 300;
          font-family: $Roboto-Regular;
          line-height: 26px;
        }
      
      .searchIconWrapper input[type=text]:focus{
        border: 2px solid #2FDAFF;
        outline: #2FDAFF;
      }
      .searchIcon {
        position: absolute;
        color: #999999;
        z-index: 1;
        display: inline-block;
        padding-left: 15px;
        padding-top: 12px;
    }
    .iconStylingMOD:hover{
        color: #0572D6;
    }
    .clear {
        font-size: 14px;
        color: #0572d6;
        text-align: right;
        line-height: 18px;
      }
      .clearButton {
        right: 20px;
        position: absolute;
        font-size: 14px;
        line-height: 51px;
        text-align: right;
        color: #0572D6;
        z-index: 2;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
      }

      @media screen and (min-width: 375px) and (max-width: 595px) , only screen and (min-device-width: 375px) and (max-device-width:595px){
        .mainInner {
          padding-left: 10px;
        }
      }
    }