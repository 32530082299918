@import '../../../../styles/variables';

.allWatchlistsContainer {
    background: $color-white;
    margin-top: 30px;
    padding: 29px 20px 37px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);

    .headerContainer {
        width: 100%;
        height: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .label {
            font-family: $Roboto-Regular;
            font-size: 20px;
            color: $color-black;
            line-height: 34px;
        }

        .dropDownContainer {
            min-width: 196px;
            height: 30px;
            position: relative;
            display: flex;
            align-items: center;

            .sortBy {
                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-black;
                line-height: 16px;
            }

            ul {
                width: 146px;
                right: 1px;
                height: 30px;

                li:first-child {
                    padding-top: 6px;
                    padding-left: 10px;
                    padding-bottom: 6px;
                    line-height: 18px;
                    padding-right: 9px;
                }

                div {
                    margin-top: 5px;
                    box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);
                    border-radius: 3px;

                    li:first-child {
                        padding-top: 4px !important;
                        padding-bottom: 4px !important;
                        background-color: $color-white;

                        &:hover {
                            background-color: $color-white-opacity;
                        }
                    }

                    li {
                        padding-top: 3px !important;
                        padding-bottom: 4px;
                    }
                }
            }
        }
    }

    .bodyContainer {

        .commonContainer {
            display: grid;
            grid-template-columns: calc(25% - 18px) calc(25% - 18px) calc(25% - 18px) calc(25% - 18px);
            width: 100%;
            grid-gap: 30px 24px;

            .contentCard {
                width: 100%;
                .nameOfWatchlist {
                    margin-top: 11px;
                    margin-left: 15px;
                    font-family: $Roboto-Bold;
                    font-size: 16px;
                    color: $color-black;
                    line-height: 22px;
                    padding: 0px;
                }

                .buttonCreateWatchlist {
                    border: none;
                    background: none;
                    padding: 0;
                }
            }

            .emptyCard {
                flex-direction: column;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $color-white;
                box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
                border-radius: 3px;
                height: 137px;
                .buttonCreateWatchlist {
                    .plusIcon {
                        .iconSize {
                            font-size: 24px;
                            line-height: 24px;
                            color: $color-blue;
                            display: inline-block;
                            cursor: pointer;
                        }
                    }

                    .createWatchlistButton {
                        font-family: $Roboto-Medium;
                        font-size: 14px;
                        color: $color-blue;
                        flex-direction: column;
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .smallCards {
            grid-template-columns: calc(50% - 12.5px) calc(50% - 12.5px);
            grid-gap: 10px 25px;

            .contentCard {
                height: 44px;
            }

            .emptyCard {
                align-items: center;
                justify-content: flex-start;
                padding-left: 15px;
                flex-direction: row;

                .buttonCreateWatchlist {
                    display: flex;
                    flex-direction: row;

                    .plusIcon {
                        .iconSize {
                            position: relative;
                            top: 2px;
                            font-size: 14px;
                            line-height: 24px;
                            color: $color-blue;
                            display: inline-block;
                        }
                    }

                    .createWatchlistButton {
                        padding: 0px 0px 0px 5px;

                    }
                }
            }

            .containerBox {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }
        }
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .allWatchlistsContainer {
        margin-top: 0px;
        padding: 30px 15px 30px 15px;
        background: $color-white;

        .bodyContainer {
            .commonContainer {
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 10px 10px;

                .contentCard {
                    height: 164px;

                }
            }

            .smallCards {
                margin-top: 9px;
                margin-bottom: -10px;
                grid-template-columns: calc(50% - 5px) calc(50% - 5px);

                .contentCard {
                    height: 44px;
                }
            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    .allWatchlistsContainer {
        margin-top: 0px;
        padding: 30px 10px 30px 10px;
        background: $color-white;

        .headerContainer {
            .label {
                font-family: $Roboto-Regular;
                font-size: 20px;
            }
        }

        .bodyContainer {
            .commonContainer {
                max-height: 540px;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                grid-gap: 0px 0px;
                padding-bottom: 18px;

                >div+div {
                    margin-top: 10px;
                }

                .contentCard {
                    height: 44px !important;
                    width: 100%;
                    flex-direction: row;
                    align-items: center;

                }

                .emptyCard {
                    width: 98% !important;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: row;
                    box-sizing: border-box;
                    padding: 11px 10px;
                    .buttonCreateWatchlist {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .plusIcon {
                            .iconSize {
                                font-size: 14px;
                                line-height: 24px;
                                color: $color-blue;
                                display: inline-block;
                            }
                        }

                        .createWatchlistButton {
                            padding: 0px 0px 0px 5px;

                        }
                    }
                }

                .smallCards {
                    margin-top: 0px !important;
                }
            }
        }
    }
}