@import '../../../styles/variables';

.customSelectContainer {
    background: transparent;
    border: 1px solid $color-light-gray !important;
    border-radius: 3px;
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: $color-black;
    line-height: 18px;
    padding: 6px 8px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    cursor: pointer;
    width: 87px !important;
    height: 34px;

    .preFixLabel {
        margin-right: 10px;
    }
    .postFixLabel {
        margin-left: 5px;
    }

    .icon {
        font-size: 12px;
    }

    .lineButton {
        padding: 0 15px;
        top: 12px;
        position: relative;
        margin: 0 20px 0 0px;
    }
}

.linesContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 132px;

    button {
        width: 100%;
        height: 5px;
        border: none;
        margin: 15px 15px 10px 15px;;
        background: none;
        border-radius: 0;
    }
}

.solid-thin {
    border-top: 2px solid #000 !important;
}
.solid-medium {
    border-top: 3px solid #000 !important;
}
.solid-large {
    border-top: 4px solid #000 !important;
}
.dotted-thin {
    border-top: 2px dotted #000 !important;
}
.dotted-medium {
    border-top: 3px dotted #000 !important;
}
.dotted-large {
    border-top: 4px dotted #000 !important;
}

.popoverContainer {
    position: absolute;
    top: -6px;
    left: 13px;

    div[data-popover-body-container="true"] {
        padding: 0px !important;
    }
}
