@import "../../../styles/variables";

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 20px 2px 4px 20px;
    font-family: $Roboto-Bold;
}
.headerSpacing{
    padding: 20px 2px 4px 0px !important;
}
