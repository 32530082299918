@import "../../../../styles/variables";

.chartsMobileFooter{
    position: fixed;
    width:100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    border-top: 1px solid $color-divider-gray;
    padding: 10px 0;
    button:first-child {
      margin-right: 10px;
    }
    button[title="Apply"]{
        background: $color-blue;
        color: $color-white;
    }
    button[title="Reset"]{
        background: $color-white;
        color: $color-blue;
        border: 1px solid rgba(0, 0, 0, 0.3);
    }
}