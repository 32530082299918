@import '../../../styles/variables';

.similarFundsContainer {
    margin-top: 30px;

    .headerContainer {
        margin-bottom: 18px;
        div {
            display: inline;
        }
    }

    .buttons {
        float: right;
        display: flex;
        flex-direction: row;
        margin-top: 5px;

        svg {
            height: 18px;
            width: 18px;
        }

        .left {
            margin-right: 5px;
        }

        .bar {
            width: 1px;
            height: 16.51px;
            border-right: 1px solid $color-bar
        }

        .right {
            margin-left: 5px;
        }

        button {
            border: none;
            padding: 0;
            margin: 0;
            background: transparent;
            font-size: 18px;
            line-height: 18px;
        }
    }

    .headerShareClassMF {
        font-family: $Roboto-Bold;
        font-size: 12px;
        line-height: 16px;
        text-Align: Left;
        font-weight: 500;
        color: $color-black;
    }
    .header {
        font-family: $Roboto-Regular;
        font-weight: 300;
        font-size: 28px;
        line-height: 34px;
        text-Align: Left;
        color: $color-black;
    }
    .text {
        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 16px;
        color: $color-gray;
        margin-left: 10px;
    }

    .groupcontainer {
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        position: relative;

        /* Firefox */
    }
    .groupcontainer {
        display: flex;
        flex-direction: row;
        // justify-content: space-between; // for cards less than 5 in desktop view, discussed with solaris.
        overflow-x: scroll;
        width: 100%;
        padding: 2px 1px 1px 1px;

        &::-webkit-scrollbar {
            display: none;
        }


        .card:first-child {
            margin-left: 0;
        }

        .card {
            margin-right: 24px;
        }

        .card:last-child {
            margin-right: 0;
        }
    }
    .shadow {
        position: absolute;
        top: 0;
        overflow: hidden;
        max-width: 40px;
        margin-top:47px;
        height: calc(100% - 47px);
        z-index: 1;
        width: 40px;
        box-shadow: none;
    }
    .shadowLeft {
        left: 0;
        background: linear-gradient(90deg, rgb(148, 148, 148) -541.2%, rgba(25, 25, 25, 0.15) -37.87%, rgba(0, 0, 0, 0) 28.43%);
    }
    .shadowRight {
        right: 0;
        background: linear-gradient(270deg, rgb(148, 148, 148) -541.2%, rgba(25, 25, 25, 0.15) -37.87%, rgba(0, 0, 0, 0) 28.43%);
      }
}

@media (min-width: 596px) and (max-width: 1023px) {
    .similarFundsContainer {
        .groupcontainer {
            .card {
                margin-right: 20px;
            }
        }
    }
}

@media (min-width: 375px) and (max-width: 595px) {
    .similarFundsContainer {
        .headerContainer {
            div:first-child {
                display: inline-block;
                width: 100%;
            }

            .buttons {
                margin-top: 0px;
            }

            .text {
                margin-left: 0;
            }
        }

        .groupcontainer {
            .card {
                margin-right: 20px;
            }
        }
        .shadow {
            margin-top:85px;
            height: calc(100% - 85px);
        }
    }
}