@import "../../../../../styles/variables";

.saveChartOptionsContainer {
    position: absolute;
    right: 0;
    top: 37px;
    padding: 7px 0px 10px 0px;
    width: 107px;
    list-style-type: none;
    margin: 0;
    background: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
    border-radius: 3px;
    .saveChartOptionItem{
        padding: 6px 6px 6px 10px;
    }
    button {
        background: none;
        border: none;
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        padding: 0px;
        margin: 0px;
    }
    li:hover {
        background: $color-white-opacity;
    }
}