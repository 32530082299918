@import "../../../styles/variables";

.performanceContainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
  .loaderContainer {
    margin-top: 30px;
  }
  .TopText {
    font-family: $Roboto-Regular;
    font-size: 28px;
    color: $color-black;
    line-height: 34px;
    margin-bottom: 15px;
    font-weight: 300;
  }
  .AdvChtLink {
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    margin-top: 15px;
  }
  .chartSelections {
    .itemsContainer {
      display: flex;
      margin-bottom: 20px;
      flex: 1;
      position: relative;
      justify-content: flex-start;
    }
    .item {
      margin-right: 8px;
      background: $color-white;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 6px;

      font-family: $Roboto-Regular;
      font-size: 14px;
      color: $color-gray;
      text-align: center;
      line-height: 18px;

      // height: 26px;

      .button {
        padding: 4px 10px;
        border: transparent;
        background: transparent;
        text-transform: uppercase;
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-gray;
        text-align: center;
        line-height: 18px;
        cursor: pointer;
      }
    }
    .disabledItem {
      opacity: 0.5;
    }
    .selectedItem {
      background: rgba(47, 174, 255, 0.25);
      border-radius: 6px;
      font-family: $Roboto-Regular;
      font-size: 14px;
      color: $color-blue;
      text-align: center;
      line-height: 18px;
      border: transparent;
    }
    .selectedItem > .button {
      color: #0572d6;
      font-family: $Roboto-Regular;
      font-size: 14px;
      text-align: center;
      line-height: 18px;
    }
    .hide {
      display: none;
    }
  }

  // chart styles heree
  .chart {
    min-height: 224px;
  }
}
.marginSetter{
  margin-top: 0px;
}

@media screen and (min-width: 375px) and (max-width: 595px) {
  .performanceContainer {
    .chartSelections {
      margin-top: 0;
      .itemsContainer {
        margin-bottom: 0px;
        .row1FirstDD {
          margin-right: 255px;
          ul {
            margin-left: 0;
            min-width: 239px;
          }
          ul,
          li {
            span {
              padding-left: 10px;
              padding-right: 12px;
            }
          }
        }
        .row1SecondDD {
          ul {
            width: 82px;
            margin: 0;
            > li {
              padding: 3px 10px;
              border-radius: 3px;
            }
            > li:focus {
              border: 1px solid rgba(0, 0, 0, 0.3) !important;
            }
          }
        }
      }
    }
    .chart {
      margin-top: 27px;
    }
  }
  .marginSetter{
    margin: 0px;
  }
}
