@import '../../../styles/variables';

.sustainabilityScoreContainer {
    color: #212529;
    .headerLabel {
        font-family: $Roboto-Regular;
        font-weight: 300;
        font-size: 28px;
        line-height: 34px;
        color: $color-black;
    }

    .upperSection {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .corporateSoverignContainer {
        .corporateSoverign {
            .headerContainer {
                display: flex;

                .labelContainer {
                    display: flex;
                    flex-wrap: nowrap;
                    margin-left: 20px;
                    font-family: $Roboto-Regular;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    padding-top: 15px;
                    .circle {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        margin-right: 5px;
                        align-self: center;
                    }

                    &:first-child {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width:595px) {
    .sustainabilityScoreContainer {
        .corporateSoverignContainer {
            .corporateSoverign {
                .headerContainer {
                    flex-wrap: wrap;
                    & > div:nth-child(3) {
                        margin-left: 0px;
                    }
                }

            }
        }
    }
}