@import '../../../../styles/variables';

.container {
  padding: 15px 31px 17px 15px;
  display: flex;
  flex-direction: row;
  background: $color-white;
  box-shadow: 0px 1px 4px $color-container-box-shadow;
  border-radius: 3px;
  justify-content: space-between;

  .childContainer {
    .labels {
      font-family: $Roboto-Regular;
      font-size: 12px;
      line-height: 16px;
      color: $color-gray;
      margin-bottom: 4px;
    }

    .value {
      font-family: $Roboto-Regular;
      font-size: 20px;
      line-height: 26px;
      color: $color-black;
    }

    .progress-bar {
      line-height: 16px;

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 2px;
        outline: none;
        background: $color-very-light-gray;
      }

      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 6px;
        height: 6px;
        background: $color-bar-graph-blue-dark !important;
        border-radius: 3px;
      }

      .slider::-moz-range-thumb {
        width: 6px;
        height: 6px;
        background: $color-bar-graph-blue-dark !important;
        border-radius: 3px;
      }
    }
  }
}

@media (min-width: 375px) and (max-width: 595px) {
  .container {
    padding: 15px 15px 17px 15px;

    .childContainer {
      .progress-bar {
        line-height: 18px;
      }
    }

    .mobileDisplay {
      margin-bottom: 34px;
    }
  }
}

@media (min-width: 596px) and (max-width: 1023px) {
  .container {
    padding: 15px 15px 17px 15px;


    .childContainer {
      .progress-bar {
        line-height: 18px;
      }
    }
  }
}