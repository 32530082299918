.ETF, .MF{
    width: 67px;
    height:24px; 
    color: #666666;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 6px;
    padding-top: 3px;
    text-align: center;
    cursor: default;
}
