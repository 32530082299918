@import '../../../../styles/variables';

.strikeColumnContainer {
    width: 104px;
    min-width: 104px;
    background-color: $color-white-opacity;
    text-align: center;
    font-size: 14px;
    color: $color-black;
    line-height: 18px;
    .popoverContainer {
        div[data-popover-body-container="true"] {
            padding: 0px !important;
        }
    }
    .header {
        font-family: $Roboto-Medium;
        padding: 12px 0 13px 0;
        border-top: 1px solid $color-black-10;
        border-bottom: 1px solid $color-netxwealth-news-research-grey-border;
        box-sizing: border-box;
    }
    .body {
        border-top: 1px solid $color-black-10;
        padding: 12px 0 13px 0;
        font-family: $Roboto-Regular;
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        .strikeLabel {
            width: 60%;
            padding-left: 30px;
            text-align: center;
            display: inline-block;
            box-sizing: border-box;
        }
        .strikeIcon {
            width: 40%;
            display: inline-block;
            > button {
            border: none;
            margin: 0px;
            padding: 0px;
            background: transparent;
            .icon {
                width: 14px;
                height: 13px;
                color: $color-dark-orange-3;
            }
            }
        }
    }
}


@media screen and (min-width: 595px) and (max-width: 1024px) { 
    .strikeColumnContainer {
        -moz-box-shadow: 0 0 8px 4px $color-white-whisper;
        -webkit-box-shadow: 0 0 8px 4px $color-white-whisper;
        box-shadow: 0 0 8px 4px $color-white-whisper;
        position: relative;
    }
}

@media screen and (max-width: 595px) { 
    .strikeColumnContainer {
        -moz-box-shadow: 4px 1px 4px 3px $color-white-whisper;
        -webkit-box-shadow: 4px 1px 4px 3px $color-white-whisper;
        box-shadow: 4px 1px 4px 3px $color-white-whisper;
        position: relative;
    }
}

