@import '../../../../styles/variables';
.baseballEventsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    .leftItem{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .rightItem {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 50%;
    }
    .textDesc {
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
        width: 65px;
    }
    .noDesc {
        padding-top: 5px;
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
    }
}
