@import "../../../../styles/variables";

.TopInstitutionalHoldersContainer {
    .headerContainer {
        font-family: $Roboto-Light;
        font-size: 24px;
        color: $color-black;
        line-height: 30px;
    }
    .tableParent {
        margin: 20px 0px 30px 0px;
    }
}
