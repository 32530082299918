@import '../../../../styles/variables';

.stockContainer {
    display: flex;
    flex-direction: column;
    min-width: 183px;
    padding-bottom: 9px;
    font-family: $Roboto-Regular;
    font-size: 12px;
    color: $color-black;
    line-height: 18px;

    .namePart {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-black;
        line-height: 18px;
        padding-bottom: 7px;
    }

    .nameText {
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-black;
        line-height: 18px;
    }

    .dot {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 6px;
        margin-bottom: 1px;
    }

    .dotStock {
        background: $color-bar-graph-blue-dark;
    }

    .dotETF {
        background: $color-dividend-performance-area-blue;
    }

    .dotMF {
        background: $color-dark-orange;
    }

    .dotOption {
        background: $color-light-green;
    }

    .numberStyle {
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-black;
        text-align: right;
        line-height: 18px;
        padding-right: 15px;
    }
}

@media(max-width: 1023px) {
    .stockContainer {
        min-width: 133px;
    }
}