@import "../ETFResearch/ETFResearch.module.scss";

.fundResearchContainer{
    background-color: $color-white;
    .fundQuoteBarContainer{
        padding: 0 10px;
        &>div {
            margin-bottom: 20px !important;
        }
    }
    .actionsContainer{
        display: inline-block;
        float: right;
        margin-bottom: -40px;
    }
    .snapshotContainer{
        .marginSetter{
            &>div {
            margin-top: 10px !important;
            }
        }
    }
    .holdingAndAllocationContainer {
        .leftSectionAdjust {
            &>div {
                margin-top: 30px !important;
            }
        }
        .summaryTable{
            background: #ffffff;
            box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
            border-radius: 3px;
            width: 100%;
        }
    }
    .scoreAndRatingContainer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 30px;
        padding-top: 30px;
        display: flex;
        flex-direction: row;
        .leftSectionContainer{
            width: 66%;
            margin-right: 2%;
        }
        .rightSectionContainer{
            width: 32%;
        }
    }
    .operationalIndicators{
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
        border-radius: 3px;
        background: $color-white-opacity;
    }
    .sharpeRatioContainer {
        min-height: 350px;
    }
    .snapshotShareClass {
        margin-bottom: 27px;
    }
    .snapshotRating {
        margin-top: 10px !important;
    }
    .headerHighlightTable{
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 34px;
        font-family: $Roboto-Regular;
        color: #000000;
        margin-bottom: 20px;
    }
    @media (min-width: 596px) and (max-width: 1023px), only screen and (min-device-width: 596px) and (max-device-width:1023px) {
        .etfResearch{
                margin-top: 10px;
        }
        .styleBoxContainer{
            .styleBoxAlign {
                display: flex;
                &>div {
                    margin-right: 10px;
                }
            }
        }
    }
    @media (min-width: 375px) and (max-width: 595px), only screen and (min-device-width: 375px) and (max-device-width:595px) {
        .etfResearch{
            padding: 10px !important;
        }
        .actionsContainer{
            float: left;
            margin-top: -55px;
        }
        .styleBoxContainer{
            .styleBoxAlign {
                display: block;
                &>div {
                    margin-right: 0px;
                }
            }
        }
    }
    @media (min-width: 375px) and (max-width: 1023px), only screen and (min-device-width: 375px) and (max-device-width:1023px) {
       .profile{
           margin-top: 30px;
       } 
       .scoreAndRatingContainer {

        flex-direction: column;
        .leftSectionContainer{
            width: 100%;
        }
        .rightSectionContainer{
            width: 100%;
        }
       }
       .snapshotContainer{
            .marginSetter{
                &>div {
                margin-top: 10px !important;
                }
            }
        }
        .fundQuoteBarContainer{
            &>div {
                margin-bottom: 70px !important;
            }
        }
    }
}