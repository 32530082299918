@import "../../../styles/variables";

.rangeSelector {
    // padding: 20px 15px;
    width: 100%;
    top:0;

    .header {            
        position: relative;
        height: 26px;
        font-family: $Roboto-Medium;        
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 15px;
        color: #000000;
        display: flex;
        justify-content: space-between;
    }
 
    .slider {
        position: relative;
        top: 40px;
    }
     div.inputItem {
        label {
            font-family: $Roboto-Medium;            
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            margin-right:10px
        }
        div{
            font-size:12px;
            line-height: 16px;
            margin-left:32px;
            color:#000000;
            .icon{
            color:#E40707;  
            height: 13px;
            font-size:12px;
            line-height: 13px;       
            margin-right:5px;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    input[type=number] {
        height: 28px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 3px;
        width: 100px;
        font-size: 12px;
        line-height: 16px;
        margin-right:33px;
        padding-right: 9px;
        text-align: right;      
                
    }
    input[type=number].err{
        background: #FFFFFF;
        border: 1px solid #FF0000;
        box-sizing: border-box;
        border-radius: 3px;
                    
    }
    input[type=number].err:focus-visible{
        border: 1px solid #FF0000 !important;
    } 
    .maxerrmsg{
        // right: 20px; // PRW-960
        // position: absolute; // PRW-960
        // margin-right: 295px; // PRW-960
    }
    .maxerrmsgforL3{
        right: 0px;
        position: absolute;
    }
}
.dateRangeCalendarContentContainer {
    width: 75%;
    position: relative;
    margin-left: 50px;
    & > div:first-child {
      width: 100%;
      height: 100%;
      margin: 0;
    }
    & > div:first-child > div,
    input {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      border: 0px solid rgba(0, 0, 0, 0.3);
    }
    input {
      padding-left: 10px;
      margin-left: 10px;
    }
}
}

.rangeSelectorLink{
    height: 18px;
    top: 6px;
    font-family: $Roboto-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    right: 0;
}

.listRange{
  padding-left: 0;
  height: auto !important;
  overflow-y: auto !important;
}

.listCheckbox{
  padding: 0.6em 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  height: 38px;
  align-items: center;
}

.checkboxLabel {
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0px !important;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked {
        ~ {
          .checkmark {
            border: 0px solid;
            background-color: #48b748;
            &:after {
              display: block;
            }
          }
        }
      }
    }    
    .checkmark {
      &:after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

  }

  .checkmark {
    position: absolute;
    left: 15px;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 1px solid black;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  .chkLabel {
    display: block;
    padding-left: 40px;
    margin-top: -9px;
  }

.radioListItem {  
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;  
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);   
  }
  .button {
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    border: none;
    background: transparent;
    cursor: pointer;
    margin-left: -13px;
}

@media screen and (min-width: 375px) and (max-width: 596px) {
    .rangeSelector {
        div.inputItem {
            input[type=text] {
                width:90px;
                font-size: 14px;                    
            }
            .maxerrmsg{
                right: 27px;
                margin-right: -27px !important;
            }
            .maxerrmsgforL3{
                right: 36px;
            }
            input[type=number] {
                height: 28px;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.3);
                box-sizing: border-box;
                border-radius: 3px;
                width: 100px;
                font-size: 12px;
                line-height: 16px;
                // margin-right:33px;
                padding-right: 9px;
                text-align: right;      
                        
            }
            
        }
    
    }
}

@media screen and (min-width: 596px) and (max-width: 686px) {
    .rangeSelector {   
        div.inputItem {                 
        .maxerrmsg{   
            margin-right: 195px !important;
        }  
        .maxerrmsgforL3{   
            margin-right: 219px !important;
        }   
     }              
    }
}
