@import '../../../styles//variables';

.tabControl {
	display: inline-flex;
	.tabItems {
		padding: 14px;
		padding-top: 25px;
	}
	.tabLink {
		text-decoration: none;
		border: none;
		white-space: nowrap;
		font-size: 14px;
    	line-height: 18px;
		text-align: center;
		cursor: pointer;
	}
	.tabHighlight {
		border-bottom: 3px solid #70BBFF;
		padding-bottom: 8px;
		font-weight: 500;
        color: $color-black;
	}
}