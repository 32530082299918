@import '../../../../styles/variables';

.esgGlossaryContainer {
    .searchIconWrapper {
        display: flex;
        position: relative;
        padding: 20px;
        margin-left: -20px;
        margin-right: -20px;
        align-items: center;
        background: #F7F7F7;

        .searchIcon {
            position: absolute;
            color: $color-netxwealth-news-research-grey-border;
            z-index: 1;
            display: inline-block;
            padding-left: 15px;
            font-size: 18px;
            svg{
                font-weight: 300;
                height: 18px;
                width: 18px;
            }
        }

        .searchInput {
            padding-left: 36px;
            width: 100%;
            height: 47px;
            border-radius: 3px;
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 26px;
            color: $color-black;
            border: none;
            box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1), 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
        }

        ::placeholder {
            font-size: 20px;
            color: $color-netxwealth-news-research-grey-border;
            font-style: normal;
            font-weight: 300;
            font-family: $Roboto-Regular;
            line-height: 26px;
        }

        .clearButton {
            right: 25px;
            position: absolute;
            font-size: 14px;
            line-height: 51px;
            text-align: right;
            color: $color-blue;
            z-index: 2;
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: 500;
            cursor: pointer;
        }
    }

    .searchIconWrapper input[type=text]:focus {
        border: 2px solid #2FDAFF;
        outline: #2FDAFF;
    }

    .searchResultsContainer {
        position: relative;
        background: $color-white;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        width: 100%;

        .searchResults {
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $color-gray;
            padding: 15px 15px 0 15px;
        }

        .result {
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $color-black;
            margin: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #E5E5E5;
            cursor: pointer;
        }

        .lastResult {
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $color-black;
            padding-bottom: 15px;
            margin: 15px;
            cursor: pointer;
        }
    }

    .terms {
        margin-top: 30px;

        .header {
            font-family: $Roboto-Bold;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $color-black;
        }

        .definition {
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $color-black;
            word-break: break-word;
        }
    }
}