@import '../../../styles/variables';

.customSelectContainer {
    background: transparent;
    border: 1px solid $color-light-gray !important;
    border-radius: 3px;
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: $color-black;
    line-height: 18px;
    padding: 6px 8px;
    display: inline-flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    cursor: pointer;
    width: 151px !important;

    .preFixLabel {
        margin-right: 10px;
    }
    .postFixLabel {
        margin-left: 5px;
    }
    .icon {
        font-size: 12px;
        margin-right: 10px;
    }
}
.paletteContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 138px;
    padding: 7px;
}
.box {
    border-radius: 1px;
    width: 25px;
    height: 18px; 
    margin: 3px;
    button {
        height: inherit;
        width: inherit;
    }
}
.hide{
    background: transparent;
    border:transparent;
}
.selected{
    border:1px solid;
    width: 30px;
    height: 21px; 
  }
  .rec{
    width: 25px;
    height: 15px; 
    background-color:red;
    border: 1px solid red
  }

.popoverContainer {
    position: absolute;
    top: -6px;
    left: 18px;

    div[data-popover-body-container="true"] {
        padding: 0px !important;
    }
}
