@import '../../../../styles/variables';

.container {
    background-color: #fff;
    padding: 0px 20px 0px 20px;

    .heading {
        padding: 5px;
        background-color: #ddd;
    }

    input {
        border: 1px solid #B2B2B2 !important;
        border-radius: 3px;
        height: 35px;
        padding: 4px;
        outline: none;
        width: 100%;    
        box-sizing: border-box;
    }

    .valuesContainer {
        padding: 15px 5px 0px 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .label {
            width: 40%;
        }

        .valueContainer {
            width: 60%;

            button {
                width: 100% !important;
                height: 35px;
                padding-left: 3px;
            }
        }
    }
}
.buttonContainer{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 15px 5px 20px 5px;
    button[data-testid='applyButton'] {
        border: none
    }
    button[data-testid='cancelButton']{ 
        background: $color-white;
        color: $color-blue;
    }
}