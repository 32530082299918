@import "../../../styles/variables";

.mindEventsContainer {
    .header {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        padding-bottom: 19px;
    }
}