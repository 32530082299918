@import "../../../../styles/variables";

.divIncreaseContainer {
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    width: 312px;
    height: 130px;
    padding: 15px 15px 20px 15px;
    display: flex;
    justify-content: space-between;
    .leftSection {
       
        min-width: 175px;
        .header {
            font-family: $Roboto-Regular;
            font-size: 16px;
            color: $color-gray;
            line-height: 22px;
            padding-bottom: 5px;
        }
        .middle {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding-bottom: 15px;
            .symbolName {
                font-family: $Roboto-Bold;
            }
        }
        .dates {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-gray;
            line-height: 18px;
            button {
                border: none;
                margin: 0;
                padding: 0;
                background: transparent;
                font-size: 14px;
                line-height: 18px;
                font-family: $Roboto-Medium;
            }
            .errorMessage{
                color: $color-red-text;
            }
        }
    }
    .rightSection {
        .rightContainer{
            width: 110px;
            text-align: center;
            .rightContainerImage{
                text-align: left;
                padding-left: 10px;
                padding-bottom: 5px;
            }
            .providerName{
                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-gray;
                line-height: 16px;
                text-align: left;
                padding: 0px 0px 2px 10px;
            }
        }
    }
    
}