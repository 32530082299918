@import '../../../../styles/variables';

.cardContainer {
  background: $color-white;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
  border-radius: 3px;
  width: 100%;
  height: 137px;

  .cardHedaer {
    padding: 11px 10px 11px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .favoriteContainer{
      
      .iconArrowRight {
        font-size: 14px;
        color: #FFA000;
        text-align: center;
        margin-left: -5px;
        margin-bottom: 2px;
        margin-right: 3.5px;
        line-height: 14px;
        // position: relative;
      }
    }

    .nameContainer {
      width: 90%;
      display: flex;
      align-items: center;
      
      .name{
        font-family: $Roboto-Bold;
        font-size: 16px;
        color: #000000;
        line-height: 22px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;

        >div {
          display: inline;
        }
      }

      button {
        background-color: transparent;
        border: none;
        outline: none;
        margin: 0;
        padding: 0;
        left: 2px;
        cursor: pointer;

        .info {
          height: 14px;
          width: 14px;
        }
      }

      div[id="bottom-popover"] {
        width: 235px;
        margin-left: 30px;
        word-break: break-word;
      }
    }

    .btnAction {
      display: flex;
    }
    .btnActionElement {
        min-width: 83px;
    }
    .createWatchlistsButton {
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-blue;
        text-align: right;
        line-height: 18px;
        border: none;
        background: none;
        white-space: nowrap;
        cursor: pointer;
    } 
  }

  .cardBody {
    border-top: 1px solid rgba(0, 0, 0, 0.15);

    .symbolCountsContainer {
      width: 25%;
      padding: 8px 15px;
      display: inline-block;
      box-sizing: border-box;

      .label {
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: #666666;
        line-height: 16px;
      }

      .value {
        font-family: $Roboto-Regular;
        font-size: 16px;
        color: #000000;
        line-height: 22px;
      }
    }
  }
}
.cardCarouselContainer {
  width: 23%;
  margin: 0 1.2%;
}
.cardCarouselContainer:first-child{
  margin-left: 0px;
}
.cardCarouselContainer:last-child{
  margin-right: 0px;
}

.cardViewContainer {
  .cardHedaer {
    height: 40px;
  }
}

.smallViewContainer {
  width: 100% !important;
  height: 44px !important;

  .nameContainer {
    display: flex;
    align-items: center;
    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 85%;
      display: inline-block;
    }

    button {
      top: -6px;
      cursor: pointer;
    }
  }
}

@media(max-width: 1023px) {
  .cardContainer {

    .cardHedaer {
      .nameContainer {
        width: 70%;
      }
      .btnAction {
        width:25%;
        align-items: center;
      }
    }
    .cardBody {
      padding: 10px 0px 15px 0px;

      .symbolCountsContainer {
        padding: 4px 15px 3px 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .label {
          font-family: $Roboto-Regular;
          font-size: 12px;
          color: #000000;
          line-height: 18px;
        }

        .value {
          font-family: $Roboto-Medium;
          font-size: 12px;
          color: #000000;
          text-align: right;
          line-height: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
  .cardContainer {
    height: 164px;
    width: 100%;
    height: 44px;

    .cardHedaer {
      justify-content: unset;
      .nameContainer {
        display: flex;
        align-items: center;
        width: 60%;
        
        .name{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 85%;
          display: inline-block;
        }

        button {
          top: -6px;
          cursor: pointer;
        }
      }
    }
  }
  
  .cardCarouselContainer{
    width: 31.33%;
    height: 172px;
}
}

@media (min-width: 375px) and (max-width: 595px) {

  .cardContainer{
    width: 98% !important;
    height: auto;
    .cardHedaer{
      height: auto;
      .btnAction{
        width: 30% !important;
      }
    }
    .cardBody {
      display: none;
    }
  }

  .iconArrowRight {
    margin-bottom: 2px;
  }
  
  .cardCarouselContainer{
    width: 100%;
    margin: 0 0.5%;
    box-shadow: inset 0px 0px 3px 1px rgba(0,0,0,0.10);
  }
}
