@import "../../../styles/variables";
.modalPopUpContentContainer {
  display: flex;
  justify-content: space-between;
  #newsArticleQuoteChartPopUp {
    display: inline-block;
    width: 220px;
    box-shadow: none;
    text-align: unset;
    span[data-value="customDivider"] {
      font-size: 18px;
      margin-right: -4px;
      padding-left: 8px;
      color: #666666;
    }
    & > div:first-child {
      position: relative;
    }
    & > div:first-child > span {
      position: absolute;
      right: 0px;
      top: 10px;
      z-index: 1;
    }
  }
  #quoteCardNews {
    display: inline-block;
    width: 220px;
    box-shadow: none;
    text-align: unset;
    & > div:first-child {
      position: relative;
    }
    & > div:first-child > span {
      position: absolute;
      right: 0px;
      top: 10px;
      z-index: 1;
    }
  }
  .articleNewsDetailsContainer {
    width: 60%;
    padding-right: 15px;
    margin-top: -20px;
    .articleNewsDetailsProvider {
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      margin-bottom: 10px;
      word-break: normal;
    }
    .articleNewsDetailsHeading {
      font-family: $Roboto-Regular;
      font-size: 16px;
      color: #000000;
      line-height: 20px;
      padding: 0 0 10px 0;
      word-break: normal;
    }
    .articleNewsDetailsDescription {
      margin-bottom: 40px;
    }
    .formatFullStory {
      font-family: $Roboto-Regular;
      font-size: 14px;
      color: #666666;
      line-height: 18px;
      word-break: break-word;
      white-space: pre-wrap;
      padding-right: 5px;
      text-align: left;
      p {
        margin: 0;
      }
    }
    .articleNewsDetailsRelatedTags {
      float: left;
      width: 100%;
      padding-bottom: 20px;
    }
    .articleNewsDetailsRelatedTagsText {
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      display: inline-block;
      margin-right: 8px;
    }
    .articleNewsDetailsTagName {
      display: inline-block;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: #666666;
      text-align: center;
      line-height: 18px;
      padding: 1px 10px 3px 10px;
      margin: 2px 4px 2px 0px;
    }
  }
  .articleNewsContainer {
    width: 100%;
    padding-right: 15px;
    .articleNewsDetailsProvider {
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      margin-bottom: 10px;
      word-break: normal;
    }
    .articleNewsDetailsHeading {
      font-family: $Roboto-Regular;
      font-size: 16px;
      color: #000000;
      line-height: 20px;
      padding: 0 0 10px 0;
      word-break: normal;
    }
    .articleNewsDetailsDescription {
      margin-bottom: 40px;
    }
    .formatFullStory {
      font-family: $Roboto-Regular;
      font-size: 14px;
      color: #666666;
      line-height: 18px;
      word-break: normal;
      white-space: pre-wrap;
      padding-right: 5px;
      text-align: left;
      p {
        margin: 0;
      }
    }
    .articleNewsDetailsRelatedTags {
      float: left;
      width: 100%;
      padding-bottom: 20px;
    }
    .articleNewsDetailsRelatedTagsText {
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      display: inline-block;
      margin-right: 8px;
    }
    .articleNewsDetailsTagName {
      display: inline-block;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: #666666;
      text-align: center;
      line-height: 18px;
      padding: 1px 10px 3px 10px;
      margin: 2px 4px 2px 0px;
    }
  }
  
  @media (min-width: 596px) and (max-width: 1024px) {
      #newsArticleQuoteChartPopUp {
        display: inline-block;
        width: 220px;
        box-shadow: none;
        text-align: unset;
        // margin-left: 40%; // I don't seee this as a good practice
        span[data-value="customDivider"] {
          font-size: 18px;
          margin-right: -4px;
          padding-left: 8px;
          color: #666666;
        }
        & > div:first-child {
          position: relative;
        }
        & > div:first-child > span {
          position: absolute;
          right: 0px;
          top: 10px;
          z-index: 1;
        }
      }

      #quoteCardNews {
        display: inline-block;
        width: 220px;
        box-shadow: none;
        text-align: unset;
        margin-left: 40%;
        & > div:first-child {
          position: relative;
        }
        & > div:first-child > span {
          position: absolute;
          right: 0px;
          top: 10px;
          z-index: 1;
        }
      }
    
    .articleNewsDetailsContainer {
      width: 59%;
      .articleNewsDetailsDescription {
        margin-bottom: 12px;
      }
    }
  }

  @media (min-width: 375px) and (max-width: 596px) {
    .articleNewsDetailsContainer {
      .articleNewsDetailsDescription {
        margin-bottom: 30px;
      }
    }
    #newsArticleQuoteChartPopUp {
      display: none;
    }
    #quoteCardNews {
      display: none;
    }
  }
}

.loaderContainer {
  justify-content: center;
}
