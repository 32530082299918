@import "../../../../styles/variables";

.companyDescriptionModalHeader{
    display: flex;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    position: relative;
    z-index: 999;
    height: 48px;
    width: 100%;
    background-color: $color-dark-gray;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    .companyDescriptionHeaderText{
        width: 92%;
        margin-left: 20px;
        color: $color-white;
        font-size: 20px;  
        line-height: 26px;
        font-family: $Roboto-Regular;
    }
    .companyDescriptionPopUpClose {
        border: none;
        background-color: rgba(255, 255, 255, 0);
        color: $color-white;
        outline: none;
        background-repeat: no-repeat;
        margin-right: 5px;
        width: 15px;

        font-size: 18px;                
        text-align: center;
        line-height: 18px;
        cursor: pointer;
        float:right;
    }
}