@import "../../../styles/variables";

.controversiesContainer {
    background: $color-white-opacity;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 15px !important;

    .header {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: $color-black;
        border-bottom: 1px dashed $color-black;
        cursor: pointer;
    }

    .header .popup {
        visibility: hidden;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $color-black;
        position: absolute;
        z-index: 1;
        background: $color-white;
        box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        max-width: 170px;
        padding: 15px !important;
        white-space: break-spaces;
    }

    .header:hover .popup {
        visibility: visible;
    }

    .bar {
        margin-top: 15px !important;
    }

    .tableContainer {
        .ipadTable {
            width: 100%;
            background: $color-white-opacity;

            .row {
                display: flex;
                align-items: center;
            }

            .divider {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            .icon {
                width: 8px;
                height: 8px;
            }

            .tableLeftDiv {
                float: left;
                width: 49%;
            }

            .tableRightDiv {
                float: right;
                width: 49%;
            }
        }
    }
}
.NXIControversiesContainer {
    background: #fff;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 15px !important;

    .header {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        border-bottom: 1px solid $color-light-gray-5;
        padding-bottom: 10px;
    }

    .bar {
        margin-top: 20px !important;
    }

    .tableContainer {
        .divider {
            border-bottom: none !important;
        }

        .row {
            border-bottom: none !important;

        }

        .ipadTable {
            width: 100%;
            background: $color-white-opacity;

            .row {
                display: flex;
                align-items: center;

                .circle {
                    width: 10px;
                    height: 10px;
                    border-radius: 25%;
                    margin-right: 5px;
                    align-self: center;
                }
            }

            .icon {
                width: 8px;
                height: 8px;
            }

            .tableLeftDiv {
                float: left;
                width: 49%;
            }

            .tableRightDiv {
                float: right;
                width: 49%;
            }
        }
    }
}