@import '../../styles/variables';

.watchlistCreateContainer {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    .item {
        padding: 0 20px 0 20px !important;

        input {
            width: 100%;
            height: 34px;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
            background: $color-white;
            border: 1px solid $color-light-gray;
            border-radius: 3px;
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            font-weight: 400;
        }
        .inputBox:focus {
            outline-color: $color-graph-light-blue1;
        }

        .errorBox:focus {
            outline-color: #ff0000;
            
        }
        input::placeholder {
            color: $color-netxwealth-news-research-grey-border;
            font-size: 14px;
        }
        textarea {
            resize: none;
            width: 100%;
            padding: 10px 10px 10px 10px;
            box-sizing: border-box;
            background: $color-white;
            border: 1px solid $color-light-gray;
            border-radius: 3px;
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            font-weight: 400;
        }

        textarea::placeholder {
            resize: none;
            color: $color-netxwealth-news-research-grey-border;
            font-size: 14px;
        }

        .searchLabel {
            padding-top: 12px;
            padding-bottom: 5px;
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-black;
            line-height: 16px;
            font-weight: 400;
        }
            .searchResults {
                position: absolute;
                z-index: 100;
                display: flex;
                flex-direction: column;
                background: $color-white;
                width: 442px !important;
                border-bottom: 1px solid $color-light-gray;
                box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.05), 1px 2px 6px 2px rgba(0,0,0,0.05);
                .searchResultsDiv{
                    overflow-y:auto;
                    max-height:150px;
                    padding-bottom: 10px;
                    padding-left: 10px;
                }
                .resultsHeader {
                  font-family: $Roboto-Medium;
                  font-size: 14px;
                  color: $color-black;
                  line-height: 18px;
                  padding: 10px 0px 10px 5px;
                  font-weight: 500;
                }
                .checkBoxContainer {
                  display: flex;
                  margin-bottom: 8px;
                  label{
                      margin-top: 6px;
                      padding-bottom: 2px;
                  }
                  
                  div[data-value='label-checkBox']{
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-blue;
                    line-height: 18px;
                    font-weight: 400;
                    align-items: end;
                    padding-bottom: 3px;
                    width: 95px;
                    display: none;
                  }
                  button[data-value='label-symbol']{
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-blue;
                    line-height: 18px;
                    font-weight: 400;
                    display: flex;
                    align-items: end;
                    padding-bottom: 3px;
                    margin-left: 15px;
                    margin-right: 15px;
                  }
                  .searchDescription{
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    padding-top: 1px;
                  }
                  input[type='checkbox'] {
                    visibility: hidden;
                  }
                }
                .searchFooter {
                    border-top: 1px solid rgba(0,0,0,0.30);
                    padding: 10px 0;
                    .apply {
                      background: $color-blue;
                      color: $color-white;
                      margin: 5px 0px 5px 25px;
                      width: 150px;
                      font-family: $Roboto-Medium;
                      height: 28px;
                      font-size: 12px;
                    }
                  }
              }


    }

    .validationErrorMsg {
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-black;
        line-height: 16px;
        font-weight: 400;
        min-height: 20px !important;
        word-break: break-word;     
        svg {
            margin-right: 5px;
            font-size: 12px;
            text-align: center;
            line-height: 13px;
            font-weight: 900;
        }   
    }

    .watchlistTable {
        margin-top: 21px;
    }

    .noResults {
        display: flex;
        font-family: $Roboto-Regular;
        font-size: 14px;
        line-height: 18px;
        text-align: Left;
        vertical-align: Top;
        padding: 0px 0px 0px 10px;
        color: #000000;
    }
    .buttonContainer {
        width: 100%;
        padding: 15px 0px 19px 0px !important;
     
        border-radius: 3px;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .buttons {
        width: 100%;
        text-align: center;
        padding-right: 8px;
        height: auto;

        .save {
            margin-top: 5px;
            background-color: $color-blue;
            color: $color-white;
            margin-right: 10px;
        }

        .reset {
            margin-right: 12px;
            margin-top: 5px;
            color: $color-blue;
            margin-bottom: 5px;
            background: $color-white;
            border: 1px solid rgba(0, 0, 0, 0.30);
            border-radius: 3px;
        }

        .alertIcon {
            width: 12px;
            height: 12px;
        }
    }
    .bottomGroup{
        margin-top: 20px;
    }

    .footerContainer {
        border-top: 1px solid rgba(0,0,0,0.30);
        padding: 15px 0px 19px 0px !important;
        text-align: center;
        button {
            height: 34px;
            width: 70px;
        }
    }

    .red {
        color: $color-red-text;
    }

    .green {
        color: green;
    }
}

@media screen and (max-width:596px) {
    .watchlistCreateContainer {
        .item {
            padding: 0 10px 0 10px !important;
        }
        .bottomGroup {
            position: fixed;
            width: 100%;
            bottom: 0;
        }
        .btnContainer {
            width: 100% ;
            position: relative;
            padding-bottom: 47px !important;
            ul {
                width: 100% ;
                right: 1px;
                div {
                    padding-top: 3px;
                    border-radius: 3px;
                }
                li:nth-child(1) {
                    height: 34px;
                    padding: 4px 13px 8px 10px;
                }
            }
        }
        .footerContainer {
         
            position: fixed;
            bottom: 0;
            width: 100%;
            padding-bottom: 20px !important;
        }
    }
}
@media screen and (max-width: 376px) {
    .watchlistCreateContainer {
        .item {
            .searchResults {
                .searchResultsDiv {
                    .checkBoxContainer {
                            label {
                                margin-top: 6px;
                            }
                    }
                }
            }
        }
    }

}