@import '../../../../styles/variables';

.carbonRiskScoreRatingContainer {
    display: flex;
    flex-direction: column;
    .title{
        font-family: $Roboto-Regular;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
        display: block;
    }
    .headerContainer {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;        
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        .labelContainer {
            display: flex;
            flex-wrap: nowrap;
            margin-right:20px;
            .circle{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 5px;
                align-self: center;
            }
            .line{
                width: 12px;
                height: 2px;
                margin-top: 7px;
                margin-right: 5px;
                background: #BDBDBD;
            }
        }
    }
    .scoreBarContainer{
        margin-bottom: 20px;
    }
}

.NXICarbonRiskScoreRatingContainer {
    display: flex;
    flex-direction: column;

    .title {
        font-family: $Roboto-Regular;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
        display: block;
    }

    .headerContainer {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        color: $color-black-solid;

        .header {
            font-family: $Roboto-Bold;
            font-style: normal;
            font-size: 16px;
            line-height: 16px;
            color: $color-black-solid;
            border-bottom: 1px dashed $color-black;
            cursor: pointer;
        }

        .header .popup {
            visibility: hidden;
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $color-black;
            position: absolute;
            z-index: 999999;
            background: $color-white;
            box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            max-width: 170px;
            padding: 15px;
            white-space: break-spaces;
        }


        .header:hover .popup {
            visibility: visible;
        }

        .labelContainer {
            display: flex;
            flex-wrap: nowrap;
            margin-right: 20px;

            .circle {
                width: 10px;
                height: 10px;
                border-radius: 25%;
                margin-right: 5px;
                align-self: center;
            }

            .icon2 svg{
                padding-right: 5px;
                margin-top: 2px;
            }

            .icon3 {
                height: 11px;
                margin-top: 3px;
                padding-right: 5px;
                border-left: 2px solid $color-blue-solid-2;
            }

            .line {
                width: 10px;
                height: 2px;
                margin-top: 7px;
                margin-right: 5px;
                background: #BDBDBD;
            }
        }
    }

    .scoreBarContainer {
        margin-bottom: 20px;
    }
}

@media (min-width: 375px) and (max-width: 595px), only screen and (max-device-width: 595px) {
    .carbonRiskScoreRatingContainer {
      .labelContainer {
         margin-bottom: 15px;
      }
  }
  .NXICarbonRiskScoreRatingContainer {
    .labelContainer {
        margin-bottom: 15px;
    }
}
}
