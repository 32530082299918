@import "../../../../styles/variables";

.divIncreaseContainer {
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    width: 312px;
    height: 130px;
    padding: 15px 15px 20px 15px;
    display: flex;
    justify-content: space-between;
    .leftSection {
       
        min-width: 175px;
        .header {
            font-family: $Roboto-Regular;
            font-size: 16px;
            color: $color-gray;
            line-height: 22px;
            padding-bottom: 5px;
        }
        .middle {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding-bottom: 15px;
        }
        .dates {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-gray;
            line-height: 18px;
            display: flex;
            justify-content: space-between;
        }
    }
    .rightSection {
        div[class="ct-chart "] {
            margin-left: -21px;
        }
        background: $color-white;
        svg[class="ct-chart-bar"] {
        max-height: 110px;
        min-width: 142px;
            g[class="ct-grids"] {
                line[class="ct-grid ct-vertical"] {
                    stroke: rgba(0,0,0,0.10);
                    stroke-dasharray: 0px;
                    
                }
                line[class="ct-grid ct-vertical"]:first-child {
                    stroke: rgba(0,0,0,0.30);
                    stroke-dasharray: 0px;
                }
                line[class="ct-grid ct-vertical"]:first-of-type{
                    stroke: rgba(0, 0, 0, 0.30);
                }
            }
            g[class="ct-series ct-series-a"] {
                line[class="ct-bar"] {
                    stroke: $color-dividend-bar-chart-blue;
                    stroke-width: 25px;
                }
            }
            g[class="ct-series ct-series-b"] {
                line[class="ct-bar"] {
                    stroke:$color-dividend-bar-chart-blue;
                    stroke-width: 25px;
                }
            }
            g[class="ct-labels"] {
                foreignObject{
                    span[class="ct-label ct-vertical ct-start"] {
                        display: none;
                    }
                }
            }
            g[class="ct-labels"] {
                span[class="ct-label ct-vertical ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    line-height: 16px;
                    justify-content: flex-end;
                }
            }
        }
    }
    
}