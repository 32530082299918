@import '../../../../styles/variables';

.chartToolsMenu {
    width: 190px;
    height: 319px;
    background: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
    border-radius: 3px;
    outline: none;
    padding: 10px;
    overflow: auto;
    left: -6px;
    
    .heading {
        background: #F7F7F7;
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: #666666;
        line-height: 16px;
        padding: 6px 10px;
    }

    ul {
        list-style-type: none;
        padding-left: 10px;
        outline: none;

        li {           
            outline: none;
            min-height: 18px;
            line-height: 18px;
            cursor: pointer;
            margin-top: 8px;
            display: flex;
            align-items: center;

            .toolLabel {
                font-size: 14px;
                color: #000000;
                line-height: 18px;
                font-family: $Roboto-Regular;
                width: 90%;
                display: inline-block;
            }
            
            .starIcon {
                font-size: 12px;
                color: #000000;
                text-align: center;
                line-height: 20px;
            }
        }
        .noFavourites {
            font-size: 12px;
            color: #666666;
            line-height: 18px;
            font-family: $Roboto-Regular;
            width: 90%;
            display: inline-block;
            text-align: center;
            padding-top: 15px;
        }
    }
}