@import "../../../../../../styles/variables";

.etfRatingsContainer {
    padding: 15px 15px 15px 15px;
    background: $color-white-opacity;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    .header {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        margin-bottom: 15px;
    }

    .etfRatings {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 6px 6px;
        width: 98%;
        .disabled {
            opacity: 0.5;
        }
        .breakTile {
            grid-column: 1 / 3;
          }
        .tileContainer {
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            background: $color-white;
            padding: 10px;
            .tilesDetails {
                display: flex;
                flex-direction: row;
                .tilesSubDetails {
                    margin-top: 8px;
                    flex: 50%;
                    display: flex;
                    flex-direction: column;
                    .tilesRightSecDetails {
                        display: flex;
                        flex-direction: row;
                        .tilesRightSubDetails {
                            flex: 50%;
                        }
                    }
                }
            }
            .tileHeader {
                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-gray;
                line-height: 16px;
                margin-bottom: 4px;
                height: 16px;
                font-style: normal;
                font-weight: 500;
            }
            
            .titleText {
                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-netxwealth-news-research-grey-border;
                line-height: 16px;
                margin-bottom: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin:0px;outline:none;
            }
            .titleValue {
                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-black;
                line-height: 30px;
                margin-bottom: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .textMaster {
                font-family: $Roboto-Regular;
                font-size: 24px;
                color: $color-black;
                line-height: 30px;
                margin-bottom: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .rating {
                    display: inline-block;
                    padding-right: 2px;

                    svg {
                        width: 18px;
                        height: 18px;
                        font-size: 16px;
                    }
                }

                .lockStyle {
                    .lockText {
                        display: inline-block;
                        opacity: 0.3;
                        font-family: $Roboto-Regular;
                        font-size: 24px;
                        color: $color-black;
                        line-height: 30px;
                    }

                    .lock {
                        display: inline-block;
                        padding-left: 5px;
                        position: relative;
                        top: -2px;

                        svg {
                            font-size: 18px;
                            width: 16px;
                            height: 18px;
                            line-height: 18px;
                        }
                    }
                }
            }

            .report {
                button {
                    border: none;
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    font-size: 12px;
                }
            }

            .asOfDate {
                margin-top: 4px;
                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-netxwealth-news-research-grey-border;
                line-height: 16px;
            }
        }

        .comingSoonContainer {
            border: 1px solid $color-netxwealth-news-research-grey-border;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 122px;

            .processIcon {
                padding-bottom: 8px;
                display: inline-block;

                svg {
                    width: 14px;
                    height: 18px;
                    font-size: 18px;
                    line-height: 18px;
                }
            }

            .reportComingSoon {
                display: inline-block;
                font-family: $Roboto-Light;
                font-size: 12px;
                color: $color-light-gray-3;
                line-height: 16px;
            }
        }
    }
}

.text {
    height: 16px;
    font-family: $Roboto-Regular;
    ;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray;
    margin-top: 15px;
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .etfRatingsContainer {
        width: 100%;

        .etfRatings {
            grid-template-columns: 1fr 1fr 2fr 1fr;
            grid-gap: 6px 3px;

            .tileContainer {
                .textMaster {
                    margin-top: 10px;
                    margin-bottom: 6px;
                }
                .tilesRightSubDetails {
                    margin-top: 10px;
                }
            }
            .breakTile {
                grid-column: auto;
              }
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .etfRatings {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 6px 6px !important;
    }
}