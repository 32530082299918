@import "../../../../styles/variables";

.stockHighlightDataDiv{
    display: flex;
    justify-content: space-between;
    width:100%;
    font-family: $Roboto-Regular;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 0px 9px 0px !important;
    border-bottom: 1px solid rgba(0,0,0,0.10);

    .nxiStockHighlightCol1{
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-gray;
        line-height: 18px;
    }
    .stockHighlightCol1{
        padding-left:10px !important;
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-gray;
        line-height: 18px;
    }

    .stockHighlightCol2{
        justify-content: flex-end;
        // padding-right:10px !important;
        text-align: right;
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
    }
}

.noBorder{
    border-bottom: none;
}
.stockHighlightDataDiv:last-child{
    border-bottom:none;
}