@import '../../../styles/variables';

.commonTabularStructureContainer {
    padding: 11px 15px 20px 15px;
    .headerTabularStructure {
        padding-bottom: 8px;
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        .headerSubTabularStructure{
            font-size: 12px;
            color: $color-gray;
            line-height: 16px;
            margin-left:5px;
        }
    }
    .mainContent{
        display: flex;
        justify-content: space-between;
        width:100%;
        padding: 8px 10px 8px 10px;
        border-bottom: 1px solid rgba(0,0,0,0.10);
        .mainContentLabel{
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-gray;
            line-height: 18px;
        }
    
        .mainContentValue{
            justify-content: flex-end;
            text-align: right;
            font-family: $Roboto-Medium;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding-left: 24px;
        }
    }
    
    .mainContent:last-child{
        border-bottom:none;
    }
    .noBottomLastChild:last-child {
        padding-bottom: 0;
    }
    .footerButton {
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    cursor: pointer;
    padding: 12px 0px 0px 0px;
    border:none;
    background: none;
    }
}

@media (min-width: 596px) and (max-width: 1023px) {
    .commonTabularStructureContainer {
        padding: 11px 15px 15px 15px;
        .ipadContentContainer {
            display: flex;
            justify-content: space-between;
            .ipadContentLeft {
                width: 50%;
            }
            .ipadContentRight {
                width: 50%;
                margin-left: 10px;
            }
        }

    }
}

@media (min-width: 0px) and (max-width: 595px) {
    .commonTabularStructureContainer {
        padding: 11px 15px 15px 15px;
        .footerButton {
            padding: 8px 0px 0px 0px;
        }
    }
}
