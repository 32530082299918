@import '../../../styles/variables';

.profileContainer {
    padding: 15px;
    background: $color-white-opacity;
    box-shadow: 0px 1px 4px 1px $color-container-box-shadow;
    border-radius: 3px;

    .header {
        font-family: $Roboto-Regular;
        font-size: 20px;
        line-height: 26px;
        color: $color-black;
    }

    .rowContainer {
        margin-top: 20px;

        hr {
            height: 1px;
            border: 1px solid $color-box-shadow;
            box-sizing: border-box;
            margin-top: 9px;
            margin-bottom: 8px;
        }

        .row {
            line-height: 18px;
            padding: 8px 10px 9px 10px;

            .fieldName {
                font-family: $Roboto-Medium;
                vertical-align: top;
                font-size: 14px;
                line-height: 18px;
                color: $color-gray;
                width: 30%;
                display: inline-block;
                .read-full-story {
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    font-weight: 500;
                    color: $color-blue;
                    line-height: 18px;
                    cursor: pointer;
                    border: transparent;
                    background: transparent;
                    padding: 0;
                }
            }

            .fieldValue {
                font-family: $Roboto-Medium;
                font-size: 14px;
                line-height: 18px;
                color: $color-black;
                text-align: right;
                display: inline-block;
                width: 70%;
                word-break: break-all;
                font-weight: 500;
                div span span span:last-child span:last-child span:last-child{
                    display: none;
                }                
            }

            // PRW-1555 : this is used to show the ellipsis for the profile section for MF and ETF page
            .fieldValueDisplay {
                div span span span:last-child span:last-child span:last-child{
                    display: inline-block;
                }
            }

            .viewAll {
                font-family: $Roboto-Regular;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: $color-blue;
                border: none;
                background: none;
                padding: 0
            }

            .sinceDate {
                font-family: $Roboto-Medium;
                font-size: 12px;
                line-height: 16px;
                float: right;
                color: $color-gray;
                font-weight: 400;
            }
        }

        .border {
            border-bottom: 1px solid $color-box-shadow;
        }

    }

    .strategy {
        font-family: $Roboto-Regular;
        font-size: 14px;
        line-height: 18px;
        color: $color-black;
    }

    .table {

        th,
        td {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding: 13px 10px;
            padding-right:0;

            div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        tr td:first-child,
        th:first-child {
            width: 240px;
            height: 44px;
        }

        tbody {
            max-height: 264px;
        }
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .profileContainer {
        .rowContainer {
            .row {
                .fieldName{
                    width:40%;
                    
                }
                .fieldValue{
                    width: 60%;
                }
            }
        }
        .table {

            tr td:first-child,
            th:first-child {
                width: 336px;
            }

            tbody {
                max-height: 264px;
            }
        }
    }
}
@media screen and (min-width: 375px) and (max-width: 595px) {
    .profileContainer {
        .rowContainer {
            .row {
                .fieldName{
                    width:40%;
                    
                }
                .fieldValue{
                    width: 60%;
                }
            }
        }
    }
}