@import "../../../../../styles/variables";

.transactionsTableContainer {
    overflow-y: auto;

    table {
        width: 100%;
        white-space: nowrap;
        margin: 0;
        border-collapse: separate;
        border-spacing: 0;
        table-layout: auto;
        border: 1px solid rgba(0, 0, 0, 0.14);
    }

    th {
        border-bottom: 1px solid $color-netxwealth-news-research-grey-border;
    }

    td,
    th {
        font-weight: inherit !important;
    }

    td {
        border-bottom: 1px solid $color-white-whisper;
    }

    .transactionsNoDataTable {
        tbody {
            tr {
                td {
                    padding: 0px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                }
            }
        }
    }

    .transactionsTable {
        thead {
            tr {
                height: 18px;

                th {
                    font-family: $Roboto-Medium;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    padding-top: 13px;
                    padding-bottom: 13px;
                    text-align: left;
                    padding-right: 10px;


                }
            }

            th:first-child {
                padding-left: 14px;
                min-width: 86px;
                max-width: 320px;
                background-color: $color-white;

                .hideArrow {
                    visibility: hidden;
                    border: none;
                    background: none;
                    padding: 0 6px;
                }

                &:hover>.hideArrow {
                    visibility: visible;
                }

                .downWardUpwardArrow {
                    border: none;
                    background: none;
                    padding: 0 6px;
                }
            }

            th:last-child {
                padding-right: 10px;
            }

            th:not(:first-child) {
                text-align: right;
                font-family: $Roboto-Medium;
                font-size: 14px;
                color: $color-black;
                line-height: 18px;

                .seprator {
                    border-left: 1px solid $color-light-gray;

                    .hideArrow {
                        visibility: hidden;
                        border: none;
                        background: none;
                        padding: 0 6px;
                    }

                    &:hover>.hideArrow {
                        visibility: visible;
                    }

                    .downWardUpwardArrow {
                        border: none;
                        background: none;
                        padding: 0 6px;
                    }

                }
            }
        }

        tbody {
            tr {

                td {
                    padding-top: 13px;
                    padding-bottom: 13px;
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    text-align: left;
                    overflow-wrap: break-word;
                    vertical-align: text-top;
                    padding-right: 10px;
                }

                td:first-child {
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    white-space: initial;
                    min-width: 86px;
                    max-width: 320px;
                    padding-left: 10px;
                    background-color: $color-white;
                }

                td:not(:first-child) {
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    text-align: right;
                    line-height: 18px;
                    padding-left: 10px;
                }

                td:last-child {
                    padding-right: 10px;
                }
            }
        }

        .green {
            color: $color-green;
        }

        .red {
            color: $color-red
        }
    }

    .shortLine {
        border-left: 1px solid $color-light-gray;
        padding-left: 10px;
    }
}

.maxWidth {
    width: 100%;
}

@media(max-width:1023px) {

    td,
    th {
        padding: 0.5rem .85rem !important;
    }

    .transactionsTableContainer {
        .headcol {
            padding-left: 10px;
            position: sticky;
            left: 0;
            z-index: 999;
            border-right: 1px solid $color-white-whisper;
        }

        .transactionsTable {
            display: block;
            background: $color-white;
            overflow-x: auto;
            padding: 0px;
            /* Shadows */
            background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
                /* Shadow covers */
                linear-gradient(to right, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0)),
                linear-gradient(to left, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0));
            background-position: left center, right center, left center, right center;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;

            /* Opera doesn't support this in the shorthand */
            background-attachment: local, local, scroll, scroll;
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;

            thead {
                tr {
                    th:first-child {
                        min-width: 168px !important;
                        max-width: 170px !important;
                    }

                    th:last-child {
                        padding-right: 10px;
                    }
                }
            }

            tbody {
                tr {
                    td:first-child {
                        min-width: 168px !important;
                        max-width: 170px !important;
                        word-break: break-word;
                        white-space: nowrap !important;
                    }

                    td:last-child {
                        padding-right: 10px;
                    }
                }
            }
        }

        .transactionsTable::-webkit-scrollbar {
            display: none;
        }
    }

    .shortLine {
        margin-left: -15px !important;
        padding-left: 30px !important;
    }
}