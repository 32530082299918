@import '../../../styles/variables';
.actionMenuContainer {
    display: inline-block;

    button {
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
    }

    .downLoadIcon {
        color: #0572D6;
        cursor: pointer;

        svg {
            width: 18px;
            height: 18px;
            font-size: 18px;
            text-align: center;
            line-height: 18px;
        }
    }

    ul {
        text-align: left;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: 4px;
            cursor: pointer;
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: #000000;
            line-height: 18px;

            &:hover {
                background-color: $color-white-opacity;
            }
        }
    }

    div[id="popover"] {
        top: 45px !important;
        left: 15px !important;
        >div{
            padding: 0;
            margin: 0;
        }
    }
}
