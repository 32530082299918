@import "../../../styles/variables";

.genericTableContainer {
  border: 1px solid $color-box-shadow;

  .genericTable {
    width: 100%;
    margin: 0;
    padding: 0;
    background: $color-white;

    tbody {
      display: block;
      overflow: auto;
      scrollbar-width: 9px;
    }

    tbody::-webkit-scrollbar {
      width: 9px !important;
      height: 22px !important;
    }
    tbody::-webkit-scrollbar-thumb {
      background-color: #B2B2B2;
      height: 22px !important;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      /* even columns width , fix width of table too*/
    }

    .header {
      border-bottom: 1px solid $color-netxwealth-news-research-grey-border;

      th {
        color: $color-black;
      }
    }
    .headerforETF {
      border-bottom: 1px solid $color-netxwealth-news-research-grey-border;
      th {
        color: $color-black;
        padding-bottom: 4px;
     }
   }
    .body {
      border-top: 0.5px solid $color-white-whisper;
    }
    .chartBody {
      border-bottom: 0.5px solid $color-white-whisper;
    }

    .green {
      color: $color-green;
    }

    .red {
      color: $color-red;
    }

    .left {
      text-align: left;
    }

    .right {
      text-align: right;
    }

    .center {
      text-align: center;
    }

    .seprator {
      border-right: 1px solid $color-light-gray;
      padding-right: 10px;
    }

    .valueSeprator {
      padding-right: 10px;
    }
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 5px 4px 0 0px;
  }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
  .genericTableContainer {
    .genericTable {
      .seprator {
        border-right: none;
        padding-right: 10px;
      }
    }
  }
}