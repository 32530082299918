@import "colors";

/**
* Fonts
*/
$Roboto-Regular: "Roboto-Regular",Arial,sans-serif;
$Roboto-Medium: "Roboto-Medium",Arial,sans-serif;
$Roboto-Light: "Roboto-Light",Arial,sans-serif;
$Roboto-Bold: "Roboto-Bold",Arial,sans-serif;

div[data-ipc-container="com_pershing_netxwealth_model_marketplace"],
div[data-ipc-container="com_pershing_netxwealth_ai_marketplace"],
div[data-ipc-container="com_pershing_netxwealth_fund_marketplace"],
div[data-ipc-container="com_pershing_netxwealth_equity_screener"],
div[data-ipc-container="com_pershing_netxwealth_universalsearch"]{
div:focus-visible, a:focus-visible, span:focus-visible, button:focus-visible, li:focus-visible, ul:focus-visible, input:focus-visible, textarea:focus-visible{
    outline: #2FDAFF !important;
    border: 1px solid #2FDAFF !important;
}
}