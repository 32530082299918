@import '../../styles/variables';

.watchlistModalHeader {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    position: relative;
    z-index: 99;
    height: 48px;
    width: 100%;
    background-color: $color-dark-gray;
    border-radius: 3px;

    .watchlistDescriptionHeaderText {
        width: 92%;
        margin-left: 20px;
        color: $color-white;
        font-size: 20px;
        line-height: 26px;
        font-family: $Roboto-Regular;
        font-weight: 400;
    }

    .watchlistDescriptionPopUpClose {
        border: none;
        background-color: rgba(255, 255, 255, 0);
        color: $color-white;
        background-repeat: no-repeat;
        margin-right: 5px;
        font-size: 18px;
        text-align: center;
        line-height: 18px;
        cursor: pointer;
        float: right;
        font-weight: 200;
    }
}

@media(max-width:374px) {
    .watchlistModalHeader {
        background-color: $color-white;
        height: 22px;
        margin-top: 10px;
        margin-bottom: 15px;
        line-height: 22px;
        .watchlistDescriptionHeaderText {
            color: $color-black;
            margin-left: 10px;
        }
    
        .watchlistDescriptionPopUpClose {
            color: $color-black;
        }
    }
}