@import "../../../styles/variables";
.articleNewsListContainer {
  width: 100%;
  .NoData {
    font-family: $Roboto-Light;
    font-size: 24px;
    color: $color-gray;
    text-align: center;
    line-height: 30px;
    padding-top: 60px;
  }
  .readArticle {
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    word-wrap: break-word;
    cursor: pointer;
  }
  .loaderContainer {
    justify-content: center;
  }
  .articleNewsProvider {
    font-family: $Roboto-Regular;
    font-size: 12px;
    color: #666666;
    line-height: 16px;
    padding: 25px 0 4px 0;
  }
  .articleNewsHeading {
    font-family: $Roboto-Medium;
    font-size: 18px;
    color: #000000;
    line-height: 22px;
    padding: 0 0 10px 0;
  }
  .articleNewsDetails {
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: #000000;
    line-height: 18px;
    width: 100%;
    cursor: default;

    a {
      font-family: $Roboto-Regular;
      font-size: 14px;
      color: #0572d6;
      line-height: 18px;
    }
  }
  .articleNewsDetails:focus {
    outline: none;
  }
  .articleNewsGreenSymbol {
    color: #348638;
  }
  .articleNewsRedSymbol {
    color: #ed0808;
  }
  .articleNewsSeprator {
    margin: 25px 0 0 0;
    width: 100%;
    padding: 0px;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    height: 1px;
  }
  .articleNewsListPagination {
    padding-top: 25px;
    padding-bottom: 48px;
  }
  .modalPopUpContentContainerParent {
    max-height: 600px;
    overflow: none;
  }
}

@media (min-width: 596px) and (max-width: 1024px) {
  .articleNewsListContainer {
    .articleNewsHeading {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media (min-width: 375px) and (max-width: 596px) {
  .articleNewsListContainer {
    .articleNewsHeading {
      font-size: 16px;
      line-height: 20px;
    }
    #newsArticleQuoteChartPopUp {
      display: none;
    }
  }
}
