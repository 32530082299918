@import "../../../../styles/variables";


.main-container {
  font-family: $Roboto-Regular !important;

  .float-right {
    float: right;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .containerDropDiv {
    margin-bottom: 10px;
  }
  ul.dropdownWithBottomBorder {
    border: none;

    & > li:first-child {
      background: #edecea;
      border-bottom: 4px solid $color-netxwealth-lightblue-border;
      margin-bottom: 10px;
    }
    li:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    li:nth-child(2) {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
  .label-text {
    display: inline-block;
  }
  .basicDropDownContainer {
    display: inline-block;
    width: 150px;
    position: absolute;
    padding: 0;
    list-style: none;
    border-radius: 3px;
    outline: none;
    margin: 0px;
    z-index: 1;
    line-height: 20px;

    &.open {
      border-radius: 0px;
      z-index: 10;
    }
    .buttons {
      width: 100%;
      text-align: right;
      padding-right: 8px;
      .apply {
        margin-top: 15px;
        background-color: #0572d6;
        color: #ffffff;
        margin-right: 10px;
      }
      .reset {
        margin-right: 12px;
        margin-top: 15px;
        background: #ffffff;
        color: #0572d6;
      }
      hr {
        position: absolute;
        background-color: #b2b2b2;
        width: 100%;
        left: 0;
        bottom: 50px;
      }
    }
    .select-section {
      border: 1px solid #b2b2b2 !important;
    }
    .selected-text {
      top: 0;
      left: 0;
    }

    li {
      border: none;
      padding: 6px 0 6px 10px;
      display: block;
      cursor: pointer;
      font-size: 14px;
      color: #000000;
      border-top: 0px solid #f7f7f7;
      background: #ffffff;
      z-index: 999;
      line-height: 18px;
      border-radius: 3px;
     
      .container {
        display: inline-block;
        position: relative;
        padding-left: 20px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked {
            ~ {
              .checkmark {
                border:0px solid;
                background-color: #48b748;
                &:after {
                  display: block;
                }
              }
            }
          }
        }
        &:hover {
          input {
            ~ {
              .checkmark {
                background-color: transparent;
              }
            }
            &:checked{
              ~ {
                .checkmark {
                  background-color: #48b748;
                }
              }
            }
          }
        }
        .checkmark {
          &:after {
            left: 5px;
            top: 0px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .checkmark {
        position: absolute;
        top: -5px;
        left: 5px;
        height: 16px;
        width: 16px;
        background-color: #ffffff;
        border: 1px solid black;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }
      .option-text {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
      }
      .lock-icon {
        width: 16px;
        height: 16px;
        float: right;
        right: 6px;
        bottom: -3px;
        position: relative;
      }
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        top: 5px;
        left: 10px;
        box-sizing: border-box;
        position: relative;
        line-height: 18px;
        width: 80%;
        display: inline-block;
      }
      &.hide {
        display: none;
      }
      &.selected {
        background: #e3edf5;
      }
     
      li:first-child {
        display: block;
        color: #2472b2;
        border-top: none;
        border-radius: 3px;
        &:hover {
          background: #ffffff !important;
        }
      }
      .icon {
         margin-right:8px;
      }
      .display-text {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85% !important;
        .name {
          left: 0;
          top: 0;
        }
      }
    }
    .main-section {
      box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1),
        1px 2px 6px 2px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      background-color: #ffffff;
    }
    .basicDropDownValueContainer {
      max-height: 190px;
      overflow-y: scroll;
      width: 100%;
      background-color: #ffffff;
      border-radius: 3px;
      margin-top: 10px 2px 0px 0px;
    }
    .button-container {
      background-color: #ffffff;
      width: 100%;
      text-align: right;
      padding: 10px 0px 15px 0px ;
      border-radius: 3px;
      display: flex;
      flex-direction: row-reverse;
      .action-item {
        margin-right: 10px;
        border-radius: 3px;
      }
      .btn-primary {
        background: #0572d6;
        color: #ffffff;
      }
      .btn-reset {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
    .applyAndResetButtons{
      padding: 0px 0 10px;
      border-top: 1px solid $color-light-gray;
      button{
        margin-top: 0px;
        width:80px;
        width:80px;
        height: 34px;
        font-family: $Roboto-Regular;
        border-radius: 3px;     
        font-size: 14px;    
        text-align: center;
        line-height: 18px;    
        border: 1px solid rgba(0,0,0,0.30);

        &:disabled {
          background: rgba(0,0,0,0.10);
          border-radius: 3px;
          color: rgba(0,0,0,0.26);
          border: none;
       }
      }
    }
    .hide {
      display: none;
    }
  }
}
