@import '../../../../styles/variables';

.recentlyView {
    padding: 20px 20px 25px 20px;
    background: $color-white-opacity;

    .heading {
        padding: 0px 10px 15px 0px;
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
    }

    .recentlyViewContainerParent {
        margin-top: 5px;

        .recentlyViewContainer {
            display: flex;
            width: 100%;
            height: 400px;

            &>div+div {
               margin-left: 24px;
            }

            .ipadSpecificFill {
                display: none;
            }

            .recentlyViewItem {
                background: $color-white;
                box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                font-family: $Roboto-Regular;
                // padding: 15px;
                width: 33%;

                .disclaimer {
                    font-size: 10px;
                    margin-top: 5px;
                    font-family: $Roboto-Regular;

                    .disclaimerSymbol {
                        font-size: 5px;
                        margin-bottom: 3px;
                    }
                }

                .marketHighlightsNewsProvider {
                    font-size: 12px;
                    color: $color-gray;
                    line-height: 16px;
                }

                .marketHighlightsNewsTitle {
                    font-family: $Roboto-Medium;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    margin: 10px 0px 5px 0px;
                    font-weight: 600;
                }

                .marketHighlightsNewsBody {
                    font-size: 14px;
                    color: $color-gray;
                    line-height: 18px;
                }
                .loaderContainer {
                    display: flex;
                }
            }
        }
    }
}

@media screen and (max-width: 595px) {
    .recentlyView {
        .recentlyViewContainerParent {
            background: $color-white;
            max-width: 983px;
            overflow-x: auto;
            margin-top: 15px;
            padding-bottom: 20px;
            /* Shadows */
            background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
                /* Shadow covers */
                linear-gradient(to right, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0)),
                linear-gradient(to left, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0));
            background-position: left center, right center, left center, right center;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;

            /* Opera doesn't support this in the shorthand */
            background-attachment: local, local, scroll, scroll;
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;

            /* Firefox */
            .recentlyViewContainer {
                min-width: 983px;
                padding: 0;

                &>div+div {
                    margin-left: 0px;
                }

                .ipadSpecificFill {
                    display: block;
                    min-width: 24px;
                    background: $color-white-opacity;

                    &:last-child {
                        display: none;
                    }
                }

                .recentlyViewItem {
                    //position: relative;
                    background: none;
                }
            }
        }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .recentlyViewContainerParent::-webkit-scrollbar {
        display: none;
    }
}