$color-netxwealth-gray-border: #dbdbdb;
$color-netxwealth-lightblue-border: #88C6FF;
$color-netxwealth-lightblue-dropdown-border: #70BBFF;
$color-dark-gray: #37474F;
$color-white: #FFFFFF;
$color-light-seprator-gray:#BDBDBD;
$color-black: #000000;
$color-red: #E40707;
$color-red-text: #EC0000;
$color-red-text-1: #ED0808;
$color-green: #008338;
$color-actual-green: #00AC00;
$color-green-light:  #48B748;
$color-green-light-2:  #8BC34A;
$color-green-light-3: #348638;
$color-white-opacity: #f7f7f7;
$color-white-whisper: #E5E5E5;
$color-blue:#0572D6;
$color-gray:#666666;
$color-light-gray:#B2B2B2;
$color-light-gray-2:#C7C7C7;
$color-dark-gray-2:rgba(0,0,0,0.26);
$color-very-light-gray:#DDDDDD;
$color-netxwealth-news-research-grey-border : #999999;
$color-box-shadow : rgba(0, 0, 0, 0.1);
$color-bar-graph-blue: #293DC0;
$color-graph-light-blue: #2FAEFF;
$color-graph-light-blue1: #2FDAFF;
$color-bar-graph-blue-dark: #004480;
$color-light-blue: #00A0D4;
$color-divider-gray: #B2B2B2;
$color-gray-border: #E5E5E5;
$color-radio-button-green: #48B748;
$color-dividend-bar-chart-blue: #004480;
$color-dividend-performance-area-blue: #0095FF;
$color-stock-blue: #2383BF;
$color-stock-orange: #E65100;
$color-dark-orange: #D97E07;
$color-dark-orange-2: #FF5722;
$color-dark-orange-3: #FFA000;
$color-dark-orange-4: #F9AF18;
$color-light-green: #00B3A1;
$color-table-background-blue: #EDF9FE;
$color-table-background-light-blue: #F5FCFE;
$color-dark-green: #00A695;
$color-dark-magenta: #AA01AA;
$color-dark-orange-shade: #FF573F;
$color-light-blue-chart: #2faeff;
$color-dark-magenta-2: #903E97;
$color-dark-orange-shade: #FF573F;
$color-checkbox-green: #48b748;
$color-gray-chart-line: #D9D9D9;
$color-measures-black: #37474f;
$color-actual-red: #FF0000;
$color-light-gray-3: #747474;
$color-light-gray-4: #A7A7A7;
$color-yellow: #eee487;
$color-light-gray-5: #e8e6e6;
$color-black-10: rgba(0,0,0,0.10);
$color-black-3: rgba(0,0,0,0.3);
$color-navy-blue: #9085FF;
$color-red-light: #BF360C;
$color-purple: #093055;
$color-light-gray-5: #9E9E9E;
$color-light-gray-6: #58595A;
$color-light-gray-7: #676767;
$color-black-solid: #333333;
$color-black-solid-2: #2A2A2A;
$color-blue-solid-2: #287CB9;
$color-pill-blue: #E8F0F7;
$color-mid-dark-grey: #464C51;
$color-divider-dark-grey: #919CA2;
$color-dark-grey-shade: #636363;
$color-black-shade: #0F0F0F;
$color-light-grey: #525252;
$color-disabled-grey: #BFBFBF;