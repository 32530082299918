@import "../../../../styles/variables";

.topCategoryContainer {

    .headinglabel {
        margin-bottom: 20px;

        .headinglabel1 {
            font-size: 20px;
            line-height: 28px;
            color: $color-black;
            font-family: $Roboto-Regular;
        }

        .headinglabel2 {
            font-size: 14px;
            line-height: 18px;
            color: $color-light-grey;
            font-family: $Roboto-Regular;
        }
    }

    .topCategoryCard {
        max-height: 97px;
        min-width: 163px;
        max-width: 183px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 15px 10px;
        display: inline-block;
        margin: 0px 20px 20px 0px;

        .categoryLabel {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 24px;

            .label {
                font-size: 14px;
                line-height: 16px;
                font-family: $Roboto-Bold;
                color: $color-black;
            }
        }

        .subLabel {
            font-size: 12px;
            line-height: 14px;
            color: $color-gray;
            font-family: $Roboto-Regular;
            margin-top: 15px;
        }

        .lastViewedDate {
            font-size: 12px;
            line-height: 14px;
            color: $color-black;
            font-family: $Roboto-Medium;
        }
    }

    .checkDisabled {
        opacity: 0.3;
    }
}

@media screen and (max-width: 596px) {
    .topCategoryContainer {
        .topCategoryCard {
            margin: 0px 10px 10px 0px;
        }
    }
}