@import '../../../styles/variables';

.betaRisk {
    // min-height: 477px;
    .header {
        font-family: $Roboto-Regular;
        font-size: 28px;
        line-height: 34px;
        color: $color-black;
        margin-bottom: 20px;
    }
    .headerModel { 
        margin-bottom: 30px;
    }
    .legends {
        .color_blue {
            background-color: $color-bar-graph-blue-dark;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            display: inline-block;
        }

        .dashed {
            height: 1px;
            width: 10px;
            display: inline-block;
            margin-bottom: 5px;
            margin-left: 20px;
            border-bottom: 1.25px dotted rgba(0, 0, 0, 0.7);
        }

        .legendtext {
            font-family: $Roboto-Regular;
            font-size: 14px;
            line-height: 18px;
            display: inline-block;
            color: $color-black;
            margin-left: 10px;
        }
    }      
   
}
.betaRiskButton{
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    cursor: pointer;
    padding:0;
    border:none;
    background: none;  
    margin-top: 260px;
    position: absolute;
}
@media (min-width: 375px) and (max-width: 1023px), only screen and (min-device-width: 375px) and (max-device-width:1023px) {
    .betaRisk{
        min-height: 385px;
    }
    .headerModel { 
        margin-bottom: 20px !important;
    }
}