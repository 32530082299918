@import '../../../../styles/variables';

.symbolCardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    min-width: 120px;
    padding: 4px 10px 8px 10px;
    line-height: 18px;
    .header {
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
        padding-right: 10px;
    }
    .priceChange {
        font-family: $Roboto-Regular;
        font-size: 14px;
        text-align: right;
        display: inline-flex;
        span:first-child {
            margin-right: 5px;
        }
        > div {
            margin-left: 10px;
        }
        .closeButton {
            cursor: pointer;
        }
    }
    .negative {
        color: $color-red;
    }
    .positive {
        color: $color-green;
    }
}

@media screen and (min-width: 375px) and (max-width: 1023px) {
   
}