@import "../../../styles/variables";
.float-right{
    float: right;
    .caretIcon{
    width: 14px;
    height: 14px;
    }
    .clickableicon {
      font-size: 24px;
      line-height: 24px;
      color: rgba(5, 114, 214, 1);
      font-weight: 400;
      width: 9px;
      height: 24px;
      
    }
}
.containerDropDiv{
  margin-bottom: 10px;
}
ul.dropdownWithBottomBorder {
    border:none;
 & > li:first-child {
        margin-bottom: 10px;
        border-bottom: 3px solid $color-netxwealth-lightblue-dropdown-border;
    }
    li:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      
    }
    li:nth-child(2) {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;      
    }
}
ul.dropdownWithBlueBackground {
  border: none !important;
& > li:first-child {
      background-color:$color-blue;
      color: $color-white;      
      border-radius: 3px;
      padding: 1px 15px 0px 20px;
  }
  & > li:first-child > span.name {
    width: 79px;
    color: $color-white;
    padding-left: 0px;
  }
}
.noBorder{
  border: none !important;
}
ul.basicDropDownContainer{
  height: auto;
  overflow-y: initial;
  position: relative;
}

.basicDropDownContainer {
    display: inline-block;
    border: 1px solid #AAADB1;
    padding: 0;
    list-style: none;
    border-radius: 3px;
    outline: none;
    margin: 0 0 0 10px;
    width: 156px;
    &.open {
      border-radius: 0px;
    }
    ul{
      height: auto;
    }
    li {
      border: none;
      display: block;
      cursor: pointer;
      font-size: 14px;
      color: #000000;
      font-family: $Roboto-Regular;
      border-top: 1px solid #f7f7f7;
      background: $color-white;
      z-index: 999;
      text-align: left;
      .name {
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        top: 2px;
        box-sizing: border-box;
        position: relative;
        margin-right: 5px;
        color:$color-gray;
        padding: 3px 0 3px 15px;
      }
      &.hide {
        display: none;
      }

      &.selected {
        background: #F7F7F7;
      }
      &:hover {
        background: #F7F7F7;
      }
      
    li:first-child {
      display: block;
      color: #2472B2;
      border-top: none;
      border-radius: 3px;

      &:hover {
        background: #F7F7F7 !important;
      }
    }

    .icon{
        margin-right: 0px;
        position: relative;
        top: 3px;
    }
  }
    .basicDropDownValueContainer {
      margin-top: 3px;    
      border-radius: 3px;

      max-height: 450px;
      overflow-y: auto;
      box-shadow: 0px 0px 10px 3px #88888899;
      position: relative;
      z-index: 10;
      ul, li{
        padding-left: 0px !important;
        margin-left: 0px !important;
      }
      ul, li:first-child{
        padding-left: 0px !important;
        margin-left: 0px !important;
        span{
          font-size: 20px;        
        }
      }
      li>span{
        font-size:14px !important;
      }
    }
    .positionAbs {
      position: absolute;
      width: 170px;
      right: 3px;
      border-radius: 3px;
      padding: 10px 0px;
      z-index: 10000 !important;
    }
    .hide{
      display: none;
    }
    .lineHeight {
      line-height: 18px;
    }
    @media (max-width: 595px), only screen and (max-device-width:595px) {
      position: relative;
      width: fit-content;
      float: right;
      margin-right: 3%;
      li{
        border-top: none;
      }
      .basicDropDownValueContainer{
        margin-top: 27px;  
      }
    }
  }
