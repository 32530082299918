@import '../../../../styles/variables';

.container {
    padding: 20px;

    .header {
        display: inline-block;
        width: 100%;

        .button {
            display: inline-block;

            .icon {
                font-size: 14px;
                color: $color-blue;
                text-align: center;
                line-height: 14px;
                margin-right: 5px;
            }

            .btnName {
                font-family: $Roboto-Regular;
                font-size: 14px;
                color: $color-blue;
                line-height: 18px;
            }

            .allWatchlistButton {
                border: none;
                padding: 0px;
                background-color: transparent;
                cursor: pointer;
            }
        }

        .delayed-quote-info {
            float: right;
            display: flex;
            font-size: 12px;
            color: $color-gray;
            line-height: 16px;
            padding: 5px 0 20px;
            font-family: $Roboto-Regular;

            .date-info {
                display: inline-block;
                margin-right: 10px;
                color: $color-gray;
            }

            .refresh-icon {
                display: inline-block;
                padding: 0;
                background: none;
                border: none;
            }

            .seprater {
                display: inline-block;
                color: rgba(0, 0, 0, 0.14);
                margin: 0px 15px;
                font-size: 18px;
                line-height: 18px;
            }

            .realTime {
                display: inline-block;

                label {
                    span {
                        // background: $color-light-seprator-gray;
                        margin-left: 10px;
                    }

                    span::before {
                        // background: $color-white-opacity;
                    }
                }
            }
        }


    }

    .watchlistDetails {
        background: $color-white;
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
        border-radius: 3px;

        .details {
            display: flex;
            align-items: center;
            width: 100%;

            .info {
                padding: 0 20px;
                min-width: 400px;
                max-width: 500px;

                .watchlistName {
                    font-family: $Roboto-Regular;
                    font-size: 20px;
                    color: $color-black;
                    line-height: 26px;
                    display: inline;
                    word-break: break-all;
                    width: 30%;

                    .editPencil {
                        display: inline-block;

                        .edit {
                            font-size: 16px;
                            color: $color-blue;
                            text-align: right;
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }
                }

                .watchlistDescription {
                    font-family: $Roboto-Regular;
                    font-size: 16px;
                    line-height: 20px;
                    color: $color-gray;
                    word-break: break-all;
                }
            }

            .downLoadIcon {
                display: inline-block;
                svg {
                    width: 18px;
                    height: 18px;
                    font-size: 18px;
                    text-align: center;
                    line-height: 18px;
                }
                .enableIcon{
                    color: $color-blue;
                    cursor: pointer;
                }
                .disabledIcon {
                    color: $color-netxwealth-news-research-grey-border;
                    cursor: not-allowed;
                }
            }

            .separator {
                border: 1px solid #E5E5E5;
                background-color: $color-gray-border;
                display: inline-block;
                margin-right: 29px;
                height: 56px;
                margin-top: 24px;
                margin-bottom: 20px;
            }

            .holdings {
                display: flex;
                width: 33%;

                .symbolCountsContainer {
                    padding-right: 60px;
                    box-sizing: border-box;

                    .label {
                        font-family: $Roboto-Regular;
                        font-size: 12px;
                        color: $color-gray;
                        line-height: 16px;
                        white-space: nowrap;
                    }

                    .value {
                        font-family: $Roboto-Regular;
                        font-size: 20px;
                        color: $color-black;
                        line-height: 26px;
                    }
                }
            }
            .editButton {
                display: inline-block;
                width: 100%;
                text-align: right;

                .edit {
                    background: $color-blue;
                    border-radius: 3px;
                    width: 126px;
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-white;
                    text-align: center;
                    line-height: 18px;
                    height: 34px;
                    margin: 0px 20px;
                    cursor: pointer;
                }
            }
        }

    }
    .watchListInsights {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 595px) {
    .container {
        .header {
            margin-bottom: 70px;

            .delayed-quote-info {
                float: left;
                flex-direction: column;
                padding: 5px 2px;
                position: absolute;
            }

            .realTime {
                margin-top: 17px;
            }
        }

        .watchlistDetails {
            .details {
                display: inline-block;
                padding: 15px;

                .info {
                    padding: 0px 0px 10px 0px;
                    min-width: 0px;

                    .watchlistName {

                        .editPencil {
                            margin-bottom: 15px;
                        }
                    }
                    .watchlistDescription{
                        margin-top: -10px;
                    }
                }

                .holdings {
                    margin-top: 15px;
                    .symbolCountsContainer {
                        padding-right: 5vh;
                    }
                }

                .separator {
                    display: none;
                }
            }
        }
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .container {
        .header {
            margin-bottom: 30px;

            .delayed-quote-info {
                float: left;
                padding: 2px 0px;
                position: absolute;
            }
        }
        .watchlistDetails {
            .details {
                .downLoadIcon {
                    display: inline;
                    margin-left: 0px !important;
                    >div {
                        display: inline;
                        >div {
                            display: inline;
                        }
                    }
                    
                }
            }
        }
    }
}

//ipad Pro
@media screen and (min-width: 1023px) and (max-width: 1024px) {
    .container {
        .watchlistDetails {
            .details {
                .info {
                    min-width: 300px;
                }
                .downLoadIcon {
                    display: inline-block;
                    margin-bottom: 10px;
                    margin-left: 70px;
                    >div {
                        display: inline;
                        >div {
                            display: inline;
                        }
                    }
                    
                }
            }
        }
    }
}