//universal search bar css
@import '../../../styles/variables';
.recentsearchForm {
    position: relative;
    
    .searchIconWrapper {
      display: flex;
      position: relative;
    }
    .searchIconRecent {
      position: relative;
      left: 15px;
      color: #000000;
      z-index: 1;
      display: inline-block;
      margin-right: 20px;
      font-weight: normal;
      font-size: 12px;
      font-style: normal;
      line-height: 26px;
    }
    .iconStylingMOD:hover {
      color: #0572d6;
    }
   
  }
  
  //universal search in pills
  .pillsFormRecent {
    width: 100%;
    margin: 0 auto;
    form {
      padding: 20px 25px 15px 25px;
      overflow-x: scroll;
    }
    .investMentInfoConatiner{
      padding-left: 9px;
      display: inline-block;
      .investmentSymbol{
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $color-black;
      }
      .investmentName{
        font-family: $Roboto-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: $color-gray
    }
  }
    .searchInFormRecent {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      color: #666666;
      position: absolute;
      z-index: 9999;
      overflow-y: auto;
      max-height: 256px;
    }
    .topPanelRecent {
      width: 100%;
      border-bottom: 1px solid #BDBDBD;
      padding-right: 12px;
      .reitText{
        font-family: $Roboto-Regular;
        font-size: 14px;
        line-height: 18px;
        color: $color-gray;
      }
    }
    .topPanelRecent:last-child{
      border-bottom: 0px;
    }
    .searchInTextRecent {
      display: inline-block;
    }
    .pillsBarRecent {
      height: auto;
      font-family: $Roboto-Regular;
      display: inline-block;
      color: #666666;
      width: 100%;
      padding:15px 0px 0px 15px;
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;

    }
    .results {
      width: 96%;
      cursor: pointer;
      display: inline-block;
    }
    .resultsMOD {
      width: 96%;
      cursor: pointer;
      display: inline-block;
    }
    .resultSelect {
      width: 96%;
      height: 60px;
      padding-left: 17px;
      background: #edf9fe;
      position: relative;
      display: inline-block;
      cursor: pointer;
    }
    .resultsRow {
      padding-bottom: 8px;
    }
    .productsRecent {
      width: 100%;
      display: inline-block;
    }
    .datequoteRecent {
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
      float: right;
      vertical-align: middle;
      padding: 10px 20px;
      box-shadow: none !important;
      width: 100%;
      text-align: right;
    }
    .productSymbol {
      margin-left: 10px;
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
     
      display: inline-block;
    }
    .productPrice {
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      display: inline-block;
      margin-left: 9%;
    }
    .productPriceAIN{
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      display: inline-block;
      margin-left: 13%;
     
  }
    .productName {
      width: 68%;
      margin-left: 10px;
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #666666;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
    .productPriceChange {
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: right;
      color: #008338;
      display: inline-block;
      margin-left: 15px;
    }
    .iconStyling {
      height: 1.8rem;
      width: 18px;
      cursor: pointer;
      margin-left: 22px;
      display: inline-block;
    }
    .iconStylingMOD {
      height: 26px;
      
      cursor: pointer;
    }
    .barBottom {
      width: 69%;
      height: 0px;
      margin-top: 3px;
      border: 1px solid rgba(0, 0, 0, 0.07);
    }
    .bottomBorder:last-child {
        border-bottom: 0px;
    }
    .bottomBorder {
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      padding-top: 15px;
      padding-bottom: 15px
    }
    .boldName{
      font-weight: 500;
      color: #000000;
    }
    .iconStyling:hover {
      color: #0572d6;
    }
    .iconStylingMOD:hover {
      color: #0572d6;
    }
    .iconStyling:hover {
      color: #0572d6;
    }
    .productTicket {
      width: 29px;
      height: 17px;
      margin-top: 15px;    
      border-radius: 3px;
      display: inline-block;
    }
    .productTicketETF{
      background: #00A695;
    }
    .productTicketMF {
      background: #d97e07;
    }
    .productTicketSC {
      background: #d97e07;
    }
    .productTicketAI {
      background: #aa01aa;
    }
    .productTicketAIN {
      background: #aa01aa;
    }
    .productTicketEQ {
      background: #004480;
     }
    .productTicketSMA,
    .productTicketMOD {
      background: #0095ff;
    }
    .productTicketSMAL {
      background-color: #0095ff;
      //background-image: url(./SMSM.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
    .productTicketModL {
      background-color: #0095ff;
      // background-image: url(./MML.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
    .productTicketText {
      width: 18px;
      height: 12px;
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: #ffffff;
      padding-left: 5px;
      padding-top: 2px;
    }
    .productTicketTextMOD {
      width: 18px;
      height: 12px;
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: #ffffff;
      padding-left: 3px;
      padding-top: 2px;
    }
    .displayInBlock {
      display: inline-block;
    }
    .displayArrow {
      width: 1%;
      position: relative;
      display: inline-block;
    }
  
  .productIconSMAL{
    width: 30px;
    height: 16px;
    background-color: #0078CD;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
  }
  .productIconModL{
    width: 30px;
    height: 16px;
    background-color: #0078CD;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.productIconSMA, .productIconMOD, .productIconETF, .productIconMF, .productIconEQ{
    width: 30px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #0078CD;
    border-radius: 3px;
    display: inline-block;

}
.adjustPositionLock {
  transform: translateY(-100%);
}

.productIconEQ {
  background: #004480;
}

.productIconMF{
  width: 30px;
  height: 16px;
  background-color: #AE6506;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
.productIconAI{
  width: 30px;
  height: 16px;
  background-color: #AA01AA;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
.productIconETF{
  width: 30px;
  height: 16px;
  background-color: #008575;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
  .productIconText{
      width: 30px;
      height: 12px;
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: #FFFFFF;
      padding: 2px;
      position: relative;
  }
  .tooltiptextIcon {
      display: none;
  }
  .lockIcon {
    display: inline-block;
    padding-left: 1px;
  }
  .productIconText:hover .tooltiptextIcon {
      display: block;
      font-family: $Roboto-Regular;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      cursor: pointer;
      background-color:#37474F;
      position: absolute;
      top: -30px;
      width: auto;
      padding: 6px;
      border-radius: 3px;
      white-space: nowrap;
  }
    .search-list {
      border: 1px solid #aaadb1;
      border-top: none;
      width: 99%;
  
      .row {
        border-bottom: 1px solid #aaadb1;
        &.row-hover:hover div {
          background: white;
          cursor: pointer;
  
          &.productSymbol {
            font-size: 14px;
            color: #2472b2 !important;
          }
          &.productName {
            color: #555b63 !important;
            font-size: 13px;
          }
        }
  
        .productSymbol {
          font-size: 14px;
          color: #2472b2 !important;
        }
        .productName {
          color: #555b63 !important;
          font-size: 13px;
        }
  
        :nth-child(3) {
          white-space: initial;
          width: 80%;
        }
      }
  
      .category {
        background: #f7f7f7;
        font-size: 12px;
        color: #003057;
        padding: 5px 10px;
        font-weight: 600;
        border-bottom: 1px solid #aaadb1;
        box-shadow: inset 0 0 1px #aaadb1;
      }
  
      .add-icon {
        float: right;
        top: 17px;
        right: 10px;
        cursor: pointer !important;
        width: 18px;
        height: 18px;
        background-position: -16px -23px !important;
        position: relative;
      }
    }
  }
  @media (max-width: 595px), only screen and (max-device-width:595px) {
   
    .pillsFormRecent {
      .investmentName{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width:258px;
        line-height: 12px;
      }
    }

  }
  @media screen and (min-width: 596px) and (max-width: 1023px) {
    .pillsFormRecent {
      .investmentName{
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        width: 435px;
        line-height: 12px;
      }
    }
  }