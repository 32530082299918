@import "../../../../styles/variables";

.fundStrategyModalDescription{
    padding-top: 20px;
    word-break: break-word;
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: $color-black;
    line-height: 18px;
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .fundStrategyModalDescription{
        padding-top: 20px;      
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .fundStrategyModalDescription{
        padding: 20px 10px 0px 0px; 
    }
}