@import '../../../../styles/variables';

.chartContainer {
  .tooltip {
    position: absolute;
    height: 36px;
    width: 180px;
    padding: 10px;
    background: $color-white;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    display: flex;
    z-index: 1;

    .color {
      width: 50%;
      text-align: left;
      font-family: $Roboto-Regular;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-black;

      .color_blue {
        background-color: $color-bar-graph-blue-dark;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
      }
    }
    .colorModel {
      width: 90%;
    }

    .text {
      width: 50%;
      text-align: right;
      font-family: $Roboto-Regular;
      font-size: 12px;
      line-height: 16px;
      color: $color-black;
    }
    .textModel {
      width: auto;
    }
  }

  .tooltipModel {
    width: 230px;
  }
  .chartBarContainer {
    width: 70px;
    background: rgba(0, 0, 0, 0.07);
    position: absolute;
    cursor: pointer;
    height: 200px;
    bottom: -216px;
  }
.DefaultChart{
  svg[class='ct-chart-bar'] {
    g[class='ct-grids'], g[class='ct-grids betaRiskBarChart'] {
      line[class='ct-grid ct-vertical'] {
        stroke: rgba(0, 0, 0, 0.15);
        stroke-dasharray: 0px;
      }
    }
    g[class='ct-grids betaRiskBarChart'] {
      line[class='ct-grid ct-vertical']:nth-child(3) {
        stroke: rgba(0, 0, 0, 0.7);
        stroke-dasharray: 2px;
        stroke-width: 1.25px;
      }
    }
    g[class='ct-series ct-series-a'] {
      line[class='ct-bar'] {
        stroke: $color-bar-graph-blue-dark;
        stroke-width: 50px;
        cursor: pointer;
      }
    }

    g[class='ct-labels'] {
      span[class='ct-label ct-vertical ct-end'] {
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-black;
        line-height: 16px;
        justify-content: flex-end;
      }
    }

    g[class='ct-labels'] {
      foreignObject {
        span[class='ct-label ct-horizontal ct-end'] {
          width: 66px !important;
          margin: 10px auto;
          font-family: $Roboto-Regular;
          font-size: 12px;
          color: $color-black;
          text-align: center;
          line-height: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
.BartChartWithoutDashedLine{
  @extend .DefaultChart;
  svg[class='ct-chart-bar'] {
    g[class='ct-grids betaRiskBarChart'] {
      line[class='ct-grid ct-vertical']:nth-child(3) {
        stroke: rgba(0, 0, 0, 0.15);
        stroke-dasharray: 0px;        
      }
    }
  }
}
.BarChartWithMinus2{
  @extend .DefaultChart;  
  svg[class='ct-chart-bar'] {
    g[class='ct-grids betaRiskBarChart'] {
      line[class='ct-grid ct-vertical']:nth-child(3) {
        stroke: rgba(0, 0, 0, 0.15);
        stroke-dasharray: 0px;
      }
      line[class='ct-grid ct-vertical']:nth-child(4) {
        stroke: rgba(0, 0, 0, 0.7);
        stroke-dasharray: 2px;
        stroke-width: 1.25px;
      }
    }
  }
}
.BartChartWithoutfor4{
  @extend .DefaultChart;
  svg[class='ct-chart-bar'] {
    g[class='ct-grids betaRiskBarChart'] {
      line[class='ct-grid ct-vertical']:nth-child(3) {
        stroke: rgba(0, 0, 0, 0.15);
        stroke-dasharray: 0px;
      }
      line[class='ct-grid ct-vertical']:nth-child(2) {
        stroke: rgba(0, 0, 0, 0.7);
        stroke-dasharray: 2px;
        stroke-width: 1.25px;
      }
    }
  }
}
}
@media (min-width: 596px) and (max-width: 1023px), only screen and (max-device-width:1023px) {
  .chartContainer {
    .chartBarContainer {
      width: 70px;
      background: rgba(0, 0, 0, 0.07);
      position: absolute;
      cursor: pointer;
      height: 200px;
      bottom: 93px;
    }
    .chartBarContainerModel {
      bottom: 145px;
    }
  }
}
@media (min-width: 375px) and (max-width: 595px), only screen and (max-device-width: 595px) {
  .chartContainer {
    .chartBarContainer {
      width: 50px;
      background: rgba(0, 0, 0, 0.07);
      position: absolute;
      cursor: pointer;
      height: 200px;
      bottom: 92px;
    }
    .chartBarContainerModel {
      bottom: 145px;
    }
    .DefaultChart{
    svg[class='ct-chart-bar'] {
      g[class='ct-series ct-series-a'] {
        line[class='ct-bar'] {
          stroke-width: 30px;
        }
      }
    }
  }
  }
}
