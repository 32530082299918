@import "../../../../../styles/variables";

.individualsTableContainer {
    .tableHeader {
        font-family: $Roboto-Light;
        font-size: 24px;
        color: #000000;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .positionContainer {
        text-align: left;
    }
}
