@import "../../../../styles/variables";

.container{
    border: 1px solid rgba(0,0,0,0.10);
    border-radius: 3px;
    height: 174px;
    width: 100%;
    text-align: center;

    .header{
        padding-top: 25px;
        text-align: center;
        font-family: $Roboto-Light;
        font-size: 24px;
    }
    .subheader{
        padding-top: 10px;
        font-family: $Roboto-Regular;
        font-size: 16px;
    }
    .button{
        padding-top: 20px;
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: #0572D6;
        text-align: center;
        cursor: pointer;

        .icon{
            font-size: 14px;
            color: #0572D6;
            text-align: center;
        }

        .btnName{
            font-family: $Roboto-Medium;
            font-size: 14px;
            color: #0572D6;
            text-align: center;
            margin-left: 5px;
        }

        .noWatchlistButton{
            border: none;
            padding: 0px;
            background-color: transparent;
        }
    }
}

@media(min-width: 596px) and (max-width:1023px) {
    .container{
        width: 100%;
    }
}

@media (min-width: 375px) and (max-width: 595px) {
    .container{
        
        width: 354px;
        width: 100%;

        .subheader{
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}