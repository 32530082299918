@import '../../../../styles/variables';

.adjustedOptionContainer{
    width: 260px;
    padding: 15px;
    .adjustedHeaderRow{
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .btnPopUpClose{
            border: none;
            background-color: transparent;
            margin: 0px;
            padding: 0px;
            .btnPopUpCloseIcon{
                font-size: 14px;
                color: $color-black;
                text-align: center;
                line-height: 14px;
            }
        }
    }
    .adjustedItemRow{
        display: flex;
        padding: 4px 0px 6px 0px;
        .adjustedLabel{
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-gray;
            line-height: 16px;
            text-align: left;
        }
        .adjustedValue{
            font-family: $Roboto-Medium;
            font-size: 12px;
            color: $color-black;
            text-align: right;
            line-height: 16px;
        }
        .col{
            width:50%;
        }
        .col1{
            width: 39%;
        }
        .col2{
            width: 61%;
        }
    }
}