@import "../../../styles/variables";
.genericTableContainer {
  .tableStyle { border-collapse: collapse; width: 100%; }
  margin: 0px 10px 0 0px;
  position: relative;
  clear: both;
  .genericTable, .genericTableColumn  {
    margin: 0;
    padding: 0;
    background: $color-white;
    // white-space: nowrap;
    overflow-x: auto;
    width: 100%;
    border: 1px solid $color-white-whisper;
    
    .fixedHeader {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0;
        z-index: 99;
        .ai{
          max-width: 100% !important;
          div {
            max-width: 100% !important;
          }
        }
    }
    .fixedHeaderAI {
      position: -webkit-sticky !important;
      left: 0;
      z-index: 99;
      .ai{
        max-width: 100% !important;
        div {
          max-width: 100% !important;
        }
      }
  }
    th{
      font-family: $Roboto-Medium;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      // padding: 12px 5px;
      padding: 12px 0px 12px 10px;
      background-color: $color-white;
    }
    th:first-child{
      padding: 12px 0px;
    }
    td {
      font-family: $Roboto-Medium;
      font-size: 14px;
      line-height: 18px;
      padding: 4px 5px;
      background-color: $color-white;
    }
    tr {
      height: 44px;
    }
    tr td:first-child, th:first-child {
      padding-left: 10px;
    }
    tr td:last-child, th:last-child {
      padding-right: 10px;
    }
   
    .header {
      height: 44px;
      border-bottom: 1px solid $color-divider-gray;
      th {
        color: $color-black;
        position: relative;
      }
    }
    .body {
      border-top: 0.5px solid $color-white-whisper;
      td {
        font-family: $Roboto-Regular;
        color: $color-black;
        padding-right: 10px;
      }
    }
    .groupedHeaderRow {
      font-family: $Roboto-Regular;
      font-weight: 500;
    }
    .expandCollapseBtn {
      padding-right: 10px;
    }
    .adjustGroupedRow {
      padding-left: 33px !important;
    }
    .green {
      color: $color-green;
    }
    .red {
      color: $color-red;
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }      
    .center {
      text-align: center;
      align-items: center;
    }
    .showSuffixAsterisk {
      position: relative;
      bottom: 4px;
    }
    .separator {
      padding-right: 10px;
      height: 18px;
      min-width: 30px;
    }
    .separatorHeader {
      border-right: 1px solid $color-light-gray;
      display: flow-root;
    }
    .separatorSpan {
      width: 100%;
      display: block;
      border-right: none !important;
    }
    .noSeparator {
      padding-right: 10px;
      height: 18px;
      min-width: 30px;
    }
    .valueseparator {
      padding-right: 10px;
    }
    ::-webkit-scrollbar {
      width: 14px;
  }
  ::-webkit-scrollbar-thumb {
      border-right: 12px $color-divider-gray solid;
  }

  }
  .genericTableETF, .genericTableColumnETF  {
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    
    .fixedHeader {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0;
        z-index: 99;
        .ai{
          max-width: 100% !important;
        }
    }
    th{
      font-family: $Roboto-Medium;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding: 12px 5px;
      background-color: $color-white;
    }
    td {
      font-family: $Roboto-Medium;
      font-size: 14px;
      line-height: 18px;
      padding: 4px 5px;
      background-color: $color-white;
    }
    tr {
      height: 44px;
    }
    tr td:first-child, th:first-child {
      padding-left: 10px;
    }
    tr td:last-child, th:last-child {
      padding-right: 10px;
    }
    .header {
      height: 44px;
      border-bottom: 1px solid $color-divider-gray;
      th {
        color: $color-black;
        position: relative;
      }
    }
    .body {
     border-top: 0.5px solid $color-white-whisper;
      td {
        font-family: $Roboto-Regular;
        color: $color-black;
      }
    }
    .bodyWithoutMargin{
      border-top: 0px;
    }
    .green {
      color: $color-green;
    }
    .red {
      color: $color-red;
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }      
    .center {
      text-align: center;
      align-items: center;
    }
    .showSuffixAsterisk {
      position: relative;
      bottom: 4px;
    }
    .separator {
      padding-right: 10px;
      height: 18px;
      min-width: 30px;
    }
    .separatorHeader {
      border-right: 1px solid $color-light-gray;
      display: flow-root;
    }
    .separatorSpan {
      width: 100%;
      display: block;
      border-right: none !important;
    }
    .noSeparator {
      padding-right: 10px;
      height: 18px;
      min-width: 30px;
    }
    .valueseparator {
      padding-right: 10px;
    }
    ::-webkit-scrollbar {
      width: 14px;
  }
  ::-webkit-scrollbar-thumb {
      border-right: 12px $color-light-gray solid;
  }

  }
  .fallInValue {
    color: $color-fallInValue-red;
  }
  .riseInValue {
    color: $color-riseInValue-green;
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 5px 4px 0 0px;
  }
  .genericTableColumnShadow {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    max-width: 40px;
    height: 100%;
    z-index: 9999;
  }
  .genericTableColumnShadowRight {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    max-width: 40px;
    height: 100%;
    z-index: 9999;
  }
  .genericTableColumn {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: $color-white;
    box-sizing: border-box;
    z-index: 1;
    width: 285px;
    left: 0;
    overflow: hidden;
    box-shadow: none;
    .leftColumnHeaderFixed {
      width: 2729px;
      overflow: hidden;
      table {
        width: 100%;
      }
    }
  }
  .genericTable {
    overflow-x: auto;
    overflow-y: auto;
    height: auto;
  }

.genericTable::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

.genericTable::-webkit-scrollbar-track {
  background: $color-white-opacity;        /* color of the tracking area */
}

.genericTable::-webkit-scrollbar-thumb {
  background-color: $color-light-gray;    /* color of the scroll thumb */
}
  .tooltiptextIcon, .tooltiptextNameDisplay, .tooltiptextNameHidden {
    display: none;
  }
  .nameCell, .headerCell {
    position: relative;
  }
  .nameCell:hover .tooltiptextNameDisplay,  .headerCell:hover .tooltiptextNameDisplay{
    display: block;
    font-family: $Roboto-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    position: absolute !important;
    width: auto;
    padding: 18px 16px;
    white-space: nowrap;
    background-color: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: $color-black !important;
  } 
  .nameCell:hover .tooltiptextNameDisplayPosition {
    z-index: 1;
    top: -55px;
  }
  .headerCell:hover .tooltiptextNameDisplay{
    z-index: 99999;
    top: 20px;
    right: 15px;
  }
  .symbolLink {
    width: fit-content;
    float: left;
    color: $color-blue;
    cursor: pointer;
    margin-left: -3px;
    max-width: 72px;
  }
  .symbolLinkforaitable {
    width: 75px;   
    float: left;
    color: $color-blue;
    cursor: pointer;
    margin-right: 3px;
    margin-left: -3px;
  }
  .productIconText{
    max-width: 30px;
    height: 12px;
    font-family: $Roboto-Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: $color-white;
    padding: 2px;
    position: relative;
}
.productIconText:hover .tooltiptextIcon {
    display: block;
    font-family: $Roboto-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    background-color:$color-dark-gray;
    position: absolute;
    top: -30px;
    width: auto;
    padding: 6px;
    border-radius: 3px;
    white-space: nowrap;
    margin-left: -2px;
  }
.lockIcon {
    display: inline-block;
    padding-left: 1px;
  }
  .btnPopUpClose {
    border: none;
    outline: none;
    color: $color-red-cross;
    background-repeat: no-repeat;
    margin: 8px 9.5px 7px 9.5px;
    cursor: pointer;
    font-size: 18px;                
    text-align: center;
    line-height: 18px;
    padding: 0px;
    background-color: $color-white;
  }
  .btnPopUpCloseAI{
    margin: 0 !important;
  }

.productIconSMAL, .productIconModL{
    width: 30px;
    height: 16px;
    background-color: $color-mod-productIcon;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    margin-bottom: 5px;
  }

.productIconSMA, .productIconMOD{
    width: 30px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $color-mod-productIcon;
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 5px;

}
.productIconMF, .productIconETF, .productIconCEF{
    width: 28px;
    height: 17px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 5px;
}
.productIconMF{
  background: $color-mf-productIcon;
}
.productIconETF{
    background: $color-etf-productIcon;
}
.productIconCEF{
  background: $color-cef-productIcon;
}

  .chkPosition {
    position: relative;
    top: -7px;
    color: transparent;
  }
  .link {
    color: $color-blue !important;
    cursor: pointer;
  }
  .sortingIcon {
    margin-right: 5px;
    margin-left: 5px;
    display: inline-block;
    svg {
      width: 12px;
      height: 13px;
    }
  }
  .setWidth {
    display: inline-block;
  }
    .rowHeader{
      font-family: $Roboto-Medium;
      font-weight: 500;
    }
    .globeRating {
      margin-left: 2px;
    }
  .centeralign{
    align-items: center;
    display: inline-block;
  }
  .collapseExpandIcon{
    display: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    color: $color-blue;
    line-height: 18px;
    right: 10px;
    position: relative;
}
.symbolWithNameHeader {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 175px;
}

.symbolWithNameHeaderWithSpace {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
td.left div, td.right div {
    max-width: 250px;
    min-width: 75px;
    div {
      position: relative;
    }
}
.plusIcon {
  position: absolute;
  top: 10px;
  left: -3px;
}
@media (max-width: 1023px), only screen and (max-device-width:1023px) {
.tableStyle:after {
  box-shadow: none;
}
td.left div, td.right div {
  max-width: 200px !important;
}
.symbolWithNameHeader {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -o-text-overflow: ellipsis;
	white-space: normal;
  height: auto !important;
}
}
@media (max-width: 595px), only screen and (max-device-width:595px) {
  .collapseExpandIcon{
    display: inline-block;
  }
  .hiddencolumns{
    display: none;
  }
  td.left div, td.right div {
  max-width: 150px !important;
  }
}
@media (min-width: 595px) and (max-width: 1023px) {
  .bodyWithoutMargin {
      border-top: 0.5px solid $color-divider-gray !important;
      border-bottom: 0.5px solid $color-white-whisper;
    }
    .genericTableColumnShadowRight{
      position: 0 !important;
    }
  .genericTableETF{
    tr td:nth-child(3){
      padding-right: 0px;
    }
    td[role='gridcell']:nth-last-child(2)  {
      border-right: 1px solid $color-divider-gray;
    }
  }
}
}