@import "../../../../../styles/variables";

.etfResearchContainer {
    background-color: $color-white;

    .quoteBarContainer {
        padding: 0 20px;
    }
}

.fundamentalsDD {
    padding-bottom: 60px !important;
}

.showFundamentalDD {
    display: none;
}

.removeMargin {
    &>div {
        &>ul {
            margin-bottom: 0px !important;
        }
    }

}

.etfResearch {
    background: $color-white;
    padding: 0 20px;

    .tabStyle {
        background-color: $color-white;
        padding-left: 0;
        margin-bottom: 0;
    }

    .snapshotContainer {
        display: flex;
        flex-direction: row;

        .leftSectionContainer {
            display: flex;
            flex-direction: column;
            width: 66%;
            margin-right: 2%;
            margin-bottom: 25px 0px;

            &>div {
                margin-top: 30px;
            }

        }

        .mobilePerformance {
            display: none;
        }

        .stockHightlights {
            background: $color-white-opacity;
            box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
            border-radius: 3px;
            width: 100%;
        }

        .rightSectionContainer {
            display: flex;
            flex-direction: column;
            width: 32%;

            &>div {
                margin-top: 30px;
            }
        }
    }

    .portfolioContainer {

        .container {
            display: flex;
            flex-direction: row;

            .leftSectionContainer {
                display: flex;
                flex-direction: column;
                width: 66%;
                margin-right: 2%;
                margin-bottom: 25px 0px;

                &>div {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }

            }

            .rightSectionContainer {
                display: flex;
                flex-direction: column;
                width: 32%;

                &>div {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }
        }
    }

    .desktopPerformance{
        display: block;
    }

    .stockValuationContainer {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        .leftSectionContainer {
            display: flex;
            flex-direction: column;
            width: 66%;
            margin-right: 2%;
            margin-bottom: 25px 0px;

            &>div+div {
                margin-top: 25px;
            }
        }

        div .stockHightlights {
            width: 32%;
        }

        .rightSectionContainer {
            display: flex;
            flex-direction: column;
            width: 32%;

            &>div+div {
                margin-top: 30px;
            }
        }
    }

    .similarFund {
        width: 168px;
        height: 34px;
        left: 20px;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 34px;
        color: #000000;
        display: inline;
    }

    .description {
        width: 280px;
        height: 16px;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #666666;
        display: inline;
        margin-left: 10px;
    }

    .similarFundsContainer {
        width: 100%;
    }

    .styleBoxContainer {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        .leftSectionContainer {
            display: flex;
            flex-direction: column;
            width: 66%;
            margin-right: 2%;
            margin-bottom: 25px 0px;

            &>div+div {
                margin-top: 25px;
            }
        }

        .leftSectionContainerWithCondition {
            display: flex;
            flex-direction: column;
            width: 100% !important;
            margin-bottom: 25px 0px;

            &>div+div {
                margin-top: 25px;
            }
        }

        .rightSectionContainer {
            display: flex;
            flex-direction: column;
            width: 32%;
            min-width: 312px;

            &>div+div {
                margin-top: 30px;
            }

            .styleBox {
                display: flex;
                flex-direction: column;
            }
        }

    }

    .newRatingContainer {
        display: flex;
        flex-direction: row;
        margin-top: 15px;

        .leftSectionContainer {
            display: flex;
            flex-direction: column;
            width: 66%;
            margin-right: 2%;
            margin-bottom: 25px 0px;

            &>div+div {
                margin-top: 25px;
            }
        }

        .leftSectionContainerWithCondition {
            display: flex;
            flex-direction: column;
            width: 100% !important;
            margin-bottom: 25px 0px;

            &>div+div {
                margin-top: 25px;
            }
        }

        .rightSectionContainer {
            display: flex;
            flex-direction: column;
            width: 32%;
            margin-top: 15px;

            min-width: 312px;

            &>div+div {
                margin-top: 30px;
            }
        }

    }


    .holdingAndAllocationContainer {
        display: flex;
        flex-direction: row;

        .leftSectionContainer {
            display: flex;
            flex-direction: column;
            width: 66%;
            margin-right: 2%;
            margin-bottom: 25px 0px;

            &>div {
                margin-top: 25px;
            }
        }

        .assetAllocation {
            background: $color-white-opacity;
            box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
            border-radius: 3px;
            width: 100%;
        }

        .rightSectionContainer {
            display: flex;
            flex-direction: column;
            width: 32%;

            &>div {
                margin-top: 30px;
            }
        }
    }


    .similarFundContainer {
        display: flex;
        flex-direction: column;
        padding: 0px 0px 15px 0px;

        .leftSectionContainer {
            width: 100%;
            margin-bottom: 30px 0px;

            &>div+div {
                margin-top: 30px;
            }
        }

        div .stockHightlights {
            width: 100%;
        }

        .rightSectionContainer {
            width: 100%;

            &>div+div {
                margin-top: 30px;
            }

            .styleBox {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .earningsAndEstimatesContainer {
        display: flex;
        flex-direction: row;

        .barContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .fundamentalsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .buttonFundamental {
        background: $color-white;
        border-radius: 0 3px 3px 0;
        border: 1px solid $color-blue;
        width: 110px;
        color: $color-black;
        font-family: "Roboto-Regular", Arial, sans-serif;
        font-size: 12px;
        line-height: 16px;
        padding: 6px 0px;
    }

    .floatRight {
        float: right;
        padding-bottom: 20px;
    }

    .performanceApp {
        display: flex;
        flex-direction: row;
    }

    .earningsAndEstimateApp {
        display: flex;
        flex-direction: row;
    }

    .dividendPayoutContainer {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        padding-top: 20px;

        .leftSectionContainer {
            width: 66%;
            margin-right: 2%;
        }

        .rightSectionContainer {
            margin-right: 0%;
        }
    }

    .peerAnnualYieldsContainer {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        padding-top: 20px;

        .leftSectionContainer {
            width: 66%;
            margin-right: 2%;
        }

        .rightSectionContainer {
            width: 32%;
            display: flex;
            flex-direction: column;
        }
    }
}

.header {
    font-family: $Roboto-Light;
    font-size: 28px;
    color: $color-black;
    line-height: 34px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding: 10px 0;
}

.symbolSearchContainer {
    display: flex;
    position: relative;
    float: right;
    border: none;
}

.appGap {
    height: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.appGapOnlyBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.btnAnnualQtr {
    text-align: right;
    margin-bottom: 10px;

    button {
        background-color: $color-blue;
        color: $color-white;
        padding: 5px 13px;
        border-radius: 5px;
        border: none;
        margin-left: 10px;

        &.selected {
            background-color: $color-blue;
            color: $color-white;
        }
    }
}

@media (min-width: 596px) and (max-width: 1023px),
only screen and (min-device-width: 596px) and (max-device-width:1023px) {
    .etfResearchContainer {
        .quoteBarContainer {
            padding: 0 15px;
        }
    }

    .fundamentalsContainer {
        display: inline-block;
        width: 100%;
    }

    .showFundamentalDD {
        display: block;
    }

    .hide {
        display: none;
    }

    .etfResearch {
        padding: 0 15px;

        .fundamentalsContainer {
            display: inline-block;
            width: 100%;
        }

        .hide {
            display: none;
        }

        .showFundamentalDD {
            display: block;
        }

        .stockValuationContainer {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }
            }

            div .stockHightlights {
                width: 100%;
            }

            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }
            }
        }

        .snapshotContainer {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }

            }

            .mobilePerformance {
                display: block;
            }

            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }
            }
        }

        .portfolioContainer {
            .container {
                display: flex;
                flex-direction: column;

                .leftSectionContainer {
                    width: 100%;
                    margin-bottom: 30px 0px;

                    &>div+div {
                        margin-top: 30px;
                    }
                
                }
                .rightSectionContainer {
                    width: 100%;
                    &>div+div {
                        margin-top: 30px;
                    }
                }
            }
        }

        .desktopPerformance{
            display: none;
        }

        .styleBoxContainer {
            display: flex;
            flex-direction: row;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }
            }

            div .stockHightlights {
                width: 100%;
            }

            .rightSectionContainer {
                width: 100%;
                display: flex;
                flex-direction: row;

                &>div+div {
                    margin-top: 30px;
                    display: flex;
                    margin-right: 10px;
                }

                .styleBox {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px
                }
            }
        }

        .newRatingContainer {
            display: flex;
            flex-direction: column;
            padding: 0px 0px 0px 0px;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }
            }

            div .stockHightlights {
                width: 100%;
            }

            .rightSectionContainer {
                width: 100%;
                display: flex;
                flex-direction: column;

                &>div+div {
                    margin-top: 30px;
                    display: flex;
                }

                .styleBox {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px
                }
            }
        }



        .holdingAndAllocationContainer {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }
            }

            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }
            }
        }

        .performanceApp {
            flex-direction: column;
        }

        .earningsAndEstimateApp {
            flex-direction: column;
        }

        .dividendPayoutContainer {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
            }
        }

        .peerAnnualYieldsContainer {
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;

            }

            .rightSectionContainer {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 20px;
                justify-content: space-between;

                .bottomSectionContainer {
                    display: flex;
                    flex-direction: row;
                }
            }
        }
    }
}

@media (min-width: 375px) and (max-width: 595px),
only screen and (min-device-width: 375px) and (max-device-width:595px) {
    .symbolSearchContainer {
        float: none !important;
    }

    .hide {
        display: none;
    }

    .etfResearch {
        .hide {
            display: none;
        }

        .stockValuationContainer {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }
            }

            div .stockHightlights {
                width: 100%;
            }

            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }
            }
        }

        .snapshotContainer {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }

            }

            .mobilePerformance {
                display: block;
            }


            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }
            }
        }

        .portfolioContainer {
            .container {
                display: flex;
                flex-direction: column;

                .leftSectionContainer {
                    width: 100%;
                    margin-bottom: 30px 0px;
    
                    &>div+div {
                        margin-top: 30px;
                    }
                    
                }
    
                .rightSectionContainer {
                    width: 100%;
    
                    &>div+div {
                        margin-top: 30px;
                    }
                }
            }
        }

        .desktopPerformance{
            display: none;
        }

        .holdingAndAllocationContainer {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }
            }

            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }
            }
        }

        .styleBoxContainer {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }
            }

            div .stockHightlights {
                width: 100%;
            }

            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }

                .styleBox {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .newRatingContainer {
            display: flex;
            flex-direction: column;
            padding: 0px 0px 0px 0px;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }
            }

            div .stockHightlights {
                width: 100%;
            }

            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }

                .ratingsContainer {
                    margin-top: 15px;
                }

                .styleBox {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .performanceApp {
            flex-direction: column;
        }

        .earningsAndEstimateApp {
            flex-direction: column;
        }

        .dividendPayoutContainer {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
            }
        }

        .peerAnnualYieldsContainer {
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;

            }

            .rightSectionContainer {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 30px;
            }
        }

        .yearlyPaymentTrendContainer {
            display: flex;
            flex-direction: column;

        }
    }

    .etfResearchContainer {
        width: 100%;
        padding: 10px;

        .quoteBarContainer {
            padding: 0;
            margin-top: -10px !important;
        }

        .etfResearch {
            background: $color-white;
            padding: 0;
        }
    }
}