$color-netxwealth-gray-border: #dbdbdb;
$color-netxwealth-lightblue-border: #88C6FF;
$color-netxwealth-lightblue-dropdown-border: #70BBFF;
$color-dark-gray: #37474F;
$color-white: #FFFFFF;
$color-light-seprator-gray:#BDBDBD;
$color-black: #000000;
$color-red: #ED0808;
$color-green: #348638;
$color-green-light:  #48B748;
$color-white-opacity: #f7f7f7;
$color-dark-gray-2: #E7E7E7;
$color-white-whisper: #E5E5E5;
$color-blue:#0572D6;
$color-gray:#666666;
$color-light-gray:#B2B2B2;
$color-netxwealth-news-research-grey-border : #999999;
$color-box-shadow : rgba(0, 0, 0, 0.1);
$color-bar-graph-blue: #293DC0;
$color-graph-light-blue: #2FAEFF;
$color-bar-graph-blue-dark: #004480;
$color-divider-gray: #B2B2B2;
$color-gray-border: #E5E5E5;
$color-radio-button-green: #48B748;
$color-table-header-bottom-border: rgba(0, 0, 0, 0.3);
$color-container-box-shadow: rgba(0, 0, 0, 0.15);
$color-very-light-gray: #DDDDDD;
$color-bar: #C7C7C7;
$color-divider-grey-2: #B3B3B3;
$color-gray-chart-line: #D9D9D9;
$color-orange: #E65100;
$color-dividend-performance-area-blue: #0095FF;
$color-dark-orange: #D97E07;
$color-dark-green: #00A695;
$color-dark-magenta: #AA01AA;
$color-dark-orange-shade: #FF573F;
$color-red-light: #BF360C;
$color-light-greeen: #00AC00;
$color-light-purple: #093055;
$color-navy-blue: #9085FF;
$color-box-shadow-26: rgba(0, 0, 0, 0.26);
$color-box-transparent: rgba(255, 255, 255, 0);
$color-light-green: #00B3A1;
$color-actual-green: #00AC00;
$color-purple: #093055;
$color-seperation-line : rgba(0, 0, 0, 0.07);
$color-measures-black: #37474f;
$color-mod-productIcon : #0078CD;
$color-mf-productIcon : #AE6506;
$color-etf-productIcon : #008575;
$color-cef-productIcon : #7168D4;
$color-red-cross: #E40707;
$color-fallInValue-red: #dc3545;
$color-riseInValue-green: #008338;
$color-black-solid: #333333;
$color-black-solid-2: #2A2A2A;
$color-light-gray-5: #9E9E9E;
$color-light-gray-6: #58595A;
$color-light-gray-7: #676767;
$color-blue-solid-2: #287CB9;
$color-pill-blue: #E8F0F7;
$color-mid-dark-grey: #464C51;