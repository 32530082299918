@import '../../../../styles/variables';
    .quoteCard{
		position: relative;
		height: 303px;
		background: $color-white;
		box-shadow: 0px 1px 4px 1px $color-box-shadow;
		border-radius: 3px;
    }
    .quoteCardHeader{
		height: 139px;
		width: 312px;
        .sparkChartDomElement{
			width:312px;
			height: 139px;
		}
    }

	.quoteCardBody{
		height: 138px;
		padding: 15px 15px 0px 15px;
		.quoteHeaderText {
			font-size: 14px;
			color:$color-black;
			line-height: 18px;
			font-weight: 500;
			font-family: $Roboto-Regular;
			white-space: normal;
			max-height: 36px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box !important;
			-webkit-box-orient: vertical;
			width: 100%;
			-webkit-line-clamp: 2;
			/* autoprefixer: off */
		}
		
		.quoteIndentDetailText {
            color:$color-gray;
            font-family: $Roboto-Regular;
		font-style: normal;
			margin-top: 4px;
            font-weight: 400;
            word-break: break-all;
			font-size: 14px;
			line-height: 18px;
			white-space: normal;
			max-height: 36px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box !important;
			-webkit-box-orient: vertical;
			width: 100%;
			-webkit-line-clamp: 2;
			/* autoprefixer: off */

		}
	}
	.appGap {
		height: 1px;
		margin-left: 15px;
		margin-right: 15px;
		border-bottom: 1px solid $color-seperation-line;
	}
	.linkContainer {
		position: absolute;
		margin-left: -6px;
		bottom: 0;
		margin-bottom: 15px;
		width: auto;
	  }
