@import '../../../../../../styles/variables';
$base_color: #000000;
$gray: #cccccc;
$bg_color: #ffffff;

.sxSelectMain {
  display: flex;
  justify-content: space-between;
  //width: 20%;
  margin: 0 auto;
  color: $base_color;

  ul {
    margin-block-end: 0;
    margin-block-start: 0;
  }
  li{
    svg{
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 16px;
      color: #666666;
      margin-right: 11px;
    }
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
  }
}

.sxSelectWrapper {
   border-radius: 3px; //commented for PRW-958 and PRW-959
}

.sxSelectHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 18px;
  cursor: default;
  padding: 8px 10px 8px 10px;
  margin-bottom: 0.21em;
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: $Roboto-Regular;
  &:hover {
    cursor: pointer !important;
    border: 1px solid $color-graph-light-blue !important;
  }
}
.sxSelectPlaceholder {
  color: #000000;
  font-size: 14px;
  line-height: 18px;
}

.separatorWrapper {
  display: flex;
  justify-content: flex-end;
  svg {
    width: 12px !important;
    height: 13px;
  }
}

.chevron {
  border-left: solid 1px $gray;
  width: 2.5em;
  fill: rgb(221, 221, 221);
  margin: 0;
  padding: 0;

  &:hover {
    fill: $base_color;
  }
}

.cross {
  composes: chevron;
  border-left: 0;
}
div[name="sxSelectListsWrapper"]{
  width: calc(100% - 15px - 15px);
}
.sxSelectListsWrapper{
  z-index: 10;
  padding-top: 15px;
  display: none;
  position: absolute;
  left: 15px;

  //width: 636px;
  ul#nav li a:hover,ul#nav li.active a{
    background-color: red;
    border-left: 2px solid #0572D6;
   }
  ul{
    height: 194px;
    overflow-y: auto;
    scrollbar-color:#B2B2B2 #F7F7F7;
    scrollbar-width: thin;
  }
  li.selectedItemHeader{
    width: 218px;
    font-family: $Roboto-Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #999999;
      }
}
.sxDropdownList {
  padding: 0;
  list-style: none;
  text-align: left;
  // border: solid 1px $gray; //commented for PRW-958 and PRW-959
  border-radius: 3px;
  position: relative;
  background-color: $bg_color;
  font-family: $Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  width: 318px;
  flex-shrink:  0;
}

ul[name="mobileHeaderText"] {
  padding: 0;
  list-style: none;
  text-align: left;
  border-bottom: solid 1px $gray; // for PRW-958 and PRW-959
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: $bg_color;
  font-family: $Roboto-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
  overflow-y: hidden;
  li{
    overflow-y: clip;
    overflow-x: auto;
    width: 315px;
    display: block;
    text-overflow: clip;
    svg {
      background-color: $bg_color;
      font-family: $Roboto-Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-left: 11px;
      color: #999999;
    }
  }
}

.sxListItem {
  padding: .6em 0; // commented code to fix the design issues in test center
  line-height: 18px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  &:not([class*=sxSelected]):hover {
      background-color:  #F2F2F2;
      border-left: 2px solid #0572D6;
  }

  .sxListItemLabel {
    padding: .72em;
  }
}

.sxSelected {
  background-color:  rgba(0, 0, 0, 0.07);
  border-left: 2px solid #0572D6;
}

.gt {
  margin-right: 2%;
  fill: $base_color;
}

.sxSubMenuWrapper{
  display: flex;
}

  /**
  * Scroll Adjustments
  */
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  /* Track */
   ::-webkit-scrollbar-track {
      background:#F7F7F7 !important;
  }
  /* Handle */
   ::-webkit-scrollbar-thumb {
      background: #B2B2B2 !important;
  }
  /* Handle on hover */
   ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
  @media screen and (min-width: 1023px), only screen and (min-device-width:1023px) {
    div[name="L3"], div[name="L2"] {
      ul {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    ul[name="L2"] {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    ul[name="L1"] {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
@media screen and (min-width: 375px) and (max-width: 1023px), only screen and (max-device-width:1023px) {
  div[name="sxSelectListsWrapper"]{
    // width: 100%;
    width: calc(100% - 15px - 15px);
  }
  div[name="L3"], div[name="L2"] {
    ul {
      height: 156px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      width: 100%;
      overflow-y: auto;
    }
  }
  div[name="L3"] {
      width: 100%;
  }
  .sxDropdownList {
    // width: calc(100vw - 82px);
    width: 100%;
 }
 ul[name="L2"]{
   height: 150px;
 }
  ul[name="mobileHeaderText"] li{
     width: calc(100vw - 84px);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
 }
  ul[name="mobileHeaderText"]{
    width: 100%;
    li {
     width: 100%;
     text-overflow: ellipsis;
     overflow: hidden;
  }
  .sxSubMenuWrapper{
    display: block;
  }
}
}
