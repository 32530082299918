@import "../../../styles/variables";

.chartTools {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0px;
  .basicToolItems {
    display: flex;
     button {
      margin-right: 10px;
      padding: 7px 9px;
      background-color:  $color-white;
    }
  }
  .chartIndicator {
    left: 56px;
  }
  .chartEvents {
    position: absolute;
    top: -6px;
    left: 54px;

    div[data-popover-body-container="true"] {
        padding: 0px !important;
    }
  }
  .chartTypeContainer {
    ul {
      margin: 0;
      > li {
        padding: 4.8px 10px;
        border-radius: 3px !important;
        background: #ffffff;
      }
      > li:focus {
        border: 1px solid rgba(0, 0, 0, 0.3) !important;
      }
    }
  }
  .toolsContainer {
    margin-left: 70px;
  }
  .linkContainer {
    display: flex;
    margin-left: 65px;
    button {
      padding-left:0px;
    }
  }
}
.advancedChartTools {
  display: flex;
  justify-content: space-between;
  
  .linkContainer {
    margin-left: 0;
    > button {
      padding-left:0px;
    }
  }
}

.addTextHeader {
  display: flex;
  justify-content: space-between;
  background-color: #ddd;
  padding: 10px;
  border-radius: 3px;
  color: $color-dark-gray;
  .closeButton {
    border: none;
    margin: none;
    outline: none;
    background: none;
    padding: none;
  }
}


.chartToolsMenuContainr {
  padding-top: 15px;
  border-top: 1px solid rgba(0,0,0,0.14);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;

  .clearDrawingsContainer{
    button {
      padding-left:0px;
    }
  }

  > div {
    margin-right: 10px;
  }

  button[name="btnChartColorPalatte"] {
    position: relative;
    width: 80px !important;
    padding: 4px;
    line-height: 34px;
    height: 34px;
  }

  button[name="ddlArrow"] {
    width: 114px;
    height: 34px;
    line-height: 34px;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 1023px) {
  .advanceSaveChart {
    display: none;
  }
}
@media screen and (max-width: 596px) {
  .chartTools {
    flex-direction: column;
    .basicToolItems {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 10px;
      button {
        margin-right: 0px;
      }
      > div {
        width: 48%;
        margin-right: 5px;
        > button {
          width: 100%;
          background: $color-white;
        }
      }
      > div > ul {
        width: 46%;
        > li {
          background: $color-white;
        }
      }
    }
    .linkContainer {
      justify-content: flex-end;
      > button {
        padding-right: 0px;
        margin-right: 0px;
      }
    }
  }
}
