@import '../../styles/variables';

.watchlistContainer {
    height: 138px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.10);
        
    table {
        width: 100%;
        white-space: nowrap;
        margin: 0;
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        table-layout: auto;
    }

    td,
    th {
        // padding: 1rem 1rem;
        
        font-weight: inherit !important;
        
    }

    td {
        border-bottom: 1px solid $color-white-whisper;
    }

    .watchlistNoDataTable {
        tbody {
            tr {
                td {
                    padding: 0px;
                    border-bottom: 0px solid rgba(0, 0, 0, 0.10);
                }
            }
        }
    }

    .watchlistTable {
        thead {
            tr {
                height: 44px;
                th {
                    font-family: $Roboto-Medium;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    text-align: right;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 27px;
                    font-weight: 500;
                    border-bottom: 1px solid $color-netxwealth-news-research-grey-border;
                    width: 96px;
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    background: $color-white;
                }

                th:first-child {
                    padding-left: 12px;
                }
                th:nth-child(2) {
                    width: 200px;
                    text-align: left !important;
                }
                th:last-child {
                    padding-right: 15px;
                    border-right: none;
                    div{
                        border-right: none;
                        padding-right: 0;
                    }
                }
            }
        }

        tbody {
            tr {
                height: 44px;

                td {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    text-align: left;
                    overflow-wrap: break-word;
                    width: 96px;
                }

                td:first-child {
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-gray;
                    line-height: 18px;
                    white-space: initial;
                    padding-left: 5px;
                    span > div {
                        margin-bottom: 4px;
                    }
                    div{
                        display: flex;
                        align-items: end;
                    }
                }
                td:nth-child(2) {
                    max-width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                td:nth-child(3)  {
                    text-align: end;
                    padding-right: 15px;
                }
                td:last-child {
                    text-align: end;
                    padding-right: 15px;
                }
            }
        }

        .green {
            color: $color-green;
        }

        .red {
            color: $color-red
        }
    }

    .shortLine {
        border-right: 1px solid $color-light-gray;
        padding: 0 10px 0 10px;
    }
}

.maxWidth {
    width: 100%;
}

@media(max-width:374px) {
    .watchlistContainer {
        .headcol {
            padding-left: 10px;
            position: sticky;
            left: 0;
            z-index: 9999;
            border-right: 1px solid $color-light-gray-4;
        }

        .watchlistTable {
            thead {
                tr {
                    th:first-child {
                        clip-path: inset(0px -15px 0px 0px);
                        min-width: 93px;
                        max-width: 96px;
                        padding-right: 10px;
                    }
                    th:last-child {
                        padding-right: 10px;
                    }
                }
            }

            tbody {
                tr {
                    td:first-child {
                        clip-path: inset(0px -15px 0px 0px);
                        white-space: initial;
                        word-break: break-word;
                        
                    }

                    td:last-child {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}