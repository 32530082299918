@import "../../../../styles/variables";

.chartEventContainer{
    padding: 15px;
    .eventHeader{
      padding-bottom: 10px;
      .eventName{
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        margin-right:4px;
      }
      .eventDate{
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
      }
    }
    
    .eventDescription{
      display: flex;
      padding:4px 0px 6px 0px;
      border-bottom: 1px solid rgba(0,0,0,0.14);
      .eventCol1{
        display: flex;
        width:72%;
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
      }
      .eventCol2{
        display: flex;
        width:28%;
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-black;
        text-align: right;
        line-height: 16px;
        justify-content: flex-end;
      }
    }
    .eventDescription:last-child{
      border-bottom: none;
    }
  }