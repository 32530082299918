@import "../../../../styles/variables";

.symbolSearchContainer {
  width: 100%;
  .sybmolSearchBodySection {
    padding: 10px;
    .searchResults {
      min-height: 215px;
      padding: 10px 0;
      .resultsHeader {
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
        padding: 10px;
        background: $color-white-opacity;
        margin-bottom: 10px;
      }
      .checkBoxContainer {
        margin-bottom: 8px;
      }
    }
    .errorBox {
      color: $color-red;
      font-size: 14px;
      text-align: center;
    }
    .defaultList {
      min-height: initial;
      padding: 10px 0 0 0;
    }
  }
  .mobileSettings {
    width: 100%;
    bottom: 0px;
    position: fixed;
  }
  .searchFooter {
    display: flex;
    justify-content: center;
    border-top: 1px solid $color-divider-gray;
    padding: 10px 0;
    button:first-child {
      margin-right: 10px;
    }
    .apply {
      background: $color-blue;
      color: $color-white;
    }
    .cancel {
      background: $color-white;
      color: $color-blue;
    }
  }
}
