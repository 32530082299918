@import "../../../../../../styles/variables";

.indexCardContainer {
    // gap: 16px;
    font-family: $Roboto-Medium;
    padding-left: 20px;   
    overflow-y: auto;
    height: auto;
    max-height: 321px;
    padding-bottom: 20px;
    .cardContainer {
        max-height: 179px;
        min-height: 114px;
        background: $color-white;
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        width: 222px;
        display: inline-block;
        margin-right: 15px;
        margin-top: 15px;
        .styleLine {
            margin: 0;
            border-radius: 2px 2px 0 0;
            height: 3px;
            background: #0078CD;
        }
        .indexInformationContainer {
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            padding: 15px;
            font-family: $Roboto-Regular;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            text-align: left;
            color: #000000;
            // height: 26px;
           .header {
            height: 52px;
            font-size: 20px;
            line-height: 26px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: flex !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            .headerName{
                width: 95%;
            }
            .headerActionMenu{
                width: 5%;
                display: flex;
                justify-content: center;
                ul{
                    li{
                        padding: 15px;
                    }
                    li:first-child{
                        padding-bottom: 7.5px;
                    }
                    li:nth-child(2){
                        padding-top: 7.5px;
                    }
                }
            }
            /* autoprefixer: off */
           }
            .symbolContainer {
                font-size: 12px;
                line-height: 16px;
                padding-top: 10px;
                margin-bottom: -25px;
                min-height: 62px;
            }
            .symbolInfoContainer {
                //styleName: 05 Header/01 Standard;
                font-size: 16px;
                line-height: 22px;
                padding-top: 35px;
                color: #000000;
            }
            .symbolDescription{
                //styleName: 03 Body/01 Standard;
                font-size: 14px;
                line-height: 18px;
                color: #666666;
            }
        }
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .indexCardContainer {
        overflow-y: auto;
        .cardContainer {
            height: auto;
            min-height: 114px;
            width: 228px;
        }
    }
}
@media screen and (min-width: 596px) and (max-width: 1023px) and (orientation : landscape) {
    .indexCardContainer {
        overflow-y: auto;
        height: 114px;
        .cardContainer {
            height: auto;
            min-height: 114px;
            width: 228px;
        }
    }
}

@media screen and (max-width: 595px) {
    .indexCardContainer {
        max-height: calc(100vh - 194px);
        overflow-y: auto;
        .cardContainer {
            height: auto;
            min-height: 114px;
            width: 97%;
        }
    }
  }
  