@import "../../../../src//styles/variables";

.noData {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: #666666;
    width: 100%;
	letter-spacing: 0;
	line-height: 18px;
	text-align: center;
	margin: 0 auto;
	padding-top: 5%;
	padding-bottom: 3%;
    .iconNoData {
        width: 21px;
        height: 18px;
    }
    .noactivity{
        margin-top: 10px;
    }
}
