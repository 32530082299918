@import "../../../../styles/variables";

.divAnnContainer {
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;

    width: 312px;
    padding: 15px 15px 20px 15px;
    height: 130px;
    .header {
        font-family: $Roboto-Regular;
        font-size: 16px;
        color: $color-gray;
        line-height: 22px;
        padding-bottom: 5px;
    }
    .middle {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        padding-bottom: 15px;
        width: 250px;
        .symbolName {
            font-family: $Roboto-Bold;
        }
    }
    .dates {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-gray;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
    }
}