@import "../../../../../../styles/variables";

.stockHighlightDataDiv{
    display: flex;
    justify-content: space-between;
    width:100%;
    font-family: $Roboto-Regular;
    font-size: 14px;
    line-height: 18px;
    border-bottom: 1px solid rgba(0,0,0,0.10);

    .stockHighlightCol1{
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-black;
        line-height: 25px;
        width: 50%;
    }

    .stockHighlightCol1OverrideCompare{
        line-height: 16px;
    }

    .stockHighlightCol2{
        justify-content: flex-end;
        padding-right:10px;
        text-align: right;
        font-weight: 500;
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-black;
        line-height: 25px;
        width: 50%;
    }
}