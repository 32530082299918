@import '../../../../styles/variables';

.cardContainer {
    height: 100%;
}
.baseBallContainer {
    min-width: 228px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    height: 100%;
    .baseBallHeaderContainer {
    .topSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 11px 10px 15px 15px;
        height: 40px;

        .favoriteContainer {
            .iconArrowRight {
              font-size: 14px;
              color: #FFA000;
              text-align: center;
              line-height: 14px;
              margin-right: 5.5px;
            }
        }

        .nameContainer {
            width: 93%;
            display: flex;
            align-items: baseline;

            .name {
                font-family: $Roboto-Bold;
                font-size: 16px;
                color: $color-black;
                line-height: 22px;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 5px;
                >div {
                    display: inline;
                }
            }

            button {
                background-color: transparent;
                border: none;
                outline: none;
                position: relative;
                margin: 0;
                padding: 0;
                left: 2px;
                cursor: pointer;
                .info{
                    height: 14px;
                    width: 14px;
                }
            }

            div[id="bottom-popover"] {
                width: 235px;
                margin-left: 30px;
                word-break: break-word;
            }
        }

        .btnAction {
            display: flex;
        }
        .btnActionElement {
            min-width: 83px
        }
        .createWatchlistsButton {
            font-family: $Roboto-Medium;
            font-size: 14px;
            color: $color-blue;
            text-align: right;
            line-height: 18px;
            border: none;
            background: none;
            white-space: nowrap;
            cursor: pointer;
        } 
    }
}

    .middleSectionLable {
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 4px;
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
    }

    .shortLine {
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
        margin-left: 15px;
        margin-right: 15px;
    }

    .middleSection {
        display: flex;
        flex-direction: row;
        padding-left: 15px;
        .middleLeft {
            margin-right: 10px !important;
            svg {
                width: 70px !important;
                height: 70px !important;
                
            }
            
        }
    }
}
@media (max-width: 376px) {
    .baseBallContainer {
        min-width: 225px;
        .middleSection {
            padding-left: 15px;
            .middleLeft {
                svg {
                    width: 70px !important;
                    height: 70px !important;
                }
    
            }
        }
    }
}
@media (min-width: 596px) and (max-width: 768px) {
    .baseBallContainer {
        min-width: 182px;
        .middleSection {
            .middleLeft {
                svg {
                    width: 50px !important;
                    height: 50px !important;
                }
            }
        }
    }
}