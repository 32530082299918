@import '../../../styles/variables';

.chartSectionContainer {
    .chartContainer {
        svg {
            min-height: 300px;
        }

        svg[class="ct-chart-bar"] {
            g[class="ct-series ct-series-a"] {
                line {
                    stroke: $color-bar-graph-blue-dark;
                    stroke-width: 75px;
                }
            }

            g[class="ct-labels"] {
                span[class="ct-label ct-horizontal ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    text-align: center;
                    line-height: 16px;
                    font-weight: 400;
                    padding-top: 9px;
                }

                span[class="ct-label ct-vertical ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    text-align: right;
                    line-height: 16px;
                    font-weight: 400;
                    margin-top: 5px;
                    justify-content: flex-end;
                }


            }
        }

        g[class="ct-grids"] {
            line {
                stroke: $color-gray-chart-line;
                stroke-dasharray: none;
                stroke-width: 1px;
            }

            line[class="ct-grid ct-horizontal hiddenChartGridLine"] {
                stroke: none;
            }

            line[class="ct-grid ct-vertical"]:first-of-type {
                stroke: rgba(0, 0, 0, 0.30);
            }
        }

    }

    text[class='ct-barlabel'] {
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-black;
        text-align: center;
        line-height: 16px;
        font-weight: 400;
        padding-top: 9px;

    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .chartSectionContainer {
        .chartContainer {
            svg[class="ct-chart-bar"] {
                g[class="ct-series ct-series-a"] {
                    line {
                        stroke-width: 43px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    .chartSectionMaster {
        display: flex;
        // margin: 20px;

        .chartSectionContainer {
            max-width: 595px;
            overflow-x: auto;
            background-image:
                /* Shadows */
                linear-gradient(to right, white, white),
                linear-gradient(to right, white, white),

                /* Shadow covers */
                linear-gradient(to right, rgba(0, 0, 0, .1), rgba(255, 255, 255, 0)),
                linear-gradient(to left, rgba(0, 0, 0, .1), rgba(255, 255, 255, 0));

            background-position: left center, right center, left center, right center;
            background-repeat: no-repeat;
            // background-color: white;
            background-size: 20px 100%, 20px 100%, 20px 100%, 20px 100%;

            /* Opera doesn't support this in the shorthand */
            background-attachment: local, local, scroll, scroll;

            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;

            /* Firefox */
            .chartContainer {
                min-width: 596px;

                svg {
                    width: 596px !important;
                }

                svg[class="ct-chart-bar"] {
                    g[class="ct-series ct-series-a"] {
                        line {
                            stroke-width: 43px;
                        }
                    }
                }

                    svg[class="ct-chart-bar"] {
                        span[class="ct-label ct-vertical ct-end"] {
                            display: none;
                        }
                    }
                }

            }

            .yAxisContainer {
                margin-left: 10px;
                font-family: "Roboto-Regular", Arial, sans-serif;
                font-size: 12px;
                color: $color-black;
                text-align: right;
                line-height: 16px;
                font-weight: 400;

                &>div:nth-of-type(n + 1) {
                    margin-top: 39px;
                }
            }
        }
    }