@import '../../../styles//variables';

.priceChangeContainer {
    display: flex;
    padding: 0px !important;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 18px;
    font-family: $Roboto-Regular;
    font-weight: 400;
    .riseInValue {
        color: #dc3545;
    }
    .fallInValue {
        color: #008338;
    }
}

