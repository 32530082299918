.carousel {
	width: 100%;
	text-align: center;
	border-radius: 3px;
	box-shadow: 0px 0px 10px 3px #88888899;
	background-color: #ffffff;
	margin: 0 auto;
	.leftArrow{
		border-right: solid 1.5px grey;
		padding-right: 12px;
		margin-left: -15px;
	}
	.arrowContainer {
		float: right;
		margin-right: 10px;
      	position: relative;
        z-index: 10;
	}
	.disableArrow{
		pointer-events: none;
		&:focus {
			outline: 0;
		}
	}
	.enableArrow{
		cursor:pointer;
	}	
	.headercarousel{
		width: 80%;
		display: inline-block;
		// overflow:hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	} 
  ul {
  width: 100%;
  }
}
.slider {
	outline: none;
	position: relative;
	width: 100%;
	height: 100%;
	// overflow: hidden;

	&__slides {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		&:focus {
			outline: 0;
		}
	}
	&__slide {
		margin-right: auto;
		margin-left: auto;
		flex-direction: column;
		min-width: 100%;
		max-width: 900px;
		list-style: none;
		//-webkit-backface-visibility: hidden;
		//backface-visibility: hidden;
		transition: transform 0.6s ease-in-out;
		position: relative;
		&__active {
			display: block;
		}
	}
	.inActiveElement {
		//visibility: hidden;
		display: none;
	}
	.activeElement {
		//visibility: unset;
		display: block;
	}

}
