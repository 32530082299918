@import "../../../../../../styles/variables";

.popupContainer {
    position: absolute;
    background: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    padding: 10px 15px;
    width: 187px;

    .date {
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-black;
        line-height: 16px;
        padding-bottom: 10px;
    }

    .stackValues {
        .row {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 16px;
            justify-content: space-between;
            padding: 4px 0 6px 0;
            border-top: 1px solid rgba(0,0,0,0.07);

            .label {
                font-family: $Roboto-Regular;
                color: $color-gray;
            }

            .value {
                font-family: $Roboto-Medium;
                color: $color-black;
                text-align: right;
            }
        }
    }
}