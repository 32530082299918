@import "../../../../styles/variables";
.dynamicPopOverContainer {
  background: #ffffff;
  box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1),
    1px 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  position: absolute;
  width: 183px;
  color: black;
  left: 0px;
  top: 28px;
  padding: 15px 0;
  font-size: 14px;
  line-height: 18px;
  z-index: 99;
  max-height: 240px;
  overflow-y: auto;
  .childPopOverContainer {
    padding: 6px 15px;
    &:hover {
      background: #f7f7f7;
    }
  }
  .popOverText {
    display: inline-block;
    width: 94%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
  }
  .clearPopOverText {
    display: inline-block;
    text-align: center;
    line-height: 14px;
  }
}
