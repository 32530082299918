@import '../../../styles/variables';

button[id="linkBtn"] {
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    border: none;
    background: transparent;
    cursor: pointer;
}
.link:disabled {
    color: $color-netxwealth-news-research-grey-border;
    cursor: default;
}