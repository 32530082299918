@import "../../../../../styles/variables";

.watchlistTableActionOptionContainer{
    justify-content: space-around !important;
}

.watchlistTableActionItemConainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .errormsg{
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;    
        border-radius: 3px;
        position: absolute;
        z-index: 9999999;
        left: 4px;
        white-space: nowrap;
        background: $color-white;
        box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
        color: $color-black;
        padding: 18px 16px;
        top: -44px;
    }

    .watchlistActionBtn{
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        .caretStyle {
            width: 18px;
            height: 18px;
        }
    }
    .colorDot{
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: $color-red;
        display: inline-block;
        margin-left: -10px;
        margin-bottom: 8px;
    }

    .popoverContainer {
        .popoverList {
            list-style-type: none;
            padding: 10px 0;
            margin: 0;

            .popoverItem {
                padding: 6px 10px;
                cursor: pointer;
                font-family: $Roboto-Regular;
                font-size: 14px;
                color: $color-black;
                line-height: 18px;

                &:hover {
                    background-color: $color-white-opacity;
                }
            }
        }
    }

    div[id="popover"] {
        width: 172px;
        margin-left: 60px;
        margin-top: -6px;
        
        >div{
            padding: 0;
            margin: 0;
        }
    }
}