@import '../../../styles/variables';

.searchBoxContainer {
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: $color-netxwealth-news-research-grey-border;
    line-height: 18px;
    .symbolSearchBox {
        background: $color-white;
        border: 1px solid rgba(0,0,0,0.30);
        border-radius: 3px;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .symbolSearchBox:focus{
        outline: auto $color-graph-light-blue1;
    }
    .searchIcon {
        line-height: 14px;
        margin-left: -25px;
    }
}