@import "../../../styles/variables";

.mindEventsRouterContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item {
    // margin: 10px;
    margin: 0px 20px 10px 0px;
  }

  .customClassItem {
    margin-right: 0px;
  }

  .upperSection {
    display: flex;
    justify-content: space-between;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    padding: 15px 15px 10px 15px;

    .symbol {
      width: 50%;
      font-family: $Roboto-Regular;
      font-size: 20px;
      color: $color-blue;
      line-height: 18px;
      font-weight: 400;
    }

    .symbolName {
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: $color-gray;
      line-height: 18px;
      font-weight: 400;
    }
  }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
  .mindEventsRouterContainer {
    .customClassItem {
      margin-right: 0px;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
  .mindEventsRouterContainer {
    .upperSection {
      padding-top: 40px;
    }

    .item {
      margin: 0px
    }
  }
}