@import "../variables";
.ScreenerContainer {
    font-family: $Roboto-Regular;
    width: 100%;
    min-height: 150px;
    // box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    padding-left: 20px;
    background-color: #FFFFFF;
    .openSaveOverlay{
        text-align: right;
        display: inline;
    }
    .screenerTitle{
        display: inline-block;
        width: calc(100% - 200px);
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 16px;
        line-height: 22px;
        color: #666666;  
        .discoverModels{
          font-size: 28px;
          color: #000000;
          line-height: 34px;
          margin-bottom: 5px;
      }      
    }
    .screenerFiltersContainer {
        font-family: $Roboto-Regular;
        width: 99%;
        background: $color-white-opacity;
        // box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);//commented for PRW-958 and PRW-959
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        // padding: 0px 0px 39px 0px;
        margin-top: 15px;
        overflow: auto;
        white-space: nowrap;
        position: relative;
        .filtersLeftShadow {
            position: absolute;
            height: 69.35px;
            overflow: hidden;
            max-width: 40px;
            z-index: 9999;
          }
          .filtersRightShadow {
            position: absolute;
            height: 69.35px;
            overflow: hidden;
            max-width: 40px;
            z-index: 9999;
            display: inline-block;
          }
        
    .selectScreenerFilter{
        border-radius: 3px;
        padding: 15px 0 15px 10px;
        display: inline-block;
        ul > li:first-child{ 
            // border-radius: 3px; //commented for PRW-958 and PRW-959
        }
      }
      .selectScreenerFilter:nth-child(2){
        padding: 15px 0 15px 15px !important;
     }
    }    
    .screenerFiltersContainer::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
      .screenerFiltersContainer {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    .close{position: relative;
        height: 13px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 13px;
        text-align: center;
        background: #37474F;
        border: none;
        color: #ffffff;
      }
    .selectedFilterPills{
        /* Rectangle */
        // box-shadow: 0 1px 0px 1px rgba(0,0,0,0.10);//commented for PRW-958 and PRW-959
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top: 1px solid rgba(0, 0, 0, 0.07); 
        width: 99%;
        padding: 0px 0px 38px 0px;
        // margin-top: 21px;
        padding-top: 15px;
        background: $color-white-opacity;
        // padding-left: 15px;
        .pillsContainer{
          white-space: normal;
          span{
            display: inline-block;
            width: auto !important;
          }
        }
        .buttonfilter {
            width: 71px;
            height:18px; 
            color: #FFFFFF;
            box-sizing: border-box;
            text-align: center;
            /* BG */
            background: #37474F;
            border-radius: 3px;
            /* Label */
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            padding-left: 7px;
            margin-left: 5px;
        }
        .resetFilters{
            width: 203px;
            display: inline-block;
            float: right;
            margin-top: 5px;
        }
      }
    .datequote{
        font-family:$Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #666666;
        height: 36px;
        vertical-align: middle;
        padding: 2px 0px 13px 0px;
        width: calc(100% - 7px);
        text-align: right;        
        bottom: 0;               
    }.dataQuoteAdjust {
      display: block;
    }
    .datequoteModelScreener{
          font-family:$Roboto-Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          height: 36px;
          vertical-align: middle;
          padding: 2px 0px 13px 0px;
          width: calc(100% - 7px);
          text-align: right;        
          bottom: 0;
          margin-top: -23px;               
      }
    .dataQuoteContainer {
      padding-top: 3px;
    }
    .delayed{
        float: right;
    }
    .allModels{
        font-family: $Roboto-Light;
        font-weight: 300 !important;
        margin: 30px 5px;
        font-size: 28px;
        line-height: 34px;
        color: #000000;
        display: inline-block;
    }
    .buttons {
        text-align: center;
        padding: 4px 10px;
        background-color: #0572d6;
        color: #ffffff;
        width: 147px;
        height: 34px;
        cursor: pointer;
       position: relative;
       display: inline-block;
       margin-left: 20px;
       border-radius: 3px;
       a{
        color: #ffffff;
        text-align: center;
        font-family: "Roboto-Regular", Arial, sans-serif;	
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        text-decoration: none;
       }
       a:hover{
           text-decoration: none;
       }
    }
    .compareButton {
        border: 1px solid;
        display: inline;
        background-color: #0572D6;
        padding: 2px;
        border-radius: 3px;
        cursor: pointer;
    }
    .disabled {
        pointer-events: none;
        background-color: rgba(0,0,0,0.10);
        a{
            color: rgba(0,0,0,0.26);
            text-decoration: none;
        }
        a:hover{
            text-decoration: none;
        }
        
      }
      .enbResetFilter {
        color: #0572D6;
      }
      .disbResetFilter {
        color: rgba(0, 0, 0, 0.26);
        pointer-events: none;
      }
      .downloadIcon{
          display: inline-block;
          font-size: 14px;
          font-style: normal;
          font-weight: 900;
          color: #0572D6;
          line-height: 18px;
          position: relative;                    
      }
      @media (max-width: 1023px), only screen and (max-device-width:1023px) {
        .screenerFiltersContainer { 
           margin-top: 20px;
        }
        .datequoteModelScreener{
          margin-top: -40px;
        }
    }
    @media (max-width: 595px), only screen and (max-device-width:595px) {
        .screenerFiltersContainer { 
            width: 97%;
        }
        .selectedFilterPills{
            width: 97%;
        }
        .screenerTitle{ 
            width: calc(100% - 100px);
        }
        .displayHidden {
          display: none;
        }
        .dataQuoteAdjust {
          display: flex;
        }
        .datequoteModelScreener {
            margin: 0px;
            margin-top: -37px;
            height: 16px;
            padding: 0px;
        } 
        .allModels{
          margin-bottom: 20px;
        }
        .buttons {
            width: 96%;
            margin-left: 0px;
            margin-bottom: 20px;   
        }
      }
}