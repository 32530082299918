@import '../../../../styles/variables';

.recentlyViewedMain {
    padding: 5px 0px 20px 20px;
    background: $color-white;

    span[data-value='customDivider']{
        color: $color-light-gray-2;
    }

    .recentlyViewedHeader {
        margin: 30.2px 0px 20px 0px;
        font-family: $Roboto-Light;
        font-size: 20px;
        color: $color-black;
        line-height: 30px;
    }

    .viewall {
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-blue;
        line-height: 18px;
        position: absolute;
        display: inline-block;
        margin-top: -38px;
        margin-right: 5px;
        border: none;
        background-color: transparent;
        z-index: 99;
        margin-left: 93%;
        cursor: pointer;
    }

    .recentlyViewedBottomLine {
        margin-top: 30px;
    }

    div[id='recentlyViewedCrousal'] {
        background-color: transparent;
        display: inline-block;
        box-shadow: none;
        text-align: unset;
        margin-top: -53px;

        span[data-value="customDivider"] {
            font-size: 17px;
            margin-right: -4px;
            padding-left: 8px;
            position: relative;
            top: -2px;
        }

        span[title='Left'] {
           svg[data-icon='chevron-left']{
            width: 18px;
            height: 18px;
           }
        }

        span[title='Right'] {
            svg[data-icon='chevron-right']{
                width: 18px;
                height: 18px;
               }
        }

        &>div:first-child {
            position: relative;
        }

        &>div:first-child>span {
            position: absolute;
            right: 0px;
            top: 10px;
            z-index: 1;
            margin-right: 90px;
        }
    }

    .recentlyViewedRow {
        display: flex;
        margin-top: 53px;
    }
}

@media screen and (max-width: 595px) {
    .recentlyViewedMain {
        .recentlyViewedHeader {
            margin: 30px 0px 5px 0px;
        }

        div[id='recentlyViewedCrousal'] {
            display: block;

            background: $color-white;
            max-width: 2200px;
            margin-top: 15px;
            padding: 0px;
            /* Shadows */
            background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
                /* Shadow covers */
                linear-gradient(to right, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0)),
                linear-gradient(to left, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0));
            background-position: left center, right center, left center, right center;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;

            /* Opera doesn't support this in the shorthand */
            background-attachment: local, local, scroll, scroll;
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;

            /* Firefox */
            .recentlyViewedMobileContainer {
                >div {
                    background: none;
                }

                .recentlyViewedRow {
                    margin: 12px 5px 0px 5px;
                    ;
                }
            }
        }

        /* Hide scrollbar for Chrome, Safari and Opera */
        div[id='recentlyViewedCrousal']::-webkit-scrollbar {
            display: none;
        }
        .viewall {
            margin-top: -30px;
            margin-left: 72%;
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .recentlyViewedMain {
        padding: 0px 0px 20px 10px;

        .recentlyViewedHeader {
            margin: 25px 0px 20px 0px;
        }
        .viewall {
            margin-left: 87%;
            cursor: pointer;
        }

        div[id='recentlyViewedCrousal'] {
            background-color: transparent;
            display: inline-block;
            box-shadow: none;
            text-align: unset;
            margin-top: -53px;

            span[title='Left'] {
                display: none;
            }

            span[title='Right'] {
                display: none;
            }

            span[data-value="customDivider"] {
                display: none;
            }

            &>div:first-child {
                position: relative;
            }

            &>div:first-child>span {
                position: absolute;
                right: 0px;
                top: 10px;
                z-index: 1;
                margin-right: 90px;
            }
        }
    }
}