@import "../../../../../../styles/variables";

.ESGScoresContainer {

    .heading {
        font-size: 28px;
        line-height: 34px;
        color: $color-black;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 300;
    }

    .scoreBox {
        background: $color-white;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        padding: 15px;
        margin: 20px 0px;
    }

    .midLabels {

        display: flex;
        .symbol {
            display: flex;
            align-items: center;
            margin-right: 20px;
            font-size: 12px;
            line-height: 16px;
            font-family: $Roboto-Regular;
        }
        .icon {
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }
    }

    .scoreBar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 20px 0px 15px;
    }

    .linkButton {
        margin-left: -6px;
    }
}

@media screen and (max-width: 595px) {

    .ESGScoresContainer {

        .scoreBar {
            display: block;
        }
    }
}