@import "../../../../../styles/variables";

.saveChartContainer {
    font-family: $Roboto-Regular !important;
    width: 250px;
    max-height: 162px;
    background-color: $color-white;

    .basicDropDownValueContainer {
        width: 100%;
        list-style: none;
        border-radius: 3px;
        outline: none;
        margin: 0px;
        z-index: 1;
        line-height: 20px;
        max-height: 162px;
        overflow-y: scroll;

        &.open {
            border-radius: 0px;
            z-index: 10;
        }

        ul {
            padding: 10px 0px 10px 0px;
            margin: 0px;

            li {
                border: none;
                padding: 6px 10px;
                display: block;
                cursor: pointer;
                font-size: 14px;
                color: $color-black;
                border-top: 0px solid $color-white-opacity;
                background: $color-white;
                z-index: 999;
                line-height: 18px;
                border-radius: 3px;

                .chartName {
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    background: none;
                    border: none;
                    text-align: left;
                    width: 60%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0px;
                }

                .chartNameSett{
                    background: none;
                    border: none;
                    float: right;
                    font-family: $Roboto-Light;
                    font-size: 12px;
                    color: $color-black;
                    text-align: right;
                    width: 40%;
                    padding: 0px;
                    .icon {
                        font-family: $Roboto-Bold;
                        font-size: 18px;
                        color: $color-black;
                        line-height: 18px;
                        padding-left: 8px;
                    }
                }

                .container {
                    display: inline-block;
                    position: relative;
                    padding-left: 20px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 14px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .option-text {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 89%;
                }

                .name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    top: 5px;
                    left: 10px;
                    box-sizing: border-box;
                    position: relative;
                    line-height: 18px;
                    display: inline-block;
                }
            }
            li:hover{
                background: $color-white-opacity;
            }
        }
    }
    
}
