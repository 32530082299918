@import '../../../styles/variables';
.topHoldingsContainer {
  font-family: $Roboto-Regular;
  width: 100%;
  color: $color-black;
  .header {
    // width: 201px;
    height: 34px;
    left: 20px;

    /* 10 TitleM&L/01 Standard */

    font-family: $Roboto-Regular; 
    font-size: 28px;
    line-height: 34px;
    font-weight: 300;
    /* identical to box height, or 121% */

    color: #000000;
  }
  .modelsTopHoldings {
    justify-content: space-between;
    padding-right: 20px;
  }
  .topHoldings {
    display: flex;
    .chart {
      svg {
        g:first-of-type {
          path {
            stroke: #004480 !important;
          }
        }
        g {
          text {
            display: none;
          }
        }
      }
      svg {
        g:nth-of-type(2) {
          path {
            stroke: #0095ff !important;
          }
        }
      }
      svg {
        g:nth-of-type(3) {
          path {
            stroke: #d97e07 !important;
          }
        }
      }
      svg {
        g:nth-of-type(4) {
          path {
            stroke: #00a695 !important;
          }
        }
      }
      svg {
        g:nth-of-type(5) {
          path {
            stroke: #aa01aa !important;
          }
        }
      }
      svg {
        g:nth-of-type(6) {
          path {
            stroke: #ff573f !important;
          }
        }
      }
      svg {
        g:nth-of-type(7) {
          path {
            stroke: #9085ff !important;
          }
        }
      }
      svg {
        g:nth-of-type(8) {
          path {
            stroke: #bf360c !important;
          }
        }
      }
      svg {
        g:nth-of-type(9) {
          path {
            stroke: #00ac00 !important;
          }
        }
      }
      svg {
        g:nth-of-type(10) {
          path {
            stroke: #093055 !important;
          }
        }
      }
      svg {
        g:nth-last-of-type(2) {
          path {
            stroke: rgba(0, 0, 0, 0.26) !important;
          }
        }
      }
      .donutchart {
        height: 180px;
        margin: 25px 0px 0px -6px;
        display: flex;
      }
    }
  }

  .table {
    width: 78%;
    margin: 15px 0px 0px 25px;
  }
  .modelsTable {
    div > table > tbody > tr {
      border-bottom: 1px solid $color-table-header-bottom-border;
    }
    div > table > tbody > tr:last-child {
      border-bottom: none;
    }
  }
  .downloadHoldingsButtonDiv{
    float: right;
    margin-top: -24px;
    margin-right: 20px;
    color: $color-blue;
    position: relative;

    .exportTooltip{
      display: none;
    }
    &:hover .exportTooltip{
        color: $color-black;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;    
        position: absolute;
        background: $color-white;
        border-radius: 3px;
        padding: 5px;
        display: block;
        width: max-content;
        z-index: 99;
        right: 0px;
        box-shadow: -1px 2px 6px 2px $color-box-shadow, 1px 2px 6px 2px $color-box-shadow;
    }
  }
  .linkBtn {
    margin-left: -3px;
  }
}
.AdvChtLink {
  font-family: $Roboto-Medium;
  font-size: 14px;
  color: $color-blue;
  line-height: 18px;
  margin-top: 15px;
}
@media (min-width: 375px) and (max-width: 595px), only screen and (max-device-width: 595px) {
  .topHoldingsContainer {
    .modelsTopHoldings {
      padding-right: 0px !important;
    }
    .downloadHoldingsButtonDiv{
      display: none;
    }
    .modelsTable {
      div > table > tbody > tr {
        border-bottom: none;
      }
    }
  }
}
@media (min-width: 596px) and (max-width: 1023px), only screen and (max-device-width: 1023px) {
  .topHoldingsContainer {
    .modelsTopHoldings {
      padding-right: 0px !important;
    }
    .downloadHoldingsButtonDiv{
      margin-right: 0px !important;
      margin-top: -30px !important;
    }
    .modelsTable {
      div > table > tbody > tr {
        border-bottom: none;
      }
    }
  }
}
@media (max-width: 595px), only screen and (max-device-width: 595px) {
  .topHoldings {
    display: block !important;
  }
  .chart {
    width: 100% !important;
    height: 180px;
  }
  .donutchart {
    // margin-left: 60px;
    text-align: center;
    justify-content: center;
  }
  .table {
    width: 100% !important;
    margin-left: 0px !important;
  }
}
@media (max-width: 390px), only screen and (max-device-width: 390px) {
  .topHoldings {
    display: block !important;
  }
  .chart {
    width: 100% !important;
    height: 180px;
  }
  .donutchart {
    justify-content: center;
  }
  .table {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .AdvChtLink {
    margin-left: 5px;
  }
}
