@import "../../../styles/variables";
.slider {
    position: relative !important;
    height: 14px;
    border-radius: 10px;
    text-align: left;    
  }
  .spanLeft {
    position: absolute;
    left:0px;
   
    line-height: 16px;
    font-size: 12px;
    font-family: $Roboto-Medium;
    color: #000000;
    padding-top: 5px;
  }
  .spanRight{
    position: absolute;
    right:0px;
    font-size: 12px;
    line-height: 16px;
    font-family: $Roboto-Medium;
    color: #000000;
    padding-top: 5px;
  }
  .slider > div {
    position: absolute;
    left: 0;
    right: 0;
    height: 14px;
    margin:0 5px;
  }
  
  .slider > div > .inverseleft {
    position: absolute;
    left: 0;
    height: 1px;
    border-radius: 10px;
    background-color: #B2B2B2;
    
  } 
  .slider > div > .inverseright {
    position: absolute;
    right: 0;
    height: 1px;
    border-radius: 10px;
    background-color: #B2B2B2;
    
  }
  
  .slider > div > .range {
    position: absolute;
    left: 0;
    height: 3px;
    border-radius: 14px;
    background-color: #004480;
  }
  
  .slider > div > .thumb {
    position: absolute;
    top: -4px;
    border:none;
    z-index: 2;
    height: 11px;
    width: 11px;
    padding: 5px;
    text-align: left;    
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    background-color: #004480;
    border-radius: 50%;
    outline: none;
    
  }
  
  .slider > input[type=range] {
    position: absolute;
    pointer-events: none;
    appearance: none;
    z-index: 3;
    height: 14px;
    top: -2px;
    width: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  
  div.slider > input[type=range]::-ms-track {
    appearance : none;
    background: transparent;
    color: transparent;
  }
  
  div.slider > input[type=range]::-moz-range-track {
    -moz-appearance: none;
    background: transparent;
    color: transparent;
  }
  
  div.slider > input[type=range]:focus::-webkit-slider-runnable-track {
    background: transparent;
    border: transparent;
  }
  
  div.slider > input[type=range]:focus {
    outline: none;
  }
  
  div.slider > input[type=range]::-ms-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: red;
  }
  
  div.slider > input[type=range]::-moz-range-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: red;
  }
  
  div.slider > input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: red;
    -webkit-appearance: none;
  }
  
  div.slider > input[type=range]::-ms-fill-lower {
    background: transparent;
    border: 0 none;
  }
  
  div.slider > input[type=range]::-ms-fill-upper {
    background: transparent;
    border: 0 none;
  }
  
  div.slider > input[type=range]::-ms-tooltip {
    display: none;
  }
  
  .slider > div > .sign {    
    position: absolute;
    margin-left: -19px;
    top: -24px;
    width:36px;
    z-index:3;   
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
  }     
  .slider > div > .sign > span {
    font-size: 14px;
    font-weight: 500;
    width:36px;
    text-align: center;
    font-family: $Roboto-Medium;
    line-height: 18px;
    padding-left: 0;
    padding-top: 0;
    color: #000000;
  }
  
  