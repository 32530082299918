@import '../../../../../../styles/variables';

.watchListMindEventsContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

div[id='watchListMindEvents']{
    background-color: transparent;
    display: inline-block;
    box-shadow: none;
    text-align: unset;
    span[data-value="customDivider"] {
      font-size: 18px;
      margin-right: -4px;
      padding-left: 8px;
      color: $color-gray;
    }
    & > div:first-child {
      position: relative;
    }
    & > div:first-child > span {
      position: absolute;
      right: 0px;
      top: 10px;
      z-index: 1;
    }
}

@media screen and (max-width: 1023px) {
    .watchListMindEventsContainer {
        padding: 0px;
    }    
}