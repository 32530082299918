@import "../../../../../styles/variables";

.transactionsSummaryContainer {
    .identifiers {
        display: inline-flex;
        margin-top: 23px;
    }

    .summaryChart {
        margin-top: 24px;
    }

    .summaryTable {
        margin-top: 36px;
    }
}