@import '../../../../styles/variables';

.PerformanceDisclosure {
    .text {
        margin-top: 20px;
        margin-right: 20px;
        font-family: $Roboto-Regular;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        /* or 120% */
        color: $color-black;
    }
}

@media (min-width: 375px) and (max-width: 595px) {
    .PerformanceDisclosure {
        .text {
            margin-right: 10px;
        }
    }
}

@media (min-width: 596px) and (max-width: 1023px) {
    .PerformanceDisclosure {
        .text {
            margin-right: 15px;
        }
    }
}