$base_color: #000000;
$gray: #cccccc;
$bg_color: #ffffff;

.sxSubDropdownList {
  //position: absolute;
  padding: 0;
  list-style: none;
  text-align: left;
  // border: solid 1px $gray; //commented for PRW-958 and PRW-959
  border-radius: 3px;
  border-left: 0;
  background-color: $bg_color;
  // width: 636px;
  max-height: 194px;  
  overflow-y: scroll;
  ul {
    margin-block-end: 0;
    margin-block-start: 0;    
  }
  .checkboxLabel {
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0px !important;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked {
        ~ {
          .checkmark {
            border: 0px solid;
            background-color: #48b748;
            &:after {
              display: block;
            }
          }
        }
      }
    }    
    .checkmark {
      &:after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

  }
  .checkmark {
    position: absolute;
    left: 11px;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 1px solid black;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  .disabled {
    position: absolute;
    top: -1px;
    left: 5px;
    height: 16px;
    width: 16px;
    background-color: #E5E5E5;
    border: 1px solid #B2B2B2;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  .name {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 2px;
    left: 10px;
    box-sizing: border-box;
    position: relative;
    line-height: 18px;
    width: 80%;
    font-size: 14px;
    color: black;
  }
  &.hide {
    display: none;
  }
  &.selected {
    background: #e3edf5;
  }
  .chkLabel {
    display: block;
    padding-left: 34px;
    margin-top: -9px;
  }
  .setTopMargin {
    margin-top: 6px
  }
  .globeIcon {
    margin-left: 2px;
    padding-top: 10px !important;
    svg {
      color: #004480 !important;
      margin-right: 2px;
      width: 14px;
      height: 26px;
    }
  }
}

.sxSubListItem {
  min-width: 8em;
  padding: .6em 0; // commented code to fix the design issues in test center
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  height: 38px;  
  position: relative;
  &:hover:not([class*=sxSelected]) {    
    background-color: #f6f6f6;
  }

  .sxSubListItemLabel {
    font-size: 14px;
    color: #000000;
    line-height: 18px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: .5em;
    padding-right: .5em;
  }
  > div {
    position: absolute;
    span:last-child {
          padding-top: 7px;
    }
  }
}

.cross {
  border-left: 0;
  //fill: rgb(221, 221, 221);
  &:hover {
    cursor: pointer;
    fill: $base_color;
  }
}

.sxSelected {
  background-color:  rgba(0, 0, 0, 0.07);
  border-left: 2px solid #0572D6;
}

  /**
* Scroll Adjustments
*/
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
  ::-webkit-scrollbar-track {
    background:#F7F7F7 !important;
}
/* Handle */
  ::-webkit-scrollbar-thumb {
    background: #B2B2B2 !important;
}
/* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.hideScrollbar::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1023px), only screen and (max-device-width:1023px) {
  .sxSubDropdownList{
    border-left: solid 1px $gray;
    //width: calc(100vw - 82px); // else L2/L3 filter width would be static and not increased, as breadcrumb size increasing
  }
}
