@import '../../../../styles/variables';

.Footer {    
    .text{
        font-family: $Roboto-Regular;
        font-weight: 400;
        margin-top:5px;
        margin-right: 20px;
        padding-top: 5px;
        border-top:1px solid $color-box-shadow;
        font-size: 10px;
        line-height: 12px;
        color: $color-black;
    }
    padding-bottom: 30px;
}
@media (min-width: 375px) and (max-width: 595px) {
    .Footer {
    .text{
        margin-right:10px;
    }
}
}
@media (min-width: 596px) and (max-width: 1023px) {
    .Footer {
    .text{
        margin-right: 15px;
    }
}
}