@import '../../../styles/variables';

.confirmationMessageContainer {
display: flex;
padding: 30px 10px 50px 30px;
.icon {
    width: 30px;
    height: 27px;
    color: $color-dark-orange-3;
    margin-right: 10px;
}
.confirmationMsg{
    font-family: $Roboto-Regular;
    font-size: 16px;
    color: $color-black;
    line-height: 18px;
}
}

.confirmationBtnContainer{
    display: flex;
    justify-content: center;
    padding: 15px 0px;
    button{
        width: 96px;
    }
    button:last-child{
        margin-left: 10px;
    }
}

@media screen and (max-width: 595px) {
    .confirmationMsg{
        width: 85%;
        padding: 20px 20px 0px 20px;
    }

    .confirmationFooter{
        position: fixed;
        bottom: 0px;
        width: 100%;
    }
}