@import "../../../styles/variables";

.newsContainer {
    .header {
        .symbol {
            font-family: $Roboto-Regular;
            font-size: 28px;
            line-height: 34px;            
            color: $color-black;
        }
    }
}