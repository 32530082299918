@import "../../../styles/variables";

.fossilFuelContainer{
    background: $color-white-opacity;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 15px !important;
    .header{
        font-family: $Roboto-Regular;
        font-style: normal;
        font-size: 20px;
        line-height: 26px;
        color: $color-black;
        border-bottom: 1px dashed $color-black;
        cursor: pointer;
    }
    .header .popup {
        visibility: hidden;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $color-black;
        position: absolute;
        z-index: 1;
        background: $color-white;
        box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        max-width: 170px;
        padding: 15px;
        white-space: break-spaces;
    }

    .header:hover .popup {
        visibility: visible;
    }

    .headerContainer {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;   
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .labelContainer {
            display: flex;
            flex-wrap: nowrap;
            padding: 5px 0px;
            .circle{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 5px;
                align-self: center;
            }
            .line{
                width: 12px;
                height: 2px;
                margin-top: 7px;
                margin-right: 5px;
                background: $color-light-seprator-gray;
            }
        }
    }
}

.NXIFossilFuelContainer {
    background: #fff;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 15px !important;

    .header {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-size: 20px;
        line-height: 26px;
        color: $color-black;
        border-bottom: 1px dashed $color-black;
        cursor: pointer;
    }

    .header .popup {
        visibility: hidden;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $color-black;
        position: absolute;
        z-index: 1;
        background: $color-white;
        box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        max-width: 170px;
        padding: 15px;
        white-space: break-spaces;
        z-index: 9999;
    }

    .header:hover .popup {
        visibility: visible;
    }

    .headerContainer {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        color: $color-black;

        .labelContainer {
            display: flex;
            flex-wrap: nowrap;
            padding: 5px 0px;            
            color: $color-black !important;
            .circle {
                width: 10px;
                height: 10px;
                border-radius: 25%;
                margin-right: 5px;
                align-self: center;
            }

            .icon2 svg{
                padding-right: 5px;
                margin-top: 2px;
            }

            .icon3 {
                height: 11px;
                margin-top: 3px;
                padding-right: 5px;
                border-left: 2px solid $color-blue-solid-2;
            }

            .line {
                width: 10px;
                height: 2px;
                margin-top: 7px;
                margin-right: 5px;
                background: $color-light-seprator-gray;
            }
        }
    }
}

@media (min-width: 596px) and (max-width: 1023px) {
    .fossilFuelContainer{
        .headerContainer{
            .labelIpad0{
                width: 22%;
            }
            .labelIpad1{
                width: 33%;
            }
            .labelIpad2{
                width: 23%;
            }
            .labelIpad3{
                width: 22%;
            }
        }
    }
    .NXIFossilFuelContainer {
        .headerContainer {
            .labelIpad0 {
                width: 22%;
            }

            .labelIpad1 {
                width: 33%;
            }

            .labelIpad2 {
                width: 23%;
            }

            .labelIpad3 {
                width: 22%;
            }
        }
    }
}

@media (min-width: 375px) and (max-width: 595px) {
    .fossilFuelContainer{
        .headerContainer{
            .labelContainer{
                width: auto;
                margin-right: 25px;
            }
        }
    }
    .NXIFossilFuelContainer {
        .headerContainer {
            .labelContainer {
                width: auto;
                margin-right: 25px;
            }
        }
    }
}

@media (min-width: 1024px) {
    .labelContainer:nth-of-type(even) {
        width:57%;
    }
    .labelContainer:nth-of-type(odd) {
        width:43%;
    }
}
