@import '../../../../styles/variables';

.container {
    .popupContainer {

        div[id=outerModalPopUp] {
            z-index: 999999;

            &>div {

                &>div:first-child {
                    height: 48px;
                    div:first-child {
                        font-size: 20px;
                        line-height: 48px;
                        font-family: $Roboto-Regular;
                    }

                    button {
                        margin-top: 0;

                    }
                }
            }
        }
    }

    .btn {
        margin-top: 30px;
        font-family: $Roboto-Regular;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $color-blue;
        border: none;
        padding: 0;
        background-color: transparent;
    }

    .text {
        font-family: $Roboto-Regular;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $color-black;
        overflow-y: scroll;
        height: -webkit-fill-available;
        /* autoprefixer: off */

        p {
            text-align: left;
            word-break: break-word;
            margin-top:0;
            margin-bottom: 10px;
            i {
                button {
                    font-style: italic;
                }
            }
        }
        p:last-child{
            margin-bottom: 0;
        }

        button {
            border: none;
            font-family: $Roboto-Regular;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $color-blue;
            background-color: transparent;
            padding: 0;
        }
    }
    
    .btnContainer {
        text-align: center;
        border-top: 1px solid $color-light-seprator-gray;
        margin-top: 20px;
        padding-top: 12px;
        .thirdPartyBtn {
            font-family: $Roboto-Regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            padding: 8px 20px;
            text-align: center;
            width: 99px;
            height: 34px;
            border-radius: 3px;
        }

        .continue {
            @extend .thirdPartyBtn;
            background: $color-blue;
            color: $color-white;
            margin-right: 12px;
        }

    }

    .thirdPartyLink {
        @extend .popupContainer;

        div[id=outerModalPopUp] {
            z-index: 999999;            
        }

    }
}

@media(min-width: 1024px) {
    .container {
        .popupContainer {

            div[id=outerModalPopUp] {
                z-index: 999999;

                &>div:first-child {                  
                    width: 815px;
                    min-height: 601px;
                    &>div:first-child {
                        background-color: $color-dark-gray;                       

                        div:first-child {
                            font-size: 20px;
                            line-height: 48px;
                            color: $color-white;
                        }

                        button {
                            margin-top: 0;

                            svg {
                                color: $color-white;
                            }
                        }
                    }
                    hr{
                        display: none;
                    }
                    &>div:nth-of-type(2){
                        height: 553px;   
                        padding-top: 20px;                     
                    }                    
                }
            }
        }

        .btn {
            margin-top: 30px;
            font-family: $Roboto-Regular;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $color-blue;
            border: none;
            background-color: transparent;
        }

        .text {
            font-family: $Roboto-Regular;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $color-black;
        }

        .thirdPartyLink {
            div[id=outerModalPopUp] {
                &>div:first-child {
                   
                    width: 419px;
                    min-height: 401px;
                    &>div:first-child {
                        background-color: $color-dark-gray;
                        height: 48px;

                        div:first-child {
                            font-size: 20px;
                            line-height: 48px;
                            color: $color-white;
                        }

                        button {
                            margin-top: 0;

                            svg {
                                color: $color-white;
                            }
                        }
                    }                    
                    &>div:nth-of-type(2){
                        height: 353px;
                        padding-top: 20px;
                    }
                }
            }

        }
    }
}

@media (min-width: 596px) and (max-width: 1023px) {
    .container {
        .popupContainer {

            div[id=outerModalPopUp] {
              
                &>div:first-child {     
                    min-height: 600px;           

                    &>div:first-child {
                        background-color: $color-dark-gray;
                        
                        div:first-child {
                            font-size: 20px;
                            line-height: 48px;
                            color: $color-white;
                        }
                        button {
                            margin-top: 0;

                            svg {
                                color: $color-white;
                            }
                        }
                    }
                    hr{
                        display: none;
                    }
                    &>div:nth-of-type(2){
                        height: 552px;   
                        padding-top: 20px;                     
                    } 
                }
            }
        }
    
        .thirdPartyLink {
            div[id=outerModalPopUp] {
                &>div:first-child {
                    min-height: 347px;
                    width: 566px;
                    &>div:first-child {
                        background-color: $color-dark-gray;
                        height: 48px;
                        div:first-child {
                            font-size: 20px;
                            line-height: 48px;
                            color: $color-white;
                        }
                        button {
                            margin-top: 0;
                            svg {
                                color: $color-white;
                            }
                        }
                    }
                    &>div:nth-of-type(2){
                        height: 299px;
                        padding-top: 20px;
                    }
                }
            }
        }
    }
}

@media (min-width: 375px) and (max-width: 595px) {
    .container {
        div[id=outerModalPopUp] {
              
            &>div:first-child {    
                &>div:nth-of-type(2){
                    max-height: 90%;                     
                } 
            }
        }

        .btnContainer {
            position: absolute;
            bottom: 30px;
            left:0;
            width: 100%;
        }
    }
}