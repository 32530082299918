@import '../../../styles/variables';

.StockNewsListContainer {
    .header {
        font-family: $Roboto-Light;
        font-size: 28px;
        color: $color-black;
        line-height: 34px;
        margin-bottom: 20px;
        .symbol {
            text-transform: uppercase;
        }
    }
    .gotoNews { 
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-blue;
        line-height: 18px;
        cursor: pointer;
        border:none;
        background: none;
        margin-top: 10px;
    }
}
@media (max-width: 575px) {
    .StockNewsListContainer {
        .filterBar {
            width: 94% !important;
        }
    }
  }