@import '../../../../styles/variables';

.chartContainer {
  position: relative;
  margin-top: 25px;
  .header{
    font-family: $Roboto-Medium;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    display: inline;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 0px;
    color: $color-black;
    background-color: $color-white;
    outline: none;
    border: none;
    border-bottom: 1px dashed #000000;
    cursor: pointer;
  }

  .tooltip {
    position: absolute;
    height: 36px;
    width: 200px;
    padding: 10px;
    background: $color-white;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    display: flex;
    z-index: 1;

    .color {
      width: 80%;
      text-align: left;
      font-family: $Roboto-Regular;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-black;
    }

    .text {
      width: 20%;
      text-align: right;
      font-family: $Roboto-Regular;
      font-size: 12px;
      line-height: 16px;
      color: $color-black;
    }

    .textModel {
      width: auto;
    }
  }

  .tooltipModel {
    width: 230px;
  }

  .chartBarHoverContainer {
    width: 80px;
    background: rgba(0, 0, 0, 0.07);
    position: absolute;
    cursor: pointer;
    height: 200px;
    bottom: 37px;
  }
  .nxiChartBarHoverContainer {
    bottom: 35px !important;
  }

  .popOverBody {
    font-size: 12px;
  }

  .nxiChartistChartContainer {
    svg[class="ct-chart-bar"] {
      min-height: 164px;
      height: 250px !important;

      g[class="ct-series ct-series-a"] {
        line {
          stroke: #004480;
          stroke-width: 60px;
        }

        line:nth-of-type(4),
        line:nth-of-type(5) {
          stroke: #58595A !important;
        }

        line[class="chartGridLineSolid"] {
          stroke: rgba(0, 0, 0, 0.3);
        }
      }

      g[class="ct-grids"] {
        line {
          stroke: rgba(0, 0, 0, 0.15);
          stroke-dasharray: none;
          stroke-width: 1px;
          stroke-linecap: round;
        }

        line[class="ct-grid ct-horizontal hiddenChartGridLine"] {
          stroke: none;
        }
      }

      g[class="ct-labels"] {
        span[class="ct-label ct-vertical ct-end"] {
          font-family: $Roboto-Regular;
          font-size: 12px;
          color: $color-black;
          line-height: 16px;
          justify-content: flex-end;
        }
      }

      g[class="ct-labels"] {
        foreignObject {
          span[class="ct-label ct-horizontal ct-end"] {
            width: 66px !important;
            margin: 5px auto;
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-black;
            text-align: center;
            line-height: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .chartistChartContainer {
    svg[class="ct-chart-bar"] {
      min-height: 164px;
      height: 250px !important;

      g[class="ct-series ct-series-a"] {
        line {
          stroke: #004480;
          stroke-width: 60px;
        }

        line:nth-of-type(4),
        line:nth-of-type(5) {
          stroke: #0095FF !important;
        }

        line[class="chartGridLineSolid"] {
          stroke: rgba(0, 0, 0, 0.3);
        }
      }

      g[class="ct-grids"] {
        line {
          stroke: rgba(0, 0, 0, 0.15);
          stroke-dasharray: none;
          stroke-width: 1px;
          stroke-linecap: round;
        }

        line[class="ct-grid ct-horizontal hiddenChartGridLine"] {
          stroke: none;
        }
      }

      g[class="ct-labels"] {
        span[class="ct-label ct-vertical ct-end"] {
          font-family: $Roboto-Regular;
          font-size: 12px;
          color: $color-black;
          line-height: 16px;
          justify-content: flex-end;
        }
      }

      g[class="ct-labels"] {
        foreignObject {
          span[class="ct-label ct-horizontal ct-end"] {
            width: 66px !important;
            margin: 5px auto;
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-black;
            text-align: center;
            line-height: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (min-width: 596px) and (max-width: 1023px),
only screen and (max-device-width:1023px) {
  .chartContainer {
    .chartBarHoverContainer {
      width: 50px;
      bottom: 36px;
      background: rgba(0, 0, 0, 0.07);
      position: absolute;
      cursor: pointer;
      height: 200px;
    }

    .chartistChartContainer {
      svg[class="ct-chart-bar"] {
        g[class="ct-series ct-series-a"] {
          line {
            stroke-width: 35px;
          }
        }
      }
    }
    .nxiChartistChartContainer {
      svg[class="ct-chart-bar"] {
        g[class="ct-series ct-series-a"] {
          line {
            stroke-width: 35px;
          }
        }
      }
    }
  }
}

@media (min-width: 375px) and (max-width: 595px),
only screen and (max-device-width: 595px) {
  .chartContainer {
    .chartBarHoverContainer {
      width: 50px;
      background: rgba(0, 0, 0, 0.07);
      position: absolute;
      cursor: pointer;
      height: 200px;
    }

    .chartistChartContainer {
      svg[class="ct-chart-bar"] {
        g[class="ct-series ct-series-a"] {
          line {
            stroke-width: 35px;
          }
        }
      }
    }
    .nxiChartistChartContainer {
      svg[class="ct-chart-bar"] {
        g[class="ct-series ct-series-a"] {
          line {
            stroke-width: 35px;
          }
        }
      }
    }
  }
}
