@import '../../styles/variables';

.watchlistContainer {
  .popupScreen {
    div[id='outerModalPopUp']>div {
      width: auto;
      min-height: auto !important;
      max-height: none !important;
      padding: 0px !important;

    }

    div[id='modalPopUpContentContainer'] {
        min-height: auto;
        height: auto !important;
        // max-width: 480px !important;
        top:0px !important;
        padding:0 !important;
        margin:0 !important;
        overflow-y: visible !important;
        overflow: inherit !important;
    }
  }
}

@media(max-width:596px) {
  .popupScreen {
    div[id='outerModalPopUp'] {
      top: 0px;
      >div:first-child {
        position: relative !important;
        height: 100%;
        width: 100% !important;
        max-height: auto!important;
      }
    }
    div[id='modalPopUpContentContainer'] {
      width: 100%!important;
    }
  }
}

@media(max-width:374px) {
.watchlistContainer {
  .popupScreen {
    div[id='outerModalPopUp']>div {
      width: auto;
      min-height: auto !important;
      height: inherit !important;
      padding: 0px !important;
    }

    div[id='modalPopUpContentContainer'] {
      min-height: auto;
      max-height: none !important;
      height: inherit !important;
      max-width: 375px !important;
      top:0px !important;
      padding:0 !important;
      margin:0 !important;
      overflow-y: visible !important;
      overflow: inherit !important;
    }
  }
  .popupScreenAlert {

    div[id=outerModalPopUp]>div {
      width: auto;
      min-height: auto !important;
      height: 145px;
    }

    div[id=modalPopUpContentContainer] {
      height: 145px;
      max-width: 444px !important;
      top: 0px !important;
      padding: 0 !important;
      margin: 0 !important;
      overflow: inherit !important;
    }
  }
}
}