@import '../../../styles/variables';

.compareSection {
   width: 100%;
   display: flex;
   padding: 15px;
   background-color: $color-white-opacity;
   border-radius: 3px;
   flex-direction: row;

   .compareButton {
      align-self: flex-end;
      width: 100px;

      button {
         border: none;
         width: 100%;
      }
   }
   .disableButton {
      pointer-events: none;
      button {
         background-color: grey;
      }
   }

   .stateContainer {
      display: flex;
      flex-direction: column;
      .header {
         margin-bottom: 8px;
         font-family: $Roboto-Medium;
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 18px;
      }

      .stateBody {
         display: flex;
         justify-content: space-between;

         .line {
            margin-left: 15px;
            margin-right: 15px;
            border-right: 1px solid #B3B3B3;
         }

         .noBorder {
            border: none;
            margin-left: 7.5px;
            margin-right: 7.5px;
         }

         // & > div:nth-of-type(n+1) {
         //    border-right: 1px solid grey;
         // }
         .inputstyle {
            width: 100%;
            font-size: 14px;
            font-family: $Roboto-Regular;
            color: $color-black;
            line-height: 18px;
            padding: 8px 0px 8px 11px;
            border: none;
            border: 1px solid $color-divider-gray;
            border-radius: 3px;
            max-height: 34px;

            &::placeholder {
               /* Chrome, Firefox, Opera, Safari 10.1+ */
               color: $color-black;
               opacity: 1;
               /* Firefox */
            }
         }

         input.inputstyle:focus {
            outline: none !important;
            border-radius: 3px;
            font-family: $Roboto-Regular;
         }

         .customSpacing {
            margin-right: 15px;
         }

         .dropdown {
            height: 34px;

            >ul {
               font-family: $Roboto-Regular;
               font-size: 14px;
               color: $color-black;
               text-align: center;
               line-height: 18px;
               width: 120px;
               margin: 0px;
               position: relative;

               >li {
                  border-radius: 3px;
                  max-height: 34px;
                  padding: 8px 10px;

                  span:first-child {
                     margin-right: 7px;
                     top: 0px
                  }
               }

            }
         }
      }
   }
}

@media screen and (min-width: 596px) and (max-width: 1024px) {
   .compareSection {
      padding: 15px 28px 15px 15px;

      .ipadSpecific {
         display: flex;
         flex-direction: column;

         .ipadBody {
            display: flex;
            margin-top: 26px;

            &:first-child {
               margin-top: 0px;
            }
         }
      }

      .stateContainer {
         margin-top: 0px;
         .line {
            &:first-child {
               border: none;
            }
         }

         .stateBody {
            .line {
               margin-left: 15px;
               margin-right: 15px;
               border-right: 1px solid #B3B3B3;
            }

            .noBorder {
               border: none;
               margin-left: 7.5px;
               margin-right: 7.5px;
            }

            .inputstyle{
               width: 100%;
            }

         }
      }
   }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
   .compareSection {
      width: 100%;
      display: block;
      padding: 15px;
      
      .stateContainer {
         &:first-child {
            margin-top: 0px;
         }
         margin-top: 15px;
         &:nth-child(4) {
            display: inline-block;
            width: 50%;
            padding-left: 7.5px;
            position: relative;
            top: 5px;

            ul {
               &>div {
                  position: absolute;
                  width: 100%;
               }
            }
         }

         &:nth-child(3) {
            display: inline-block;
            width: 50%;
            padding-right: 7.5px
         }

         .stateBody {
            .inputstyle {
               width: 100%;
            }

            .dropdown {
               width: 100%;

               ul {
                  width: 100%;

                  li {
                     width: 100%;
                  }
               }
            }

         }

         .line {
            display: none;
         }

         .noBorder {
            display: none;
         }
      }

      .compareButton {
         width: 100%;
         margin-top: 15px;

         button {
            width: 100%;
         }
      }
   }
}