@import "../../../../styles/variables";

.saveChartContainer{
    width: 100%;

    .saveChartInputContainer{
        padding: 26px 0px 16px 0px;
        display: flex;
        .saveChartInput{
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            width: 100%;
            outline: none;
            padding: 8px 10px;
            background: $color-white;
            border: 1px solid rgba(0,0,0,0.30);
            border-radius: 3px;
        }
        .saveChartInput:focus{
            outline: 1px solid $color-graph-light-blue1;
        }  
        .saveChartErrorMsg{
            color: $color-red-text;
            font-size: 14px;
            font-family: 14px;
            font-family: $Roboto-Regular;
        }
    }

    .saveChartButtonContainer{
        display: flex;
        padding: 10px 0px 1px 0px;
        justify-content: center;
        float: right;

        .saveChartButton{
            background: $color-blue;
            border-radius: 3px;
            font-family: $Roboto-Medium;
            font-size: 12px;
            color: $color-white;
            text-align: center;
            line-height: 16px;
            border: 0px;
        }
    
        .cancelChartButton {
            background: $color-white;
            border: 1px solid rgba(0,0,0,0.30);
            border-radius: 3px;
            font-family: $Roboto-Medium;
            font-size: 12px;
            color: $color-blue;
            text-align: center;
            line-height: 16px;
            margin-left: 8px;
        }
    }
}