@import "../../../../../styles/variables";

.watchlistContainer {
    background: $color-white;
    margin-top: 30px;
    .header{
        font-family: $Roboto-Light;
        font-size: 28px;
        color: $color-black;
        line-height: 34px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .headline {
            float: left;
            padding-left: 10px; 
        }
    }

    .dividendContainer {
        .optionsTableContainer{
            padding: 0px 15px;
        }
        .disclaimer {
            padding: 10px 0px 0px 0px;
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-black;
            line-height: 16px;
        }
        .showMore {
            padding-top: 15px;
            font-family: $Roboto-Medium;
            font-size: 14px;
            color: $color-blue;
            line-height: 18px;
            border: transparent;
            background: transparent;
            cursor: pointer;
        }
    }

    div[id='outerModalPopUp']>div {
        width: auto;
        min-height: auto !important;
        max-height: none !important;
        padding: 0px !important;
    }
    
    div[id='modalPopUpContentContainer'] {
        min-height: auto;
        height: auto !important;
        // max-width: 480px !important;
        top:0px !important;
        padding:0 !important;
        margin:0 !important;
        overflow-y: visible !important;
        overflow: inherit !important;
    }
}

@media screen and (max-width: 595px) {
    .watchlistContainer {
        div[id='outerModalPopUp']>div {
            width: 100%;
            min-height: auto !important;
            height: inherit !important;
          }
      
          div[id='modalPopUpContentContainer'] {
            min-height: auto;
            max-height: none !important;
            height: inherit !important;
            top:0px !important;
            padding:0 !important;
            margin:0 !important;
            overflow-y: visible !important;
            overflow: inherit !important;
        }
    }
}

@media(min-width: 376px) and (max-width:1024px) {
    .watchlistContainer{
        .dividendContainer{
            .optionsTableContainer{
                tbody{
                    tr{
                        td:first-child {
                            left: 15px !important;
                        }
                    }
                }
            }
        }
    }
}