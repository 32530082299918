@import "../../../../styles/variables";

.upcomingDivContainer {
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    width: 312px;
    padding: 15px 15px 20px 15px;
    height: 130px;
    display: flex;
    justify-content: space-between;
    .leftSection {
        width: 55%;
        .header {
            font-family: $Roboto-Regular;
            font-size: 16px;
            color: $color-gray;
            line-height: 22px;
            padding-bottom: 5px;
        }
        .middle {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding-bottom: 15px;
            width: 157px;
        }
        .dates {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-gray;
            line-height: 18px;
            display: flex;
            justify-content: space-between;
        }
    }
    .rightSection {
        width: 101px;
        min-height: 68px;
        background: $color-white;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.20);
        margin-left: 15px;
        .month {
            background-color: $color-dividend-bar-chart-blue;
            min-height: 18px;
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-white;
            line-height: 16px;
            text-align: center;
        }
        .day {
            min-height: 50px;
            padding-top: 6px;
            font-family: $Roboto-Regular;
            font-size: 28px;
            color: $color-black;
            text-align: center;
            line-height: 34px;
        }
    }
}