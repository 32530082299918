@import '../../styles/variables';

.costCompareContainer {
    .descriptiveText {
        margin: 30px 0px;
        font-family: $Roboto-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;

        .link {
            color: $color-blue;
        }

    }
    .importantDisclousers {
        margin: 15px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $color-blue;
        font-family: $Roboto-Medium;
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .costCompareContainer {
        .descriptiveText {
            margin: 30px 0px 0px 0px;
        }
    }
}