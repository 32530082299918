@import "../../../styles/variables";

.chartSymbolPriceInfo{
    width: 97%;
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;

    .chartSymbolPriceItem{
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: $color-gray;
      line-height: 16px;
      border-right: 1px solid rgba(0,0,0,0.14);
      padding:0px 9.5px;
    }
    
    .chartSymbolPriceItem:last-child{
      border-right: none;
    }
    
    .chartSymbolPriceItem:first-child{
      padding-left:0px;
    }
}