@import '../../../styles/variables';
.chartSettingsContainer {
    border-bottom: 1px solid rgba(0,0,0,0.10);
    height: 40px;
    margin-bottom: 10px;
.chartSettingMain {
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: #0572D6;
    float: right;
    .chartSettingGearIcon {
        color: #0572D6;
        border: none;
        background: none;
        padding-right: 20px;
        span {
            padding-left: 5px;
        }
    }
}
}


@media screen and (min-width: 375px) and (max-width: 596px) {
    .customSelectContainer {
        padding: 6px 4px;
        .preFixLabel {
            margin-right: 5px;
        }
    }
    div[id=outerModalPopUp] > div {
        max-height: 812px !important;
        position: absolute;
    }
  }

  .settingsContainer{
    .settingsHeader{
        font-family: $Roboto-Medium;
        font-size: 16px;
        color: $color-black;
        margin-top: 0px;
        line-height: 30px;
        padding-left: 15px;
        width: 100%;
        left: 0px;
        right: 0px;
        border-bottom: 1px solid rgba(0,0,0,0.30);
    }

    .settingsContent{
        padding:7px 15px 6px 15px;
        color: $color-black;
    }

    .settingsIPadContainer{
        float:left;
        width: 112%;
        margin-left: -28px;
        margin-bottom:20px;
    
        .button-container
        {
            border-top: 1px solid $color-light-seprator-gray;
            padding-top: 10px;
            width: 100%;
            text-align: center;
            bottom: 10px;
            position: fixed;
            display: block;
            button:first-child {
                margin-right: 10px;
            }
         }
        .settingsIPadDivLeft{
            float:left;
            width:100%;
            .fontIcon{
                padding-left: 0px;
                margin-left: 5px;
            }
            
.settingsDataDiv{
    display: flex;
    justify-content: space-between;
    width:102%;
    font-family: $Roboto-Regular;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 0px 9px 0px;
    border-bottom: 1px solid rgba(0,0,0,0.10);

    .settingsCol1{
        padding-left:10px;
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-gray;
        line-height: 24px;
    }

    .settingsCol2{
        justify-content: flex-end;
        padding-right: 0px;
        text-align: right;
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        background: none;
        border: none;
    }
}

        }
        .settingsIPadDivRight{
            float:left;
            width:49%;
            margin-left:10px;
        }

    }
}