@import '../../../styles/variables';

.modalRoot {
	font-family: $Roboto-Light;
	width: 100%;
    height: 100%;
    z-index: 1000000; 
    top: 0;
    left: 0;
    position: fixed;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);

	.openScreenModal{
		width: 754px;
	}
	.saveScreenModal{
		width: 419px;
	}
	.addCompareModal{
		width: 815px;
		-ms-overflow-style: none !important;  /* IE and Edge */
		scrollbar-width: none !important;  /* Firefox */
		::-webkit-scrollbar {
			display: none !important;
		}
		table tr td{
            padding: 0px 5px !important;
		}
		table:first-child th:last-child {
            div{
                width:50px;
            }
            span{
                border-right: 0px;
			}
		}
		table tr td:first-child div{

            max-width:395px !important;
		}
		.tableTitle{
			display: block;
			height: 16px;
			font-family: $Roboto-Regular;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			letter-spacing: 0px;
			text-align: left;
			padding: 30px 30px 10px 20px;
		}
		.compareResultTable {
			margin: 19px 10px 11px 20px;
			height: 267px;
    		overflow-y: auto;
		}
		table:first-child th:last-child {
            div{
                width:50px;
            }
            span{
                border-right: 0px;
            }
		}		
		.noCompareSearchResult {
			position: absolute !important;
			width: 97.5% !important;
		}
		.disabled {
			pointer-events: none;
			opacity: 0.4;
			background-color: rgba(0,0,0,0.10);
			a{
				color: rgba(0,0,0,0.26);
				text-decoration: none;
			}
			a:hover{
				text-decoration: none;
			}
		  }
		  svg[data-icon="search"] {
			&[data-icon-style="fa-input-compare-search"] {
				width: 18px;
				height: 18px;
			}	
		}
		.searchMainModal{
			height:53vh;
			.reitText{
				font-family: $Roboto-Regular;
				font-size: 14px;
				line-height: 18px;
				color: $color-gray;
				margin-top: 20px; 
				margin-left: 20px;
				svg {
					margin-right: 8px;
				}
			}
		}

	}
	.ViewShareClassModal {
        width: 952px;
        height: 540px;
		 
		  table {
                tbody {
                    tr:first-of-type {
                        td:first-of-type {
                            label {
								
                                span:first-of-type {
                                    border: 1px solid #B2B2B2 !important;
									background-color: #E5E5E5;
									
									&:after {
										display: block;
										border: solid #B2B2B2;
										border-width: 0 2px 2px 0;
										left: 5px;
										top: 2px;
										width: 5px;
										height: 10px;
										-webkit-transform: rotate(45deg);
										-ms-transform: rotate(45deg);
										transform: rotate(45deg);
									  }
                                }
                            }
                        }
                    }
                }
            }
		
    }
	.viewManagerModal {
		width: 419px;
		height: 396px;
		-ms-overflow-style: none !important;  /* IE and Edge */
		scrollbar-width: none !important;  /* Firefox */
		::-webkit-scrollbar {
			display: none !important;
		}
		.header {
		  padding-left: 20px !important;
		}
		table tr td {
            padding: 13px 10px 13px 10px !important;
		}
		table th {
            padding: 13px 10px 13px 10px !important;
		}
		table:first-child th:last-child {
            span {
                border-right: 0px;
				position: relative;
			}
		}
		table tr td:first-child div {
            max-width:395px !important;
		}
		.tableTitle {
			display: block;
			height: 16px;
			font-family: $Roboto-Regular;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			letter-spacing: 0px;
			text-align: left;
			padding: 30px 30px 10px 20px;
		}
		.compareResultTable {
			margin: 20px 10px 20px 20px;
			height: 314px;
    		overflow-y: auto;
		}
		.searchMainModal {
			height:328px !important
		}
	}
	.modalPopUp {
        z-index: 999;
        border-radius: 3px;
        background: #FFFFFF;
        box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.1), 1px 2px 6px rgba(0, 0, 0, 0.1);
        height: auto;
        top: 0%;
        padding: 0px;
		.headerModalAdjust > div {
			border-radius: 0px !important;
		}
        .headerContainer{
            background: #37474F;
            height: 48px;
			border-radius: 3px;
        }
		.openModalheaderContainer{
            height: 60px;
			background: #37474F;
			border-radius: 3px;
        }
		.viewShareClassModalheaderContainer {
			height: 60px;
			background: $color-dark-gray;
			border-radius: 3px;
		}
        .header {
		  padding-left: 17px;
          width: 90%;
        }
        .headerText {
          color: #FFFFFF;
          float: left;
		  line-height: 26px;
          font-size: 20px;
          margin: 11px 0 11px 0px;
        }
		.openModalheaderText{
		  color: #FFFFFF;
          float: left;
		  line-height: 26px;
          font-size: 20px;
          margin: 16.5px 0 16.5px 0px;
		  font-weight: 400;
		}
		.viewShareClassModalheaderText{
			font-family: $Roboto-Light;
			color: $color-white;
			float: left;
			font-size: 28px;
			line-height: 34px;
			margin: 13px 0 13px 10px;
			font-weight: normal;
			padding-left: 10px !important;
		}
		.closeSection {
			button:focus-visible{
              outline: 1px solid #b2b2b2;
			}
		}
		.shareClassBtnPopUpClose {
			border: none;
			background-color: $color-box-transparent;
			outline: none;
			color: $color-white;
            float: right;
			background-repeat: no-repeat;
			margin: 18px 20px 0px 0px;
			cursor: pointer;               
			text-align: center;
			padding: 0px;
			font-weight: 300;
			font-size: 24px;
			line-height: 24px;
		}
		.btnPopUpClose {
			border: none;
			background-color: rgba(255, 255, 255, 0);
			outline: none;
			color: #FFFFFF;
            float: right;
			background-repeat: no-repeat;
			margin: 7.5px 9.5px 7.5px 0px;
			cursor: pointer;
			font-size: 18px;                
			text-align: center;
			line-height: 18px;
			padding: 0px;
		}
		.searchMainModal {
			height: auto;
		}
		.searchText {
			margin: 0 auto;
			padding-left: 20px;
		}
		.lblTemplate {
			font-family: $Roboto-Medium;
			font-weight: 500;
			color: #000000;
			font-size: 14px;
			line-height: 18px;
			float: left;
			padding-right: 8px;
			padding-top: 48px;
		}
		.lblDescriptionTemplate {
			font-family: $Roboto-Medium;
			font-weight: 500;
			color: $color-black;
			font-size: 14px;
			line-height: 18px;
			float: left;
			padding-right: 8px;
			padding-top: 3px;
		}
		.templateNameText {
			font-family: $Roboto-Regular;
			width: 273px;
    		padding-left: 10px;
			height: 34px;
    		line-height: 18px;
    		font-size: 14px;
			margin-top: 40px;
			color: #000000;
			border-radius: 3px;
			border: 1px solid rgba(0, 0, 0, 0.3);
			background: #FFFFFF;
			box-sizing: border-box;
		}
		
		.templateDescriptionText {
			resize: none;
			font-family: $Roboto-Regular;
			width: 273px;
			padding-left: 10px;
    		line-height: 18px;
    		font-size: 14px;
			margin: 0px 0px 15px 22px;
			color: $color-black;
			border-radius: 3px;
			border: 1px solid rgba(0, 0, 0, 0.3);
			background: $color-white;
			box-sizing: border-box;
		}
		.templateNameErrorText {
			font-family: $Roboto-Regular;
			width: 273px;
    		padding-left: 10px;
    		height: 34px;
    		font-size: 14px;
			line-height: 18px;
			margin-top: 40px;
			color: #000000;
			background: #FFFFFF;
			border: 1px solid #FF0000;
			box-sizing: border-box;
			border-radius: 3px;
		}
		input:focus {
			color: #000000;
			border: 1px solid #2FDAFF;
		}
		.errroPlaceholer {
			margin-top: 15px;
		}
		.errroMsg {
			font-family: $Roboto-Regular;
			font-size: 12px;
			line-height: 16px;
			color: #000000;
			width: 260px;
			height: 16px;
			margin: 4px 0px 14px 125px;
			.ErrorIcon{
				color: #E40707;
				width: 13px;
				height: 12px;
				margin-right: 5px;
			}
		}
		.errorMsgAdjust {
			margin-bottom: 0px !important;
		}
		.footerContainer {
			border-top: 1px solid #CEC8C8;
		}
		.footer {
			text-align: center;
		}
		.topContainer{
			display: flex;
		}
		.shareClassTableSection {
			margin: 0px 0px 0px 10px;
		}
		.shareClassHeading{
			font-family: $Roboto-Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 18px;
			color: $color-gray;
			margin: 20px 23px 0px 20px;
			width: 73%;
			.mobileViewText {
				display: none;
			}
			.desktopViewText {
				display: block;
			}
		}
		.downloadIcon{
			display: inline-block;
			font-size: 14px;
			font-style: normal;
			font-weight: 900;
			color: $color-blue;
			line-height: 18px;
			position: relative; 
			margin-top: 23px;                  
		}
		.addToComparisonBtn{
			font-family: $Roboto-Regular;
			text-align: center;
			height: 34px;
            width: 161px;
			background: $color-blue;
			margin: 15px 10px 10px 20px; 
			border-radius: 3px;
			padding: 8px 20px;
			border: none;
			color: $color-white;
			font-size: 14px;
			line-height: 18px;
			text-decoration: none;
			
		}
		.disabled {
			pointer-events: none;
			background-color: rgba(0,0,0,0.10);
			color: rgba(0,0,0,0.26);
			text-decoration: none;
			:hover{
				text-decoration: none;
			}
		}
		.enabled {
			background: #0572D6;
    		color: #FFFFFF;
		}
		.saveBtn {
			font-family: $Roboto-Regular;
			font-size: 14px;
			line-height: 18px;
			margin-right: 10px;
			text-align: center;
			height: 34px;
            width: 73px;
			background: #0572D6;
    		color: #FFFFFF;
			border: 1px solid;
			padding: 0px;
			margin-top: 16px;
			margin-bottom: 16px;
			border-radius: 3px;
		}
		.comparBtn {
			font-family: $Roboto-Regular;
			font-size: 14px;
			line-height: 18px;
			margin-right: 10px;
			text-align: center;
			height: 34px;
            width: 73px;
			border: 1px solid;
			padding: 0px;
			margin-top: 16px;
			margin-bottom: 20px;
			border-radius: 3px;
		}
		.cancelBtn {
			font-family: $Roboto-Regular;
			font-size: 14px;
			line-height: 18px;
			margin-right: 10px;
			text-align: center;
			height: 34px;
			width: 83px;
			background: white;
			color: #0572D6;
			border: 1px solid #d0c4c7;
			padding: 0px;
			margin-top: 16px;
			margin-bottom: 20px;
			border-radius: 3px;
		}
		.comparBtn {
			width: 97px;
		}
		.borderbtm{
			border-bottom: solid 3px #70BBFF;
		}
		.screenerSelect{
			min-height: 84px;
			padding-top: 32px;
			padding-left: 10px;
			ul li:first-child {
				font-family: $Roboto-Regular;
				font-size: 20px;
				color: $color-black;
				line-height: 26px;
				border-top: none;
			}
		}
		.lblSavedScreener{
			font-family: $Roboto-Regular;
			font-size: 20px;
			line-height: 26px;
			text-align: Left;
			vertical-align: Top;
			padding: 32px 45px 12px 20px;
			color: #000000;
			text-decoration: underline 3px #70BBFF;
			text-underline-offset: 4px;
			
		}
		.lblCompare{
			font-family: $Roboto-Regular;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: 0px;
			height: 310px;
    		text-align: center;
			color:#999999;
			padding-top: 140px;
			align-items: center;
		}
		.beginCompare {
			margin-top: 10px;
		}
    }
}

@media only screen and (max-width: 595px), only screen and (max-device-width:595px) {
    .modalRoot {
		align-items: stretch;
		.modalPopUp {
			width: 100%;
			height: 100vh;
			.templateNameText {
				width: 99%;
				margin: 8px 10px 0px 0px;
			}
			.templateDescriptionText {
				width: 99%;
				margin: 8px 10px 0px 0px;
			}
			.templateNameErrorText {
				width: 99%;
				margin: 8px 10px 0px 0px;
			}
			.lblTemplate {
				padding-right: 4px;
				padding-top: 24px;
			}
			.lblDescriptionTemplate {
				padding-right: 4px;
				padding-top: 4px;
			}
			.searchText {
				padding-left: 10px;
				width: 100%;				
			}
			.errroMsg {
				margin: 4px 0px 14px 10px;
			}
			.errroPlaceholer {
				margin-top: 15px;
			}
			.displayHidden {
				display: none;
			}
			.topContainer{
				display: flex;
			}
			.shareClassTableSection {
				margin: 0px -10px 0px 0px;
			}
		}
    }
}


@media screen and (min-width: 596px) and (max-width: 1023px) , only screen and (min-device-width: 596px) and (max-device-width:1023px){
	.modalRoot {
	  .openScreenModal{
		width: 556px;
	}
	.ViewShareClassModal{
		width: 566px;
		.shareClassHeading{
			width: 60%;
			margin: 20px -8px 20px 20px;
			.mobileViewText, .compareSymbolText {
				display: block;
			}
			.desktopViewText {
				display: none;
			}
		}
		.shareClassTableSection {
			padding-bottom: 20px;
		}
		.addToComparisonBtn{
			margin: 21px 10px 21px 20px;
		}
		.downloadIcon{
			margin-top: 31px;                   
		}
	}
	.addCompareModal{
		width:94vw;
		table tr td:first-child div{
			max-width:395 !important;
		}
		table:first-child th:last-child {
			div{
				width:50px;
			}
			span{
				border-right: 0px;
			}

		}
		
		table td:last-child {
			text-align: center;
		}
		.searchMainModal{
			height:58vh !important;
			// overflow: auto; // todo breaking UI for tablet mode
		}
		.lblCompare{
			padding-top: 14vh
		}
	}
	.viewManagerModal{
		width: 566px;
		height: 390px;
		.compareResultTable {
			margin: 20px 10px 14px 20px;
			height: 314px;
		}
		table tr td:first-child div {
			max-width:413px !important;
		}
		table:first-child th:last-child {
			div {
				width:169px;
			}
			span {
				border-right: 0px;
			}
		}
		table:first-child th:first-child {
			div {
				width:313px;
			}
		}
		.searchMainModal {
			height:328px !important
		}
	}
  }
}
@media screen and (min-width: 300px) and (max-width: 595px) , only screen and (min-device-width: 300px) and (max-device-width:595px){
	.modalRoot {
		.openScreenModal{
		  width: 100%;
	  }
	  .saveScreenModal{
		  width: 100%;
	  }
	  .ViewShareClass{
		  width: 100%;
	  }
	  .ViewShareClassModal{
		  width: 100%;
		  .openModalheaderContainer{
			border-bottom: 1px solid #000000;
			background: #ffffff;
		}
		.viewShareClassModalheaderContainer{
			border-bottom: 1px solid $color-black;
			background: $color-white;
			height: 44px;
		}
		.viewShareClassModalheaderText{
			font-family: $Roboto-Medium;
			font-weight: 500;
			font-size: 16px;
			line-height: 22px;
			margin: 12px 0px 10px 0px;
		}
		.viewShareClassModalheaderText, .btnPopUpClose {
			color: $color-black;
		}
		.shareClassBtnPopUpClose {
			color: $color-black;
			margin: 10px 10px 0px 0px;
		}
		  .topContainer{
			  display: inline-block;
			  .shareClassHeading{
				  width: 100%;
				  margin: 20px 12px 0px 10px;
				    .mobileViewText, .compareSymbolText {
				    	display: block;
				    }
					.desktopViewText {
						display: none;
					}
			  }
			  .addToComparisonBtn{
				  margin: 15px 0px 20px 10px;
			  }
		  }
	  }
	  .addCompareModal{
			width:98vw;
		
		.compareResultTable{
			height: 392px !important;
		}
		.lblCompare{
			padding-top: 25vh
		}
		
		table:first-child th:last-child {
			div{
				width:50px !important;
			}
			span{
				border-right: 0px;
			}

		}
		table td:last-child {
			text-align: center;
			display: table-cell;
		}
		table tr td:first-child div{
				max-width:180px !important;
			}
		.headerContainer{
				border-bottom: 1px solid #000000;
				background: #ffffff;
			}
		.openModalheaderText{
				color: #000000;
			}
			svg[data-icon="times"] {
				&[data-icon-style="fa-compare-close"] {
					width: 15px;
					height: 24px;
					color:#000000;
				}	
			}

		}
		.viewManagerModal {
			width:100%;
			height: 100%;
			.header {
				padding-left: 10px !important;
			}
			.compareResultTable {
				margin: 20px 0px 10px 10px;
			}
			table tr td:first-child div {
				width: 220px !important;
			}
			table tr td:last-child div {
				width: 100px !important;
			}
			
			.headerContainer {
				border-bottom: 1px solid #000000;
				background: #ffffff;
			}
			.openModalheaderText {
				color: #000000;
			}
			svg[data-icon="times"] {
				&[data-icon-style="fa-compare-close"] {
					width: 15px;
					height: 24px;
					color:#000000;
				}	
			}
			.searchMainModal{
				height:92vh !important
			}
			@supports (-webkit-appearance:none) {
				.compareResultTable {
					height: 70vh !important;
				}
				.searchMainModal {
					height:77vh !important
				}
			}
		}
		.searchMainModal{
			height:72vh
		}
	}
}