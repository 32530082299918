@import "../../../styles/variables";

.modcharts-rootmessages {
    font-family: $Roboto-Regular;
    position: absolute;
    color: #666;
    font-size: 15px;
    z-index: 1;
    padding: 0;
    width: 100%;
    height: 100%;
}

.modcharts-rootmessages .modcharts-chartnotavailable {
    padding-top: 50px;
    margin: auto;
    width: 270px;
    height: 70px;
    text-align: center;
}

.modcharts-noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.modcharts-rootoverlay line {
    z-index: 2;
}

div[id="priceChart"] {
    div[class="modcharts-panel"] {
        z-index: 1;

        div[class="modcharts-legend"] {
            z-index: 1;
            margin-bottom: 10px;
        }
    }

    div[class="modcharts-panel"]:nth-child(2) {
        z-index: 0 !important;
    }
}

span[class="panelLegend"] {
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    padding: 3px 10px 6px 10px;
    line-height: 18px;


    span[class="label"] {
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
        padding-right: 8px;
        position: relative;
        text-align: left;
    }

    button[class="closeButton"] {
        content: "\00d7";
        cursor: pointer;
        font-size: 20px;
        top: 12px;
        right: 10px;
        border: none;
        background-color: transparent;
    }

    button[class="closeButton"]::after {
        content: "\00d7";
        cursor: pointer;
        font-size: 20px;
        top: 12px;
        right: 10px;
        border: none;
        background-color: transparent;
    }
}

/* Panel legend */
// .modcharts-panel {
//     // z-index: 1;

//     .modcharts-legend {
//         margin-top: 8px;
//         position: absolute;
//         z-index: 1;
//         font-size: 11px;
//         font-family: $Roboto-Regular;
//     }
// }


/* Chart loading */
div[class="modcharts-root modcharts-noselect modcharts-cursor-default modcharts-root-loading"] {
    pointer-events: none;

    .modcharts-panel,
    .modcharts-legend {
        opacity: 0.5;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

div[class="modcharts-root modcharts-noselect modcharts-cursor-default modcharts-root-loading"]::before {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse 2s ease infinite;
    content: 'Loading...';
    font-size: 1rem;
    opacity: 1;
}

.modcharts-root-loading .modcharts-legend,
.modcharts-root-loading .modcharts-panel {
    opacity: 0.5;
}

/* Cursor */
.modcharts-cursor-default {
    cursor: default;
}

.modcharts-cursor-pointer {
    cursor: pointer;
}

.modcharts-cursor-ns-resize {
    cursor: ns-resize;
}

.modcharts-cursor-cross {
    cursor: crosshair;
}

.modcharts-cursor-closed_hand {
    cursor: grabbing;
}

/* Crosshair and circle */
.modcharts-crosshair {
    position: absolute;
    color: #ccc;
    height: 20px;
}

.modcharts-crosshair-horiz,
.modcharts-crosshair-vert {
    stroke: #777;
    stroke-width: 1;
    stroke-dasharray: 2, 1;
    shape-rendering: crispEdges;
}

.modcharts-crosshair-circle {
    fill: #fff;
    stroke: #333;
    width: 3.5px;
    stroke-width: 1;
}

.modcharts-flag-xaxis {
    position: absolute;
    padding: 0 0.4rem;
    border-radius: 2px;
}

div[class*="modcharts-cursor-cross"] {
    cursor: crosshair;
}

[class*="modcharts-crosshair"] {
    position: absolute;
    color: #ccc;
    height: 20px;
}

g[class*="modcharts-crosshair-horiz"],
[class*="modcharts-crosshair-vert"] {
    stroke: #777;
    stroke-width: 1;
    stroke-dasharray: 2, 1;
    shape-rendering: crispEdges;
}

circle[class*="modcharts-crosshair-circle"] {
    fill: #fff;
    stroke: #333;
    width: 3.5px;
    stroke-width: 1;
}