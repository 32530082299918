
@import "../../../styles/variables";
.summaryTableContainer{
  .table{
    border: 1px solid #E6E6E6;
    margin-top: 30px;
    width: 100%;
    margin-bottom: 1rem;
    td {
      padding: 13px;
      line-height: 18px;
      font-size: 14px;
      color: #000;
      font-family: $Roboto-Regular;
      span {
        font-weight: 400;
      }
    }    
  }
  .float-right{
    float: right;
  }

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; 
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; 
}

  .row {
    margin: 0;
  }
  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border-bottom: 1px dotted black;
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    white-space: nowrap;
    visibility: hidden;
    top: 22px;
    position: absolute;
  }
  .tooltip:hover{
    color: gray;
  }
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    color: #000000;
    font-family: $Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    background: #FFFFFF;
    border-radius: 3px;
    padding: 10px;
    display: block;
    z-index: 99;
    box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
  }
  .headerWithSeparator{
    border-right: 1px solid #B2B2B2;
    height: 18px;
  }

  .linkButton {
    margin-left: -6px;
  }
}
.nonReit{
  table tr:last-child{
    display: none;
  }
}
.MFSnapshot{
  .table {
    margin-top: 0px;
    table-layout: fixed;
  tr:first-child{
    border-bottom: 1px solid #999999;
    td:last-child{
      span {
        font-weight: 500;
      }
    }
  }
  }
}
@media (max-width: 1023px), only screen and (max-device-width:1023px) {
  .MFSnapshot{
    margin-bottom: 0px;
  }
}
