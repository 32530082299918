@import "../../../styles/variables";

.symbolSearchContainer {
    display: flex;
    position: relative;
    float: right;
    border: none;

    input[type='text'] {
        width: 200px;
        height: 35px;
        font-size: 15px;
        line-height: 35px;
        border: 1px solid #ddd;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px 0px 0 5px;
    }

    input[type='button'] {
        height: 35px;
        line-height: 35px;
        font-size: 20px;
        border: 1px solid #ddd;
        border-left: 0;
        color: #000000;
    }
}

@media screen and (max-width: 375px) {
    .symbolSearchContainer {
        float: none !important;

        input[type='text'] {
            width: 275px;
        }

        input[type='button'] {
            height: 35px;
            line-height: 35px;
            font-size: 20px;
            border: 1px solid #ddd;
            border-left: 0;
            color: #000000;
        }
    }
}