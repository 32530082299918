@import '../../../../styles/variables';

.symbolWithPopoverContainer{
    .symbol{
        padding: 0px;
        cursor: pointer;
        border: none;
        font-family: $Roboto-Medium;
        font-size: 14px;
        line-height: 18px;
        background-color: transparent;
        color: $color-blue;
        .searchDescription{
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding-top: 1px;
            padding-left: 12px;
          }
    }
    .fixedPopupPosition{        
        top: -48%;
        position: fixed;
    }    
    .searchDescriptionPopupPosition{        
        top: -110%;
        position: fixed;
    }
    div[id="quoteCardWatchlist"]{
        margin: -10px;
     }
    div[id="quote-card-popover"]{
        position: absolute;
        z-index: 9999;
        background: $color-white;
        box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
        border-radius: 3px;
        margin-left: 125px;
    }
}

@media (max-width: 596px) {
    .symbolWithPopoverContainer{
        div[id="quote-card-popover"]{
            left: 35px !important;
         }
    }
}

@media print {
  button {
      display: block !important;
    }
}