@import "../../../styles/variables";

.chartsModalHeader{
    display: flex;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    position: relative;
    z-index: 999;
    height: 48px;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.30);

    .chartsHeaderText{
        width: 92%;
        margin-left: 10px;
        font-size: 16px;  
        line-height: 22px;
        font-family: $Roboto-Medium;
        color: $color-black;
    }

    .chartsPopUpBtnClose{
        color: #0572D6;
        border: none;
        background: none;
        padding-right: 20px;
        padding-right: 10px;
    }

    .chartsPopUpClose {
        border: none;
        background-color: rgba(255, 255, 255, 0);
        color: $color-black;
        outline: none;
        background-repeat: no-repeat;

        font-size: 24px;                
        text-align: center;
        line-height: 24px;
        cursor: pointer;
        float:right;
    }
}