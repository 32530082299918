@import "../../../styles/variables";

.rangeSelector {    
    padding: 20px 15px;
    width: 100%;
    top:0;

    .header {
        position: relative;
        height: 26px;
        font-family: $Roboto-Medium;        
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
        color: #000000;
    }
     .slider {
        position: relative;
        top: 43px;        
    }
     div.inputItem {
         display: flex;
        label {
            font-family: $Roboto-Medium;            
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            color: #000000;
            margin-right:10px
        }
        div.errmsg{
            position: relative;
            left: 33px;
        }
        div.firstdiv{
            font-size:12px;
            line-height: 16px;            
            color:#000000;
            .icon{
            color:#E40707;  
            height: 13px;
            font-size:12px;
            line-height: 13px;       
            margin-right:5px;
        }
        
    }
    div.seconddiv{
        font-size:12px;
        line-height: 16px;
        margin-left:33px;
        color:#000000;
        .icon{
        color:#E40707;  
        height: 13px;
        font-size:12px;
        line-height: 13px;       
        margin-right:5px;
    }
}
   
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    input[type=number] {
        height: 28px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 3px;
        width: 100px;
        font-size: 12px;
        line-height: 16px;        
        padding-right: 9px;
        text-align: right;      
                
    }
    input[type=number].err{
        background: #FFFFFF;
        border: 1px solid #FF0000;
        box-sizing: border-box;
        border-radius: 3px;
                    
    }
    input[type=number].err:focus-visible{
        border: 1px solid #FF0000 !important;
    }    
}
     
    

}

@media screen and (min-width: 375px) and (max-width: 1024px) {
    .rangeSelector {                    
        input[type=number] {
            width:90px !important;
            font-size: 14px;                    
        }                    
    }
}