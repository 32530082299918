@import '../../../styles/variables';

.quoteCardParentContainer {
    font-family: $Roboto-Regular;
    font-size: 12px;
    color: #666666;
    line-height: 18px;

    .compressedQuoteCard {
        padding: 15px 0 20px 0 !important;
    }

    .popOverContainer {
        word-wrap: break-word;
        // padding: 10px 15px;
    }

    .btnViewQuote {
        display: flex;
        justify-content: end;
        padding-right: 15px;
        padding-bottom: 15px;

        button:disabled {
            background: #E6E6E6;
            color: #BEBEBE;
        }

        button {
            background: #0572D6;
            border-radius: 3px;
            font-size: 12px;
            color: #FFFFFF;
            text-align: center;
            line-height: 16px;
            padding: 6px 20px;
            outline: none;
            border: none;
            cursor: pointer;
        }
    }

    .popOverHeader {
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-black;
        line-height: 16px;
        padding: 0 0 5px 5px;
    }

    .popOverBody {
        font-family: $Roboto-Regular;
        white-space: break-spaces;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        padding: 0 5px 5px 5px;
        span{
            white-space: normal;
        }
    }

    .asOfDate {
        text-align: end;
        font-family: $Roboto-Regular;
        font-size: 10px;
        color: $color-gray;
        padding-right: 15px;
    }

    .quoteCardContainer {
        background: $color-white;
        border: 1px solid rgba(0, 0, 0, 0.07);
        border-radius: 3px;
        font-family: $Roboto-Medium;
        padding: 15px 0 5px 0;
        display: inline-block;
        min-width: 230px;
        height: fit-content;
        word-break: normal;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;

        .quoteCardHeader {
            padding: 0 15px 10px 15px;

            .quoteCardHeaderMain {
                font-size: 14px;
                color: #0572D6;
                line-height: 18px;
                display: flex;
                justify-content: space-between;
                white-space: nowrap;
            }

            .quoteCardHeaderDesc {
                font-size: 12px;
                color: #666666;
                line-height: 18px;
                font-family: $Roboto-Regular;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 188px; 
                cursor: pointer;
            }

            .quoteCardHeaderDesc .popup {
                visibility: hidden;
                font-family: $Roboto-Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $color-black;
                position: absolute;
                z-index: 1;
                background: $color-white;
                box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                padding: 10px;
            }

            .quoteCardHeaderDesc:hover .popup {
                visibility: visible;
            }

            .pill {
                margin-top: 5px;
                margin-bottom: 5px;
                display: inline-flex;
                align-items: center;
                padding: 3px 8px;
                height: 22px;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.3);
                box-sizing: border-box;
                border-radius: 6px;
                white-space: nowrap;
            }

            .ellipseIcon {
                float: right;
            }

        }

        .quoteCardModeChart {
            border: none;
            div[data-value="chartUnavailable"]{
                font-size: 14px;
            }
        }

        .buttonPopOver {
            border: none;
            background: none;
            outline: none;
            margin: 0;
            padding: 0;
            cursor: pointer;
            font-size: inherit;
            font-family: inherit;
            line-height: inherit;
        }

        .quoteCardChart {
            margin-top: -68px;

            .quoteCardChartMain {
                font-size: 20px;
                color: $color-black;
                padding: 0 15px 0 15px;
                font-weight: bold;
            }

            .quoteCardChartDesc {
                padding: 5px 15px 0 15px;
                font-family: $Roboto-Regular;
                font-size: 14px;
            }
        }

        .quoteCardChartCompare {
            display: flex;
            align-items: baseline;
            margin-top: -68px;

            .quoteCardChartMain {
                font-size: 20px;
                color: $color-black;
                padding: 0 15px 0 15px;
                font-weight: bold;
            }

            .quoteCardChartDesc {
                font-family: $Roboto-Regular;
                font-size: 14px;
            }
        }

        .quoteCardMainContentDisplay {
            display: none;
        }

        .quoteCardMainContent {
            padding: 14px 15px 15px 15px;
            margin-top: 35px;
            color: $color-black;

            .mainSectionContainer {
                display: flex;
                padding-bottom: 5px;
                padding-top: 5px;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0, 0, 0, 0.14);

                .mainSectionLabel {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: #666666;
                    line-height: 16px;
                }

                .markitConsensusLabel {
                    border-bottom: 2px dashed #0572D6;
                }

                .mainSectionContent {
                    font-family: $Roboto-Medium;
                    font-size: 12px;
                    line-height: 16px;
                    .priceChange {
                        display: inline-block;
                        padding: 0;
                    }
                }

                .markitConsensusContentContainer {
                    display: flex;
                    justify-content: space-between;

                    .markitConsensusContent {
                        width: 8px;
                        height: 16px;
                        background: #DBDBDB;
                        border-radius: 1px;
                        margin-left: 1px;
                    }

                    .consensus {
                        font-size: 12px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 16px;
                        width: -webkit-fit-content;
                        width: -moz-fit-content;
                        padding: 0px 14.5px;

                    }

                    .positive {
                        background: $color-green;
                    }

                    .negative {
                        background: $color-red;
                    }

                    .neutral {
                        background: $color-dark-gray;
                    }
                }
            }

            .volumeSectionContainer {
                display: flex;
                padding-bottom: 5px;
                padding-top: 5px;
                justify-content: space-between;
                border-bottom: none;

                .mainSectionLabel {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: #666666;
                    line-height: 16px;
                }

                .markitConsensusLabel {
                    border-bottom: 2px dashed #0572D6;
                }

                .mainSectionContent {
                    font-family: $Roboto-Medium;
                    font-size: 12px;
                    line-height: 16px;
                }

                .markitConsensusContentContainer {
                    display: flex;
                    justify-content: space-between;

                    .markitConsensusContent {
                        width: 8px;
                        height: 16px;
                        background: #DBDBDB;
                        border-radius: 1px;
                        margin-left: 1px;
                    }

                    .consensus {
                        font-size: 12px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 16px;
                        width: -webkit-fit-content;
                        width: -moz-fit-content;
                        padding: 0px 14.5px;

                    }

                    .positive {
                        background: $color-green;
                    }

                    .negative {
                        background: $color-red;
                    }

                    .neutral {
                        background: $color-dark-gray;
                    }
                }
            }
        }

        .red {
            color: $color-red;
        }

        .green {
            color: $color-green;
        }

        .black {
            color: $color-black;
        }
    }
}

@media (max-width: 596px) {
    .quoteCardParentContainer {
        display: none;

        .quoteCardContainer {
            .quoteCardHeaderDesc:hover .popup {
                left: 35%;
            }
        }
        
    }

    .quoteCardCompareParentContainer {
        display: inline-block;
    }
}