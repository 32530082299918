@import "../../../../styles/variables";

.insiderTransactionsContainer {
    .transactionType {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;

        ul {
            margin: -5px 0 0 4px;

            li {
                span {
                    font-size: 20px !important;
                    text-align: center !important;
                    line-height: 26px !important;
                }
            }

            div>li {
                span {
                    font-size: 16px !important;
                    line-height: 18px !important;
                }
            }
        }
    }
}