@import '../../../styles/variables';

.customSelectContainer {
    background: transparent;
    border: 1px solid $color-light-gray;
    border-radius: 3px;
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: $color-black;
    line-height: 18px !important;
    padding: 6px 8px;
    display: inline-flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    cursor: pointer;
    
    .preFixLabel {
        margin-right: 10px;
    }
    .postFixLabel {
        margin-left: 5px;
    }
    .icon {
        font-size: 12px;
    }
}

.popoverContainer {
    position: absolute;
    top: -6px;
    left: 18px;

    div[data-popover-body-container="true"] {
        padding: 0px !important;
    }
}


@media screen and (min-width: 375px) and (max-width: 596px) {
    .customSelectContainer {
        padding: 6px 4px;
        .preFixLabel {
            margin-right: 5px;
        }
    }
  }