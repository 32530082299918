@import '../../../styles/variables';

.popover {
  position: absolute;
  background-color: #fff;
  z-index: 10;
  outline: none;

  .popover-body-container {
    background: #FFFFFF;
    box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
    border-radius: 3px;
    padding: 7px;
  }
  .popover-body-container-withoutpadding {
    background: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
    border-radius: 3px;    
    padding: -10px;
  }
  .popover-close-icon {
    float: right;
    top: 5px;
    position: relative;
    right: 10px;
    cursor: pointer;
  }

  .popover-arrow {
    height: 16px;
    width: 16px;
    background-color: #fff;
    position: absolute;
    border: 2px solid transparent;
    border-top: 2px solid #ccc;
    border-left: 2px solid #ccc;
    z-index: 9;

    &.top {
      bottom: -8px;
      transform: rotate(-135deg);
    }
    &.bottom {
      top: -8px;
      transform: rotate(45deg);
    }

    &.left {
      right: -8px;
      transform: rotate(135deg);
    }
    &.right {
      left: -8px;
      transform: rotate(-45deg);
    }
  }

  /**
  * Scroll Adjustments
  */
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
   ::-webkit-scrollbar-track {
      background: #f1f1f1;
  }
  /* Handle */
   ::-webkit-scrollbar-thumb {
      background: #888;
  }
  /* Handle on hover */
   ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
}
