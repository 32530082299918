@import "../../../../src//styles/variables";

.topStocksContainer{
    .topStocksHeader{
        display: inline-block;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
        width: 100%;
        padding: 5px 0 20px;
        font-family: $Roboto-Regular;
        .delayed-quote-info {
            float: right;
            display: flex;
        }
        .date-info {
            display: inline-block;
            margin-right: 10px;
            color: $color-gray;
        }
        .refresh-icon {
            display: inline-block;
        }
        .seprater{
            display: inline-block;
            color: rgba(0, 0, 0, 0.14);
            margin: 0px 15px;
            font-size: 18px;
            line-height: 18px;
        }
        .realTime{
            display: inline-block;
            label{
                span{
                    margin-left: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 595px) { 
    .topStocksContainer{
        .topStocksHeader{
            padding: 5px 2px;
            .delayed-quote-info {
                float:left;
                flex-direction: column;
            }
            .realTime{
                margin-top: 17px;
            }
        }
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) { 
    .topStocksContainer{
        .topStocksHeader{
            padding: 2px 0px;
            .delayed-quote-info {
                float:left;
            }
        }
    }
}