@import "../../../../../../styles/variables";
.header {
    font-family: $Roboto-Light;
    font-size: 28px;
    color: $color-black;
    line-height: 34px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding: 10px 0;
    
    h3 {
        display: inline;
    }
}

.quoteAndResearch {
    background: $color-white;
    padding: 0 15px;
    
    .floatRight {
        float: right;
        padding-bottom: 20px;
    }
}

.symbolSearchContainer {
    display: flex;
    position: relative;
    float: right;
    border: none;
}

@media (min-width: 0px) and (max-width: 596px) {
    .symbolSearchContainer {
        float: none !important;
    }
    
    .quoteAndResearch {
        padding:0 11px;
    }
}
