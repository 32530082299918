@import "../../../../src//styles/variables";

.divider {
    line-height: 0;
    padding: 0;
    margin: 0;
    height: 1px;
    border-width: 0;
    color: #e5e5e5;
    background-color: #e5e5e5;
}
