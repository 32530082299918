@import "../../../styles/variables";
.errormsg{
    color: #FFFFFF;
    font-family: $Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
    height: 36px;
    background: #37474F;
    border-radius: 3px;
    padding: 10px;
    display: block;
    position: absolute;
    z-index: 9999999;
    top: -30px;
    left: 4px;
}
.getStylesForModelScreener{
    width: 180px;
    background: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
    height: 48px !important;
    color: $color-black;
    padding: 18px 16px 14px;
    top: -44px;
}