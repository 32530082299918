@import '../../../../styles/variables';

.stockContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;

    .topText {
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
    }

    .bar {
        padding-top: 20px;
        padding-bottom: 5px;
    }

    .bottomSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;

        .leftItem {
            display: flex;
            flex-direction: column;
        }

        .rightItem {
            display: flex;
            flex-direction: column;
            text-align: right;
        }

        .textDesc {
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-gray;
            line-height: 16px;
        }

        .noDesc {
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-black;
            line-height: 16px;

            .disclaimerSymbol {
                font-size: 5px;
                margin-bottom: 4px;
            }
        }
    }

    div[data-custom-select='custom-select-baseball-bar'] {
        div {
            height: 8px;
            min-width: auto;
        }
    }
}