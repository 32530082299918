@import "../../../../../../styles/variables";

.chartContainer {

    .stackChart {
        height: 300px;

        .stackBarChartContainer {
            width: 100%;
            height: 300px;
            position: relative;

            div {
                height: 100%;
            }

            svg[class="ct-chart-bar"] {
                g[class="ct-grids"] {
                    line[class="ct-grid ct-vertical"] {
                        stroke: rgba(0, 0, 0, 0.15);
                        stroke-dasharray: 0px;
                    }
                }

                g[class="ct-series ct-series-a"] {
                    line[class="ct-bar"] {
                        cursor: pointer;
                        stroke: $color-bar-graph-blue-dark;
                        stroke-width: 25px;
                    }
                }

                g[class="ct-series ct-series-b"] {
                    line[class="ct-bar"] {
                        cursor: pointer;
                        stroke: $color-dividend-performance-area-blue;
                        stroke-width: 25px;
                    }
                }

                g[class="ct-series ct-series-c"] {
                    line[class="ct-bar"] {
                        cursor: pointer;
                        stroke: $color-dark-green;
                        stroke-width: 25px;
                    }
                }

                g[class="ct-series ct-series-d"] {
                    line[class="ct-bar"] {
                        cursor: pointer;
                        stroke: $color-dark-magenta;
                        stroke-width: 25px;
                    }
                }

                g[class="ct-series ct-series-e"] {
                    line[class="ct-bar"] {
                        cursor: pointer;
                        stroke: $color-dark-orange-shade;
                        stroke-width: 25px;
                    }
                }

                g[class="ct-labels"] {
                    span[class="ct-label ct-vertical ct-start"] {
                        font-family: $Roboto-Regular;
                        font-size: 12px;
                        color: $color-black;
                        text-align: left;
                        line-height: 16px;
                        justify-content: flex-start;
                    }

                    foreignObject {
                        span[class="ct-label ct-horizontal ct-end"] {
                            font-family: $Roboto-Regular;
                            font-size: 12px;
                            color: $color-black;
                            text-align: center;
                            line-height: 16px;
                        }

                        span[class="ct-label ct-horizontal ct-end hideExtraLabels"] {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .barChartContainer {
        margin-top: 40px;
        width: 100%;

        div {
            height: 100%;
        }

        svg[class="ct-chart-bar"] {
            g[class="ct-grids"] {
                line[class="ct-grid ct-vertical"] {
                    stroke: rgba(0, 0, 0, 0.15);
                    stroke-dasharray: 0px;
                }
            }

            g[class="ct-series ct-series-a"] {
                line[class="ct-bar"] {
                    stroke: $color-bar-graph-blue-dark;
                    stroke-width: 2px;
                }
            }

            g[class="ct-labels"] {
                span[class="ct-label ct-vertical ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    line-height: 16px;
                    justify-content: flex-end;
                }

                foreignObject {
                    span[class="ct-label ct-horizontal ct-end"] {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 596px) and (max-width: 1024px) {
    .chartContainer {
        .stackChart {
            height: inherit;
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .chartContainer {
        .stackChart {
            height: inherit;
            .stackBarChartContainer {
                svg[class="ct-chart-bar"] {
                    g[class="ct-series ct-series-a"] {
                        line[class="ct-bar"] {
                            stroke-width: 12px;
                        }
                    }

                    g[class="ct-series ct-series-b"] {
                        line[class="ct-bar"] {
                            stroke-width: 12px;
                        }
                    }

                    g[class="ct-series ct-series-c"] {
                        line[class="ct-bar"] {
                            stroke-width: 12px;
                        }
                    }

                    g[class="ct-series ct-series-d"] {
                        line[class="ct-bar"] {
                            stroke-width: 12px;
                        }
                    }

                    g[class="ct-series ct-series-e"] {
                        line[class="ct-bar"] {
                            stroke-width: 12px;
                        }
                    }
                }
            }
        }

        .barChartContainer {
            svg[class="ct-chart-bar"] {
                g[class="ct-series ct-series-a"] {
                    line[class="ct-bar"] {
                        stroke-width: 0.5px !important;
                    }
                }
            }
        }
    }
}