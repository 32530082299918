@import "../../../../styles/variables";

.topFundHolderContainer {
    .headerContainer {
        font-family: $Roboto-Light;
        font-size: 24px;
        color: $color-black;
        line-height: 30px;
        padding-bottom: 20px;
    }
    .filterContainer {
        button {
            height: 26px;
            background: $color-white;
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 6px;
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-gray;
            text-align: center;
            line-height: 18px;
            font-weight: 400;
            padding: 0px 10px 0px 10px;
        }

        .list {
            padding: 0;
            margin: 0;
            display: flex;
            & > li + li {
                margin-left: 8px;
            }
            li {
                display: inline-flex;
                flex-direction: row;
            }
        }

        .selected {
            color: $color-blue;
            background-color: rgba(47, 174, 255, 0.25);
            border: none;
        }
    }
    .tableParent {
        margin: 15px 0px;
    }
}
