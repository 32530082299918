@import "../../../styles/variables";

.chartComparisonContainer {
    height: 100%;
    width: 100%;
    button{
      cursor:pointer;
      border: 1px solid rgba(0,0,0,0.30);
      border-radius: 3px;
    }
  .divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .mainContent {
    width: 100%;
  }
}
