@import "../../../styles/variables";

.chartStylesMobile {
    height: 100%;
    width: 100%;
  .styleOptions {
    height: 50vh;
    padding: 12px;
     > div {
      margin-bottom: 20px;
      display: -webkit-box;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    border-top: 1px solid $color-divider-gray;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    button:first-child {
      margin-right: 10px;
    }
  }
}
