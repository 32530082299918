@import "../../../styles/variables";
.holdingTableContainer {
    font-family: $Roboto-Regular;
    color: $color-black;
    font-size: 14px;
    line-height: 18px;
    .srOnly {
        position:absolute ;
        left: -10000px;
        width: 1px;
        height: 0px;
        top: auto;
        overflow: hidden;
      }
      table {
        width: 100%;
      } 
    tr td {
        margin-top: 7px;
        vertical-align: text-bottom;
    }
    .flexPos {
        display: flex;
        align-items: baseline;
    }
    .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
    }
    .chartCircle {
        width: 10px;
        height: 10px;
        padding-bottom: 7px;
    }
    .symbol {
        color: #0572D6;
        margin: 0 5px 0 10px;
        font-weight: 500;
        left: 0px;
        border: none;
        background: none;
        padding: 0px;
    }
    .name {
        width: 80%;
    }
    .allocation {
        text-align: right;
        margin-right: -10px;
    }
}

@media (max-width: 1023px), only screen and (max-device-width:1023px) {
    .holdingTableContainer {
    .name {
        max-width: 330px;    
    }
}
}
    
@media (max-width: 595px), only screen and (max-device-width:595px) {
    .holdingTableContainer {
    .name {
        max-width: 330px;        
    }
    .allocation {
        text-align: right;
        right: 20px;
    }
    
}
}