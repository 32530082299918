@import '../../../styles/variables';
.tooltipContainer {
  position: relative;
  width: 14px;
  align-items: center;
  display: inline;
.overlayDesign {
  z-index: 99999 !important;
  background: white !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border-radius: 3px !important;
  //padding: 10px;
  list-style-type: none;
  left: -10px;
  width: 150px;
  position: absolute;
  top: 20px;
}
.aligndropdown{
  position: absolute;
  top: 20px;
  left: -80px;
}
 .clickableicon {
  font-size: 14px;
  line-height: 26px;
  color: #000000;
  font-weight: 400;
  margin-left: 2px;
}
.positionRel{
  position: relative !important;
}
.displayNone{    
  display: none;
}
.ActionDropdown{
  height: 34px;
  width: 131px;
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
  box-sizing: border-box;
  border-radius: 3px;
  display: block;
  padding: 7px 0px 3px 7px;
  cursor: pointer;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  float: right;
  margin-right: 5%;
    .dropdownsvg{
      float: right;
      padding-right: 11px;
      svg{
      height: 13px;
      width: 12px;
      }
    }
}

.alignRight{    
  display: block;
  width: 100%;
  width: 18px;
  cursor: pointer;
  text-align: center;
  svg > path {
    fill: #0572D6;    
  }
  svg {
    width: 18px;
    height: 18px;
  }
}
.disabled{
  svg > path {
    fill: rgba(0, 0, 0, 0.26)
  }
}
.alignR{    
  display: inline-block;
  cursor: pointer;
  text-align: center;
  width: 14px;
}
.setZindex {
  position: relative;
  z-index: 2;
}
a, button.enabled {
  color: #000000 !important;
}
a, button {
  color: #000000;
  text-decoration: none;
  background-color: transparent;
  font-family: "Roboto-Regular", Arial, sans-serif;	
  font-size: 14px;
  line-height: 28px;
  margin-right: 10px;
  border: none;
  padding: 0px;
}
a, button:hover {
  color: #000000;
  text-decoration: none;
}
.buttonsOverlay {
  width: 100%;
  text-align: left;
}
.menuItem{
  padding: 0px 10px;
}
.menuItem:first-child{
  margin-top: 10px;
}
.menuItem:last-child{
  margin-bottom: 10px;
}
.menuItem:hover, .menuItem:focus {
  background-color: #F7F7F7;
  cursor: pointer;
}
}
@media (max-width: 595px), only screen and (max-device-width:595px) {
  .tooltipContainer{
    .ActionDropdown{
      float: left;
      margin-left: 5%;
    }
    .aligndropdown{
      left: 120px;
    }
  }
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

  .overlayDesign {
		top: 0px !important;
   }
}}

.tooltipContainer:focus-visible {
  outline: 1px solid #2fdaff;
}
