@import '../../../styles/variables';

.collapsibleCardContainer {
    width: 100%;
    display: block;
    .headerContainer {
        outline: none;
        background: none;
        border: none;
        display: flex;
        padding: 0 15px;
        width: 100%;
        .iconContainer {
            display: inline-block;
            color: $color-black;
            .iconStyle {
                width: 18px;
                height: 1.8rem;
                line-height: 18px;
                font-size: 18px;
            }
        }
        .title {
            font-family: $Roboto-Light;
            font-size: 28px;
            color: $color-black;
            line-height: 34px;
            padding-left: 10px;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
    .contentContainer {
        display: block;
        padding-top: 10px;
    }
    .hideChild {
        display: none;
    }
}