@import "../../../styles/variables";

.performanceContainer {
  display: flex;
  flex-direction: column;
  .loaderContainer {
    margin-top: 30px;
  }
  .TopText {
    font-family: $Roboto-Regular;
    font-size: 20px;
    color: $color-black;
    line-height: 26px;
    margin-bottom: 20px;
    font-weight: normal;
  }
  .AdvChtLink {
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    margin-top: 15px;
  }
  .legendsChart {
    margin: 15px 0px 10px 0px;
  }
  .blockDiv {
    display: inline-block;
    margin: 5px 5px;
    max-width: 180px;
    vertical-align: top;
    height: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
  .internalBlockDiv {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
  }
  .circleLegend {
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
    margin-top: 3px;
  }
  .chartSelections {
      height: 64px;
      padding-top: 19px;
      padding-bottom: 19px;
      padding-left: 15px;
      padding-right: 15px;
      background: #F7F7F7;
      margin-right: 20px;
    .itemsContainer {
      display: flex;

      flex: 1;
      position: relative;
      justify-content: flex-start;
    }
    .item {
      margin-right: 10px;
      background: $color-white;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 6px;

      font-family: $Roboto-Regular;
      font-size: 14px;
      color: $color-gray;
      text-align: center;
      line-height: 18px;
      width: 43px;
      height: 26px;

      .button {
        padding: 3px 10px;
        border: transparent;
        background: transparent;

        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-gray;
        text-align: center;
        line-height: 18px;
        cursor: pointer;
      }
    }
    .item:nth-last-child(-n + 2) {
      // margin-right: 0px;
    }

    .selectedItem {
      background: rgba(47, 174, 255, 0.25);
      border-radius: 6px;
      font-family: $Roboto-Regular;
      font-size: 14px;
      color: $color-blue;
      text-align: center;
      line-height: 18px;
      border: transparent;
    }
    .selectedItem > .button {
      color: #0572d6;
      font-family: $Roboto-Regular;
      font-size: 14px;
      text-align: center;
      line-height: 18px;
    }
    .hide {
      display: none;
    }
  }

  // chart styles heree
  .chart {
    min-height: 224px;
    padding-right: 10px;
  }
}

@media (max-width: 595px), only screen and (max-device-width:595px) {
  .performanceContainer {
    .blockDiv {
      max-width: 100%;
      margin: 2px 5px;
    }
    .chartSelections {
      margin-top: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      .itemsContainer {
        .row1FirstDD {
          margin-right: 255px;
          ul {
            margin-left: 0;
            min-width: 239px;
            width: 100%;
          }
          ul,
          li {
            span {
              padding-left: 10px;
              padding-right: 12px;
            }
          }
        }
        .row1SecondDD {
          ul {
            width: 82px;
            margin: 0;
            > li {
              padding: 3px 10px;
              border-radius: 3px;
            }
            > li:focus {
              border: 1px solid rgba(0, 0, 0, 0.3) !important;
            }
          }
        }
      }
    }
    .chart {
      margin-top: 27px;
    }
  }
}
