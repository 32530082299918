@import '../../../styles/variables';

.styleBoxContainer {
  width: 100%;
  max-width: 312px;
  background: $color-white-opacity;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 15px 15px 15px 15px;

  .styleBoxHeader {
    font-family: $Roboto-Regular;
    font-size: 20px;
    color: $color-black;
    line-height: 26px;
    padding-bottom: 15px;
  }

  .styleBoxDetail {
    margin: 15px 10px 15px 49px;
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: $color-black;
    line-height: 18px;
  }

  .styleBoxBottomDetail {
    font-family: $Roboto-Regular;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray;
  }
}

  .fixedIncomeStyleBox {
    @extend .styleBoxContainer;
    margin-top: 40px !important;
  }


@media screen and (min-width: 596px) and (max-width: 1023px) {
  .styleBoxContainer {
    max-width: 278px;
  }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
  .styleBoxContainer {
    max-width: 355px;

    .styleBoxDetail {
      margin: 15px 10px 10px 50px !important;
    }
  }
}

@media (min-width: 1023px), only screen {
  .styleBoxContainer {
    max-width: 100%;
  }
}
