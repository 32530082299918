@import "../../../styles/variables";

.noresultcontainer{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: auto;
    z-index: 9999;
    text-align: center;

    .noresult{
        text-align: center;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        display: inline-block;
        vertical-align: middle;
       
        .searchmsg {
            color: rgba(0, 0, 0, 1);
            font-size: 20px;
            line-height: 26px;
            margin: 0 auto;
            padding-top: 5px;
        }
        .recentsearchmsg {
            color: rgba(0, 0, 0, 1);
            font-size: 20px;
            line-height: 26px;
            margin: 0 auto;
            padding-top: 45px;
        }
                
        .searchIcon{
            color: rgba(0, 0, 0, 0.26);
            font-size: 42px;
            line-height: 24px;
            margin: 0 auto;
            padding-top: 55px;
            svg {
                height: 46px !important;
                width: 44px;
            }
        }
        .recentsearchIcon{
            color: rgba(0, 0, 0, 0.26);
            font-size: 42px;
            line-height: 24px;
            margin: 0 auto;
            padding-top: 55px;
            height: 46px;
            width: 44px;
            svg {
                height: 46px !important;
                width: 44px;
            }
        }
        .modelScreenermsg{
            color: #666666;
            font-size: 14px;
            line-height: 18px;
            margin-top: 10px;
        }
        .modelScreenerIcon{
            color: #666666;
            font-size: 24px;
            line-height: 24px;
            margin-top:83%;
            svg {
                height: 18px !important;
                width: 21px;
            }
        }
        .optionaltext{
            color: #666666;
            font-size: 14px;
            line-height: 18px;
            margin-top: 2%;
        }
        .noSymbolSelectedIcon{
            color: rgba(0, 0, 0, 0.26);
            font-size: 42px;
            line-height: 24px;
            margin-top:50%;
            svg {
                height: 46px !important;
                width: 44px;
            }
        }
        .noSymbolSelectedMsg{
            font-weight: 400;
            text-align: center;
            div>div:first-child {
                font-size: 20px;
                color: #000000;
                line-height: 26px;
            }
            div>div:nth-child(2) {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #666666;
            }
        }
    }
}

