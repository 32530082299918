@import "../../../../styles//variables";
.leftFilterSectionContainer {
  .filterIcon {
    color: #0572d6;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    &:hover {
      color: Black;
      .hoverActiveFilterIcon {
        display: block;
      }
    }
    &:focus {
      .hoverActiveFilterIcon {
        display: none;
      }
    }
    .hoverActiveFilterIcon {
      display: none;
      padding: 10px;
      text-align: center;
      position: absolute;
      background: white;
      top: -54px;
      color: #666666;
      left: -10px;
      border-radius: 3px;
      width: -webkit-max-content;
      width: max-content;
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    }
  }
  .iconSize {
    height: 18px;
    width: 25px;
  }
}
$filterHoverColor: #0572d6;  
:export {
  filterHoverColor: $filterHoverColor;
}
