@import "../../../../styles/variables";

.newsPreferencesModalContainer{
  div[id='outerModalPopUp'] {
    > div:first-child {
      width: 870px !important;
      max-height: 90vh;
    }
  }
  .disabled {
    color: $color-disabled-grey;
    cursor: not-allowed;
  }
  .modalHeader{
    display: flex;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    position: relative;
    z-index: 999;
    height: 48px;
    width: 100%;
    background-color: $color-dark-gray;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    .modalHeaderText{
        width: 100%;
        margin-left: 20px;
        color: $color-white;
        font-size: 20px;  
        line-height: 26px;
    }
  }
  .modalInnerContentContainer{
    height: 58vh;
    overflow: auto;
    padding:0 20px;
    .modalContentMainTitle, .modalContentSubTitle{
      font-family: $Roboto-Regular;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $color-black;
    }
    .modalContentMainTitle{
      font-size: 16px;
      line-height: 22px;
      padding: 15px 0 0 0;
    }
    .modalContentSubTitle{
      font-size: 14px;
      padding: 10px 0px;
    }
    .allCategoryLabel {
      font-size: 20px;
      line-height: 28px;
      color: $color-black;
      font-family: $Roboto-Regular;
      margin: 10px 0px;
    }
    .modalInnerContent{
      padding-bottom: 10px;
      .groupHeaderItemContainer{
        .groupHeadersText{
          font-family: $Roboto-Bold;
          font-size: 14px;
          line-height: 18px;
          padding: 12px 0px;
          color: $color-black;
        }
        .groupHeadersCheckboxContainer{
          display: flex;
          margin-left: -6px
        }
        .columnGroup{
          width: 33%;
          .eachNewsItemCheckbox{
            font-size: 14px;
            padding-bottom: 6px;
          }
          .checkDisabled {
            opacity: 0.3;
          }
        }
        .multiDropDownContentContainer{
          font-size: 12px;
        }
      }
    }
  }
  .footerContainer {
    border-top: 1px solid rgba(0,0,0,0.30);
    padding: 15px;
    .selectedCount {
      font-size: 14px;
      line-height: 18px;
      color: $color-light-grey;
      font-family: $Roboto-Regular;
    }
    .buttonLabels {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .clearButton {
        font-size: 14px;
        line-height: 18px;
        font-family: $Roboto-Medium;
      }
      .active {
        color: $color-blue;
        cursor: pointer;
      }

      .cancelButton {
        background: $color-white;
        color: $color-blue;
        cursor: pointer;
        width:80px;
        height: 34px;
        font-family: $Roboto-Regular;
        border-radius: 3px;     
        font-size: 14px;    
        text-align: center;
        line-height: 18px;    
        border: 1px solid rgba(0,0,0,0.30);
      }
    }
    button {
        height: 34px;
        padding: 8px 20px;
        line-height: 18px;
        margin-left: 24px;
    }
  }
  .modalHeaderCloseIcon{
    display: flex;
  }
  .modalCloseIcon {
    .btnPopUpClose {
        border: none;
        background-color: rgba(255, 255, 255, 0);
        outline: none;
        color: $color-black;
        background-repeat: no-repeat;
        margin-top: 2px;
        margin-right: 5px;
        cursor: pointer;
        font-size: 18px;                
        text-align: center;
        line-height: 18px;
        margin-left: auto;
      svg{
        path{
          fill:$color-white;
        }
      }
    }
    button:focus {
      border: 2px solid $color-white
  }
  }
}

@media screen and (min-width: 596px) and (max-width: 1024px) {
  .newsPreferencesModalContainer{
    
  div[id=outerModalPopUp] {
    > div:first-child{
      max-height: 618px !important;
      width: 670px !important;
    }
  }
    .modalInnerContentContainer{
      max-height: 495px;
      .modalInnerContent{
        .groupHeaderItemContainer{
          .columnGroup{
            width: 50%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 596px) {
  div[id='outerModalPopUp']{
    top: 0px;
    ul{
      width: 200px !important;
      li{
        padding: 4px 0px 2px 10px !important;
      }
    }
  }

  .newsPreferencesModalContainer {
    .modalHeader{
      background-color: $color-white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      .modalHeaderText{
        color:$color-black;
        font-weight: 500;
        font-size: 16px;
        .modalCloseIcon {
          .btnPopUpClose {
            svg{
              path{
                fill:$color-black;
              }
            }
          }
        }
      }
    }
    .groupHeaderItemContainer {
      display: flex;
      line-height: 23px;
      .groupHeadersText{     
         min-width: 40%;
      }
    }
    div[id='modalPopUpContentContainer'] {
      min-height: auto;
      max-height: none !important;
      height: inherit !important;
      max-width: auto !important;
      top: 0px !important;
      padding: 0 !important;
      margin: 0 !important;
      overflow-y: visible !important;
      overflow: inherit !important; 
      .modalInnerContentContainer{
        height: 100vh;
        overflow: auto;
        position: relative;
        .modalInnerContent {
          height: 70vh;
        }
        .multiDropDownContentContainer{
          padding-top: 8px;
        } 
      }
    }

    div[id='outerModalPopUp'] {
      >div:first-child {
        position: relative !important;
        height: 100% !important;
        max-height: 100% !important;
        width: auto !important;
      }

      .footerContainer {
        justify-content: center;
        border-top: 1px solid #B2B2B2;
        padding: 20px 0px 20px 10px;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 1;
        background: $color-white;

        .selectedCount {
          width: 98%;
        }

        .errorCount {
          color: $color-red-text-1;
          font-family: $Roboto-Medium;
        }
        .buttonLabels {
          white-space: nowrap;
          width: 95%;
          margin-top: 10px;
        }
        button {
            height: 34px;
            padding: 8px 20px;
            line-height: 16px;
        }
      }
    }
  }
}