@import "../../../../../styles/variables";

.popupContainer {
    background: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    width: 190px;
    padding: 10px 10px 16px 15px;
    position: absolute;
    pointer-events: none;
    z-index: 1;
    .firstRow {
        font-family: $Roboto-Medium;
        font-size: 12px;
        color: $color-black;
        line-height: 16px;
        padding-bottom: 9px;
        border-bottom: 1px solid rgba(0,0,0,0.07);
    }

    .secondRow {
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
        display: flex;
        justify-content: space-between;

        .label {
            font-family: $Roboto-Regular;
            color: $color-gray;
        }

        .value {
            font-family: $Roboto-Medium;
            color: $color-black;
        }
    }
}