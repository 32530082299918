@import "../../../../../styles/variables";

.chartContainer {
    div[id='overlayContainer'] {
        width: 90% !important;
        height: 280px !important;
    }

    .lineChart {
        width: 100%;
        position: relative;

        div {
            height: 300px;
            width: 100%;
            position: absolute;
        }

        svg[class="ct-chart-line"] {
            g[class="ct-grids"] {
                line[class="ct-grid ct-vertical"] {
                    stroke: rgba(0, 0, 0, 0.15);
                    stroke-dasharray: 0px;
                }
            }

            g[class="ct-series ct-series-a"] {
                path[class="ct-line"] {
                    stroke: $color-dark-gray;
                    stroke-width: 1px;
                    cursor: pointer;
                }

                line[class='ct-point'] {
                    stroke-linecap: initial;
                }
            }

            g[class="ct-labels"] {
                span[class="ct-label ct-vertical ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    text-align: right;
                    line-height: 16px;
                    justify-content: flex-end;
                }

                foreignObject {
                    span[class="ct-label ct-horizontal ct-end"] {
                        display: block;
                        font-family: $Roboto-Regular;
                        font-size: 12px;
                        color: $color-black;
                        line-height: 16px;
                    }

                    span[class="ct-label ct-horizontal ct-end hideExtraLabels"] {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .chartContainer {
        .lineChart {
            svg[class="ct-chart-line"] {
                g[class="ct-labels"] {
                    foreignObject {
                        height: 30px !important;
                    }
                }
            }
        }
    }
}