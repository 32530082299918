@import '../../../../styles/variables';

.premiumPriceMovementContainer{
    width: 100%;
    padding-top: 30px;
    .premiumPriceHeader{
        font-family: $Roboto-Light;
        font-size: 28px;
        color: $color-black;
        line-height: 34px;
        padding-bottom: 24px;
    }
    .premiumPriceContent{
        display: flex;
        flex-direction: row;
        .premiumPriceChart{
            width: 68%;
            margin-right: 2%;
        }
        .premiumPriceTable{
            width:30%;
            height: 188px;
            background: $color-white-opacity;
            box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
            border-radius: 3px;
            header{
                display: none;
            }
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 1023px) {
    .premiumPriceMovementContainer{
        .premiumPriceContent{
            flex-direction: column;
            .premiumPriceChart{
                width: 100%;
                margin-right: 0%;
            }
            .premiumPriceTable{
                width: 100%;
                margin-top:30px;
                div[data-table-structure='table-ipad-container'] {
                    display: block;
                }
                div[data-table-structure='table-ipad-container'] > div{
                    width: 100%;
                }
            }
        }
    }
}