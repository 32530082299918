.topPerformimgChartView {
    div[id="topPerformingChart"] {
        height: 160px;
        width: 100%;
      }
      .topStocksCard {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        > * {
          margin-right: 8px;
          margin-bottom: 10px;
        }
        div[data-symbol-card='modchart-symbol-card']{
          div[data-symbol-name='modchart-symbol-card-name']{
            padding-right: 15px !important;
          }
        }
      }
}