@import '../../../styles/variables';

.optionsContainer {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    .optionTable {
        display: flex;
        .callsOption {
            text-align: right;
        }
        .putsOption {
            text-align: left;
        }
        .table {
            max-width: calc(50% - 50px);
            .tableScroll {
                overflow-x: auto;
            }
        }
        .header {
            color: $color-black;
            text-align: center !important;
            display: block;
            width: 100%;
            background-color: $color-white;
            padding: 13px 0 14px 0;
            height: 48px;
            box-sizing: border-box;
        }
        .strikeColumn {
            .divider {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-white;
                height: 48px;
                box-sizing: border-box;
                .seprator {
                    border-left: 1px solid $color-black-10;
                    height: 24px;
                    box-sizing: border-box;
                }
                
            }
        }
    }
    
}

@media screen and (max-width: 1023px) {
    .optionsContainer {
        .optionTable {
            .table {
                .ipadScroll {
                    overflow: scroll;
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
    }
}

@media screen and (max-width: 595px) {
    .optionsContainer {
        .optionTable {
            .callsOption, .putsOption {
                overflow-x: auto;
            }
            .header {
                display: none;
            }
            .strikeColumn {
                .divider {
                    display: none;
                }
            }
        }
    }
}