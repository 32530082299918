@import '../../../../styles/variables';

.scoreRatingContainer {
    display: flex;
    align-items: center;
    width: 100%;

    .ratingContainer {
        width: 20%;

        .ratingType {
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-black;
            line-height: 16px;
            display: inline;
            border-bottom: 1px dashed #000000;
        }

        .ratingCount {

            .icon {
                margin-right: 3px;
                font-size: 14px;
                line-height: 26px;
            }
        }
    }

    .separator {
        border: 1px solid #E5E5E5;
        background-color: $color-gray-border;
        display: inline-block;
        height: 56px;
    }

    .scoreContainer {
        display: flex;
        justify-content: space-around;
        width: 80%;

        .scoreType {
            box-sizing: border-box;

            .label {
                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-gray;
                line-height: 16px;
                white-space: nowrap;
            }

            .value {
                font-family: $Roboto-Regular;
                font-size: 20px;
                color: $color-black;
                line-height: 26px;
            }
        }
    }
}

@media screen and (max-width: 595px) {

    .scoreRatingContainer {
        display: inline-block;

        .ratingContainer {
            padding: 0px 0px 10px 0px;
            min-width: 0px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ratingType {

                .editPencil {
                    margin-bottom: 15px;
                }
            }
        }

        .scoreContainer {
            margin-top: 15px;
            width: 100%;
            justify-content: space-between;
            .scoreType {
                padding-right: 0px;
            }
        }

        .separator {
            display: none;
        }
    }
    
}