@import './fonts.scss';

body {
    background-color: #f7f7f7;
    font-family: "Roboto-Regular",Arial,sans-serif;
    overflow: hidden;
    a, h3 {
        color: #787b7d;
    }

  a,
  h3 {
    color: #787b7d;
  }

  a[class="disabled"]:hover {
    color: #787b7d !important;
    text-decoration: none !important;
    cursor: not-allowed;
  }

  a[class="enabled"] {
    color: #00485e !important;
  }
  .textCtr {
    text-align: center;
  }
  .hide {
    display: none;
  }
  .left-align {
    float: left;
  }
  .right-align {
    float: right;
  }
}
