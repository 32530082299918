@import '../../../styles/variables';

.performanceChartContainer {
  .performanceComparisonChartDomElement {
    height: 300px;
    width: 100%;
  }
    .bottomDivider {
    width: 99%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 20px 0px;
  }
  .bottomDividerWithDisclaimer {
    width: 99%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 10px 0px;
  }
  .disclaimerChart {
    font-size: 12px;
    margin-top: 10px;
    color: $color-black;
  }
  .noDataDiv {
    padding: 30px 0px;   
  }
}
