@import "../../styles/variables";

.chartEventInfoContainer {
  pointer-events: none;
}

.chartsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .chartTabContainer {
    flex: 1;
    position: relative;

    ul {
      background: $color-white;
      border-bottom: none;
      padding: 0px;
    }

    .lowerIndicator {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 10px;
      position: absolute;
      bottom: 115px;

      > * {
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }
  .chartTabMobile {    
    flex: 1;
    position: relative;
    ul {
      background: $color-white;
      border-bottom: none;
      margin-bottom: 60px;
    }

    .lowerIndicator {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 10px;
      position: absolute;
      bottom: 115px;

      > * {
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }
  .chartTools {
    background: $color-white-opacity;
    border-radius: 3px;
    padding: 15px 15px 15px 15px;
  }

  div[id="priceChart"] {
    height: 620px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .modcharts-panel {
      .modcharts-legend{
        z-index: 1;
      }
    }
  }

  .tabSaveChartContainer {
    display: none;
  }
}

.chartEvent {
  font-family: $Roboto-Regular;
  font-size: 14px;
  line-height: 18px;
  color: $color-white;
  text-align: center;
  cursor: pointer;
  padding-top: 1px;
  border-radius: 2px;
  width: 14px;
  height: 20px;
  position: absolute;
}

.dividends {
  background-color: #7D324C;
}

.splits {
  background-color: #00B3A1;
}

.earnings {
  background-color: #008338;
}

.chartPopover {
  background: #FFFFFF;
  box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
  border-radius: 3px;
  position: absolute;
  z-index: 996;
}
@media screen and (min-width: 375px) and (max-width: 1023px) {
  .chartsContainer {
    .tabSaveChartContainer {
      display: block;
      float: right;
      margin-top: -50px;
    }
  }
}