@import "../../../../styles/variables";
.filterContainer {
  position: absolute;
  z-index: 999;
  background: white;
  width: 50%;
  max-width: 345px;
  top: 60px;
  left: 0px;
  box-shadow:  -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
  border-radius: 3px;
  .apply {
    background-color: #0572d6;
    color: #ffffff;
    border: 0px solid rgba(0, 0, 0, 0.3);
  }

  .reset {
    background: #ffffff;
    color: #0572d6;
  }

  .crossButtonFilter {
    float: right;
    margin-right: 1%;
    cursor: pointer;
  }

  .multiDropDown {
    display: flex;
    margin-top: 20px;
    height: 34px;
    ul {
      width: 100%;
    }

    ul > li:first-child {
      height: 34px;
      padding: 8px 0px 8px 10px;
      box-sizing: border-box;
      font-weight: normal;
    }

    .labelmultiDropDown {
      font-size: 14px;
      color: #000000;
      line-height: 18px;
      display: inline-block;
      width: 34%;
      padding-right: 8px;
      margin: auto 0;
      font-family: $Roboto-Medium;
    }
    .multiDropDownContentContainer {
      width: 66%;
      position: relative;
    }
    .dateRangeCalendarContentContainer {
      width: 66%;
      position: relative;
      & > div:first-child {
        width: 100%;
        height: 100%;
        margin: 0;
      }
      & > div:first-child > div,
      input {
        width: 100%;
        height: 34px;
        box-sizing: border-box;
      }
      input {
        padding-left: 10px;
      }
    }
  }
}

.headerLabel {
  display: inline-block;
  font-size: 16px;
  color: #000000;
  line-height: 22px;
  font-family: $Roboto-Medium;
}

.filterContainerHeading {
  display: block;
  padding: 13px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.searchFilterContainer {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  .searchFilterIcon{
    position: absolute;
    right: 30px;
    top: 78px;
    z-index: 1;
    height: 14px;
    width: 14px;
    color: $color-black;
  }
}

.searchBoxFilter {
  width: 100%;
  font-size: 14px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  box-sizing: border-box;
}

.buttonsFilter {
  margin: 0 auto;
  display: flex;
  padding: 15px 20px;
  justify-content: center;

  .commonButton {
    margin-right: 10px;
  }
}

div.hide {
  display: none;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media (min-width: 576px) and (max-width: 1023px) {
  .filterContainer {
    width: 70%;
  }
}
@media (max-width: 575px) {
  .filterContainer {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    :global .react-datepicker-popper[data-placement^="bottom"],
        :global .react-datepicker-popper{
        left: -85px !important;
        }

    .multiDropDown {
      ul {
        width: 100%;
      }
    }
  }
}
