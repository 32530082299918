@import "../../../styles/variables";
.popupScreenAlert {
    div[id=outerModalPopUp]>div {
        width: auto;
        min-height: 130px !important;
        max-height: 130px !important;
        top: 10%;
      }
  
      div[id=modalPopUpContentContainer] {
        min-height: 145px !important;
        max-width: 444px !important;
        top: 0px !important;
        padding: 0 !important;
        margin: 0 !important;
        overflow: inherit !important;
      }
.ExitAlertContainer {
    .headerText {
        margin-left: 10px !important;
        font-family: $Roboto-Regular;
        line-height: 18px;
        word-break: break-word;
        font-size: 14px;
        color: $color-black;
        font-weight: 400;
    }
    .content {
        padding: 25px 25px 0px 25px;
        color: $color-dark-orange-3;
        line-height: 18px;
        font-weight: 400;
        display: flex;
        justify-content: center;
    }
    .iconNoData {
        font-size: 24px;
        color: $color-dark-orange-3;
        text-align: center;
        line-height: 24px;
        font-weight: 900;
        margin-left: 25px;
    }
    .button-container {
        text-align: center;
        padding-top: 23px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        line-height: 16px;
        padding-bottom: 16px;
        button {
            width: auto;
            padding: 0 15px;
        }
        button:first-child {
            margin-right: 10px;
        }
    }
}
}