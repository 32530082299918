@import "../../../../styles/variables";

.fundStrategyModalHeader{
    display: flex;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    position: relative;
    z-index: 999;
    height: 48px;
    width: 100%;
    background-color: $color-dark-gray;

    .fundStrategyModalHeaderText{
        width: 92%;
        margin-left: 20px;
        margin-top: 12px;
        margin-bottom: 10px; 
        color: $color-white;
        font-size: 20px;  
        line-height: 26px;
        font-family: $Roboto-Regular;
    }
    .fundStrategyModalPopUpClose {
        border: none;
        background-color: rgba(255, 255, 255, 0);
        color: $color-white;
        outline: none;
        background-repeat: no-repeat;    

        font-size: 18px;                
        text-align: center;
        line-height: 18px;
        cursor: pointer;
        float:right;
        margin-top: 16px;
        margin-bottom: 14px;     
        margin-right: 4px; 
    }
}


@media screen and (min-width: 596px) and (max-width: 1023px) {
    .fundStrategyModalHeader{
      
        .fundStrategyModalHeaderText{ 
            margin-top: 12px;
            margin-bottom: 10px;   
            margin-right: 10px;      
        }

        .fundStrategyModalPopUpClose {          
            margin-top: 16px;
            margin-bottom: 14px;    
                      
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .fundStrategyModalHeader{
        background-color: $color-white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);    
       
        .fundStrategyModalHeaderText{
            color: #000000;
            margin-left: 10px;
            margin-top: 12px;
            margin-bottom: 6px;         
        }

        .fundStrategyModalPopUpClose {           
            color: $color-black;
            font-weight: 300;
            font-size: 24px;
            line-height: 24px;  
            margin-right: 6px;           
        }
    }
}