@import '../../../../styles/variables';

.optionsTableContainer {
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-black;
    line-height: 18px;
    background-color: $color-white;
    min-width: 440px;
    .header {
        text-align: center;
        padding: 13px 0 14px 0;
        box-sizing: border-box;
    }
    .tableRow {
        display: inline-block;
        width: 100%;
        border-top: 1px solid $color-black-10;
        padding: 12px 0 13px 0;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        box-sizing: border-box;
        > div {
            display: inline-block;
            padding: 0 10px;
            width: 16.66%;
            box-sizing: border-box;
        }
    }
    .tableHeader {
        border-bottom: 1px solid $color-netxwealth-news-research-grey-border;
    }
    .tableBody {
        font-family: $Roboto-Regular;
        .blueText {
            color: $color-blue;
        }
        .cursorPointer{
            cursor: pointer;
        }
        .blueBg {
            background-color: $color-table-background-blue;
        }
    }
    .reverseColumn {
        display: flex;
        flex-direction: row-reverse;
    }
}