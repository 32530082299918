@import "../../../../../../styles/variables";

.glossaryContainer {
    text-align: left;
    display: inline;

    .glossaryBtn {
        margin-right: 4px;
        display: inline;
    }
}