@import "../../../../../../styles/variables";

.watchlistTopNewsSectionContainer {
    background: $color-white;
    border: 1px solid $color-white-whisper;
    border-radius: 3px;
    padding: 10px 10px 0px 19px;
    .header {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        font-weight: 400;
        padding-bottom: 5px;
    }

    .watchlistInsightBody {
        display: flex;
        padding-top: 15px;
        padding-bottom: 0.5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.14);
        flex-direction: column;

        .provider {
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-gray;
            line-height: 16px;
            font-weight: 400;
        }

        .headline {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            font-weight: 400;
            padding-top: 4px;
        }

        .symbolName {
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-green-light-3;
            line-height: 18px;
            font-weight: 400;
            margin-top: -5px;
        }

        .fotterText {
            font-family: $Roboto-Medium;
            font-size: 14px;
            color: $color-blue;
            line-height: 18px;
            font-weight: 400;
            padding: 5px 0px 15px 0px;
            border: none;
            background: none;
            width: fit-content;
            cursor: pointer;
        }

        div[data-value="Symbol List Container"] {
            flex-direction: row;
            div[data-value="Symbol container"] {
                width: auto;
                padding-right: 20px;
            }
        }
    }

    .watchlistInsightBody:last-child {
        border-bottom: none;
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .watchlistTopNewsSectionContainer {
        padding: 0px;
        border: none;
        margin-top: 20px;
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .watchlistTopNewsSectionContainer {
        border: none;
        padding: 0px;
        margin-top: 20px;
    }
}