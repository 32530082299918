@import "../../../styles/variables";

.newsHeaderContainer {
    .provider{
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: #666666;
        line-height: 16px;
        padding: 25px 0 4px 0;
    }
    .headline {
        font-family: $Roboto-Medium;
        font-size: 18px;
        color: #000000;
        line-height: 22px;
        padding: 0 0 10px 0;
        word-break: break-word;
    }
}