@import "../../../styles/variables";

.emptyNewsContainer {
    font-family: $Roboto-Regular;
    background: $color-white-opacity;
    min-height: 100px;
    min-width: 100px;
    padding: 10px 15px;
    color: $color-black;
    margin-bottom: 20px;
    border-radius: 4px;

    .header {
        font-family: $Roboto-Regular;
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
        padding: 10px 0px;
    }

    .body {
        .description {
            font-size: 14px;
            line-height: 26px;
            font-weight: 400;
            padding: 18px 0px
        }

        .selectPreferences {
            border-top: 1px solid rgba(0,0,0,0.10);
            padding: 15px 0px 8px 0px !important;
            text-align: center;

            .preferencesBtn {
                background: $color-blue;
                border-radius: 3px;
                min-width: 180px;
                font-size: 14px;
                color: $color-white;
                text-align: center;
                line-height: 18px;
                height: 34px;

                font-weight: 400;
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 596px) {
    .emptyNewsContainer {
        .body {
            .selectPreferences {
                .preferencesBtn {
                    width: 100%;
                }
            }
        }
    }
}
