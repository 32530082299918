@import "../../../styles/variables";

.esgAumRiskScoreChartContainer {

    .chartBarContainer {
        width: 34px;
        background: rgba(0, 0, 0, 0.07);
        position: absolute;
        bottom: 64px;
        cursor: pointer;
        z-index: 1;
      }

    .chartContainer {
        width: 100%;
        height: 300px !important;

        div {
            height: 100%;
        }

        svg[class="ct-chart-bar"] {
            min-height: 164px;

            g[class="ct-series ct-series-a"] {
                line {
                    stroke: $color-bar-graph-blue-dark;
                    stroke-width: 25px;
                }
            }

            g[class="ct-series ct-series-b"] {
                line {
                    stroke: $color-graph-light-blue;
                    stroke-width: 25px;
                }
            }

            g[class="ct-labels"] {
                span[class="ct-label ct-horizontal ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    text-align: center;
                    line-height: 16px;
                    padding-top: 9px;
                }

                span[class="ct-label ct-vertical ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    text-align: right;
                    line-height: 16px;
                    margin-top: 5px;
                    justify-content: flex-end;
                }


            }
        }

        g[class="ct-grids"] {
            line {
                stroke: $color-divider-grey-2;
                stroke-dasharray: none;
                stroke-width: 1px;

            }

            line[class="ct-grid ct-horizontal hiddenChartGridLine"] {
                stroke: none;
            }

            line[class="ct-grid ct-vertical"] {
                stroke: rgba(0, 0, 0, 0.15);
            }
        }
    }

    .nxiChartContainer {
        width: 100%;
        height: 300px !important;

        div {
            height: 100%;
        }

        svg[class="ct-chart-bar"] {
            min-height: 164px;

            g[class="ct-series ct-series-a"] {
                line {
                    stroke: $color-bar-graph-blue-dark;
                    stroke-width: 25px;
                }
            }

            g[class="ct-series ct-series-b"] {
                line {
                    stroke: $color-black-solid;
                    stroke-width: 25px;
                }
            }

            g[class="ct-labels"] {
                span[class="ct-label ct-horizontal ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    text-align: center;
                    line-height: 16px;
                    padding-top: 9px;
                }

                span[class="ct-label ct-vertical ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    text-align: right;
                    line-height: 16px;
                    margin-top: 5px;
                    justify-content: flex-end;
                }


            }
        }

        g[class="ct-grids"] {
            line {
                stroke: $color-divider-grey-2;
                stroke-dasharray: none;
                stroke-width: 1px;

            }

            line[class="ct-grid ct-horizontal hiddenChartGridLine"] {
                stroke: none;
            }

            line[class="ct-grid ct-vertical"] {
                stroke: rgba(0, 0, 0, 0.15);
            }
        }
    }
}

@media (min-width: 375px) and (max-width: 595px) {
    .esgAumRiskScoreChartContainer {
        .chartContainer {
            svg[class="ct-chart-bar"] {
    
                g[class="ct-series ct-series-a"] {
                    line {
                        stroke: $color-bar-graph-blue-dark;
                        stroke-width: 15px;
                    }
                }
    
                g[class="ct-series ct-series-b"] {
                    line {
                        stroke: $color-graph-light-blue;
                        stroke-width: 15px;
                    }
                }
            }
        }
        .nxiChartContainer {
            svg[class="ct-chart-bar"] {
    
                g[class="ct-series ct-series-a"] {
                    line {
                        stroke: $color-bar-graph-blue-dark;
                        stroke-width: 15px;
                    }
                }
    
                g[class="ct-series ct-series-b"] {
                    line {
                        stroke: $color-black-solid;
                        stroke-width: 15px;
                    }
                }
            }
        }
    }
}