@import "../../../../styles/variables";

.eventsContainer {
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    width: 312px;
    padding: 15px 15px 20px 15px;
    display: flex;
    justify-content: space-between;
    height: 130px;

    .leftSection {
        .header {
            font-family: $Roboto-Regular;
            font-size: 16px;
            color: $color-gray;
            line-height: 22px;
            padding-bottom: 5px;
        }
        .middle {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding-bottom: 15px;
            width: 70%;
            .symbolName {
                font-family: $Roboto-Bold;
            }
        }
        .change {
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-gray;
            line-height: 18px;
        }
    }
    .rightSection{
        .chart {
            padding-top: 33px;
        }
        div[permance-Chart-noData="true"] {
            &>div>div>div:nth-of-type(2) {
                font-size: 14px !important;
                line-height: 14px !important;
            }
        }
    }

}