.footerContainer {
    border-top: 1px solid rgba(0,0,0,0.30);
    padding: 15px 0;
    text-align: center;
    button {
        height: 34px;
        width: 155px;
    }
}

@media screen and (min-width: 375px) and (max-width: 595px){
    .footerContainer {
        position: relative;
        bottom: 0;
        width: 100%;
    }
}