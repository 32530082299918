@import '../../../styles/variables';
.toggle {
	position: relative;
	font-family: $Roboto-Regular;
	font-size: 12px;
	color: #666666;
	line-height: 16px;
	input[type="checkbox"] {
		height: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
		width: 0;
		&:focus + label {
			color: black;
			background-color: transparent;
			border-color: black;
			outline: 0;
		}
	}
	label {
		align-items: center;
		display: inline-flex;
		
		> span {
			background: rgb(165, 187, 179);
			cursor: pointer;
			display: block;
			width: 48px;
			height: 15px;
			border-radius: 12px;

			&.leftControl {
				margin-right: 15px;
			}

			&.rightControl {
				margin-left: 8px;
			}
			
			position: relative;
			transition: background 250ms ease-in-out;
			will-change: background;
			&:before {
				background: #348638;
				box-shadow: 0 1px rgba(51, 51, 51, 0.4);
				display: block;
				content: '';
				left: 1px;
				width: 22px;
				height: 22px;
				border-radius: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				transition: left 100ms linear;
				will-change: left right;
			}
		}
	}
	input[type="checkbox"]:checked + label {
		> span {
			background: rgba(72,183,72,0.50);;
			transition: background 250ms ease-in-out;
			&:before {
				left: calc(100% - 23px);
				transition: left 100ms linear;
			}
		}
	}
}
