@import '../../../styles/variables';

.dataUnavailableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
	letter-spacing: 0;
	text-align: center;
	margin: 0 auto;
	padding-top: 5%;
	padding-bottom: 3%;
    .iconContainer {
        position: relative;
        font-size: 18px;  
        padding-bottom: 5px;
        color: $color-gray;
        text-align: center;
        .iconDefault {
            width: 18px;
            line-height: 18px;
        }
    }
    .dataUnavailableText {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-gray;
        line-height: 30px;
    }
    .dataUnavailableLongMsg {
        font-size: 14px;
        line-height: 18px;
    }

}
$defaultColorIcon: $color-gray;  
:export {
    defaultColorIcon: $defaultColorIcon;
}