.container {
    .showMoreTblRows{
        display : inline-block;
        padding-top: 5px;
        line-height: 18px !important;
        color: #0572D6;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
    }
    .benchmarkDisclaimer{
        display: inline-block;
    }
    .setNoResultMargin {
        margin: 0 10px 0 10px;
    }
    .helperMessage {
        font-size: 12px;
        color: #000000;
        padding-left: 8px;
    }
    @media (max-width: 1023px), only screen and (max-device-width:1023px) {
        .helperMessage {
            padding-left: 0px;
            display: block;
            width: 100%;
            clear: both;
        }
        .benchmarkDisclaimer {
            display: block;
             // margin-top: 40px;
        }
    }
    @media (max-width: 595px), only screen and (max-device-width:595px) {
        .benchmarkDisclaimer {
            margin-top: 40px;
        }
		.showMoreTblRows {
			float: left;
		}
    }
}