@import '../../../styles/variables';
.shareClassContainer{
    .heading {
        font-family: $Roboto-Regular;
        font-size: 28px;
        line-height: 34px;
        text-Align: Left;
        color: $color-black;
        margin-top: 30px;
    }
    .shareClassContainer{
        display: flex;
        .fixedCard{
            width:254px ;
            margin-top: 20px;
            border-right: 1px solid rgba(0,0,0,0.1);
            .header {
                font-family: $Roboto-Bold;
                font-size: 12px;
                line-height: 24px;
                text-Align: Left;
                font-weight: 500;
                color: $color-black;
            }
            .cardContainer{
                padding: 18px 0px 3px 0px;
            }
        }
        .scrollableCards{
            width: calc(100% - 255px);
            padding-left: 24px;
        }
        .loader{
            width: 100vw;
        }
    }
    @media (max-width: 595px), only screen and (max-device-width:595px) {
        .shareClassContainer{
            display: inline-block;
            .fixedCard{
                width: 100vw;
                border: none;
                .cardContainer{
                    padding: 18px 0px 0px 0px;
                }
            }
            .scrollableCards{
                width: calc(100vw - 45px);
                padding-left: 0px;
            }
        }
    }
}