@import "../../../../../src//styles/variables";

.topStocksMain{
    .topStocksHeader{
        margin: 30.2px 0px 15.8px 0px;
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 30px;
    }
    .topStockBottomLine{
        margin-top: 30px;
    }
    div[id='TopStocksCrousal']{
        background-color: transparent;
        display: inline-block;
        box-shadow: none;
        text-align: unset;
        margin-top: -53px;
        span[data-value="customDivider"] {
          font-size: 18px;
          margin-right: -4px;
          padding-left: 8px;
          color: $color-gray;
        }
        & > div:first-child {
          position: relative;
        }
        & > div:first-child > span {
          position: absolute;
          right: 0px;
          top: 10px;
          z-index: 1;
        }
    }
    .topStocksRow{
        display: flex;
        margin-top: 53px;
    }
}

@media screen and (max-width: 595px) { 
    .topStocksMain{
        .topStocksHeader{
            margin: 30px 0px 13px 0px;
        }
        div[id='TopStocksCrousal']{
            display: block;

            background: $color-white;
            max-width: 2200px;
            overflow-x: auto;
            margin-top: 15px;
            padding: 0px;
            /* Shadows */
            background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
                /* Shadow covers */ linear-gradient(to right, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0)),
                linear-gradient(to left, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0));
            background-position: left center, right center, left center, right center;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;

            /* Opera doesn't support this in the shorthand */
            background-attachment: local, local, scroll, scroll;
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            .topStockMobileContainer{
                display: flex;
                min-width: 2200px;
                >div{
                    background: none;
                }
            }
        }
        /* Hide scrollbar for Chrome, Safari and Opera */
        div[id='TopStocksCrousal']::-webkit-scrollbar {
            display: none;
        }
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) { 
    .topStocksMain{
        .topStocksHeader{
            margin: 25px 0px 13px 0px;
        }
    }
}