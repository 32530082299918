@import "../../../../styles/variables";

.watchlistContainer {
    background: $color-white;
    .maxComparePopup {
        div[id='maxCompare'] {
        display: flex;
        justify-content: center;
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: auto;
        top: 40%;
        }
    }
    .header {
        font-family: $Roboto-Light;
        font-size: 28px;
        color: $color-black;
        line-height: 34px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .headline {
            float: left;
            padding-left: 10px; 
        }

        .compareDiv {
            position: absolute;
            right: 0px;
            margin-top: 53px;
            .refreshIcon{
                background: none;
                border: none;
                font-size: 12px;
                line-height: 16px;
            }
            .comingSoon{
                width: auto;
                height: 18px;
                padding: 0px 8px;
                font-family: $Roboto-Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: $color-white;
                background: $color-stock-orange;
                border-radius: 3px;
                display: inline-block;
                position: absolute;
                z-index: 9999999;
                margin: 25px 0px 0px 12px;
              }
            .disableCompareCompany{
                float: right;
                margin-right: 35px;
                border: 1px;
                padding: 8px 20px;
                background: rgba(0, 0, 0, 0.10);
                border-radius: 3px;
                font-family: $Roboto-Regular;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.26);
                text-align: center;
                line-height: 18px;
                cursor: pointer;
            }
            .enableCompareCompany{
                float: right;
                margin-right: 35px;
                border: 1px;
                padding: 8px 20px;
                background: $color-blue;
                border-radius: 3px;
                font-family: $Roboto-Regular;
                font-size: 14px;
                color: $color-white;
                text-align: center;
                line-height: 18px;
            }
        }
    }

    .stockTabs {
        background: $color-white;
        padding: 0 15px;

        .floatRight {
            float: right;
            padding-bottom: 20px;
        }

        .appGap {
            height: 30px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.07);
        }
        ul[id="watchlistStocksTab"] > li:nth-child(even) {
            padding-right: 0px;
            padding-left: 0px;
        }

        ul[id="watchlistStocksTab"]{
            margin-bottom: 5px;
        }
    }

    div[id='outerModalPopUp']>div {
        width: auto;
        min-height: auto !important;
        max-height: none !important;
        padding: 0px !important;
    }
    
    div[id='modalPopUpContentContainer'] {
        min-height: auto;
        height: auto !important;
        // max-width: 480px !important;
        top:0px !important;
        padding:0 !important;
        margin:0 !important;
        overflow-y: visible !important;
        overflow: inherit !important;
    }
}

@media screen and (max-width: 595px) {
.watchlistContainer {
    div[id='outerModalPopUp']>div {
        width: 100%;
        min-height: auto !important;
        height: inherit !important;
      }
  
      div[id='modalPopUpContentContainer'] {
        min-height: auto;
        max-height: none !important;
        height: inherit !important;
        top:0px !important;
        padding:0 !important;
        margin:0 !important;
        overflow-y: visible !important;
        overflow: inherit !important;
    }
    .compareDiv{
        position: relative !important;
        margin-top: 0px !important;
        .disableCompareCompany{
            margin-right: 0px !important;
        }
        .enableCompareCompany{
            margin-right: 0px !important;
        }
    }
}
}
