@font-face {
    font-family: 'Roboto-Regular';
    src: url('https://content.markitcdn.com/pershing-netxwealth.wallst.com/assets/fonts/Roboto-Regular-webfont.eot');
    src: url('https://content.markitcdn.com/pershing-netxwealth.wallst.com/assets/fonts/Roboto-Regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('https://content.markitcdn.com/pershing-netxwealth.wallst.com/assets/fonts/Roboto-Medium-webfont.eot');
    src: url('https://content.markitcdn.com/pershing-netxwealth.wallst.com/assets/fonts/Roboto-Medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('https://content.markitcdn.com/pershing-netxwealth.wallst.com/assets/fonts/Roboto-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'Roboto-Bold';
    src: url('https://content.markitcdn.com/pershing-netxwealth.wallst.com/assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }