@import "../../../../styles/variables";

.scoreBarContainer {
    margin-right: 24px;
    width: 100%;

    .label {
        font-size: 12px;
        line-height: 16px;
        font-family: $Roboto-Medium;
        color: $color-black;
        margin-bottom: 10px;
        min-height: 1px;
    }

    .hideLabel {
        visibility: hidden;
    }

    .bar {
        position: relative;
        width: 100%;

        .valueBar {
            position: absolute;

            .value {
                position: absolute;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                color: $color-white;
                padding-top: 4px;
                margin-left: -2px;
                width: 100%;
            }

            .icon {
                width: auto;
                height: 30px;
                margin-bottom: 15px;
                margin-left: -15px;
            }

            .icon2 svg{
                width: auto;
                height: 11px;
                margin-top: 32px;
            }

            .icon3 {
                height: 11px;
                margin-top: 32px;
                position: absolute;
                border-left: 2px solid $color-blue-solid-2;
            }

            .popOverBody {
                max-width: 302px;
                font-family: $Roboto-Regular;
                text-align: justify;
                font-size: 12px;
                color: $color-black;
                line-height: 16px;
                padding: 0 5px 5px 5px;

                span {
                    white-space: normal;
                }
            }

            .tooltip {
                display: none;
            }
        }

        .valueBar>div>div {
            transform: translate3d(0px, -51px, 0px) !important
        }

        .valueBar:hover>.tooltip {
            display: block;
        }
    }

    .scoreBar {
        width: 100%;
        background: rgba(0, 0, 0, 0.26);
    }

    .scoreBarMarin {
        margin: 47px 0px 10px;
        border-top: 1px inset rgb(238, 238, 238) !important;
    }

    .minMaxContainer {
        display: flex;
        justify-content: space-between;

        .minValue {
            font-size: 14px;
            line-height: 18px;
            color: $color-black;
        }

        .maxValue {
            float: right;
            font-size: 14px;
            line-height: 18px;
            color: $color-black;
        }

        .minMaxLabel {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $color-gray;
        }

        .noValue {
            position: relative;
            top: 1px;
        }
    }
}

@media screen and (max-width: 595px) {

    .scoreBarContainer {
        margin-bottom: 20px;
        margin-right: 0px;

        .scoreBar {
            width: 100%;
        }
    }

}