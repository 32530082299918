@import "../../../styles/variables";

.ownershipContainer{
    .insiderTransactions {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(0,0,0,0.10);
        margin-bottom: 30px;
    }
    .topInstitutionalHolders{
        padding-bottom: 30px;
            border-bottom: 1px solid rgba(0,0,0,0.10);
            margin-bottom: 30px;
    }
}
