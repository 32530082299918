@import '../../../../../styles/variables';

.topPerformingContainer{
    display: flex;
    flex-direction: column;
    .topPerformingHeader{
        margin: 30px 0px 20px 0px;
        display: flex;
        .topPerformingHeaderText{
            width: 80%;
            font-family: $Roboto-Light;
            font-size: 28px;
            color: $color-black;
            line-height: 34px;
        }
        .topPerformingIconsRow{
            display: flex;
            justify-content: flex-end;
            width: 20%;
            .topPerformingIcon1{
                width: 36px;
                height:34px;
                text-align: center;
                font-size: 14px;
                line-height: 14px;
                border: none;
                background-color: transparent;
                color: #A9A9A9;
                cursor: pointer;
            }
            .topPerformingIcon2{
                width: 36px;
                height:34px;
                text-align: center;
                font-size: 18px;
                line-height: 18px;
                border: none;
                background-color: transparent;
                color: #A9A9A9;
                cursor: pointer;
            }
            .active{
                background: #0572D6;
                border-radius: 3px;
                color: $color-white !important;
            }
        }
    }
    .topPerformingDivider{
        background: rgba(0,0,0,0.10);
        margin: 30px 0px;
        width: 100%;
    }
}
@media screen and (max-width: 595px) { 
    .topPerformingContainer{
        .topPerformingHeader{
            .topPerformingIconsRow{
                .active{
                    padding-left: 10px;
                }
                }
            }
        }
}