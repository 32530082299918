@import '../../../../../styles/variables';

.notePopupContainer {
    button[data-value="companyDescriptionPopUpClose"] {
        margin-right: 8px !important;
    }

    div[id="modalPopUpContentContainer"] {
        width: 480px;
        height: auto;
    }

    .noteContainer {
        padding: 20px;
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;

        textarea {
            background: $color-white;
            border: 1px solid $color-light-gray;
            border-radius: 3px;
            width: 100%;
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding: 10px -5px 0px 10px;
        }

        textarea:focus {
            outline: none !important;
            border: 1px solid $color-light-gray;
        }
        .viewNotes {
            .date {
                font-family: $Roboto-Medium;
                font-size: 12px;
                color: $color-gray;
                line-height: 16px;
            }
            .note {
                font-family: $Roboto-Regular;
                font-size: 14px;
                color: $color-black;
                line-height: 18px;
                white-space: break-spaces;  
            }
        }
    }

    .noteFooter {
        .noteBtnContainer {
            display: flex;
            justify-content: center;
            padding: 20px;
            .notesEditDeleteContainer {
                display: flex;
                & :first-child {
                    margin-right: 10px;
                }
                .deleteButton {
                    border: 1px solid $color-blue;
                    background: rgba(5, 114, 214, .1);
                }
            }
        }
    }
}


@media screen and (max-width: 595px) {
    .notePopupContainer {
        button[data-value="companyDescriptionPopUpClose"] {
            margin-right: 0px !important;
        }
        div[id="modalPopUpContentContainer"] {
            width: auto;
            height: auto;
        }

        .noteContainer {
            padding: 20px;
        }

        .noteFooter {
            position: fixed;
            bottom: 0px;
            width: 100%;
        }
    }
}