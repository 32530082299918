@import "../../../../styles/variables";

.divIncreaseContainer {
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    width: 312px;
    height: 130px;
    padding: 15px 15px 20px 15px;
    display: flex;
    justify-content: space-between;
    .leftSection {
       
        min-width: 170px;
        .header {
            font-family: $Roboto-Regular;
            font-size: 16px;
            color: $color-gray;
            line-height: 22px;
            padding-bottom: 5px;
        }
        .middle {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding-bottom: 15px;
            padding-right: 10px;
            .symbolName {
                font-family: $Roboto-Bold;
            }
        }
        .dates {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-gray;
            line-height: 18px;
            button {
                border: none;
                margin: 0;
                padding: 0;
                background: transparent;
                font-size: 14px;
                line-height: 18px;
                font-family: $Roboto-Medium;
            }
            .errorMessage{
                color: $color-red-text;
            }
        }
    }
    .rightSection {
        .rightContainer{
            background: $color-white;
            box-shadow: 0 0 2px 0 rgba(0,0,0,0.20);
            border-radius: 6px;
            width: 115px;
            text-align: center;
            margin-top: 5px;
            .logo{
                margin-top: 30px;
                img {
                    height: 25px;
                }
            }
            .rightItem{
                border: 1px solid #E0E0E0;
                font-family: $Roboto-Regular;
                font-size: 14px;
                color: #666666;
                text-align: center;
                line-height: 18px;
                padding: 5px 0px;
            }
            .selected{
                background: #37474F;
                color: #fff;
            }
        }
        .noBorder{
            box-shadow: none !important;
        }
    }
    
}