@import "../../../styles/variables";

.addToWatchlistsContainer{
    .watchlistsPopupContent{
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        font-weight: 400;
        padding: 30px 44px 30px 20px;
        word-break: break-word;
        white-space: break-spaces;
    }
    .footerContainer {
        text-align: center;
        button {
            height: 28px;
            width: 117px;
        }
        button:first-child {
            width: 142px;
            margin-right: 10px;
        }
    }
    div[id=outerModalPopUp] {
        >div:first-child {
            max-height: 525px;
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 595px){
    .addToWatchlistsContainer {
        div[id=outerModalPopUp] {
            >div:first-child {
                position: relative !important;
                max-height: 525px;
            }
        }
        .watchlistsPopupContent{
            padding: 10px 20px;
        }
        .footerContainer {
            display: flex;
            justify-content: center;
            border-top: 1px solid #B2B2B2;
            padding: 20px 0;
            width: 100%;
            button {
                height: 34px;
            }
            button:first-child {
                padding-bottom: 2px;
            }
        }
    }
}
