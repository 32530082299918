@import "../../../../../styles/variables";

.tableContainer {
    .customNoData {
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        height: 150px;
        justify-content: center;
        header {
            font-family: $Roboto-Light;
            font-size: 24px;
            color: $color-black;
            text-align: center;
            line-height: 30px;
            margin-bottom: 10px;
        }
        content {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            text-align: center;
            line-height: 18px;
        }
    }
    table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid rgba(0,0,0,0.10);
    }
    td,
    th {
        font-weight: inherit !important;
        padding-left: 25px;
        min-width: 95px;
    }
    td {
        border-bottom: 1px solid $color-white-whisper;
    }

    .topInstitutionalHoldersTable {
        thead {
            tr {
                height: 56px;
                background-color: $color-white-opacity;
                th {
                    font-family: $Roboto-Medium;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    padding-top: 13px;
                    padding-bottom: 9px;
                    text-align: right;
                    background-color: $color-white-opacity;
                    min-width: 18%;
                    padding-right: 10px;
                    border-bottom: 1px solid $color-black;
                    vertical-align: text-top;
                }
                th:first-child {
                    padding-left: 12px;
                    text-align: left;
                    min-width: 28%;
                }
                th:last-child {
                    padding-right: 12px;
                }
            }
        }
        tbody {
            tr {
                background-color: $color-white;
                td {
                    padding-top: 14px;
                    padding-bottom: 14px;
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    text-align: right;
                    background-color: $color-white;
                    min-width: 18%;
                    padding-right: 10px;
                }
                td:first-child {
                    padding-left: 12px;
                    text-align: left;
                    min-width: 28%;
                }
                td:last-child {
                    padding-right: 12px;
                }
            }
        }
        .shortLine {
            border-left: 1px solid $color-light-gray;
        }
        .linkButton {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-blue;
            line-height: 18px;
        }
        button {
            border: none;
            background: none;
            text-align: left;
        }
        .redColor {
            color: $color-red-text;
        }
    }

    .eventStyles {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-white;
        line-height: 18px;
        padding: 2px 6px 2px 6px;
        border-radius: 2px;
        margin-left: 2px;
    }
}

.noVisiblity {
    visibility: hidden;
}
.textAlignCenter {
    text-align: center !important;
}
.emptyTable {
    min-width: 100% !important;
}
@media screen and (max-width: 1023px) {
    .tableContainer {
        .topInstitutionalHoldersTable {
            position: relative;
            width: 100%;
            margin: auto;
            overflow: auto;
            border-right: 1px solid $color-white-whisper;
            box-shadow: 3px 5px 10px $color-white-whisper;
            .fixedHeader {
                padding-left: 12px;
                text-align: left;
                position: sticky;
                left: 0;
            }
            thead {
                tr {
                    th {
                        padding-left: 25px;
                        padding-top: 13px;
                        padding-bottom: 13px;
                        text-align: right;
                        min-width: 135px;
                    }
                    th:first-child {
                        border-right: 1px solid $color-white-whisper;
                        box-shadow: 3px 5px 10px $color-white-whisper;
                        padding-left: 12px;
                        text-align: left;
                        position: sticky;
                        min-width: 182px !important;
                        padding-right: 33px;
                        left: 0;
                    }
                    th:last-child {
                        padding-right: 10px;
                    }
                    th:nth-of-type(2) {
                        padding-right: 12px;
                        padding-left: 13px;
                    }
                }
            }
            tbody {
                box-sizing: none;
                tr {
                    td {
                        padding-left: 25px;
                        height: 44px;
                        padding-top: 13px;
                        padding-bottom: 13px;
                        text-align: right;
                        min-width: 125px;
                    }
                    td:first-child {
                        border-right: 1px solid $color-white-whisper;
                        box-shadow: 3px 5px 10px $color-white-whisper;
                        white-space: initial;
                        min-width: 182px !important;
                        padding-right: 33px;
                        padding-left: 12px;
                        text-align: left;
                        position: sticky;
                        left: 0;
                    }
                    td:last-child {
                        padding-right: 10px;
                        text-align: right;
                    }
                    td:nth-of-type(2) {
                        padding-right: 12px;
                        padding-left: 13px;
                    }
                }
            }
            .shortLine {
                margin-left: -20px
            }
        }
    }
    *::before,
    *::after {
        box-sizing: none;
    }
}

@media screen and (max-width: 595px) {
    .tableContainer {
        .customNoData {
            display: flex;
            margin-top: 20px;
            flex-direction: column;
            align-items: center;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            height: 150px;
        }
        .topInstitutionalHoldersTable {
            position: relative;
            width: 100%;
            margin: auto;
            overflow: auto;
            border-right: 1px solid $color-white-whisper;
            box-shadow: 3px 5px 10px $color-white-whisper;
            .fixedHeader {
                padding-left: 10px;
                text-align: left;
                position: sticky;
                left: 0;
            }
            thead {
                tr {
                    th {
                        padding-right: 12px;
                        padding-top: 13px;
                        padding-bottom: 13px;
                        text-align: right;
                        min-width: 125px;
                    }
                    th:first-child {
                        border-right: 1px solid $color-white-whisper;
                        box-shadow: 3px 5px 10px $color-white-whisper;
                        padding-left: 12px;
                        padding-right: 7px;
                        text-align: left;
                        position: sticky;
                        min-width: 140px !important;
                        left: 0;
                    }
                    th:last-child {
                        padding-right: 12px;
                    }
                    th:nth-of-type(2) {
                        padding-left: 8px;
                        min-width: 125px;
                    }
                }
            }
            tbody {
                box-sizing: none;
                tr {
                    td {
                        padding-right: 12px;
                        height: 44px;
                        padding-top: 13px;
                        padding-bottom: 13px;
                        text-align: right;
                        min-width: 125px;
                    }
                    td:first-child {
                        border-right: 1px solid $color-white-whisper;
                        box-shadow: 3px 5px 10px $color-white-whisper;
                        white-space: initial;
                        min-width: 140px !important;
                        padding-left: 12px;
                        padding-right: 7px;
                        text-align: left;
                        position: sticky;
                        left: 0;
                    }
                    td:last-child {
                        padding-right: 12px;
                    }
                    td:nth-of-type(2) {
                        padding-left: 8px;
                        min-width: 105px;
                    }
                }
            }
        }
    }
    *::before,
    *::after {
        box-sizing: none;
    }
}
