.maxCompareContainer{
    background: #FFFFFF;
    box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.1), 1px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    position: relative;
    height: 145px;
    max-width: 444px;
    width: 444px;
    white-space: normal;
    z-index: 999999;
    top: -385px;
    margin: -10% auto 0;
        
    .upperdiv{
        width: 90%;
        display: flex;
        position: relative;
        top: 25px;
        left: 25px;
    .icon{
        color: #FFA000;
        
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 24px;
        height: 24px;
        width: 27px;
        display: inline-block;
        svg{
            margin-bottom: 10px !important;
        }
    }
    .maxCompareText{
       padding-left: 10px;
        width: 370px;
        font-family: "Roboto-Regular", Arial, sans-serif;	
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        display: inline-block;
        
    }
}
    .buttons{
        text-align: center;
        padding: 6px 15px;
        background-color: #0572d6;
        color: #ffffff;
        width: 71px;
        height: 34px;
        cursor: pointer;
        position: relative;
        border-radius: 3px;
        font-family: "Roboto-Regular", Arial, sans-serif;	
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        text-decoration: none;
        display: block !important;
        top: 50px;
        margin-left: 182px;
        border-style: none;
    }
}
@media (max-height: 595px), only screen and (max-device-height:595px) {
    .maxCompareContainer {
        top: -210px;
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) and (orientation : portrait) {
    .maxCompareContainer{
        top: -563px; 
    }
}

@media (max-width: 595px), only screen and (max-device-width:595px) {
    .maxCompareContainer{
        width: 355px;
        margin-top: -25%;
        height: 153px;
        top: -574px;
        .buttons{
            top: 45px;
            margin-left: 135px;
        }
    }
    
}

@media (max-width: 595px), only screen and (max-device-width:595px) and (orientation : landscape) {
    .maxCompareContainer {
        //top: -237px;
        margin-top: -15%;
    }
}
