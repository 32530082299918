@import "../../styles/generic";

.fundPortfolioContainer {

    .container {
        display: flex;
        flex-direction: row;

        .leftSectionContainer {
            display: flex;
            flex-direction: column;
            width: 66%;
            margin-right: 2%;
            margin-bottom: 25px 0px;

            &>div {
                margin-top: 30px;
                margin-bottom: 30px;
            }

        }

        .rightSectionContainer {
            display: flex;
            flex-direction: column;
            width: 32%;

            &>div {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }

    .regionalAllocation {
        margin-top: 30px;
    }
}

@media (min-width: 596px) and (max-width: 1023px),
only screen and (min-device-width: 596px) and (max-device-width:1023px) {
    .fundPortfolioContainer {

        .container {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }

            }

            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }
            }
        }
    }
}

@media (min-width: 375px) and (max-width: 595px),
only screen and (min-device-width: 375px) and (max-device-width:595px) {
    .fundPortfolioContainer {

        .container {
            display: flex;
            flex-direction: column;

            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;

                &>div+div {
                    margin-top: 30px;
                }

            }

            .rightSectionContainer {
                width: 100%;

                &>div+div {
                    margin-top: 30px;
                }
            }
        }
    }
}