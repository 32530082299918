@import '../../../styles/variables';

.imgButton{
    background: transparent;
    border-radius: 3px;
    width: 75px;
    height: 34px;
    font-family: $Roboto-Regular;
    font-size: 14px;    
    text-align: center;
    line-height: 18px;    
    border: 1px solid rgba(0,0,0,0.30);

    .label {
        font-size: 14px;
        line-height: 18px;  
        font-family: $Roboto-Regular;
        margin-left: 5px;
    }

    .icon {
        font-size: 14px;
        line-height: 14px;
    }

    &.selected {
        color: #0572D6;
        border: 1px solid #0572D6;

        .icon {
            color: inherit;
        }

        .label {
            color: inherit;
        }
    }
 }
