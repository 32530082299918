@import './variables';

body {
    margin: 0;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff; 
}

[data-f2-app-conatiner='MOD_F2_APP_CONTAINER']{
    [role="button"] {
        cursor: pointer;
    }

    *, *::before,*::after {
        -webkit-box-sizing: border-box;
                box-sizing: border-box; 
    }

    
    input[type="radio"], input[type="checkbox"] {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
    }

    button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled){
        cursor: pointer;
    }

    input, button, select, optgroup, textarea {
        margin: 0;
        font-family: $Roboto-Regular;
        font-size: default;
        line-height: default;
    }
    button, input {
        overflow: visible;
    }

    table {
        border-collapse: collapse;
    }
}