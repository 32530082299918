@import "../../../styles/variables";
.eventContainer{  
  font-family: $Roboto-Regular !important;
  width: 200px;
  max-height: 162px;
  background-color: $color-white;
  .basicDropDownValueContainer{
  width:100%;
  padding-top: 12px;
  list-style: none;
  border-radius: 3px;
  outline: none;
  margin: 0px;
  z-index: 1;
  line-height: 20px;
  &.open {
    border-radius: 0px;
    z-index: 10;
  }
  .ul{
    border-bottom: 1px solid #b2b2b2;
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
  }
  .buttons {
    width: 100%;
    text-align: right;
    padding-right: 8px;
    .disabledApply {
      background-color: $color-white;
      color: $color-netxwealth-news-research-grey-border;
      margin-right: 10px;
      width:71px;
      height:34px;
      cursor:not-allowed;
    }
    .disabledReset {
      background-color: $color-white;
      color: $color-netxwealth-news-research-grey-border;
      width:71px;
      height:34px;
      cursor:not-allowed;
    }
    .apply {
      background-color: #0572d6;
      color: #ffffff;
      margin-right: 10px;
      width:71px;
      height:34px;
      cursor:pointer;
      border: none;
    }

    .reset {
      background: #ffffff;
      color: #0572d6;
      width:71px;
      height:34px;
      margin:0px;
      cursor:pointer;
    }
  }
  .select-section {
    border: 1px solid #b2b2b2 !important;
  }
  .selected-text {
    top: 0;
    left: 0;
  }
  ul {
    padding: 0px;
    margin: 0px;
    li {
      border: none;
      padding: 2px 0 2px 10px;
      display: block;
      cursor: pointer;
      font-size: 14px;
      color: #000000;
      border-top: 0px solid #f7f7f7;
      background: #ffffff;
      z-index: 999;
      line-height: 18px;
      border-radius: 3px;
      .container {
        display: inline-block;
        position: relative;
        padding-left: 20px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked {
            ~ {
              .checkmark {
                border:0px solid;
                background-color: #48b748;
                &:after {
                  display: block;
                }
              }
            }
          }
        }
        &:hover {
          input {
            ~ {
              .checkmark {
                background-color: transparent;
              }
            }
          }
        }
        .checkmark {
          &:after {
            left: 5px;
            top: 0px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .checkmark {
        position: absolute;
        top: -5px;
        left: 5px;
        height: 16px;
        width: 16px;
        background-color: #ffffff;
        border: 1px solid black;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }
      .option-text {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 89%;
      }
      .lock-icon {
        width: 16px;
        height: 16px;
        float: right;
        right: 6px;
        bottom: -3px;
        position: relative;
      }
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        top: 5px;
        left: 10px;
        box-sizing: border-box;
        position: relative;
        line-height: 18px;
        display: inline-block;
      }
      &.hide {
        display: none;
      }
      &.selected {
        background: #e3edf5;
      }
      li:first-child {
        display: block;
        color: #2472b2;
        border-top: none;
        border-radius: 3px;
        &:hover {
          background: #ffffff !important;
        }
      }
      .icon {
        margin-right:8px;
      }
      .display-text {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85% !important;
        .name {
          left: 0;
          top: 0;
        }
      }
    }
  }
  .main-section {
    box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1),
      1px 2px 6px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: #ffffff;
  }
  .button-container {
    background-color: #ffffff;
    padding: 0px 0px 10px 0px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    .action-item {
      margin-right: 10px;
      border-radius: 3px;
    }
    .btn-primary {
      background: #0572d6;
      color: #ffffff;
    }
    .btn-reset {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
  .hide {
    display: none;
  }
}
}