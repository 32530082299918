@import "../../../../../../styles/variables";
.header{
    height: 26px;
    width: 146px;
    font-family: $Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-top: 15px;
    margin-left: 15px;
}
.tableContainer{
    td, th{
        background-color: #f7f7f7 !important; 
    }
    div{
        border: 0px;
    }
    .tablediv{
        overflow-x: hidden !important;
        overflow-y: hidden !important;
        margin-left: 10px;
        margin-right: 10px;
        padding: 0px 0px 0px 0px !important;
        margin-top: 15px;
        margin-bottom: 15px;
        tr{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
        }
    }
    .chartDiv{
        margin-left: 30px !important;
        margin-right: 30px !important;
        background-color: #f7f7f7 !important;  
        td[role='gridcell']:first-child  {
            border-right: 1px solid #B2B2B2;
            border-left: 0px;
        }
        th:first-child{
            width: 20%;
            text-align: start !important;
            border-left: 0px;
        }
        th{
            width: 30px;
            height: 16px;
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            text-align: right;
            padding: 0px !important;
            span>div{
                padding-right: 0px !important;
            }
        }
        tr{
            height: 25px !important;
        }
    }

    table{
        width: 100%;
        background-color: #f7f7f7 !important
        }
    th{
        white-space: nowrap;
        height: 16px;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
    }
  
    tr{
        border-collapse: separate;
        text-align: right;
    }
   
   
    tr td:first-child, th:first-child{
       width: 15px;
    }
     th:nth-child(2){
        width: 33%;
        text-align: left;
     }
    tr td:nth-child(2){
        width: 33%;
        text-align: left;
        height: 18px !important;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px !important;
        line-height: 18px !important;
        color: #666666;
     }
     tr td:nth-child(3),td:nth-child(4),td:nth-child(5){
        height: 18px !important;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        text-align: right;
        color: #000000;
     }
     td {
        border-collapse: separate !important;
        border-top: 0px !important;
      }
    td[role='gridcell']:not(:nth-last-of-type(-n+2)) div {
        padding-right: 11px;
    }

    td[role='gridcell']:last-child,td[role='gridcell']:nth-last-child(2)  {
        padding: 0px !important;
        div{
            min-width: 0px !important;
        }
        .leftSection {
            width: 100%;
            height: 15px;
            position:relative
        }
        .rightSection {
            width: 100%;
            height: 15px;
            position:relative
        }
        .short {
            height: 15px;
            position: absolute;
            right: 0;
            border: 1px solid ;
            box-sizing: border-box;
        }
        .long {
            height: 15px;
            position: absolute;
            left: 0;
        }
        .measurement {
            width: 100%;
            display: flex;
            height: 24px;
            position:relative
        }
        .netLong{
            height: 15px;
            background: #004480;
            z-index: 9;
            position: absolute;
            left: 0;
        }
        .netShort{
            height: 15px;
            background: #004480;
            z-index: 9;
            position: absolute;
            right: 0;
        }
    }
    .linkButton {
        margin-bottom: 15px;
        margin-left: 5px;
    }
}
.button{
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    cursor: pointer;
    padding:0;
    border:none;
    background: none;
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
}
    @media (min-width: 595px) and (max-width: 1023px) {
        .chartDiv{margin-left: 10px;
        margin-right: 10px;
        border: 0px !important;
        padding: 0px 0px 0px 0px !important;
        background-color: rebeccapurple;
        }
        .tableContainer{
            margin-left: 10px;
            margin-right: 10px;
            overflow-x: hidden !important;
        overflow-y: hidden !important;
        tr{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
        }
        }
    }
    @media (min-width: 736px) and (max-width: 1023px) {
        .tableContainer{
        td[role='gridcell']:not(:nth-last-of-type(-n+2)) div:first-child {
            width: 126px !important;
        }
    }
    }
    