@import "../../../../styles/variables";

.tableContainer {

    .row {
        display: flex;
        align-items: center;
        .circle {
            width: 10px;
            height: 10px;
            border-radius: 25%;
            margin-right: 5px;
            align-self: center;
        }
    }

    .divider {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .icon {
        width: 10px;
        height: 10px;
    }

}
