@import "../../../styles/variables";
.pagination {
  float: right;

  .pageCounter,
  .itemCounter {
    display: inline-block;
    padding: 0px 5px;
  }

  .paginationNav {
    display: inline-block;
    padding: 0px 5px;
    cursor: pointer;
    border: 0px;
    margin-left: 9px;

    button {
      background: none;
    }
  }
  .paginationNavNext {
    display: inline-block;
    padding: 0px 5px;
    cursor: pointer;
    border: 0px;
    margin-left: 2px;

    button {
      background: none;
    }
  }

  .pageInfoText {
    display: inline-flex;
    justify-content: center;
    text-align: center;
    font-family: $Roboto-Regular;
    font-size: 12px;
    color: #000000;
    line-height: 16px;
  }
  select {
    background-color: white;
    border: 1px solid rgba(0,0,0,0.26);
    border-radius: 3px;
    width: 70px;
    height: 28px;
    padding: 0px 0px 0px 10px;   
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  select.paginationSelect {
    border: 1px solid rgba(0,0,0,0.26);
    background-image:
      linear-gradient(45deg, transparent 50%, black 50%),
      linear-gradient(135deg, black 50%, transparent 50%),
      linear-gradient(to right, white, white);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    background-position-y: 10px;
  }
  select.paginationSelectOpen {
    background-image:
      linear-gradient(45deg, black 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, black 50%),
      linear-gradient(to right, white, white);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    background-position-y: 10px;
  }

  .pagePreviousIcon {
    width: 100%;
    font-size: 18px;
    text-align: center;
    line-height: 18px;
  }

  .paginationButton {
    border: none;
    background: none;
    width: 18px;
  }
}
