.rangeSelectorContainer {
    .button{
        background-color: white;
        width: 122px;
        height: 34px;
        top: 48px;
        border: 1px solid #0572D6;
        box-sizing: border-box;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        font-size: 14px !important;
        line-height: 18px !important;
    }
    :first-child{
        border-radius: 3px 0px 0px 3px;
    }
    :last-child{
        border-radius: 0px 3px 3px 0px;
    }
    .buttonClicked {
        background-color: #0572D6;
        color: white;
        text-align: center;
    }
    .icon{
        color: white;
        display: none;
    }
    button.buttonClicked > svg {
        width: 14px !important;
        height: 14px !important;
    } 
    .iconOnClick{
        display: inline !important;
        color: #FFFFFF !important;
        margin-left: 10px !important;
    }
}