@import "../../../../styles/variables";
.marketHighlightsNewsContainerParent {
    margin-top: 15px;
    .marketHighlightsNewsContainer {
        display: flex;
        & > div + div {
            margin-left: 24px;
        }
        .ipadSpecificFill {
            display: none;
        }
        .marketHighlightsNewsItem {
            background: $color-white;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
            border-radius: 3px;
            font-family: $Roboto-Regular;
            padding: 15px;
            width: 33%;

            .marketHighlightsNewsProvider {
                font-size: 12px;
                color: $color-gray;
                line-height: 16px;
                .highlightExtra{
                    font-family: $Roboto-Medium;
                    font-size: 14px;
                }
            }
            .marketHighlightsNewsTitle {
                font-family: $Roboto-Medium;
                font-size: 14px;
                color: $color-black;
                line-height: 18px;
                margin: 10px 0px 5px 0px;
                font-weight: 600;
            }
            .marketHighlightsNewsBody {
                font-size: 14px;
                color: $color-gray;
                line-height: 18px;
            }
            .viewPDFButton {
                font-size: 14px;
                color: $color-blue;
                line-height: 18px;
                font-family: $Roboto-Medium;
                background: none;
                border: none;
                cursor: pointer;
                margin: 0;
                padding: 0;
                font-weight: 500;
            }
            .loaderContainer {
                display: flex;
            }
        }
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .marketHighlightsNewsContainerParent {
        background: $color-white;
        max-width: 983px;
        overflow-x: auto;
        margin-top: 15px;
        padding: 0px;
        /* Shadows */
        background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
            /* Shadow covers */ linear-gradient(to right, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0)),
            linear-gradient(to left, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0));
        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;

        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        .marketHighlightsNewsContainer {
            min-width: 983px;
            padding: 0;
            & > div + div {
                margin-left: 0px;
            }
    
            .ipadSpecificFill {
                display: block;
                min-width: 24px;
                background: #f7f7f7;
                &:last-child {
                    display: none;
                }
            }
            .marketHighlightsNewsItem {
                background: none;
            }
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .marketHighlightsNewsContainerParent::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 595px) {
    .marketHighlightsNewsContainerParent {
        margin-top: 10px;
        .marketHighlightsNewsContainer {
            #marketHighlightsNewsCarousel {
                display: inline-block;
                box-shadow: none;
                text-align: unset;
                span[data-value="customDivider"] {
                    padding-left: 8px;
                    margin-right: -4px;
                    position: RELATIVE;
                    top: -1PX;
                    color: #c7C7C7;
                    display: inline-block;
                    font-size: 18px;
                }
                & > div:first-child {
                    position: relative;
                }
                & > div:first-child > span {
                    position: absolute;
                    right: 0px;
                    top: 10px;
                    z-index: 1;
                }
            }
            .marketHighlightsNewsItem {
                padding: 16px 17px 20px 13px;
                width: 100%;
            }
        }
    }
}
.marketHighlightsNewsLoader {
    width: 100%;
    height: 100px;
    display: block!important;
    background: $color-white;
}

@media print {
    button {
        display: block !important;
      }
  }