
svg[data-icon="check-square"] {
	&[data-icon-style="check-square-green"] {
		color: green;
	}	
}
svg[data-icon="calendar-alt"] {
    &[data-icon-style="calendar-alt-black"]{
		font-size: 14px;
		color: #000000;
	}
}
svg[data-icon="file-alt"] {
    &[data-icon-style="file-alt-blue"]{		
		color: #0572D6;
	}
}
svg[data-icon="eye"] {
	&[data-icon-style="fa-blue-eye"] {
		color: #0572D6;
		width: 16px;
		height: 14px;
		cursor: pointer;
	}	
}
svg[data-icon="sync-alt"] {
	&[data-icon-style="fa-blue-refresh"] {
		color: #0572D6;
		cursor: pointer;
	}	
}

svg[data-icon="spinner-third"] {
	path[class="fa-secondary"] {
		color: #c8c8c8 !important;
		opacity: 1 !important;
	}	
}

svg[data-icon="caret-down"] {
	&[data-icon-style="fa-select-caret-down"] {
		width: 12px;
		height: 13px;
	}	
}
svg[data-icon="search"] {
	&[data-icon-style="fa-compare-search"] {
		width: 19px;
		height: 24px;
	}
}	

svg[data-icon="info-circle"] {
	&[data-icon-style="info-circle-blue"]{		
		color: #0572D6;
	}		
}
svg[data-icon="ellipsis-v"] {
	&[data-icon-style="ellipsis-v"]{		
		color: #000000;
	}
}
