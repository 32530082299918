@import "../../../../../../styles/variables";
.quoteAndResearch {
    background: $color-white;
    padding: 0 15px;
    .stockValuationContainer {
        display: flex;
        flex-direction: row;
        margin-top: 14px;
        .leftSectionContainer {
            display: flex;
            flex-direction: column;
            width: 68%;
            margin-right: 2%;
            margin-bottom: 25px 0px;
            & > div + div {
              margin-top: 25px;  
            }
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_valuation"] {
            width: 30%;
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_mind_events"] {
            position: sticky;
        }
        .rightSectionContainer{
            display: flex;
            flex-direction: column;
            width: 30%;
            & > div + div {
                margin-top: 25px;
            }
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_leverageratios"] {
            margin-top: 55px;
        }
        div[data-ipc-container="com_pershing_netxwealth_long_term_growth_rates"] {
            margin-bottom:10px;
        }
    }
    .newsResearchContainer {
        display: flex;
        flex-direction: row;
        .leftSectionContainer {
            display: flex;
            flex-direction: column;
            width: 68%;
            margin-right: 2%;
            margin-bottom: 25px 0px;
            & > div {
              margin-top: 25px;  
            }
        }
        .rightSectionContainer{
            display: flex;
            flex-direction: column;
            width: 30%;
            & > div {
                margin-top: 25px;
            }
        }
    }

    .earningsAndEstimatesContainer {
        display: flex;
        flex-direction: row;
        .barContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px;
        }
    }
    
    .fundamentalsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .buttonFundamental{
        background: #FFFFFF;
        border-radius: 0 3px 3px 0;
        border: 1px solid #0572D6;
        width: 110px;
        color: black;
        font-family: "Roboto-Regular", Arial, sans-serif;
        font-size: 12px;
        line-height: 16px;
        padding: 6px 0px;
    }
    .floatRight {
        float: right;
        padding-bottom: 20px;
    }
    .fundamentalsDD {
        padding-bottom: 60px !important;
    }
    .showFundamentalDD {
        display: none;
    }
    .performanceApp {
        display: flex;
        flex-direction: row;
        div[data-ipc-container="com_pershing_netxwealth_stock_performance"] {
            width: 68%;
            float: left;
            margin-right: 2%;
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_highlights"] {
            width: 30%;
            float: left;
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_company_description"] {
            width: 30%;
            float: left;
        }
    }
    .earningsAndEstimateApp {
        display: flex;
        flex-direction: row;
        div[data-ipc-container="com_pershing_netxwealth_stock_earnings_and_estimates"] {
            width: 68%;
            float: left;
            margin-right: 2%;
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_highlights"] {
            width: 30%;
            float: left;
        }
    }
    div[data-ipc-container="com_pershing_netxwealth_key_historical_prices"] {
       margin: 0 -15px;
    }
    .dividendPayoutContainer {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        padding-top: 20px;

        .leftSectionContainer {
            width: 68%;
            margin-right: 2%;
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_dividend_payout"] {
            width: 30%;
        }
        .rightSectionContainer {
            margin-right: 0%;
        }
    }
    .peerAnnualYieldsContainer{
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        padding-top: 20px;
        .leftSectionContainer {
            width: 68%;
            margin-right: 2%;
        }
        .rightSectionContainer {
            width: 30%;
            display: flex;
            flex-direction: column;
            div[data-ipc-container="com_pershing_netxwealth_peer_annual_yields"] {
               margin-top: 30px;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_dividend_payout"] {
                margin-bottom: 30px;
             }
        }
   
    }
}
.header {
    font-family: $Roboto-Light;
    font-size: 28px;
    color: $color-black;
    line-height: 34px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding: 10px 0;
    display: block;

    h3 {
        display: inline;
    }
}

.symbolSearchContainer {
    display: flex;
    position: relative;
    float: right;
    border: none;
}

@media screen and (max-width: 375px) {
    .symbolSearchContainer {
        float: none;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

.appGap {
    height: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

@media (min-width: 596px) and (max-width: 1023px) {
    .quoteAndResearch {
        .fundamentalsContainer {
            display: inline-block;
            width: 100%;
        }
        .hide {
            display: none;
        }
        .showFundamentalDD {
            display: block;
        }
        .stockValuationContainer {
            display: flex;
            flex-direction: column;
            padding: 0px 0px 15px 0px;
            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;
                & > div + div {
                    margin-top: 30px;  
                }     
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_valuation"] {
                width: 100%;
            }
            .rightSectionContainer{
                width: 100%;
                &>div + div {
                    margin-top: 30px;
                }
            }
        }
        .newsResearchContainer {
            display: flex;
            flex-direction: row;
            .leftSectionContainer {
                display: flex;
                flex-direction: column;
                width: 68%;
                margin-right: 2%;
                margin-bottom: 25px 0px;
                & > div {
                  margin-top: 25px;  
                }
            }
            .rightSectionContainer{
                display: flex;
                flex-direction: column;
                width: 30%;
                & > div {
                    margin-top: 25px;
                }
            }
        }
        .performanceApp {
            flex-direction: column;
            div[data-ipc-container="com_pershing_netxwealth_stock_performance"] {
                width: 100%;
                margin-right: 0;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_highlights"] {
                width: 100%;
                margin-top: 20px;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_company_description"] {
                width: 100%;
                margin-top: 20px;
            }
        }
        .earningsAndEstimateApp {
            flex-direction: column;
            div[data-ipc-container="com_pershing_netxwealth_stock_earnings_and_estimates"] {
                margin-top: 20px;
                width: 100%;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_highlights"] {
                margin-top: 20px;
                width: 100%;
            }
        }
        .dividendPayoutContainer {
            display: flex;
            flex-direction: column;
            .leftSectionContainer {
                width: 100%;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_dividend_payout"] {
                width: 100%;
            }
        }
        .peerAnnualYieldsContainer{
            flex-direction: column;
            .leftSectionContainer {
                width: 100%;
                
            }
            .rightSectionContainer {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 20px;
                justify-content: space-between;
                div[data-ipc-container="com_pershing_netxwealth_stock_dividend_payout"] {
                    width: 100%;
                }
                .bottomSectionContainer {
                display: flex;
                flex-direction: row;
                div[data-ipc-container="com_pershing_netxwealth_yearly_payment_trend"] {
                   margin-top: 0px;
                   width: 50%;
                }
                div[data-ipc-container="com_pershing_netxwealth_peer_annual_yields"] {
                    margin-top: 0px;
                    margin-left: 10px;
                    width: 49%;
                 }
                }
            }
    }
}
}
@media (min-width: 0px) and (max-width: 596px) {
    .quoteAndResearch {
        padding:0 11px;
        .hide {
            display: none;
        }
        .showFundamentalDD {
            display: block;
        }
        .stockValuationContainer {
            display: flex;
            flex-direction: column;
            padding: 15px 0px;
            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;
                & > div + div {
                    margin-top: 30px;  
                }     
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_valuation"] {
                width: 100%;
            }
            .rightSectionContainer{
                width: 100%;
                &>div + div {
                    margin-top: 30px;
                }
            }
        }
        .newsResearchContainer {
            display: flex;
            flex-direction: column;
            padding: 15px 0px;
            .leftSectionContainer {
                width: 100%;
                margin-bottom: 30px 0px;
                & > div + div {
                    margin-top: 30px;  
                }     
            }
            .rightSectionContainer{
                width: 100%;
                &>div + div {
                    margin-top: 30px;
                }
            }
        }
        .performanceApp {
            flex-direction: column;
            div[data-ipc-container="com_pershing_netxwealth_stock_performance"] {
                width: 100%;
                margin-right: 0;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_highlights"] {
                width: 100%;
                margin-top: 20px;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_company_description"] {
                width: 100%;
                margin-top: 20px;
            }
        }
        .earningsAndEstimateApp {
            flex-direction: column;
            div[data-ipc-container="com_pershing_netxwealth_stock_earnings_and_estimates"] {
                margin-top: 20px;
                width: 100%;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_highlights"] {
                margin-top: 20px;
                width: 100%;
            }
        }
        div[data-ipc-container="com_pershing_netxwealth_key_historical_prices"] {
            margin: 0 -5px;
         }
         .dividendPayoutContainer {
            display: flex;
            flex-direction: column;
            .leftSectionContainer {
                width: 100%;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_dividend_payout"] {
                width: 100%;
            }
        }
        .peerAnnualYieldsContainer{
            flex-direction: column;
            .leftSectionContainer {
                width: 100%;

            }
            .rightSectionContainer {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 30px;
                div[data-ipc-container="com_pershing_netxwealth_stock_dividend_payout"] {
                    width: 100%;
                    margin-bottom: 0;
                }
                div[data-ipc-container="com_pershing_netxwealth_yearly_payment_trend"] {
                   margin-top: 30px;
                   width: 100%;
                }
                div[data-ipc-container="com_pershing_netxwealth_peer_annual_yields"] {
                    margin-top: 30px;
                    width: 100%;
                 }
            }
    }
        .yearlyPaymentTrendContainer {
            display: flex;
            flex-direction: column;

        }
    }
}

@media (max-width: 596px) {
    .header {
        display: block;
        h3 {
            display: inline;
        }
    }
}