@import '../../../styles/variables';

.top {
  .riskScoreBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: auto;
    background-color: $color-white;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 15px 20px 15px 20px;

    .riskScoreBoxItems {
      button {
        color: $color-gray;
        background-color: $color-white;
        outline: none;
        border: none;
        cursor: pointer;
        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 16px;
        border-bottom: 1px dashed $color-black;
        padding: 0px;
      }
    }

    .subheading {
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: $color-gray;
      line-height: 16px;
      display: inline;
      cursor: pointer;
      padding-bottom: 4px;
    }

    .popOverBody {
      max-width: 302px;
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: $color-black;
      line-height: 16px;
      padding: 0 5px 5px 5px;

      span {
        white-space: normal;
      }
    }

    .icon {
      width: 109px;
      height: 26px;
      margin-right: 3px;
      font-size: 20px;
      line-height: 26px;
      margin-top: 3px;
      letter-spacing: 3px;
    }

    .subText {
      line-height: 26px;
      margin-top: 3px;
      font-size: 20px;
      font-family: $Roboto-Regular;
      color: $color-black;
      padding-right: 4px;
    }

    .subText2 {
      line-height: 18px;
      margin-top: 3px;
      font-size: 14px;
      color: $color-gray;
    }
  }
}

.NXIheader {
  .riskScoreBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: auto;
    background-color: $color-white;
    border-radius: 3px;
    padding: 5px 20px 15px 0px;

    .riskScoreBoxItems {
      button {
        color: $color-gray;
        background-color: $color-white;
        outline: none;
        border: none;
        cursor: pointer;
        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 16px;
        border-bottom: 1px dashed $color-black;
        padding: 0px;
      }
    }

    .subheading {
      font-family: $Roboto-Regular;
      font-size: 14px;
      color: $color-light-gray-7;
      line-height: 16px;
      display: inline;
      cursor: pointer;
      padding-bottom: 4px;
    }

    .popOverBody {
      max-width: 302px;
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: $color-black;
      line-height: 16px;
      padding: 0 5px 5px 5px;

      span {
        white-space: normal;
      }
    }

    .icon {
      width: 109px;
      height: 26px;
      margin-right: 3px;
      font-size: 20px;
      line-height: 26px;
      margin-top: 3px;
      letter-spacing: 3px;
    }

    .subText {
      line-height: 26px;
      margin-top: 3px;
      font-size: 20px;
      font-family: $Roboto-Regular;
      color: $color-black;
      padding-right: 4px;
    }

    .subText2 {
      line-height: 18px;
      margin-top: 3px;
      font-size: 14px;
      color: $color-gray;
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
  .top {
    .riskScoreBox {
      padding: 15px;
      & > div:nth-child(3) {
        margin-top: 20px;
      }
    }
  }
  .NXIheader {
    .riskScoreBox {
      padding: 15px 0px;

      &>div:nth-child(4) {
        margin-top: 20px;
      }
    }
  }
}





