@import '../../../styles/variables';

.link {
    font-family: $Roboto-Medium;
    font-size: 14px;
    color: $color-blue;
    line-height: 18px;
    border: none;
    background: transparent;
    cursor: pointer;
	outline: none;
}