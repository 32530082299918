@import '../../../styles/variables';

.watchlistsLandingContainer {
    .headerContainer{
        margin: 0px 20px;
        padding: 11px 0px; 
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0e6e6;
        .label {
            font-family: $Roboto-Light;
            font-size: 28px;
            color: $color-black;
            line-height: 34px;
        }
        .createWatchlistsButton {
            cursor: pointer;
            font-family: $Roboto-Medium;
            font-size: 14px;
            color: $color-blue;
            text-align: right;
            line-height: 18px;
            border: none;
            background: none;

        }
    }
    .sectionContainer {
        // margin-top: 10px;
    }
    .sectionContainerTopStocks{
        margin: 40px;
    }
    .sectionContainerWatchlistStocks{
        margin: 20px;
    }
}
@media screen and (min-width: 596px) and (max-width: 1023px) {
    .watchlistsLandingContainer {
        .headerContainer{
            margin: 0px 15px;  
        }
    }
}
@media screen and (min-width: 0px) and (max-width: 595px) {
    .watchlistsLandingContainer {
        .headerContainer{
            margin: 0px 10px;  
        }
    }
}