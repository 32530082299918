@import "../../../styles/variables";

.technicalIndicator {    
    padding: 20px 15px;
    width: 100%;
    top:0;

    .header {
        position: relative;
        height: 26px;
        font-family: $Roboto-Medium;        
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
        color: #000000;
    }
    .technicalIndicatorDropdown {
        display: flex;
        > div > span {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }
        > div {
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            margin-top: auto;
        }
        > div > ul > li:first-child {
            border-bottom: 0;
            span:last-child{
                padding-top: 2px;
            }
        }
    }
    .technicalIndicatorButtons{
        > div {
            display: flex;
            > button {
                flex: 1;
                width: auto;
            }
        }
    }
}
@media screen and (min-width: 375px) and (max-width: 1023px), only screen and (max-device-width:1023px) {
    .technicalIndicatorDropdown > div > ul > li:first-child {
            border-bottom: 0;
            span:last-child{
                padding-top: 2px;
            }
        }
  }