@import "../../../styles/variables";

.newsContainer {
    .teaser {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: #000000;
        line-height: 18px;
        width: 100%;
        cursor: default;
        a {
          font-family: $Roboto-Regular;
          font-size: 14px;
          color: #0572d6;
          line-height: 18px;
        }
    }
    .readArticle {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-blue;
        line-height: 18px;
        word-wrap: break-word;
        cursor: pointer;
        background: none !important;
        border: none;
        padding: 0 !important;
      }

    .topCategory{
      margin-bottom: 14px;
    }

    .topCategoryLabel{
      font-family: $Roboto-Medium;
      font-weight: 500;
      font-size: 10px;
      color: #FFFFFF;
      height: 22px;
      background-color: #008575;
      border-radius: 3px;
      padding: 5px 3px 5px 3px
    }
}