.watchlistTableContainer {
    > div {
        margin: 20px;
        height: 375px;
    }
    table tbody {
        display: block;
        max-height: 330px;
        overflow-y: scroll;
    }
    table tbody::-webkit-scrollbar {
        width: 5px;
    }
     
    table tbody::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
     
    table tbody::-webkit-scrollbar-thumb {
        background: #B2B2B2;
    }
    table thead, table tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        
    }
    table > thead > tr > th:first-child { 
        div {
            padding-left: 30px;
        }
    }
    .alreadyExistsError {
        font-size: 12px;
        color: red;
        margin-left: 10px;
    }

}