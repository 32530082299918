@import "../../../styles/variables";

.lastelement:last-child .blankBorder{
    border: 1px dashed rgba(0, 0, 0, 0.26);
    border-radius: 3px;
    float: right;
    height: 233px;
    width: 3%;
    border-right:none;
    margin-right: 8px;
    margin-left: 25px;
}


.lastelement:last-child{
    display: flex;
}
.maxcompare{
    position: absolute;
    top: 70%;
    left: -105%;
}
.comparisonContainer{
    border: 1px dashed rgba(0, 0, 0, 0.26);
    box-sizing: border-box;
    border-radius: 3px;
    font-family: $Roboto-Regular;
    font-style: normal;
    font-weight: 400;
    float: left;
    width: 220px;
    height: 233px;
    .itemSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 233px;
    }    
    .compareImg{
        font-size: 16px;
        line-height: 14px;
        height: 14px;
        width: 16px;
        margin-bottom: 15px;
        border: none;
        background: none;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .compareData{
        font-size: 14px;
        line-height: 18px;
        white-space: normal;
        width: 109px;
        text-align: center;
    }
    @media (min-width: 595px) and (max-width: 1023px) {
        .itemSection{ 
            height: 233px;
        }
       
    }
    @media (min-width: 375px) and (max-width: 594px) {
        .itemSection{ 
            height: 233px;
        }  
    }
}
@media (min-width: 375px) and (max-width: 594px) {
    .maxcompare{
        left: -68%;
    }
}
