@import '../../../../../styles/variables';

.confirmationMsg{
    padding: 20px 20px 53px 20px;
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: $color-black;
    line-height: 18px;
    white-space: break-spaces;
}

.confirmationBtnContainer{
    display: flex;
    justify-content: center;
    padding: 15px 0px;
    button{
        width: 96px;
    }
    button:last-child{
        margin-left: 10px;
    }
}

div[id="DeleteConfirmation"],
div[id="UpdateScreenerConfirmation"]{
    div[id="outerModalPopUp"] > div{
        width: 480px;
        height: auto;
    }
    
    div[id="outerModalPopUp"] {
        div[id="modalPopUpContentContainer"] > div:first-child{
            padding: 20px;
        }
        button{
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 595px) {
    .confirmationMsg{
        width: 85%;
        padding: 20px 20px 0px 20px;
    }

    .confirmationFooter{
        position: fixed;
        bottom: 0px;
        width: 100%;
    }

    div[id="DeleteConfirmation"],
    div[id="UpdateScreenerConfirmation"]{
        div[id="outerModalPopUp"] > div{
            width: auto;
        }
        div[id="modalPopUpContentContainer"]{
            width: auto !important;
            > div:nth-child(2){
                position: static;
            }
        }
    }
}

