@import "../../../styles/variables";
.indicatorConfiguration {
    min-width: 330px;
    max-width: 336px;
    background: #FFFFFF;
    box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
    border-radius: 3px;
    
    .scroll2 {
        overflow-y:auto; 
        max-height: 470px;
    }
    button {
        margin-right: 0px;
    }
    .sections2 {
        display: flex;
        flex-direction: column;
        
        .topSection2 {
            background: $color-dark-gray;
            border-radius: 3px 3px 0 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            .topSectionHeader2{
                padding-left: 20px !important;
                font-family: $Roboto-Regular;
                font-size: 20px;
                color: $color-white;
                line-height: 26px;
                text-align: center !important;

            }
            .crossButton{
                background-color: transparent !important;
                margin-bottom: -5px;
            }
            .crossButton2 {
                width: 20px;
                height: 20px;
                background: transparent;
                font-size: 18px;
                color: $color-white;
                text-align: center;
                line-height: 18px;
            }
        }
 
        .header {
            font-family: $Roboto-Bold;
            font-size: 16px;
            color: $color-black;
            line-height: 22px;
            //padding-bottom: 15px;
            padding-top: 15px;
            padding-left: 20px;
        }
        .items {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 15px;
            font-family: $Roboto-Medium;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            input {
                width: 193px !important;
                height: 31px !important;
                border: 1px solid #B2B2B2;
                padding-left: 10px;
                border-radius: 3px;
            } 
            input:focus{
                outline: 2px solid $color-graph-light-blue1;
            }           
            .leftItem {
                padding-top: 5px;
                font-family: $Roboto-Medium;
                font-size: 14px;
                color: $color-black;
                line-height: 18px;
                word-break: break-word;
            }
            .rightItem > button {
                width: 193px !important;
                height: 34px !important;
                border: 1px solid #B2B2B2;
                background: #FFFFFF;
                border-radius: 3px;
                margin-right: 0px;
            }
        }
        .items:last-child{
            padding-bottom: 20px;
        }
    .bottomLine {
        padding-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: 1px solid rgba(0,0,0,0.14)
    }
    .fullBottomLine {
        margin-left: 0px;
        margin-right: 0px;
    }
    .lineAboveSave {
        border-bottom: 1px solid rgba(0,0,0,0.14);
        background-image: linear-gradient(180deg, rgba(238,238,238,0.00) 0%, #BCB8B8 100%);
    }

    button {
        background: transparent;
        border: transparent;        
    }
    button:hover {
        cursor: pointer;
    }

    .button-container {
        // background-color: $color-white;
        // width: 100%;
        height: 45px;
        text-align: center;
        padding: 5px 0px 5px 0px !important;
        border-radius: 3px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        
    }
    .buttons {
        width: 100%;
        text-align: center;
        padding-right: 8px;
        height: auto;
        .apply {
            margin-top: 5px;
            background-color: $color-blue;
            color: $color-white;
            margin-right: 10px;
        }
        .reset {
            margin-right: 12px;
            margin-top: 5px;
            color: $color-blue;
            margin-bottom: 5px;
            background: $color-white;
            border: 1px solid rgba(0,0,0,0.30);
            border-radius: 3px;
        }
    }
    }
}

@media(max-width:596px)  {
    .indicatorConfigurationMobile {
    max-width: 100%;
    height: auto;
    // width: 375px;
    // height: 619px;
    background: #FFFFFF;
    border-radius: 3px;
    .CancelPopup {
        min-height: auto;
        max-width: auto !important;
        top:0px !important;
        padding:0 !important;
        margin:0 !important;
        overflow-y: visible !important;
        overflow: inherit !important;
    }
    div[id=outerModalPopUp] {
        min-height: auto !important;
        top: 0px;
        // border: 5px solid green;
    }
    div[id=outerModalPopUp] > div {
        width: auto;
        min-height: auto !important;
        height: auto !important;
        padding: 0px !important;
        word-break: break-word !important;
        // border: 5px solid orange;
    }
    div[id=modalPopUpContentContainer] {
        min-height: auto;
        max-width: 375px !important;
        top:0px !important;
        padding:0 !important;
        margin:0 !important;
        overflow-y: visible !important;
        overflow: inherit !important;
        // border: 5px solid pink;
    }
    .bottomAlign {
        top: auto !important;
        position: fixed;
        width: 100%;
        bottom: 0 !important;
    }

    button {
        margin-right: 0px;
    }
    .sections2 {
        display: flex;
        flex-direction: column;
        .topSection2 {
            border-radius: 3px 3px 0 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            color: $color-black;
            border-bottom: 1px solid rgba(0,0,0,0.30);
            .topSectionHeader2{
                padding-left: 10px !important;
                font-family: $Roboto-Regular;
                font-size: 20px;
                line-height: 26px;
                text-align: center !important;
            }
            .crossButton2 {
                width: 20px;
                height: 20px;
                background: transparent;
                font-size: 18px;
                text-align: center;
                line-height: 18px;
                margin-right: 10px;
                color: $color-black !important;
            }
            button {
                padding-right: 0 !important;
            }
        }
        .header {
            font-family: $Roboto-Bold;
            font-size: 16px;
            color: $color-black;
            line-height: 22px;
            padding-top: 15px;
            padding-left: 10px;
        }
        .items {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 15px;
            font-family: $Roboto-Medium;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            input {
                width: 255px !important;
                height: 31px !important;
                border: 1px solid #B2B2B2;
                padding-left: 10px;
                border-radius: 3px;
            }
            input:focus{
                outline: 2px solid $color-graph-light-blue1;
            }
            .leftItem {
                padding-top: 5px;
                font-family: $Roboto-Medium;
                font-size: 14px;
                color: $color-black;
                line-height: 18px;
                word-break: break-word;
            }
            // .rightItem > div > button {
            .rightItem > button {
                width: 255px !important;
                height: 34px !important;
                border: 1px solid #B2B2B2;
                background: #FFFFFF;
                border-radius: 3px;
            }
        }
        .items:last-child{
            padding-bottom: 20px;
        }
    .bottomLine {
        padding-top:20px;
        margin-left: 10px;
        margin-right: 10px;
        border-bottom: 1px solid rgba(0,0,0,0.14)
    }
    .fullBottomLine {
        margin-left: 0px;
        margin-right: 0px;
    }
    .lineAboveSave {
        border-bottom: 1px solid rgba(0,0,0,0.14);
        background-image: linear-gradient(180deg, rgba(238,238,238,0.00) 0%, #BCB8B8 100%);
    }
    .scroll2 {
        overflow-y:auto !important;  
        height: auto;
        max-height: 700px;
    }

    button {
        background: transparent;
        border: transparent;        
    }
    button:hover {
        cursor: pointer;
    }

    .button-container {
        // background-color: $color-white;
        width: 100%;
        // height: 45px;
        text-align: center;
        padding: 10px 0px 15px 0px !important;
        border-radius: 3px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        
    }

    .buttons {
        width: 100%;
        text-align: center;
        padding-right: 8px;
        height: auto;
        .apply {
            margin-top: 5px;
            background-color: $color-blue;
            color: $color-white;
            margin-right: 10px;
        }
        .reset {
            margin-right: 12px;
            margin-top: 5px;
            color: $color-blue;
            margin-bottom: 5px;
            background: $color-white;
            border: 1px solid rgba(0,0,0,0.30);
            border-radius: 3px;
        }
    }
    }
    }
}