@import '../../../../../styles/variables';

.dividendContainer {
    .showMore {
        padding-top: 15px;
        font-family: $Roboto-Medium;
        font-size: 14px;
        color: $color-blue;
        line-height: 18px;
        border: transparent;
        background: transparent;
        cursor: pointer;
    }

    .downWardUpwardArrow {
        float: right;
        padding-left: 30px;
    }

    .footer {
        padding: 10px 0px 10px 0px;
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-black;
        line-height: 16px;
    }
}