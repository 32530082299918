@import '../../../styles/variables';

.expandCollapseContainer {
    display: flex;
    padding: 20px 0 15px 0;
    .seprator {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-gray;
        line-height: 18px;
    }
}