@import '../../../../../../../styles/variables';

.square {
  width: 50px;
  height: 50px;
  text-align: center;
  justify-content: space-between;
  background: $color-white;
  border: 1.5px solid $color-white;
  box-sizing: border-box;
  border-radius: 3px;
}
.firstRow {
  margin-left: 5px;
  padding-left: 29px;
}
.middleRow {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.lastRow {
  margin-right: 5px;
}

.rowColumnLabel {
  height: auto;
  display: flex;
  -ms-flex-pack: justify;
  background: transparent;
  font-family: 'roboto-regular', arial, sans-serif;
  font-size: 12px;
  color: $color-black;
  line-height: 16px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.lastRowlabel {
  width: 50px;
  height: auto;
  display: flex;
  -ms-flex-pack: justify;
  background: transparent;
  font-family: 'roboto-regular', arial, sans-serif;
  font-size: 12px;
  color: $color-black;
  line-height: 16px;
  justify-content: center;
  align-items: center;
}

.middleLastLabelCell {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.firstLastLabelCell {
  margin-left: 5px;
  padding-left: 29px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.lastLabelCell {
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.focusedCell {
  background: rgba(0, 68, 128, 0.25);
  border: 1.5px solid $color-bar-graph-blue-dark;
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  text-align: center;
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
  .firstRow {
    padding-left: 12px;
  }

  .firstLastLabelCell {
    padding-left: 12px;
  }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
  .firstRow {
    padding-left: 50px;
  }

  .firstLastLabelCell {
    padding-left: 50px;
  }
}
