@import "../../../styles/variables";

.genericTableContainer {
  border: 1px solid $color-white-whisper;

  .genericTable {
    width: 100%;
    margin: 0;
    padding: 0;
    background: $color-white;

    th,
    td {
      font-family: $Roboto-Medium;
      font-size: 14px;
      line-height: 18px;
      padding: 12px 5px;
    }

    tr td:first-child,
    th:first-child {
      padding-left: 10px;
    }

    tr td:last-child,
    th:last-child {
      padding-right: 10px;
    }

    .header {
      border-bottom: 1px solid $color-netxwealth-news-research-grey-border;

      th {
        color: $color-black;
      }
    }

    .body {
      border-top: 0.5px solid $color-white-whisper;

      td {
        font-family: $Roboto-Regular;
        color: $color-black;
      }

      td:first-child {
        font-family: $Roboto-Medium;
        color: $color-blue;
      }
    }

    .green {
      color: $color-green;
    }

    .red {
      color: $color-red;
    }

    .left {
      text-align: left;
    }

    .right {
      text-align: right;
    }

    .center {
      text-align: center;
    }

    .seprator {
      border-right: 1px solid $color-light-gray;
      padding-right: 10px;
    }

    .valueSeprator {
      padding-right: 10px;
    }
  }
}