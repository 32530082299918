@import '../../../styles/variables';

.esgOverAllScoreContainer {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: $color-white-opacity;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    .title{
        font-family: $Roboto-Regular;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
        display: block;
    }
    .headerContainer {
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;        
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        .labelContainer {
            display: flex;
            flex-wrap: nowrap;
            .circle{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 5px;
                align-self: center;
            }
        }
        & > div:nth-child(2) {
            margin-left: 20px;
        }
        & > div:nth-child(3) {
            width: 100%;
        }

    }
    .scoreBarContainer{
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .esgOverAllScoreContainer {
        .headerContainer {
            & > div:nth-child(3) {
                width: unset;
                margin-left: 20px;
            }                       
        }
    }   
}