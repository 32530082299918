.performanceChartContainer {
  .performanceChartDomElement {
    height: 300px;
    width: 100%;
  }
  .topDivider {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin: 20px 48px 0 0;
  }
}
