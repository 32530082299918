.testPageContainer {
    margin: 0;
    padding: 0;
    font-family: "Lato", sans-serif;
    display: flex;
    flex-direction: column;
    .itemList {
        width: 100%;
        background-color: #f1f1f1;
        display: flex;
    }
    .itemDetail {
        padding: 10px 15px;
    }
    .ulStyle {
        width: 100%;
        list-style-type: none;
        outline: none;
        margin: 0px;
        padding: 0px;
        .liContainer {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;    
        }
    }
    .liStyle {
        display: block;
        color: black;
        padding: 16px;
        text-decoration: none;
        cursor: pointer;
        &:hover, &:focus {
            background-color: #555;
            color: white;
        }
    }
}