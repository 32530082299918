@import "../../../styles/variables";

.indexDataContainer{
    background: $color-white-opacity;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    width:100%;

    .header{
        padding:11px 0 0 15px;
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
    }

    .indexDataContent{
        padding:7px 15px 0px 15px;
        color: $color-black;
    }
}

.nxiIndexDataContainer{
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    width:100%;

    .header{       
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        border-bottom: 1px solid $color-light-gray-5;
        padding: 15px 0px 10px;
        margin: 0px 15px;
    }

    .indexDataContent{
        padding:7px 15px 15px 15px;
        color: $color-black;
    }
}