@import "../../../../styles/variables";

.divIncreaseContainer {
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    width: 312px;
    height: 130px;
    padding: 15px 15px 20px 15px;
    display: flex;
    justify-content: space-between;
    .leftSection {
       
        width: 47%;
        .header {
            font-family: $Roboto-Regular;
            font-size: 16px;
            color: $color-gray;
            line-height: 22px;
            padding-bottom: 5px;
        }
        .middle {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            padding-bottom: 15px;
        }
        .dates {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-gray;
            line-height: 18px;
            button {
                border: none;
                margin: 0;
                padding: 0;
                background: transparent;
                font-size: 14px;
                line-height: 18px;
                font-family: $Roboto-Medium;
            }
            .errorMessage{
                color: $color-red-text;
            }
        }
    }
    .rightSection {
        width: 20%;
        .rightContainer{
            text-align: center;
            img{
                height: 52px;
                width: 100%;
            }
        }
    }
    .middleSection {
        width: 20%;
        font-size: 14px;
        margin-top: 4px;
        .ratingList {
            text-align: center; 
            border: 1px solid gray;
            color: $color-gray;
            padding: 4px;
        }
        .rating {
            background-color: $color-dark-gray;
            color: $color-white-whisper;
        }
    }
}
@media only screen and (min-width:1024px) {
    .leftSection {
        width: 42% !important;
    }
}