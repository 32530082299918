@import "../../../../../src//styles/variables";

.topStockCardMain{
    width: 23%;
    margin: 0 1.2%;
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
    border-radius: 3px;
    min-height: 119px;
    .topStockContainerLoader{
        min-height: 119px;
        display: flex;
        justify-content: center;
    }
    .topStockCard{
        position: relative;
        .symbolInfo{
            padding: 10px 10px 0px 10px;
            .symbolName{
                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-gray;
                line-height: 18px;
                height: 18px;
            }
            .symbolShortName{
                font-family: $Roboto-Medium;
                font-size: 14px;
                color: $color-blue;
                line-height: 18px;
                .symbolAddIcon{
                    text-align: center;
                    line-height: 24px;
                    float: right;
                    cursor: pointer;
                }
            }
        }
        img{
            height: 43px;
        }
        .symbolPriceInfo{
            font-family: $Roboto-Medium;
            font-size: 20px;
            color: $color-black;
            padding-left: 10px;
            position: absolute;
            top: 74px;
            .symbolPriceChange{
                font-family: $Roboto-Regular;
                font-size: 14px;
            }
            .red{
                color: $color-red;
            }
            .green{
                color: $color-green;
            }
        }
    }
}
.topStockCardMain:first-child{
    margin-left: 0px;
}
.topStockCardMain:last-child{
    margin-right: 0px;
}

@media screen and (min-width: 596px) and (max-width: 1023px) { 
    .topStockCardMain{
        width: 31.33%
    }
}

@media screen and (max-width: 595px) { 
    .topStockCardMain{
        width: 100%;
        margin: 0 0.5%;
        box-shadow: inset 0px 0px 3px 1px rgba(0,0,0,0.10);
    }
}