@import "../../../../../../../styles/variables";
.stocks-main-container-fund{
  background-color: $color-white !important;
}

.stocks-main-container {
  font-family: $Roboto-Regular;
  background: $color-white-opacity;
  margin-bottom: 32px;

  .font-20 {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .upper_container {
    .row-1 {
      padding-top: 30px;

      .main-share-name {
        font-size: 20px;
        color: $color-black;
      }  
      span {
        margin-right: 5px;
        &:last-child {
          margin-right: 0px;
        }
        &:nth-child(2) {
          color: $color-gray;
        }
      }
    }
    .row-2 {
      display: inline-block;
      font-size: 12px;
      color: $color-gray;
      line-height: 16px;
      width: 100%;
      margin-bottom: 20px;
      div[id="fund-pill-container"]{
        display: inline-block;
        > .pill{          
          width: fit-content;
          height: 22px;
          color: $color-gray;
          background: $color-white;
          border: 1px solid $color-black-3;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 6px;
          margin: 4px 10px 0px 0px;
          text-align: left;
          font-size: 12px;
          line-height: 16px;
          padding: 3px 8px;                
      }
      .multiAsset{         
          font-size: 12px;
          line-height: 16px;
          color: $color-gray;
      }
    }
      .delayed-quote-info {
        float: right;
      }
    }
  }
  .stock-quote-bar-container {
    height: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background: $color-white-opacity !important;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 20px 20px 35px 20px !important;
    .box {
      .stock-name {
        font-size: 14px;
        color: $color-gray;
        line-height: 16px;
        padding-bottom: 10px;
      }
      .stock-name-fund{
        font-size: 12px !important;
        padding-bottom: 5px !important;
      }
      .stock-pricing {
        .pricing-value {
          font-size: 20px;
          color: $color-black;
          line-height: 26px;
        }
        .pricing-factor {
          font-size: 14px;
          color: $color-gray;
          line-height: 18px;
          padding: 10px 0 10px;
        }
      }
      .stock-pricing-value {
        .pricing-value {
          font-size: 20px;
          color: $color-black;
          line-height: 26px;
        }
        .pricing-factor {
          font-size: 14px;
          color: $color-gray;
          line-height: 18px;
          padding: 10px 0 10px;
        }
      }
    }

    .last-trade-info {
      position: absolute;
      font-size: 12px;
      color: $color-netxwealth-news-research-grey-border;
      line-height: 16px;
      bottom: 20px;
    }
    .last-trade-info-fund{
      color: $color-gray !important;
      bottom: 15px !important;
    }
    .negative {
      color: $color-red;
    }
    .positive {
      color: $color-green;
    }
  }
}
@media (min-width: 596px) and (max-width: 1023px), only screen and (max-device-width:1023px) {
  .stocks-main-container {
    padding: 0px;
    margin: 0px;

    .upper_container {
      .row-2 {
        margin-bottom: 15px !important;

        div[id="fund-pill-container"]{
          display: block !important;
        }
        .delayed-quote-info {
          float: left;
          padding-top: 20px;
        }
      }
    }
  
    .stock-quote-bar-container {
      .ipad-display {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &:first-child {
          padding-bottom: 10px;
        }
        .display-content {
          .box {
            &:first-child {
              padding-bottom: 20px;
            }
          }
          .boxMF{
            &:first-child{
              padding-bottom: 25px !important;
            }
          }
        }
      }
      .box {
        .stock-pricing {
          .pricing-value {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
      .last-trade-info {
        position: absolute;
        bottom: 20px;
      }
      .last-trade-info-fund {
        position: absolute;
        bottom: 20px !important;
      }
    }
  }
}
@media (min-width: 375px) and (max-width: 595px), only screen and (max-device-width: 595px) {
  .stocks-main-container {
    padding: 0px;
    margin: 0px;
    margin-bottom: 70px !important;

    .upper_container {
      .row-2 {
        margin-bottom: 15px !important;
        
        .delayed-quote-info {
          float: left;
          padding-top: 20px;
        }
      }
    }

    .stock-quote-bar-container {
      padding: 10px 20px 20px 20px;
      .mobile-display {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center !important;
      }
      .spacing {
        width: 100%;
        border: 0;
        margin-top: 0em;
        margin-bottom: 0em;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
      .box {
        width: 100%;
        .font-bold {
          font-size: 14px;
          color: $color-black;
          line-height: 18px;
          font-weight: bold;
        }
        .stock-name {
          margin-top: 9px;
          font-size: 12px;
        }
        .stock-name-fund{
          font-size: 14px !important;
          line-height: 18px !important;
          padding-bottom: 8px !important;
        }
        .firstbox{
          margin-top:0;
          font-size: 12px!important;
          line-height: 16px !important;
          padding-bottom: 5px !important;
        }
        .stock-pricing-value {
          padding-bottom: 9px;
          .pricing-value {
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
          }
          .pricing-factor {
            font-size: 14px;
            color: $color-gray;
            line-height: 18px;
            font-weight: normal;
          }
        }
        .stock-pricing-value-first{
          padding-bottom: 16px !important;
        }
        .stock-pricing {
          margin-top: -31px;
          float: right;
          .pricing-value {
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 18px !important;
          }
        }
      }
      .last-trade-info {
        position: relative;
        bottom: 15px !important;
      }
      .last-trade-info-fund {
        position: relative;
        top: 15px !important;
      }
    }
  }
}