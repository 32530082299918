@import '../../../styles/variables';

.custombutton{
    width:80px;
    height: 34px;
   // font-family: $Roboto-Regular,Arial, Helvetica, sans-serif;
    font-family: $Roboto-Regular;
    border-radius: 3px;     
    font-size: 14px;    
    text-align: center;
    line-height: 18px;    
    border: 1px solid rgba(0,0,0,0.30);
 }
 button.custombutton:disabled {
    background: rgba(0,0,0,0.10);
    border-radius: 3px;
    color: rgba(0,0,0,0.26);
    border: none;
 }
 .applyButton {
    background: $color-blue;
    color: $color-white;
    cursor: pointer;
}
.resetButton {
    background: $color-white;
    color: $color-blue;
    cursor: pointer;
  }

