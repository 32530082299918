@import "../../../../../../styles/variables";

.individualsFiltersContainer {

    .transactionTypesDropdown {
        button[data-testid="button_CustomSelectMenu_Transaction Types"] {
            background: $color-white;
            border: 1px solid rgba(0, 0, 0, 0.30);
            border-radius: 5px;
            padding: 4px 10px;

            >span {
                color: $color-gray;
                margin-right: 18px;
            }
        }

        div[id="Popover_Parent_Container"] {
            left: 4px;

            div[data-custom-event-container="custom-event-select-container"] {
                width: 188px;
                max-height: 248px;

                >div {
                    padding-top: 8px;

                    >ul>li {
                        padding: 6px 0 6px 10px;
                    }

                    div[data-custom-event-select="base-button-wrapper"] {
                        >button {
                            font-family: $Roboto-Medium;
                            width: 80px;
                            height: 28px;
                        }
                    }
                }
            }
        }

        .transactionTypes {

            div[data-popover-body-container="true"] {
                padding: 0px !important;
            }
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .transactionTypesDropdown {
        button[data-testid="button_CustomSelectMenu_Transaction Types"] {
            border: 1px solid #B2B2B2 !important;
            border-radius: 3px !important;
            padding: 6px 10px !important;
            width: 100%;
            height: 34px;

            >span {
                color: $color-black !important;
                margin-right: 21px !important;
            }
        }

        div[id="Popover_Parent_Container"] {
            left: -5px !important;

            div[data-custom-event-container="custom-event-select-container"] {
                max-height: 294px !important;

                >div {
                    padding-top: 3px;

                    >ul>li {
                        padding: 11px 0 11px 10px !important;
                    }
                }
            }
        }

    }
}