@import "../../../styles/variables";
.IndicatorCancelPopupContainer {
    width: 300px;
    height: 157px;
    padding: 15px;
    // border: 3px solid blue;
    .header {
        font-family: $Roboto-Bold;
        font-size: 16px;
        color: $color-black;
        line-height: 22px;   
    }
    .content {
        padding-top: 10px;
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        word-break: break-word;
    }
    .button-container {
        height: 45px;
        text-align: center;
        // padding: 5px 0px 5px 0px !important;
        padding-top: 25px;
        border-radius: 3px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        
    }
    .buttons {
        width: 100%;
        text-align: center;
        padding-right: 8px;
        height: auto;
        .yes {
            margin-top: 5px;
            background-color: $color-blue;
            border-radius: 3px;
            color: $color-white;
            margin-right: 10px;
        }
        .cancel {
            margin-right: 12px;
            margin-top: 5px;
            color: $color-blue;
            margin-bottom: 5px;
            background: $color-white;
            border: 1px solid rgba(0,0,0,0.30);
            border-radius: 3px;
        }
    }
}
@media screen and (max-width: 596px) {
    .IndicatorCancelPopupContainer {
        width: 100%;
        height: 100%;
    }
}
