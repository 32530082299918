@import "../../../styles/variables";
.filterContainer {
  display: flex;
  align-items: center;
  background: $color-white;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px 21px 10px 15px;
  font-family: $Roboto-Regular;
  position: relative;
  min-height: 36px;
  .divider {
    background: $color-light-gray;
    width: 1px;
    height: 26px;
    margin: 0 15px;
  }
  .filterLabel {
    font-size: 12px;
    color: #666666;
    line-height: 16px;
    margin-right: 1%;
  }
  .tags {
    display: flex;
    flex: 5;
    align-content: space-between;
    margin-right: 5%;
    padding: 5px 5px 5px 5px;
  }
  .clear {
    font-size: 14px;
    color: #0572d6;
    text-align: right;
    line-height: 18px;
  }
  .clearButton {
    font-family: $Roboto-Regular;
    font-size: 14px;
    color: #0572d6;
    line-height: 18px;
    cursor: pointer;
  }
}
@media (max-width: 575px) {
  .filterContainer {
    width: 99% !important;
  }
}
