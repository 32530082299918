@import '../../../../styles/variables';

.AICardHeader {
    padding: 5px 10px 0px 10px;
    position: relative;

    .AICardHeaderMain {
        color: $color-blue;
        width: 76px;
        height: 18px;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }

    .AICardHeaderDesc {
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
        font-family: $Roboto-Regular;
        width: 187px;
        height: 32px;
        font-style: normal;
        font-weight: 400;
        white-space: normal;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* autoprefixer: off */
    }
}

.toolTip {
    float: right;
    position: absolute;
    right: 15px;
    top: 0px;
    padding-top: 6px;
}

.price {
    width: 62px;
    height: 22px;
    font-family: $Roboto-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: $color-black;
    margin-top: 5px;
    margin-left: 32px;
}

.red {
    color: $color-red;
}

.green {
    color: $color-green;
}

.AI {
    width: 106px;
    height: 24px;
    color: $color-gray;
    background: $color-white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 5px;
    text-align: center;
    margin-left: 10px;
}

.strategyTypediv {
    display: flex;
}