@import '../../../styles/variables';

.tabs {
	width: 100%;
	background-color: $color-white;
	.tab_list {
		border-bottom: 1px solid rgba(0,0,0,0.10);
		background: #F7F7F7;
		color: #F7F7F7;
		display: flex;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.noBackground{
		border-bottom: none !important;
		background: $color-white !important;
		margin-left: -40px;
	}
	.tab_list::-webkit-scrollbar {
		display: none;
	}
	.tab_list_item {
		font-family: $Roboto-Regular;
		font-size: 14px;
		color: #000000;
		text-align: center;
		line-height: 18px;
		padding: 11px 15px;

		cursor: pointer;
		display: block;
		list-style: none;
		white-space: nowrap;
	}
	.tab_list_item::before {
		display: block;
		height: 0;
		overflow: hidden;
		visibility: hidden;
		
	}
	.tab_list_active {
		font-family: $Roboto-Medium;
		font-size: 14px;
		color: #000000;
		text-align: center;
		line-height: 18px;
		border-bottom: 3px solid #70BBFF;
	}

	.tab {
		font-size: 14;
	}
}
	@media only screen and (max-width: 767px) and (min-width: 375px) {
		.tabs {
			.tab_list {
			padding: 0px;
		}
		.noBackground{
			border-bottom: none !important;
			background: $color-white !important;
			padding: 0px;
			margin-left: 0px;
		}
	}
	}
