@import '../../../styles//variables';

body {
    background-color: #f7f7f7 !important;
    font-family: "Roboto-Regular",Arial,sans-serif;
}

div[data-page='F2AppContainer'] {
    width: 90%;
    margin: 0 auto;
}

.branchDetails{
    float: right;
    margin-right: 5px;
    width: 38%;
    text-align: end;
    font-size: 16px;
    color: #000;
    line-height: 18px;
    font-weight: bold;
	margin-top: -40px;
}

.menuTabList {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: #F7F7F7;
    color: #F7F7F7;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    li {
        font-family: "Roboto-Regular", Arial, sans-serif;
        font-size: 14px;
        color: #000000;
        text-align: center;
        line-height: 18px;
        padding: 11px 15px;
        cursor: pointer;
        display: block;
        list-style: none;
        white-space: nowrap;
        &[data-active="true"] {
            font-family: "Roboto-Medium", Arial, sans-serif;
            font-size: 14px;
            color: #000000;
            text-align: center;
            line-height: 18px;
            border-bottom: 3px solid #70BBFF;
        }

        a {
            text-decoration: none;
            color: inherit;
        }
    }
    padding: 0px;
    background: none !important;
    li {
        padding-left: 0px !important;
        margin-right: 30px;
    }
}

.newsSection {
    background: #fff;
    padding: 5px 25px;
    margin-top: 60px;
}

.newsAndResearch {
    display: flex;
    div[data-ipc-container="com_pershing_netxwealth_research_list"] {
        min-width: 275px !important
    }
}

.researchAndNewsForYou{
    width: 25%;
    margin-right: -5px;
}

.marketOverviewLeftSection{
    width:69%;
    float:left;
    padding-right:1%;
}
.marketOverviewPerformanceSection {
    width:100%;
    display: flex;
    margin-bottom: 10px;
    background: white;
    padding: 20px 10px;
    .marketOverviewLeftSectionPerformance {
        width:69%;
        padding-right: 10px;
    }
    .marketOverviewRightSectionPerformance {
        width:31%;
        padding-bottom: 20px;
    }
    
}
.marketOverviewSectorPerformanceSection {
    width:100%;
    display: flex;
    margin-bottom: 10px;
    background: white;
    padding: 20px 10px;
    div[data-ipc-container="com_pershing_netxwealth_markets_sector_performance"] {
        padding-bottom: 10px;
        width:100%;
    }
}
.economicsGlance {
    padding-top: 10px;
    padding-bottom: 10px;
}
.eventsCalendar {
    padding-top: 10px;
    padding-bottom: 10px;
}
.marketOverviewRightSection{
    width: 30%;
    float:left;
    div[data-ipc-container="com_pershing_netxwealth_markets_unusual_volume"] {
        margin-top: 10px;
    }
}
.marketHighlights {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
}
div[data-ipc-container="com_pershing_netxwealth_markets_highlights"] {
    div[data-f2-app-conatiner="MOD_F2_APP_CONTAINER"] {
        background-color: inherit !important;
    }
}
div[data-ipc-container="com_pershing_netxwealth_markets_highlights"] {
    div[data-f2-app-conatiner="MOD_F2_APP_CONTAINER"] {
        background-color: inherit !important;
    }
}
div[data-ipc-container="com_pershing_netxwealth_news_filter"] {
    width: 75%;
    margin-top: -45px;
    div[data-f2-app-conatiner="MOD_F2_APP_CONTAINER"] {
        background-color: inherit !important;
    }
}

div[data-ipc-container="com_pershing_netxwealth_news_articlelist"] {
    width: 75% !important;
    display: inline-block;
    padding-right: 40px;
}

div[data-ipc-container="com_pershing_netxwealth_stocks_news_for_you"] {
    padding-top: 10px;
}

div[data-ipc-container="com_pershing_netxwealth_news_headertopstories"] {
    padding: 0 20px;
}

div[data-ipc-container="com_pershing_netxwealth_research_list"] {
  padding-top: 5px; 
}

div[data-test-token-events="TestF2Events"] {
    position: fixed;
    bottom: 0;
    right: 11px;
    padding: 5px;
    background: #ddd;
    button {
        background: none;
        border: none;
        cursor: pointer;
    }
    div[data-id='token-test-controls'] {
        background: #fff;
        position: fixed;
        width: 230px;
        padding: 5px;
        bottom: 0;
        right: 0;
        border: 1px solid #eee;
        display: none;
        &[data-visible="true"] {
            display: block;
        }
        button[data-id='btnInvalidateToken'] {
            background-color: red;
            color: #FFF;
            padding: 10px 38px;
            width: 100%;
        }
        button[data-id='btnSetToken'] {
            background-color: green;
            color: #FFF;
            padding: 10px 38px;
            width: 100%;
        }
        input[type="text"] {
            margin: 20px 0;
            width: 100%;
            padding: 5px;
            margin-top: 5px;
        }
        span {
            font-size: 13px;
            padding-top: 12px;
            display: inline-block;
        }
    }
}

.quoteAndResearch {
    background: $color-white;
    padding: 0 15px;
    .stockValuationContainer {
        display: flex;
        .leftSectionContainer{
            width: 68%;
            margin-right: 2%;
        }
      div[data-ipc-container="com_pershing_netxwealth_stock_valuation"] {
            width: 30%;
        }
    }
    .performanceApp {
        display: flex;
        flex-direction: row;
        div[data-ipc-container="com_pershing_netxwealth_stock_performance"] {
            width: 68%;
            float: left;
            margin-right: 2%;
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_highlights"] {
            width: 30%;
            float: left;
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_news_articlelist"] {
            width: 68%;
            float: left;
            margin-right: 2%;
        }
        div[data-ipc-container="com_pershing_netxwealth_stock_company_description"] {
            width: 30%;
            float: left;
        }
    }
}
.header {
    font-family: $Roboto-Light;
    font-size: 28px;
    color: $color-black;
    line-height: 34px;
    border-bottom: 1px solid rgba(0,0,0,0.07);
    padding: 10px 0;
}

.appGap {
    height: 30px;
    border-bottom: 1px solid rgba(0,0,0,0.07);
}

@media (min-width:596px) and (max-width: 1023px) {
    div[data-page='F2AppContainer'] {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box
    }

    div[data-ipc-container="com_pershing_netxwealth_news_filter"] {
        width: 100%;
    }

    .newsAndResearch {
        div[data-ipc-container="com_pershing_netxwealth_news_articlelist"] {
            width: 68% !important;
        }

        div[data-ipc-container="com_pershing_netxwealth_research_list"] {
            margin-top: 0;
            min-width: 200px !important;
        }
    }

    .researchAndNewsForYou{
        width: 35%;
        margin-top: 25px;
        margin-right: -5px;
    }

    .marketOverviewLeftSection{
        width:99%;
        float:left;
        padding-right:1%;
    }

    .marketOverviewRightSection{
        width: 100%;
        float:left;
        display: flex;
        margin-top: 10px;
        div[data-ipc-container="com_pershing_netxwealth_markets_mostactive"] {
            width: 50%;
            margin-top: 10px;
            padding-right: 10px;
        }
        div[data-ipc-container="com_pershing_netxwealth_markets_unusual_volume"] {
             width: 50%;
        }
    }
    .marketOverviewPerformanceSection {
        flex-direction: column;
        margin-bottom: 10px;
        .marketOverviewLeftSectionPerformance {
            width: 100%;
            padding-bottom: 10px;
        }
        .marketOverviewRightSectionPerformance {
           width: 100%;
        }
        
    }

    .quoteAndResearch {
        .stockValuationContainer {
            display: flex;
            flex-direction: column;
            .leftSectionContainer{
                width: 100%;
            }
          div[data-ipc-container="com_pershing_netxwealth_stock_valuation"] {
                width: 100%;
            }
        }
        .performanceApp {
            flex-direction: column;
            div[data-ipc-container="com_pershing_netxwealth_stock_performance"] {
                width: 100%;
                margin-right: 0;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_highlights"] {
                width: 100%;
                margin-top: 20px;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_news_articlelist"] {
                width: 100%;
                margin-right: 0;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_company_description"] {
                width: 100%;
                margin-top: 20px;
                height: 400px;
            }
        }
    }
}
@media(min-width: 375px) and (max-width:595px) {
    div[data-page='F2AppContainer'] {
        width: 100%;
        padding: 10px;
        box-sizing: border-box
    }
    div[data-ipc-container="com_pershing_netxwealth_news_filter"] {
        width: 100%;
    }

    .newsAndResearch {
        display: block; 
        position: inherit;
        div[data-ipc-container="com_pershing_netxwealth_news_articlelist"] {
            width: 100% !important;
        }

        div[data-ipc-container="com_pershing_netxwealth_research_list"] {
            width: 100%;
            margin-top: 0;
        }
    }

    .marketOverviewLeftSection{
        width:99%;
        float:left;
        padding-right:1%;
    }

    .marketOverviewRightSection{
        width: 100%;
        float:left;
        margin-top: 10px;
        div[data-ipc-container="com_pershing_netxwealth_markets_unusual_volume"] {
             width: 100%;
        }
    }

    .newsSection {
        padding: 5px 34px;
    }
    
    .researchAndNewsForYou{
        width: 100%;
        margin-top: 0;
        margin-right: -5px;
    }

    div[data-ipc-container="com_pershing_netxwealth_markets_highlights_news"] {
        background: #f7f7f7;
    }

    .marketOverviewPerformanceSection {
        flex-direction: column;
        margin-bottom: 10px;
        padding-top: 20px;
        padding-right: 0px;
        background: #f7f7f7;
        .marketOverviewLeftSectionPerformance {
            width: 100%;
            padding-bottom: 10px;
        }
        .marketOverviewRightSectionPerformance {
           width: 100%;
        }
        
    }

    .quoteAndResearch {
        .stockValuationContainer {
            display: flex;
            flex-direction: column;
            .leftSectionContainer{
                width: 100%;
            }
          div[data-ipc-container="com_pershing_netxwealth_stock_valuation"] {
                width: 100%;
            }
        }
        .performanceApp {
            flex-direction: column;
            div[data-ipc-container="com_pershing_netxwealth_stock_performance"] {
                width: 100%;
                margin-right: 0;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_highlights"] {
                width: 100%;
                margin-top: 20px;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_news_articlelist"] {
                width: 100%;
                margin-right: 0;
            }
            div[data-ipc-container="com_pershing_netxwealth_stock_company_description"] {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}

@media(min-width: 0px) and (max-width:374px) {
    .quoteAndResearch {
        .stockValuationContainer {
            display: flex;
            flex-direction: column;
            .leftSectionContainer{
                width: 100%;
            }
        div[data-ipc-container="com_pershing_netxwealth_stock_valuation"] {
                width: 100%;
            }
        }
    }
}