@import "../../../styles/variables";
.indicatorContainer {
    width: 225px;
    height: 399px;
    padding: 0 10px 0 10px;
    background: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.05), 1px 2px 6px 2px rgba(0,0,0,0.05);
    border-radius: 3px;
    
    .popupExtraSetting {
        will-change: auto !important;
        transform: none !important;
    }
    .popupExtraSettingReset {
        will-change: transform !important;
        transform: translate3d(5px, 140px, 0px) !important;
    }
    .sections {
        //top Indicator design starts here
        display: flex;
        flex-direction: column;
        .searchFilterContainer {
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 34px;
            .searchBoxFilter {
                font-size: 14px;
                color: $color-netxwealth-news-research-grey-border;
                line-height: 18px;
                width: 100%;
                height: 30px;
                display: flex;
                input {
                    width: 100%;
                    height: 30px;
                    border: 1px solid rgba(0,0,0,0.30);
                    padding: 0px 25px 0px 6px;
                    background: $color-white;
                    border-radius: 3px;
                    font-family: $Roboto-Regular;
                }
                input:focus{
                    outline: none;
                    border: 1px solid $color-graph-light-blue1;
                }
                .inputSearchIcon{
                    margin-left: -20px;
                    line-height: 30px;
                }
            }
            .searchFilterIcon{
                font-size: 14px;
                color: $color-black;
                text-align: center;
                line-height: 14px;
                width: 14px;
                height: 14px;
                margin-right: 15px;
            }
        }
        .scroll {
            overflow-y:auto; 
            overflow-x: hidden;
            height: 292px;
        }
        //Items logic below
        .itemsListContainer {
            margin-top: 11px;
            .itemsHeader {
                padding: 6px 0 0 0;
                background: $color-white-opacity;
                height:28px;
                padding-left: 10px;

                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-gray;
                line-height: 16px;
            }
            .items {
                font-family: $Roboto-Regular;
                font-size: 14px;
                color: $color-black;
                line-height: 18px;
                padding-bottom: 16px;
                .itemContainer{
                    padding-bottom: 2px;
                    .itemLIContainer{
                        padding-top: 0px !important;
                    }
                }
                ul , li {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                }
                li {
                    padding-top: 12px;
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                }
                li:first-child {
                    padding-top: 6px;
                }
                li:hover {
                    cursor: pointer;
                }
            }
        }
        // above button line
        .bottomLine {
            width: 225px;
            margin-left: -10px;
            opacity: 0.32;
            background-image: linear-gradient(180deg, rgba(238,238,238,0.00) 0%, #BCB8B8 100%);
            border: 1px solid #BCB8B8;
        }
    }
    .expColHead {
        background: #F7F7F7;
        height: 28px;
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 8px;
        .leftSpace {
            padding-left: 10px;
        }
        button {
            width: 100%;
            text-align: left;
            color: $color-gray !important;
        }
    }
    .panel-collapse {
        overflow-y: auto;
        }
    .panel-close {
        max-height: 0;
    }
    .popupScreen > div{
        min-height: auto !important;
    }
    .popupScreen > div > div{
        width: auto;
        min-height: auto !important;
        height: auto !important;
        padding: 0px !important;
    }
    .popupScreen > div > div > div{
        min-height: auto;
        max-width: 336px !important;
        top:0px !important;
        padding:0 !important;
        margin:0 !important;
        overflow-y: visible !important;
        overflow: inherit !important;
    }
    .bottomLine {
        border: 1px solid rgba(0,0,0,0.14)
    }
    button {
        background: transparent;
        border: transparent;        
    }
    button:hover {
        cursor: pointer;
    }
    .button-container {
        background-color: $color-white;
        width: 100%;
        text-align: center;
        padding: 10px 0px 10px 0px ;
        border-radius: 3px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }
    .buttons {
        width: 100%;
        text-align: center;
        .apply {
            background-color: $color-blue;
            color: $color-white;
            margin-right: 5px;
        }
        .reset {
            color: $color-blue;
            margin-left: 4px;
            margin-right: 0px;

            background: $color-white;
            border: 1px solid rgba(0,0,0,0.30);
            border-radius: 3px;
        }
    }
}

@media(min-width:596px) and (max-width: 1024px)  {
    .indicatorContainer {
        .panel-collapse {
            position: relative;
        }
    }
}

@media(max-width:596px)  {
    .indicatorContainer {
        width: 375px;
        height: auto;
        padding: 0;
        background: $color-white;
        box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.05), 1px 2px 6px 2px rgba(0,0,0,0.05);
        border-radius: 3px;
        .topSection {
            padding: 0 10px 0 10px;
            border-radius: 3px 3px 0 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            color: $color-black;
            border-bottom: 1px solid rgba(0,0,0,0.30);        
            .topSectionHeader{
                color: $color-black;
                text-align: center !important;
                font-family: $Roboto-Medium;
                font-size: 16px;
                line-height: 22px;
            }
            .crossButton {
                padding-left: 7px;
                width: 20px;
                height: 20px;
                background: transparent;
                font-size: 18px;
                text-align: center;
                line-height: 18px;
                color: $color-black !important;
            }
            button {
                padding-right: 0 !important;
            }
        }
        .sections {
            //top Indicator design starts here
            display: flex;
            flex-direction: column;
            .searchFilterContainer {
                margin-top: 15px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                background: $color-white;
                border-radius: 3px;
                font-family: $Roboto-Regular;
                height: 34px;
                margin-left: 10px;
                margin-right: 10px;
                .searchBoxFilter {
                    font-size: 14px;
                    color: $color-netxwealth-news-research-grey-border;
                    line-height: 18px;
                    width: 100%;
                    height: 30px;
                    padding-left: 10px;
                    display: flex;
                    input {
                        width: 100%;
                        height: 30px;
                        border: 1px solid rgba(0,0,0,0.30);
                    }
                    input:focus{
                        outline: none;
                        border: 1px solid $color-graph-light-blue1;
                    }
                }
                .searchFilterIcon{
                    font-size: 14px;
                    color: $color-black;
                    text-align: center;
                    line-height: 30px;
                    width: 14px;
                    height: 14px;
                    padding-right: 25px !important;
                }
            }

            .scroll {
                padding: 0 10px 0 10px;
                overflow-y:auto; 
                height: auto;
                max-height: 430px;
            }
            
            //Items logic below
            .itemsListContainer {
                margin-top: 11px;
                


                .itemsHeader {
                    padding: 6px 0 0 0;
                    background: $color-white-opacity;
                    height:28px;
                    padding-left: 10px;

                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-gray;
                    line-height: 16px;
                }
                .items {
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    padding-bottom: 16px;
                    max-height: 600px;
                    ul , li {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                    }
                    li {
                        padding-top: 16px;
                        font-family: $Roboto-Regular;
                        font-size: 14px;
                        color: $color-black;
                        line-height: 18px;
                    }
                    li:first-child {
                        padding-top: 6px;
                    }
                    li:hover {
                        cursor: pointer;
                    }
                }
            }

            // above button line
            .bottomLine {
                opacity: 0.32;
                width: 102%;
                background-image: linear-gradient(180deg, rgba(238,238,238,0.00) 0%, #BCB8B8 100%);
                border: 1px solid #BCB8B8;
            }
        }
        .bottomLine {
            border: 1px solid rgba(0,0,0,0.14)
        }
        button {
            background: transparent;
            border: transparent;        
        }
        button:hover {
            cursor: pointer;
        }
        .button-container {
            background-color: $color-white;
            width: 100%;
            text-align: center;
            padding: 5px 0px 15px 0px ;
            border-radius: 3px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
        }
        .bottomAlign {
            position: fixed;
            width: 100%;
            bottom: 0;
        }
        .buttons {
            width: 100%;
            text-align: center;
            padding-right: 8px;
            .apply {
                margin-top: 5px;
                background-color: $color-blue;
                color: $color-white;
                margin-right: 10px;
            }
            .reset {
                margin-right: 12px;
                margin-top: 5px;
                color: $color-blue;

                background: $color-white;
                border: 1px solid rgba(0,0,0,0.30);
                border-radius: 3px;
            }
        }
        .expColHead {
            background: #F7F7F7;
            height: 40px;
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-gray;
            line-height: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 8px;
            .leftSpace {
                padding-left: 10px;
            }
            button {
                width: 100%;
                text-align: left;
                color: $color-gray !important;
            }
        }
        .panel-collapse {
            overflow-y: auto;
            }
        .panel-close {
            max-height: 0;
        }
        .popupScreen > div{
            min-height: auto !important;
        }
        .popupScreen > div > div{
            width: auto;
            min-height: auto !important;
            height: auto !important;
            padding: 0px !important;
        }
        .popupScreen > div > div > div{
            min-height: auto;
            max-width: 375px !important;
            top:0px !important;
            padding:0 !important;
            margin:0 !important;
            overflow-y: visible !important;
            overflow: inherit !important;
        }
        
    }
}