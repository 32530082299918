@import '../../../../styles/variables';
    .quoteCardHeader , .quoteLckCardHeader {
        padding: 5px 10px 0px 10px;
        position: relative;
        .quoteCardHeaderMain {
            color: #0572D6;
            width: 76px;
            height: 18px;
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }
        .fixedCardSymbol{
            color: $color-black; 
            cursor: default;
         }
        .quoteCardHeaderDesc {            
            font-size: 12px;
            color: #666666;
            line-height: 16px;
            font-family: $Roboto-Regular;
            width: 176px;
            height: 32px;
            font-style: normal;
            font-weight: normal;
            white-space: normal;
        }
        .quoteCardHeaderDesc {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            /* autoprefixer: off */
        }
    }

    .quoteCardModeChart{
        border: none;
    }
    .quoteCardModeChart img {
        height: 55px;
    }
    .toolTip{
        float:right;
        position: absolute;
        right: 15px;
        top: 0px;
        padding-top: 6px;
        z-index: 9999;
    }
    
    .quoteCardChart {
        margin-top: -40px;
        .quoteCardChartMain {
            font-size: 20px;
            color:  #000000;
            font-weight: 500;
            display: inline-block;
            height: 23px;
            line-height: 23px;
            font-family: $Roboto-Regular;
            font-style: normal;
            margin-left: 10px;
            margin-top: -4px;
        }

        .quoteCardChartDesc {
            padding: 5px 10px 0 10px;
            font-family: $Roboto-Regular;
            font-size: 14px;
            display: inline-block;
            height: 16px;
            line-height: 16px;
        }
    }
    .dvTags{
        display: flex;
    }
    .stockHighlightsAlign {
        padding: 4px 0px 12px 0px;
    }

    .red {
        color: $color-red;
    }
    .green {
        color: #008338;
    }
    .ETF, .MF{
        width: fit-content;
        padding: 3px 8px;
        font-size: 12px;
        line-height: 16px;
        color: #666666;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-top: 5px;
        text-align: center;
        margin-left: 10px;
    }
    .custom{
        background: $color-white-opacity;
    }
    .noTag  {
        height: 29px;
    }
    .adjustWidth {
        width: 135px
    }
    .stockHighlightIPadDivLeft {
        padding: 0 10px 10px 10px;
    }
    .stockHighlightIPadDivLeftOverideForCompare{
        padding: 0px !important;
        margin-left: -5px !important;
        margin-right: -5px !important;
        margin-bottom: 10px;
    }
	.stockHighlightIPadDivLeft>div:first-child,.stockHighlightIPadDivLeft>div:nth-child(2){
        display: flex;
        justify-content: space-between;
        width:100%;
        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 16px;
        padding: 4px 0 5px 0px;
        border-bottom: 1px solid rgba(0,0,0,0.10);
    }    
    .stockHighlightIPadDivLeft>div:last-child{
        display: flex;
        justify-content: space-between;
        width:100%;
        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 16px;
        padding: 4px 0 5px 0px;
        border-bottom: 0;
    }
    .stockHighlightIPadDivLeftOverideForCompare>div:first-child,.stockHighlightIPadDivLeftOverideForCompare>div:nth-child(n+1){
        padding: 4px 0px 5px 0px;
    }
    .stockHighlightIPadDivLeftOverideForCompare>div:last-child{
        padding: 3px 0px 0px 0px;
        margin-bottom: 16px;
    }
    .stockHighlightDataDiv:last-child{
        border-bottom:none;
    }
    .stockHighlightDataDiv{
        display: flex;
        justify-content: space-between;
        width:100%;
        font-family: $Roboto-Regular;
        font-size: 14px;
        line-height: 18px;
        padding: 4px 0px 5px 0px;
        border-bottom: 1px solid rgba(0,0,0,0.10);
    
        .stockHighlightCol1{
            color: #666666;
            font-family: $Roboto-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            height: 16px;
            top: -4px;
    
        }
    
        .stockHighlightCol1OverrideCompare{
            line-height: 16px;
        }
    
        .stockHighlightCol2{
            justify-content: flex-end;
            text-align: right;
            font-family: $Roboto-Medium;
            font-size: 12px;
            color: $color-black;
            line-height: 16px;
            height: 16px;
            
        }
    }