@import "../../../../../../styles/variables";

.typeContainer {
    display: flex;
    align-items: center;
    margin-right: 19px;

    .icon {
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    .type {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        white-space: nowrap;
    }
}