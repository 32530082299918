@import '../../../styles/variables';
.articleNewsSymbolContainerIndexList{
    width: 100%;
    height: 28px;
    display: flex;
    flex-direction: row;
    .articleNewsSymbolItems{
        width: 75%;
        height: 28px;
        display:flex;
        flex-wrap:wrap;
        overflow-y:hidden;
        font-family: $Roboto-Regular;
    }
    .articleNewsSymbolMore {
        margin-top:10px;
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-gray;
        line-height: 18px;
    }
}
@media (min-width:1024px) {
    .articleNewsSymbolContainerIndexList{
        .articleNewsSymbolItems{
        width: 475px;
        }
    }
}