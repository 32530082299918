@import "../../../../styles/variables";

.top {
  .riskScoreBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: auto;
    padding-right: 80px;
    background-color: $color-white;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    margin-bottom: 30px;

    .riskScoreBoxItems {
      padding: 15px 0px 0px 15px;

      button {
        color: $color-gray;
        background-color: $color-white;
        outline: none;
        border: none;
        cursor: pointer;

        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .subheading {
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: $color-gray;
      line-height: 16px;
      display: inline;
      cursor: pointer;
      padding-bottom: 2px;

      button {
        padding-left: 0px;
        padding-right: 0px;
        border-bottom: 1px dashed $color-black;
      }
    }

    .popOverBody {
      max-width: 302px;
      font-family: $Roboto-Regular;
      font-size: 12px;
      color: $color-black;
      line-height: 16px;
      padding: 0 5px 5px 5px;

      span {
        white-space: normal;
      }
    }

    .icon {
      width: 109px;
      height: 26px;
      margin-right: 3px;
      font-size: 20px;
      line-height: 26px;
      margin-top: 3px;
      letter-spacing: 3px;
    }

    .subText {
      line-height: 26px;
      margin-top: 3px;
      font-size: 20px;
      font-family: $Roboto-Regular;
      color: $color-black;
      padding-right: 4px;
      padding-bottom: 15px;
    }

    .subText2 {
      line-height: 18px;
      margin-top: 3px;
      font-size: 14px;
      color: $color-gray;
    }
  }
  .nxiRiskScoreBox{
    box-shadow: none !important;    
    padding-right: 10px !important;
    
    .riskScoreBoxItems {
      padding: 12px 0px 0px 0px !important;
    }
  }
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
  .riskScoreBox {
    padding-right: 58px !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
  .top {
    .riskScoreBox {
      padding-right: 8px !important;
      padding-bottom: 15px !important;

      .riskScoreBoxItems {
        padding: 15px 0px 0px 8px;
      }

      .subText {
        padding-bottom: 0px !important;
      }
    }
  }
}