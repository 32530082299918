@import '../../../styles/variables';
.articleNewsSymbol{
    flex-basis: inherit;
    font-family: $Roboto-Regular;
    font-size: 12px;
    line-height: 18px;
    margin-top:10px;
    margin-left: 20px !important;
    .symbol {
        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 18px;
    }
    .upDownIcon {
        margin-left: 6px;
        font-size: 12px;
        text-align: center;
    }
    .percent {
        margin-left: 2px;
        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 18px;
    }
    &:first-child {
        margin-left: 0 !important;
    }
}
.negativePercent {
    color: $color-red
}
.positivePercent {
    color:$color-green;
}