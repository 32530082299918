@import "../../../styles/variables";

.fundStrategyContainer {
    width: 100%;
    background: #F7F7F7;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 15px 15px 15px 15px;


    div[id='outerModalPopUp']{
        padding-top: 148px;      
        align-items: start;       
    }
    

    .fundStrategyHeader {
        padding-bottom: 20px;
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
    }

    .fundStrategyDetail {      
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: #000;
        line-height: 18px;
        .fundStrategyReadMore {
            color: $color-blue;
            cursor: pointer;
            border: none;
            background: none;
            font-family: $Roboto-Regular;
            text-decoration: none;
        }
    }    
}


@media screen and (min-width: 1024px){
    .fundStrategyContainer {
        width: 100%;
        div > div[role='presentation']{
            width: auto;
            max-height: 500px; 
            max-width: 500px;         
            margin: 0 302px 0 303px;
        }
    }
}


@media screen and (min-width: 596px) and (max-width: 1023px) {
    .fundStrategyContainer {
        width: 100%;
        div[id='outerModalPopUp']{
            padding-top: 160px;      
            align-items: start;       
        }
        div > div[role='presentation']{
            width: auto;
            max-height: 500px; 
            // height: 232px;           
            margin: 0 15px 0 15px;
        }        
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .fundStrategyContainer {
        width: 100%;
        div[id='outerModalPopUp']{
            padding-top: 0px;      
            align-items: start;   
            top: 0px;    
        }
        div[id='modalPopUpContentContainer']{
            padding: 0 10px !important;      
        }
      
    }
}
