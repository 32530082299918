.styleboxcontainer{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
  }
  
  @media screen and (min-width: 375px) and (max-width: 390px) {
    // Below property is giving issue in between these width, so handling them separately
    .styleboxcontainer{
        margin-left: -30px;
      }
  }
  @media (min-width: 375px) and (max-width: 595px), only screen and (max-device-width: 595px) {
    .styleBoxMainContainer {
      padding-left: 30px;
    }
  }
  @media (min-width: 1023px), only screen {
    .styleBoxMainContainer {
      display: table;
      margin: 0 auto;
    }
  }