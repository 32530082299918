@import "../../../styles/variables";
.float-right{
    float: right;
    .caretIcon{
    width: 12px;
    height: 13px;
    }
}
.containerDropDiv{
  margin-bottom: 10px;
}
ul.dropdownWithBottomBorder {
    border:none;
 & > li:first-child {
        margin-bottom: 10px;
        border-bottom: 3px solid $color-netxwealth-lightblue-dropdown-border;
    }
    li:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      
    }
    li:nth-child(2) {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
}
.basicDropDownContainer {
    display: inline-block;
    width:fit-content;
    position: absolute;
    border: none;
    padding: 0;
    list-style: none;
    outline: none;
    margin: 0 0 0 10px;
    &.open {
      border-radius: 0px;
    }
    .enableBorder {
      border: 1px solid #AAADB1;
      border-radius: 3px;
    }
    li {
      border: none;
      padding: 3px 0 3px 0px;
      display: block;
      cursor: pointer;
      font-size: 14px;
      color: #000000;
      font-family: $Roboto-Regular;
      border-top: 1px solid #f7f7f7;
      background: #FFFFFF;
      z-index: 999;
      text-align: left;
      .name {
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        top: 2px;
        box-sizing: border-box;
        position: relative;
        margin-right: 5px;
      }
      &.hide {
        display: none;
      }

      &.selected {
        background: $color-white-opacity;
      }
      
    li:first-child {
      display: block;
      color: #2472B2;
      border-top: none;
      border-radius: 3px;

      &:hover {
        background: #FFFFFF !important;
      }
    }

    .icon{
        margin-right: 0px;
    }
  }
    .basicDropDownValueContainer {
      max-height: 450px;
      overflow-y: auto;
      box-shadow: 0px 0px 10px 3px #88888899;
      position: relative;
      z-index: 10;
      visibility: hidden;
      ul, li{
        padding-left: 10px !important;
        margin-left: 0px !important;
      }
      ul, li:first-child{
        padding-left: 10px !important;
        margin-left: 0px !important;
        span{
          font-size: 20px;        
        }
      }
      li>span{
        font-size:14px !important;
      }
    }
    .hide{
      display: none;
    }

  }
  .basicDropDownCustomContainer {
    li {
      &.selected {
          background: $color-white-opacity;
        }
        &:hover {
          background: $color-white-opacity;
        }
      }
  }