@import "../../../../styles/variables";
.tag {
  display: inline-block;
  font-size: 12px;
  color: $color-white;
  line-height: 16px;
  background: $color-dark-gray;
  border-radius: 3px;
  white-space: nowrap;
  margin: 3px;
  padding: 2px 8px 2px 7px;
  .formatTag {
    margin-right: 12px;    
    padding-right: 8px;
  }
  .crossButtonTag {
    cursor: pointer;
  }
}
.moreTagFilter {
  position: relative;
  cursor: pointer;
  width: 55px;
}
