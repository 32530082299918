@import '../../../../styles/variables';
.container{

    .divider{
        margin-top: 30px;
    }
    
    .header{
        font-family: $Roboto-Light;
        font-size: 28px;
        color: $color-black;
        line-height: 34px;
        margin-bottom: 20px;
        margin-top: 30px;
        .eyeIcon{
            display: inline-block;
            padding-left: 7px;
            .tooltipText {
                visibility: hidden;
                width: 105px;
                height: 30px;
                background-color: $color-white;
                font-size: 12px;
                color: $color-gray;
                line-height: 30px;
                text-align: center;
                box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
                border-radius:3px;
                position: absolute;
                margin:-25px 0px 10px 10px;
                z-index: 1;
            }
            &:hover .tooltipText {
                visibility: visible;
            }
            .eyeIconFont{
                height: 25px;
            }
        }
    }

    .adjustmentHeader{
        background: $color-white;
        border: 1px solid $color-dark-orange-4;
        border-left-width: 9px;
        border-radius: 3px;
        margin-bottom: 30px;
        display: flex;
        .iconNoData{
          color: $color-dark-orange-3;
          margin: 11px 8px 9px 9px;
          font-size: 24px;
          line-height: 24px;
        }
        .adjustmentMsg{
          padding: 12px 0px 10px 0px;
          font-family: $Roboto-Regular;
          font-size: 16px;
          color: $color-black;
          line-height: 22px;
          .adjustmentBoldMsg{
            font-weight: bolder;
          }
        }
      }

    .optionsBar{
        background: $color-white;
        box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
        border-radius: 3px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .box{
            .options-label{
                font-family: $Roboto-Regular;
                font-size: 12px;
                color: $color-gray;
                line-height: 16px;
            }
            .options-value{
                font-family: $Roboto-Regular;
                font-size: 20px;
                color: $color-black;
                line-height: 26px;

                .red{
                    color: $color-red
                }

                .green{
                    color: $color-green-light-3;
                }
            }
        }
    }
}

@media (min-width: 596px) and (max-width:1023px) {
    .container {
        .optionsBar {
            align-items: flex-start;
            .box-2 {
                margin-top: 16px;
            }
        }
    }
}

@media (min-width: 375px) and (max-width:595px) {
    .container {
        .optionsBar {
            display: block;
            padding: 4px 25px;
        }

        .box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 11px 0px;

            .options-label {
                font-size: 14px;
            }

            .options-value {
                font-family: $Roboto-Medium !important;
                font-size: 14px !important;
                line-height: 18px !important;
            }
        }
    }
}