@import "../../../styles/variables";
.research {
    background: $color-white-opacity;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    min-height: 100px;
    min-width: 100px;
    padding: 11px 15px 0px 15px;
    .header {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        font-weight: 400;
    }
    .researchContainer {
        position: relative;
        display: block;
        padding-top: 11px;
        padding-bottom: 0.5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.14);

        .researchProvider {
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-gray;
            line-height: 16px;
            font-weight: 400;
        }

        .researchName {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-black;
            line-height: 18px;
            font-weight: 400;
            padding-top: 5px;
        }

        .fotterText {
            font-family: $Roboto-Regular;
            font-size: 14px;
            color: $color-blue;
            line-height: 18px;
            font-weight: 400;
            padding: 6px 0px 15px 0px;
            border: none;
            background: none;
            text-align: left;
            cursor: pointer;
        }
    }
    .researchContainer:last-child {
        border-bottom: none;
    }
    div[data-value="Symbol container"] {
        width: 75%;
    }
    .actionButton {
        padding:0px;
        border:none;
        background-color: transparent;
        color: $color-blue;
        cursor: pointer;
    }
    .editPreferencesButton{
      padding: 15px 0px;
    }
    .viewAllButton{
       float: right;
       padding-top: 5px;
       .viewAllLabel{
        font-size: 14px;
       }
    }
    .viewAllIcon{
        height: 20px;
        width: 20px;
        padding-top: 5px;
    }
}

@media screen and (min-width: 375px) and (max-width: 596px) {
    .research {
        padding: 0px 5px 10px 5px;
        .researchContainer {
            float: left;
            width: 100%;
            .researchProvider {
                text-align: left;
                top: 12px;
            }
            .researchName {
                text-align: left;
                padding-top: 10px;
            }
            .fotterText {
                border: none;
                background: none;
                text-align: left;
                float: left;
            }
        }
        .editPreferencesButtonContainer{
            float: left;
        }
        .researchCaraousal{
            padding: 10px;
        }

        .researchCaraousal > div > div {
            position: relative;
            top: 11px;
        }
        .researchCaraousal > div > div:nth-child(2) {
            position: relative;
            top: 15px;
            margin-right: 15px;
        }
        #researchCarouselHeader {
            span[data-value="customDivider"] {
                font-size: 18px;
                margin-right: -4px;
                padding-left: 8px;
                color: $color-light-gray-2;
            }
        }

        .researchCaraousal div:first-child > span:nth-child(2) {
            margin-right: 15px;
            display: none;
        }
        .researchCaraousal > div > div:first-child {
            padding-bottom: 35px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.14);
        }
        .researchCaraousal > div > div > span:first-child {
            font-family: $Roboto-Regular;
            font-size: 20px;
            color: $color-black;
            line-height: 26px;
            float: left;
            text-align: left;
        }

        .researchCaraousal > div {
            background: $color-white-opacity;
            padding: 0px;
            box-shadow: none;
        }
        .viewAllButton{
            .viewAllIcon{
             position: absolute;
             right: 0%;
             top: -10%;
            }
         }
    }
}

@media screen and (min-width: 596px) and (max-width: 1024px) {
    .researchContainer {
        .researchProvider {
            top: 18px;
        }
        .researchName {
            margin-top: 8px;
        }
        .researchTime {
            display: inline;
            left: 0px;
            position: static;
        }
    }
}
