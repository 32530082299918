@import '../../../../styles/variables';

.watchlistDividendTableContainer {
    overflow-y: auto;
    border: 1px solid $color-box-shadow;

    table {
        width: 100%;
        white-space: nowrap;
        margin: 0;
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        table-layout: auto;
        background: $color-white;
    }

    td,
    th {
        font-weight: inherit !important;
        color: $color-black;
        background: $color-white;
        border-bottom: 1px solid $color-netxwealth-news-research-grey-border;

    }

    td {
        border-bottom: 1px solid $color-white-whisper;
    }

    .watchlistNoDataTable {
        tbody {
            tr {
                td {
                    padding: 0px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                }
            }
        }
    }

    .watchlistDividendTable {
        thead {
            tr {
                height: 44px;
                background-color: $color-white-opacity;

                .optionHeadCol{
                    width:45px !important;
                }

                th {
                    font-family: $Roboto-Medium;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    padding: 13px 10px;
                    min-width: 90px;
                    text-align: right;
                    background-color: $color-white;

                    .seprator {
                        border-left: 1px solid $color-light-gray;

                        .hideArrow {
                            visibility: hidden;
                            border: none;
                            background: none;
                            padding: 0 6px;
                        }
                        &:hover>.hideArrow {
                            visibility: visible;
                        }

                        .downWardUpwardArrow {
                            border: none;
                            background: none;
                            padding: 0 6px;
                        }

                       
                    }

                }

                th:first-child {
                    border-right: 1px solid $color-white;
                    padding-left: 10px;
                    div {
                        height: 20px;
                        width: 1px;
                        margin:auto;
                        border-right: 1px solid $color-light-gray;
                        background-color: $color-white;
                    }
                }

                th:nth-child(2) {
                    text-align: left;
                }

                th:nth-child(3) {
                    padding-left: 12px;
                    min-width: 310px;
                    max-width: 320px;
                    text-align: left;
                }

                th:last-child {
                    div {
                        text-align: right;
                        padding-left: 0px;
                    }

                }
            }
        }

        tbody {
            tr {
                background-color: $color-white;

                td {
                    min-width: 90px;
                    padding: 13px 10px;
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    text-align: right;
                    background-color: $color-white;
                }

                td > div[data-val="symbolCol"] {
                    padding-left: 30px;
                }

                td:first-child {
                    text-align: left;
                    padding-left: 20px;
                    padding-right: 10px;
                }

                td:nth-child(2) {
                    text-align: left;
                    font-family: $Roboto-Medium;
                    color: $color-blue;

                    >span {
                        padding-left: 30px;
                    }
                }

                td:nth-child(3) {
                    text-align: left;

                    span {
                        padding-left: 30px;
                    }
                }

                td:last-child {
                    padding-right: 11px;
                }

                td > div[data-val="rating"] {
                    text-align: left;
                    display: flex;

                    div[data-watchlist-rating="HOLD"]{
                        color: $color-black;
                        width: 36px;
                    }
                    div[data-watchlist-rating="NEUTRAL"]{
                        color: $color-dark-orange;
                        width: 36px;
                    }
                    div[data-watchlist-rating="SELL"]{
                        color: $color-red;
                        width: 36px;
                    }
                    div[data-watchlist-rating="UNDERPERFORM"]{
                        color: $color-red;
                        width: 36px;
                    }
                    div[data-watchlist-rating="BUY"]{
                        color: $color-green;
                        width: 36px;
                    }
                    div[data-watchlist-rating="OUTPERFORM"]{
                        color: $color-green;
                        width: 36px;
                    }
                }
            }
        }

        .grey{
            color: $color-dark-gray !important;
        }

        .green {
            color: $color-green;
        }

        .red {
            color: $color-red
        }

        .align {
            text-align: left;
            padding-left: 43px;
            padding-right: 40px !important ;
            padding-top: 17px;

            .report {
                margin-left: 20px;
                button {
                    border: none;
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    font-size: 18px;
                }

                .errorMessage{
                    color: $color-red-text;
                }
            }
            .noReport{
                visibility: hidden;
            }
        }

    }

    .watchlistSplitTable {
        thead {
            tr {
                height: 44px;
                background-color: $color-white-opacity;

                th {
                    font-family: $Roboto-Medium;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    padding-top: 13px;
                    min-width: 90px;
                    padding-bottom: 13px;
                    text-align: right;
                    background-color: $color-white-opacity;
                }

                th:first-child {
                    padding-left: 12px;
                    text-align: left;
                }

                th:nth-child(2) {
                    text-align: left;
                    padding-left: 12px;
                }

                th:last-child {
                    padding-right: 11px;
                    text-align: right;
                }

                th:nth-last-child(2) {
                    width: 70%;
                }
            }
        }

        tbody {
            tr {
                background-color: $color-white;

                td {
                    padding-top: 13px;
                    min-width: 90px;
                    padding-bottom: 13px;
                    font-family: $Roboto-Regular;
                    font-size: 14px;
                    color: $color-black;
                    line-height: 18px;
                    text-align: right;
                    background-color: $color-white;
                }

                td:first-child {
                    padding-left: 12px;
                    font-family: $Roboto-Medium;
                    color: $color-blue;
                    white-space: initial;
                    min-width: 275px;
                    max-width: 310px;
                    text-align: left;
                }

                th:nth-child(2) {
                    text-align: left;
                }

                td:last-child {
                    padding-right: 11px;
                }
            }
        }

        .grey{
            color: $color-dark-gray;
        }

        .green {
            color: $color-green;
        }

        .red {
            color: $color-red;
        }
    }
}

.maxWidth {
    width: 100%;
}


@media(min-width: 375px) and (max-width:1023px) {
    .watchlistDividendTableContainer {
        .watchlistDividendTable {
            thead {
                tr {
                    th:first-child {
                        position: sticky;
                        left: 0;
                        border-right: 1px solid $color-light-gray-4;
                        box-shadow: 0px 1px 5px $color-light-gray-4;
                        clip-path: inset(0px -15px 0px 0px);
                        min-width: 108px;
                        text-align: left;
                    }
                    .headcol {
                        padding-left: 10px;
                        .actionContainer {
                            display: flex;
                            padding-left: 10px;
                        }
                    }

                    th:nth-child(2) {
                        text-align: left;
                        >div{
                            border-left: none;
                        }
                        
                    }

                    th:last-child {
                        padding-right: 10px;
                    }
                }
            }

            tbody {
                tr {
                    td:first-child {
                        position: absolute;
                        left: 0;
                        border-right: 1px solid $color-light-gray-4;
                        box-shadow: 0px 1px 3px $color-light-gray-4;
                        white-space: initial;
                        min-width: 109px;
                        max-width: 95px;
                    }

                    td:last-child {
                        padding-right: 10px;
                        text-align: right;
                    }

                    td:nth-last-child(2) {
                        text-align: right;
                    }
                }
            }
        }

        .watchlistSplitTable {
            thead {
                tr {
                    th:last-child {
                        padding-right: 10px;
                        text-align: right;
                    }

                    th:nth-last-child(2) {
                        text-align: right;
                    }
                }
            }

            tbody {
                tr {
                    td:first-child {
                        background-image: linear-gradient(90deg, #949494 100%, #949494 100%), linear-gradient(to right, #191919 15%, #191919 15%), linear-gradient(to right, #000000 0%, #000000 0%);
                        white-space: initial;
                        min-width: 275px;
                        max-width: 275px;
                    }

                    td:last-child {
                        padding-right: 10px;
                        text-align: right;
                    }

                    td:nth-last-child(2) {
                        text-align: right;
                    }
                }
            }
        }
    }
}
