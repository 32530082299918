@import '../../../../styles/variables';

.modalConatainer {
    font-family: $Roboto-Light;
    width: 100%;
    height: 100%;
    z-index: 100000;
    top: 0;
    left: 0;
    position: fixed;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background: $color-table-header-bottom-border;

    .Management {
        width: 419px;
        max-height: 396px;

        .mainModal {
            padding: 20px;
            min-height: 348px;
        }

    }
    .InvestmentStrategy {
        width: 419px;
        min-height: 286px;

        .mainModal {
            padding: 20px;
        }

    }

    .modalPopUp {
        z-index: 999;
        border-radius: 3px;
        background: $color-white;
        box-shadow: -1px 2px 6px $color-box-shadow, 1px 2px 6px $color-box-shadow;
        height: auto;
        top: 0%;
        padding: 0px;

        .headerContainer {
            background: $color-dark-gray;
            height: 48px;
            font-family: $Roboto-Regular
        }

        .header {
            padding-left: 20px;
            width: 90%;
        }

        .headerText {
            color: $color-white;
            float: left;
            line-height: 26px;
            font-size: 20px;
            margin: 12px 0 10px 0px;
        }

        .closeSection {
            button:focus-visible {
                outline: 1px solid $color-divider-gray;
            }
        }

        .btnPopUpClose {
            border: none;
            background-color: rgba(255, 255, 255, 0);
            outline: none;
            color: $color-white;
            float: right;
            background-repeat: no-repeat;
            margin: 7.5px 9.5px 7.5px 9.5px;
            cursor: pointer;
            font-size: 18px;
            text-align: center;
            line-height: 18px;
            padding: 0px;
        }

        .mainModal {
            width: auto;
        }
    }

}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .modalConatainer {

        .Management {
            width: calc(100% - 30px);
            min-height: 232px;

            .mainModal {
                min-height: 342px;
            }

        }
        .InvestmentStrategy {
            width: calc(100% - 30px);
            max-height: 286px;
    
            .mainModal {
                padding: 20px;
            }
    
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    .modalConatainer {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .Management,.InvestmentStrategy {
            height: inherit;
            width: inherit;
            max-height: 100%;

            .mainModal {
                padding: 20px 10px;
            }
        }

        .modalPopUp {
            height: 100%;

            .headerContainer {
                border-bottom: 1px solid $color-table-header-bottom-border;
                background: $color-white;
                height: 44px;
            }

            .headerText {
                color: $color-black;
            }

            svg[data-icon="times"] {
                &[data-icon-style="fa-compare-close"] {
                    width: 15px;
                    height: 24px;
                    color: $color-black;
                }
            }
        }
    }
}