@import '../../../../../styles/variables';

.watchlistInsightsMaster {
    background: $color-white;
    margin-top: 30px;
    padding: 15px 15px 10px 15px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
    border-radius: 3px;

    .watchlistInsightsContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .watchlistInsightsLeftSection {
            width: 70%;
            .insightsMindEvents {
                margin-right: 10px;
            }
        }

        .watchlistInsightsRightSection {
            width: 30%;
        }
    }
}

@media screen and (max-width: 1023px) {
    .watchlistInsightsMaster {
        .watchlistInsightsContainer {
            flex-direction: column;

            .watchlistInsightsLeftSection {
                width: 100%;
                .insightsMindEvents {
                    margin-right: 0px;
                }
            }

            .watchlistInsightsRightSection {
                width: 100%;
            }
        }
    }
}