@import "../../../styles/variables";

.checkBoxContainer {
  .checkboxLabel {
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0px !important;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked {
        ~ {
          .checkmark {
            border: 0px solid;
            background-color: #48b748;
            &:after {
              display: block;
            }
          }
        }
      }
    }    
    .checkmark {
      &:after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .checkmark {
    position: absolute;
    // top: 7px;
    left: 5px;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 1px solid black;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  .disabled {
    position: absolute;
    // top: 7px;
    left: 5px;
    height: 16px;
    width: 16px;
    background-color: #E5E5E5;
    border: 1px solid #B2B2B2;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  .name {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 2px;
    left: 10px;
    box-sizing: border-box;
    position: relative;
    line-height: 18px;
    width: 80%;
    font-size: 14px;
    color: $color-black;
  }
  &.hide {
    display: none;
  }
  &.selected {
    background: #e3edf5;
  }
  .chkLabel {
    display: block;
    width:1px;
    height: 1px;
    overflow: hidden;
  }
}
