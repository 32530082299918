@import '../../../styles/variables';

.filterTagContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $color-white;
  box-shadow: 0 1px 3px 1px rgba(0,0,0,0.10);
  border-radius: 3px;
  min-width: 100px;
  max-width: 200px;
  padding: 5px 10px;
  line-height: 18px;
  border-top: 4px solid $color-light-gray;

  .header {
    font-family: $Roboto-Medium;
    font-size: 12px;
    color: $color-gray;
    line-height: 16px;
    padding-right: 10px;
  }

  .closeButton {
    font-family: $Roboto-Regular;
    font-size: 14px;
    text-align: right;
    display: inline-flex;
    cursor: pointer;
  }
}
