@import "../../../styles/_variables.scss";
.stocks-main-container-etf{
  background-color: $color-white !important;
  margin-bottom: 20px;
}

.stocks-main-container {
  font-family: $Roboto-Regular;
  background: $color-white-opacity;
  .font-20 {
    font-size: 20px;
    line-height: 26px;
  }
  .upper_container {
    .row-1 {
      padding-top: 30px;
      .main-share-name {
        font-size: 20px;
        color: $color-black;
      }
      .ai-main-share-name {
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        margin-right: 11px !important;
      }
      .main-share-name-etf {
        line-height: 26px;
      } 
      .main-share-code {
        font-size: 16px;
        font-family: $Roboto-Light;
        color: $color-gray;
      }
      .ai-main-share-code {
        font-size: 16px;
        font-family: $Roboto-Light;
        color: $color-gray; 
        line-height: 22px;
        font-weight: 300;
      }  
      .main-share-code-etf {
        line-height: 22px;
      }   
      .eye-icon {
        display: inline-block;
        .tooltip-text {
          visibility: hidden;
          width: 105px;
          height: 30px;
          background-color: white;
          font-size: 12px;
          color: $color-gray;
          line-height: 30px;
          text-align: center;
          box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
          border-radius:3px;
          position: absolute;
          margin:-25px 0px 10px 10px;
          z-index: 1;
        }
        &:hover .tooltip-text {
          visibility: visible;
        }
      }
      span {
        margin-right: 5px;
        &:last-child {
          margin-right: 0px;
        }
        &:nth-child(2) {
          color: $color-gray;
        }
      }
      .toggle-section {
        float: right;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
      }
      .realtime{
        float: right;
        font-family: $Roboto-Regular;
        font-size: 12px;
        color: $color-gray;
        line-height: 16px;
        font-weight: 400;
      }
    }
    .row-2-ETF {
      padding:4px 0 15px !important
    }
    .row-2 {
      display: inline-block;
      font-size: 12px;
      color: $color-gray;
      line-height: 16px;
      width: 100%;
      padding: 5px 0 20px;
      .domain-info {
        float: left;
      }
      .ETF{
        display: inline-block;
        .pill{          
          width: 91px;
          height: 22px;
          color: $color-gray;
          background: $color-white;
          border: 1px solid rgba(0, 0, 0, 0.3);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 6px;
          margin-top: 4px;
          text-align: left;
          font-size: 12px;
          line-height: 16px;
          padding: 3px 8px;                
      }
      .multiAsset{
        margin-left: 10px;
          font-size: 12px;
          line-height: 16px;
          color: $color-gray;
      }
      .ETFMargin{
        margin-left: 0px;
      }
    }
      .delayed-quote-info {
        float: right;
      }
      .ai-date-info {
        display: inline-block;
        margin-right: 10px;
        color: $color-black;
      }
      .date-info {
        display: inline-block;
        margin-right: 10px;
        color: $color-gray;
      }
      .refresh-icon {
        display: inline-block;
      }
    }
  }
  .stock-quote-bar-container-etf {
    background: $color-white-opacity !important;
    padding: 20px 21px 25px 20px !important;
  }
  .stock-quote-bar-container-ai {
    background: $color-white-opacity !important;
    padding: 20px !important;
  }
  .stock-quote-bar-container-ai-hidden {
    display: none !important;
  }
  .stock-quote-bar-container {
    height: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-flow: wrap;
    background: $color-white;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 20px;
    .box {
      .stock-name {
        font-size: 14px;
        color: $color-gray;
        line-height: 16px;
        padding-bottom: 10px;
      }
      .stock-name-etf{
        font-size: 12px !important;
        padding-bottom: 5px !important;
      }
      .stock-name-ai{
        font-size: 12px !important;
        padding-bottom: 9px !important;
      }
      .volume-section {
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
      }
      .stock-pricing {
        .pricing-value {
          font-size: 20px;
          color: $color-black;
          line-height: 26px;
        }
        .pricing-factor {
          font-size: 14px;
          color: $color-gray;
          line-height: 18px;
          padding: 10px 0 10px;
        }
      }
      .stock-pricing-value {
        .pricing-value {
          font-size: 20px;
          color: $color-black;
          line-height: 26px;
        }
        .pricing-factor {
          font-size: 14px;
          color: $color-gray;
          line-height: 18px;
          padding: 10px 0 10px;
        }
      }
      .progress-bar-etf {
        height: 20px;
      }
      .progress-bar {
        .slider {
          -webkit-appearance: none;
          width: 100%;
          height: 2px;
          outline: none;
          background: $color-very-light-gray;
        }
        .slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 6px;
          height: 6px;
          background: $color-bar-graph-blue-dark !important;
          border-radius: 3px;
        }

        .slider::-moz-range-thumb {
          width: 6px;
          height: 6px;
          background: $color-bar-graph-blue-dark !important;
          border-radius: 3px;
        }
      }
    }
    .ai-val {
      font-size: 20px;
      line-height: 26px;
      color: $color-black;
    }
    .ai-val-percent-leased {
      font-size: 20px;
      line-height: 26px;
      color: $color-black;
    }
    .life-stage-box {
      padding-top: 16px;
    }
    .real-state {
      width: 64px;
    }
    .real-state-val {
      width: 108px;
    }
    .inv-section {
      width: 72px;
    }
    .leased-section {
      width: 48px;
    }
    .weightage-section {
      width: 103px;
    }
    .current-annual-section {
      width: 95px;
    }

    .last-trade-info {
      position: absolute;
      font-size: 12px;
      color: $color-netxwealth-news-research-grey-border;
      line-height: 16px;
      bottom: 20px;
    }
    .last-trade-info-etf{
      color: $color-gray !important;
      bottom: 5px !important;
    }
    .negative {
      color: $color-red;
    }
    .positive {
      color: $color-green;
    }
    .last-Header {
      border: 3px solid blue($color: $color-black);
    }
  }
}
@media (min-width: 596px) and (max-width: 1023px), only screen and (max-device-width:1023px) {
  .stocks-main-container {
    padding: 0px;
    margin: 0px;
    .stock-quote-bar-container-ai {
      background: $color-white-opacity !important;
      padding: 20px 15px 5px 20px !important;
    }
    .upper_container {
      .row-2 {
        padding-bottom: 15px !important;
        .domain-info {
          width: 100%;
        }
        .delayed-quote-info {
          padding-top: 20px;
          width: 100%;
        }
        .realtime{
          display: inline-block;
          margin-left: 30px;
        }
      }
    }
    .stock-quote-bar-container-etf{
      padding: 20px 20px 22px 20px !important;
    }
    .stock-quote-bar-container {
      .ipad-display {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &:first-child {
          padding-bottom: 10px;
        }
        .display-content {
          .box {
            &:first-child {
              padding-bottom: 20px;
            }
          }
          .boxETF{
            &:first-child{
              padding-bottom: 25px !important;
            }
          }
        }
      }
      .box {
        .stock-pricing {
          .pricing-value {
            font-size: 20px;
            line-height: 26px;
          }
        }
        .progress-bar {
          position: relative;
          bottom: 5px;
        }
        .stock-name-ai{
          font-size: 12px !important;
          padding-bottom: 5px !important;
        }
      }
      .ai-val-percent-leased {
        padding-top: 16px;
        font-size: 20px;
        line-height: 26px;
        color: $color-black;
      }
      .real-state {
        width: 100%;
      }
      .inv-section {
        width: 100%;
      }
      .leased-section {
        width: 100%;
      }
      .life-stage-box {
        padding-top: 0px;
      }
      .last-trade-info {
        position: absolute;
        bottom: 20px;
      }
      .last-trade-info-etf {
        position: absolute;
        bottom: 16px !important;
      }
    }
  }
}
@media (min-width: 375px) and (max-width: 595px), only screen and (max-device-width: 595px) {
  .stocks-main-container {
    padding: 0px;
    margin: 0px;
    .stock-quote-bar-container-ai {
      background: $color-white-opacity !important;
      padding: 15px 15px 10px 15px !important;
    }
    .upper_container {
      .row-2 {
        padding-bottom: 15px !important;
        .domain-info {
          float: left;
        }
        .delayed-quote-info {
          float: left;
          padding-top: 20px;
        }
        .date-info {
          float: left;
        }
        .ai-date-info {
          float: left;
          color: $color-gray;
        .realtime{
          display:block;
          margin-top: 14px;
        }
      }
    }
  }
  .stock-quote-bar-container-etf {
    padding: 15px 15px 30px 15px !important;
  }
  .stock-quote-bar-container {
    padding: 10px 20px 20px 20px;
    .mobile-display {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center !important;
    }
    .spacing {
      width: 100%;
      border: 0;
      margin-top: 0em;
      margin-bottom: 0em;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .box {
      width: 100%;
      .font-bold {
        font-size: 14px;
        color: $color-black;
        line-height: 18px;
        font-weight: bold;
      }
      .stock-name {
        margin-top: 9px;
        font-size: 12px;
      }
      .stock-name-etf{
        font-size: 14px !important;
        line-height: 18px !important;
        padding-bottom: 8px !important;
      }
      .stock-name-ai{
        font-size: 14px !important;
        line-height: 18px;
        padding-bottom: 5px !important;
        font-weight: 400;
      }
      .firstbox{
        margin-top:0;
        font-size: 12px!important;
        line-height: 16px !important;
        padding-bottom: 5px !important;
      }
    
      .volume-section {
        margin-top: -26px;
        float: right;
      }
      .stock-pricing-value {
        padding-bottom: 9px;
        .pricing-value {
          font-size: 14px;
          line-height: 18px;
          font-weight: bold;
        }
        .pricing-factor {
          font-size: 14px;
          color: $color-gray;
          line-height: 18px;
          font-weight: normal;
        }
      }
      .stock-pricing-value-first{
        padding-bottom: 16px !important;
      }
      .stock-pricing {
        margin-top: -31px;
        float: right;
        .pricing-value {
          font-size: 14px;
          line-height: 18px;
          font-weight: bold;
        }
      }
    }
    .real-state {
      width: 100%;
    }
    .ai-val-percent-leased {
      padding-top: 0px;
      font-size: 20px;
      line-height: 26px;
      color: $color-black;
    }
    .real-state-section {
      height: 63px;
    }
    .life-stage-box {
      padding-top: 0px;
    }
    .last-trade-info {
      position: relative;
      bottom: 0px;
    }
    .last-trade-info-etf {
      position: relative;
      top: 15px !important;
    }
    .right-align-ai {
      font-size: 14px;
      line-height: 18px;
      font-family: $Roboto-Bold;
      font-weight: 500 !important;
    }
  }
}
}