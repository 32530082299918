.container {
    padding: 20px;
    background-color: white;
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
    .container {
        padding: 15px;
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    .container {
        padding: 10px;
    }
}