@import "../../../styles/variables";
 .radioButtonContainer {
  overflow: hidden;  
  .radio {
    float: left;
    clear: none;
    input[type="radio"] {
      appearance: none;
      width: 16px;
      height: 16px;
      border: 1px solid $color-black;
      border-radius: 50%;
      background-clip: content-box;
      padding: 2px;
      float: left;
      clear: none;
      margin: 5px 0 0 2px;
    }

    input[type="radio"]:checked {
      background-color: $color-green-light;
      border: 1px solid $color-green-light;
    }
    .radiolabel {
      float: left;
      clear: none;
      display: block;
      padding: 3px 1em 0px 8px;
      color: $color-black;
      margin: 0;
      font-size: 14px;
    }
  }  
}
.screenerFilterRadio{
  padding-left: 15px;
}
