@import "../../../../../../styles/variables";

div[id="modalPopupHeader"] {
    background: $color-white-opacity;
    border-bottom: none;
    z-index: 0;
}
div[id="modalPopUpContentContainer"] {
    div[id="basicChartTools"] {
        background: $color-white-opacity;
        padding-bottom: 15px;
        margin: 0 -10px 0 -10px;
        padding-left: 10px;
        div[id="Popover_Parent_Container"] {
            z-index: 2;
            top: auto !important;
            left: auto !important;
            > div {
              position: inherit !important;
              top: auto !important;
              left: auto !important;
              transform: none !important;
            }
        }
        div[data-custom-select="custom-select-menu"] {
          button[data-testid='button_CustomSelectMenu_Indicators'] {
            background: $color-white;
          }
          button[data-testid='button_CustomSelectMenu_Events'] {
            background: $color-white;
          }
          button[data-testid='button_CustomSelectMenu_1 Year'] {
            background: $color-white;
          }
        }
        div[data-custom-select="custom-chart-menu"] {
          li {
            background: $color-white;
          }
        }
    }
    div[id="priceChart"] {
        height: 300px;
        width: 100%;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
        .modcharts-panel {
          .modcharts-legend{
            z-index: 1;
          }
        }
        div[class="modcharts-panel"] {
          canvas{
            padding-right: 10px;
          }
        }
      }
}
.viewChartsContainer {
    position: relative;  
    width: 100%;
    padding: 0 10px 10px 10px;
    display: block;
    min-height: 450px;
    box-sizing: border-box;
    white-space: normal !important;
}

@media screen and (max-width: 1024px) {
  div[id="modalPopUpContentContainer"] {
    div[id="priceChart"] {
        overflow-y: scroll;
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: #888;
    }
  }
}

@media screen and (min-width: 596px) and (max-width: 1024px) {
  div[id="modalPopUpContentContainer"] {
    div[id="priceChart"] {
      > div[class="modcharts-rootmouse"] {
        width: 550px !important;
      }
    }
  }
}

@media screen and (max-width: 596px) {
  div[id="modalPopUpContentContainer"] {
    div[id="basicChartTools"] {
      padding-right: 10px;
      div[id="Popover_Parent_Container"] {
        height: 100%;
        div[id="CustomSelectMenu_Events"] {
          div[data-custom-event-container="custom-event-select-container"] {
            width: 169px;
          }
        }
        div[id="CustomSelectMenu_Indicators"] {
          > div {
            height: 100%;
            > div {
              width: 355px;
            }
          }
          footer {
            position: relative;
          }
        }
      }
      
    }
    div[id="priceChart"] {
      overflow-y: scroll;
      max-height: 450px;
      height: 450px;
      > div[class="modcharts-rootmouse"] {
        width: 350px !important;
      }
    }
  }
  .viewChartsContainer {
    height: 600px;
  }
}