@import '../../../styles/variables';

.outerModalPopUp {
    width: 100%;
    height: 100%;
    z-index: 99999;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.30);

    .modalPopUp {
        z-index: 999;
        border-radius: 3px;
        background: #FFFFFF;
        box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);
        width: 632px;
        max-height: 505px;
        min-height: 325px;
        top: 0%;
        padding: 0px;
        .modelHeader{
            font-family: $Roboto-Medium;
            text-align: left;
            float: left;
            font-size: 16px;
            color: $color-black;
            margin-top: 0px;
            line-height: 45px;
            padding-left: 36px;
            margin-left: -20PX;
            width: 100%;
        }
        hr{
            border-bottom: 1px solid rgba(0,0,0,0.30);
            // margin-left: -20px;
            margin-top: 42px;
            width: 100%; 
        }
        .modalHeaderCloseIcon{
            display: flex;
            color: rgba(255, 255, 255, 0);
        }

        .modalCloseIcon {
            text-align: right;
            position: relative;
            z-index: 999;
            float: right;
            height: 40px;
            width: 100%;

            .btnPopUpClose {
                border: none;
                background-color: rgba(255, 255, 255, 0);
                outline: none;
                color: $color-black;
                background-repeat: no-repeat;
                margin-top: 5px;
                margin-right: 5px;
                cursor: pointer;
                font-size: 18px;                
                text-align: center;
                line-height: 18px;
            }
        }

        .modalPopUpContentContainer {
            float: left;
            top: 0;
            max-height: 455px;
            width: 100%;
            word-wrap: break-word;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0 20px 20px 20px;
            display: table-cell;
            word-break: break-all;
            overflow-y: auto;
        }
    }
}

@media(min-width: 596px) and (max-width:1024px) {
    .outerModalPopUp {
        .modalPopUp {
            width: 566px;
            max-height: 552px;
            min-height: 375px;
        }
    }
}

@media (max-width:596px) {
    .hideBodySroll {
        overflow: hidden!important;
    }
    .outerModalPopUp {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 47px;
        left: 0;
        right: 0;
        bottom: 0;

        .modalPopUp {
            width: inherit;
        }
    }
}