@import "../../../styles/variables";

.ESGOverallScoreContainer {
    width: 100%;
    background: $color-white-opacity;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    .headerLabel {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        margin: 15px;
    }

    .headerLabel2 {
        font-family: $Roboto-Regular;
        color: $color-netxwealth-news-research-grey-border;
        padding-left: 15px;
        padding-top: 15px;

        .label2 {
            font-size: 12px;
            line-height: 16px;
        }
    }

    .bodyPart {
        display: flex;
        flex-direction: column;

        .upperSection {

            .categoryLabels {
                display: flex;
                justify-content: flex-start;
                margin-left: 17px;
                align-items: center;

                .chartLabels {
                    font-family: $Roboto-Regular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: $color-gray;
                    margin-right: 20px;
                    margin-bottom: 2px;

                    .icon {
                        width: 8px;
                        height: 8px;
                        margin-right: 5px;
                        margin-bottom: 2.5px;
                    }
                }
            }

            .chartContainer {
                width: 100%;
                height: 225px;

                div {
                    height: 100%;
                }

                svg[class="ct-chart-bar"] {
                    g[class="ct-grids"] {
                        line[class="ct-grid ct-vertical"] {
                            stroke: rgba(0, 0, 0, 0.15);
                            stroke-dasharray: 0px;
                        }

                        line[class="ct-grid ct-vertical ct-baseLine"] {
                            stroke: $color-divider-gray;
                            stroke-dasharray: 0px;
                        }
                    }

                    g[class="ct-labels"] {
                        foreignObject {
                            span[class="ct-label ct-horizontal ct-end"] {
                                font-family: $Roboto-Bold;
                                font-size: 12px;
                                color: $color-black;
                                text-align: center;
                                line-height: 16px;
                            }

                            span[class="ct-label ct-vertical ct-end"] {
                                font-family: $Roboto-Regular;
                                font-size: 12px;
                                color: $color-black;
                                text-align: right;
                                line-height: 16px;
                                margin-top: 5px;
                                justify-content: flex-end;
                            }
                        }
                    }

                    g[class="ct-series ct-series-a"] {
                        line[class="ct-bar"] {
                            stroke: $color-bar-graph-blue-dark;
                            stroke-width: 25px;
                        }
                    }

                    g[class="ct-series ct-series-b"] {
                        line[class="ct-bar"] {
                            stroke: $color-dividend-performance-area-blue;
                            stroke-width: 25px;
                        }
                    }
                }
            }

        }

        .tableSection {
            padding: 0 15px 6px 15px;

            .lineBorder {
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
            }

            .tableRow {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 8px 0 9px 0;

                .rowLeftItem {
                    font-family: $Roboto-Regular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: $color-gray;
                    padding-left: 10px;
                }

                .rowrigtItem {
                    font-family: $Roboto-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: $color-black;
                    text-align: right;
                    line-height: 18px;
                    padding-right: 10px;
                }
            }
        }
    }
}

.NXIESGOverallScoreContainer {
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    .headerLabel {
        font-family: $Roboto-Regular;
        font-size: 20px;
        color: $color-black;
        line-height: 26px;
        margin: 15px;
        border-bottom: 1px solid $color-light-gray-5;
        padding-bottom: 10px;
    }

    .subHeaderLabel {
        font-family: $Roboto-Regular;
        color: $color-black-solid;
        padding-left: 15px;
        padding-top: 5px;
        font-size: 14px;
        line-height: 16px;

        .label2 {
            font-size: 18px;
            line-height: 18px;
            font-family: $Roboto-Bold;
        }
    }

    .headerLabel2 {
        font-family: $Roboto-Regular;
        color: $color-black-solid;
        padding-left: 15px;

        .label2 {
            font-size: 12px;
            line-height: 16px;
        }
    }

    .bodyPart {
        display: flex;
        flex-direction: column;

        .upperSection {


            .chartContainer {
                width: 100%;
                height: 225px;

                div {
                    height: 100%;
                }

                svg[class="ct-chart-bar"] {
                    g[class="ct-grids"] {
                        line[class="ct-grid ct-vertical"] {
                            stroke: rgba(0, 0, 0, 0.15);
                            stroke-dasharray: 0px;
                        }

                        line[class="ct-grid ct-vertical ct-baseLine"] {
                            stroke: $color-divider-gray;
                            stroke-dasharray: 0px;
                        }
                    }

                    g[class="ct-labels"] {
                        foreignObject {
                            span[class="ct-label ct-horizontal ct-end"] {
                                font-family: $Roboto-Regular;
                                font-size: 10px;
                                color: $color-black-solid;
                                text-align: center;
                                line-height: 16px;
                            }

                            span[class="ct-label ct-vertical ct-end"] {
                                font-family: $Roboto-Regular;
                                font-size: 10px;
                                color: $color-black-solid;
                                text-align: right;
                                line-height: 16px;
                                margin-top: 5px;
                                justify-content: flex-end;
                            }
                        }
                    }

                    g[class="ct-series ct-series-a"] {
                        line[class="ct-bar"] {
                            stroke: $color-bar-graph-blue-dark;
                            stroke-width: 25px;
                        }
                    }

                    g[class="ct-series ct-series-b"] {
                        line[class="ct-bar"] {
                            stroke: $color-light-gray-6;
                            stroke-width: 25px;
                        }
                    }
                }
            }

        }

        .tableSection {
            padding: 0 15px 6px 15px;

            .lineBorder {
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
            }

            .tableRow {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 8px 0 9px 0;

                .rowLeftItem {
                    font-family: $Roboto-Regular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: $color-gray;
                }

                .rowrigtItem {
                    font-family: $Roboto-Regular;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: $color-black;
                    text-align: right;
                    line-height: 16px;
                    padding-right: 10px;
                }
            }
        }

        .lowerSection {
            font-size: 12px;
            line-height: 18px;
            color: $color-black-solid;
            margin-left: 17px;
            margin-top: 10px;
            padding-bottom: 20px;

            .categoryLabels {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 2px;

                .chartLabels {
                    font-family: $Roboto-Regular;
                    font-style: normal;
                    font-weight: 400;
                    margin-right: 20px;
                    margin-bottom: 2px;
                    display: flex;

                    .circle {
                        width: 10px;
                        height: 10px;
                        border-radius: 25%;
                        margin-right: 5px;
                        align-self: center;
                    }

                    .icon {
                        width: 8px;
                        height: 8px;
                        margin-right: 5px;
                        margin-bottom: 2.5px;
                    }
                }
            }
        }
    }
}