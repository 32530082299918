@import "../../../styles/variables";

.watchlistsPopupHeader{
    display: flex;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    position: relative;
    z-index: 999999;
    height: 48px;
    width: 100%;
    background-color: $color-dark-gray;
    border-radius: 3px 3px 0 0;

    .headerText{
        width: 92%;
        margin-left: 20px;
        color: $color-white;
        font-size: 20px;  
        line-height: 26px;
        font-family: $Roboto-Regular;
    }
    .closeIcon {
        border: none;
        background-color: rgba(255, 255, 255, 0);
        color: $color-white;
        outline: none;
        background-repeat: no-repeat;
        margin-right: 5px;
        font-size: 18px;                
        text-align: center;
        line-height: 18px;
        cursor: pointer;
        float:right;
        font-weight: 200;
    }
}

@media screen and (min-width: 375px) and (max-width: 596px){
    .watchlistsPopupHeader{
        background-color: $color-white;
    
        .headerText{
            font-family: $Roboto-Regular;
            font-size: 16px;
            color: $color-black;
            line-height: 22px;
            font-weight: 400;
        }
        .closeIcon {
            font-size: 24px;
            color: $color-black;
            text-align: center;
            line-height: 24px;
            font-weight: 200;
        }
    }
}