@import '../../../../../styles/variables';

div[id='DetailsCrousal'] {
    display: block;
    background: $color-white;
    overflow-x: auto;
    /* Shadows */
    background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
        /* Shadow covers */
        linear-gradient(270deg, rgba(238, 238, 238, 0.00) 1%, #D8D8D8 100%),
        linear-gradient(90deg, rgba(238, 238, 238, 0.00) 1%, #D8D8D8 100%);
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    .scrollableComponent {
        display: flex;
        width: 100%;
        align-items: center;

        .bar {
            min-width: 170px;
            display: contents;

            .symbol {
                display: inline-block;
                font-family: $Roboto-Medium;
                font-size: 12px;
                color: #000000;
                line-height: 16px;
                margin-left: 10px;
                margin-right: 10px;
                white-space: nowrap;
            }

            .perChange {
                display: inline-block;
                font-family: $Roboto-Regular;
                font-size: 14px;
                text-align: right;
                line-height: 18px;
                white-space: nowrap;
                margin-right: 10px;
            }

            .red {
                color: $color-red;
            }

            .green {
                color: $color-green;
            }

            .chart {
                display: inline-block;

                >div {
                    img {
                        width: fit-content;
                    }

                    >div {
                        >div {
                            font-size: 6px;
                            line-height: 6px;
                        }
                    }
                }
            }

            .separator {
                display: inline-block;
                color: rgba(151, 151, 151, 1);
                height: 20px;
                margin: 10px 27px 15px 27px;
            }
        }
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
div[id='DetailsCrousal']::-webkit-scrollbar {
    display: none;
}