
@import "../../../styles/variables";
.headerContainer, .headerContainerModel{
    width: auto;
    height: 76px;
    font-family: $Roboto-Regular;
    font-style: normal;
    display: flex;
   justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 15px 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    flex-direction: row;
    .maxWidthforEllipsis{
        max-width: 130px;
    }
    .datapoint{           
    .heading{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #666666;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; 
        flex:  1; 
    }
    .values{
        padding-top: 4px;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        color: $color-black !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .modelValues {
        padding-top: 4px;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        color: $color-black;
    }
    .title{
        display: none;
    }
    .values:hover .title{
        color: #000000;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;    
        position: absolute;
        background: #FFFFFF;
        border-radius: 3px;
        padding: 5px;
        display: block;
        width: fit-content;
        z-index: 99;
        top: 10px;
        left: 255px;
        box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.1), 1px 2px 6px 2px rgba(0, 0, 0, 0.1);

    }
}
}
.headerContainerMobile{
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;    
    margin-top: 20px;    
    
    .datapointMobile, .modelDatapointMobile{
        width: 100%;
        font-family: $Roboto-Regular;
        font-style: normal;
        padding: 8px 0px 9px 0px;
        
        border-bottom: 1px solid rgba(0,0,0,0.10);
        display: flex;
        justify-content: space-between;       
        .headingMobile{
            font-family: $Roboto-Regular;
            font-size: 12px;
            color: $color-gray;
            line-height: 26px;
            width: 100%;
            display: inline;
            font-weight: 500; 

        }
        .headingMobileModel{
            line-height: 16px;
        }
        .valuesMobile{
            justify-content: flex-end;
            padding-right:10px;
            text-align: right;
            font-family: $Roboto-Medium;
            font-size: 12px;
            color: $color-black;
            line-height: 26px;
            width: 30%;
        }
        .valuesMobileModel{
            font-size: 24px !important;
            line-height: 30px !important;
            font-weight: 300;
            font-family:  $Roboto-Regular;
        }
    }
    .datapointMobile:last-child{
        border-bottom: 0;
    }
    .datapointMobile, .modelDatapointMobile{
        flex-wrap: wrap;
        width: 50%;
        border-bottom: 0;        
        display: inline-block;
        .valuesMobile{
            font-size: 20px;
            line-height: 26px;
            text-align: left;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;            
        }
    }
    // PRW-234 : To handle scenarios for skipping 1 section
    .modelDatapointMobile:nth-child(odd) {
        width: 33%;
    }
    .modelDatapointMobile:nth-child(even) {
        width: 67%;
    }
    .datapointMobilePershing:first-child{
        width: 100%;        
    }
}
@media only screen and (min-width: 1200px){
    .headerContainer{
        display: flex;
        justify-content: space-between;
        .maxWidthforEllipsis{
            max-width: 297px;
        }

    }
}
@media (min-width: 595px) and (max-width:690px) {
    .headerContainer{
       flex-direction: column;
    }
}
@media (min-width: 595px) and (max-width: 1023px), only screen and (max-device-width: 1023px) {
    .headerContainerModel{
        width: auto;
        height: 96px;
        font-family: $Roboto-Regular;
        font-style: normal;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        padding: 15px 20px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        flex-direction: row;
        .datapoint{           
            .heading{
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #666666;
                white-space: inherit;
                overflow: inherit;
                height: 32px;
            }
            .modelValues {
                padding-top: 4px;
                font-style: normal;
                font-weight: 300;
                font-size: 24px;
                line-height: 30px;
                color: $color-black;
                white-space: inherit;
                overflow: inherit;
            }
        }
        .datapoint:nth-child(2), .datapoint:nth-child(3) {
            width: 18%;
        }
        .datapoint:nth-child(1){
            width: 10%;
        }
        .datapoint:nth-child(4){
            .heading{
                width: 40%;
            }
        }
    }
}
