@import '../../../styles/variables';

.timePeriodsContainer {
    width: 120px;
    height: 317px;
    background: $color-white;
    box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
    border-radius: 3px;
    outline: none;
    .timestampDiv{
        width: 90px;
    }
    .custom{
        .calenderDiv{
            width: 273px;
            height: 109px;
            display: inline-block;
            .customCalender{
                display:flex;
            .calendarMaker{
                position: relative;
                margin:10px 10px 0px 10px;
                    > div:first-child {
                        width: 110px;
                        max-width: 300px;
                        height: 100%;
                        margin: 0;
                    }
                    input {
                        width: 100%;
                        height: 34px;
                        box-sizing: border-box;
                        padding: 8px 10px 8px 10px;
                        font-family: $Roboto-Regular;
                        font-size: 14px;
                        color: $color-netxwealth-news-research-grey-border;
                        line-height: 18px;
                        background: $color-white;
                        border: 1px solid rgba(0,0,0,0.30);
                        border-radius: 3px;
                    }
                    input:focus{
                        outline: none;
                        border: 1px solid $color-graph-light-blue1;
                    }
                    svg[data-icon="calendar-alt"][data-icon-style="calendar-alt-black"]{
                        display: none;
                    }
                    div[class="react-datepicker"]{
                        border: none;
                        button[class="react-datepicker__navigation react-datepicker__navigation--previous"]{
                            margin-right: 15px;
                            padding: 7px 0px;
                            svg{
                                color: $color-black;
                                opacity: 0.26;
                            }
                        }
                        button[class="react-datepicker__navigation react-datepicker__navigation--next"]{
                            svg{
                                color: $color-black;
                                opacity: 0.26;
                            }
                        }
                        :global div.react-datepicker__week{
                            width: 310px;
                        }
                        :global div.react-datepicker__day--selected{
                            color: $color-black;
                        }
                        :global div.react-datepicker__day:hover{
                            background-color: #eaf9ff;
                        }
                        :global div.react-datepicker__day:focus{
                            border-color: #00ddff;
                        }
                        :global div.react-datepicker__day:focus:hover{
                            border-color: #00ddff;
                            background-color: #c3ecff;
                        }
                    }
                    span[class="react-datepicker__month-year-read-view--down-arrow"]{
                        top: 0px;
                    }
            }
            .toContainer {
                margin-top: 19px;
                font-family: $Roboto-Regular;
                font-size: 14px;
                color: $color-gray;
                line-height: 18px;
            }
        }
            .line{
                width: 273px;
                height: 1px;
                background: rgba(219,219,219,1);
            }
            .applyButton {
                background: $color-blue;
                color: $color-white;
                margin: 10px 0px 10px 95px;
                justify-content: flex-end;
            }
        }
}
    ul {
        list-style-type: none;
        padding: 7px 0 0 0;
        margin: 0; 
        border-radius: 3px;
        outline: none;

        li {
            width: 100%;
            height: 30px;
            padding: 6px 0 6px 10px;
            box-sizing: border-box;
            cursor: pointer;
            line-height: 18px;
            outline: none;

            ul {
                margin: 0; 
                background: $color-white;
                box-shadow: -1px 2px 6px 2px rgba(0,0,0,0.10), 1px 2px 6px 2px rgba(0,0,0,0.10);
                border-radius: 3px;
                position: relative;
                left: 112px;
                display: none;
                width: 273px;
                top: -32px;
                padding: 7px 0 7px 0;
                outline: none;
            }

            &:hover, &:focus {
                background: #F7F7F7;
                ul {
                    display: inline-block;
                }
            }
        }
    }

    .periodLabel {
        font-family: $Roboto-Regular;
        font-size: 14px;
        color: #000000;
        line-height: 18px;
        width: 71px;
        display: inline-block;

        &:hover {
            font-family: $Roboto-Medium;
        }
    }

    .iconArrowRightContainer {
        width: 39px;
        text-align: center;
        display: inline-block;

        .iconArrowRight {
            width: 14px;
            height: 14px;
        }
    }
}