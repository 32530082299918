@import "../../../../styles/variables";

.saveChartContainer {
    display: flex;
    flex-direction: row;
    div[data-custom-select='custom-select-menu'] {
      > div > div {
        left: 25px !important;
      }
    }
    .saveChartButton {
      background: $color-blue;
      border-radius: 3px;
      font-family: $Roboto-Regular;
      font-size: 14px;
      color: $color-white;
      text-align: center;
      line-height: 18px;
      width: 108px;
      margin-left: 10px;
    }
  }
  .saveChartPopupScreenAlert {
    div[id=outerModalPopUp]>div {
      width: auto;
      min-height: auto !important;
      max-width: 333px !important;
    }

    div[id=modalPopUpContentContainer] {
      height: 145px;
      max-width: 444px !important;
      top: 0px !important;
      padding: 0 !important;
      margin: 0 !important;
      overflow: inherit !important;
    }
  }