@import '../../../../../../styles/variables';

.etfResearchContainer {
  margin: 0 auto;
}
@media (min-width: 375px) and (max-width: 595px) {
  .etfResearchContainer {
    padding: 0 10px;
  }
}

@media (min-width: 596px) and (max-width: 1023px) {
  .etfResearchContainer {
    padding: 0 15px;
  }
}