.maxCompareContainer{
    background: #FFFFFF;
    box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.1), 1px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    //position: absolute;
    height: 145px;
    max-width: 444px;
    //width: 50%;
    width: 444px;
    white-space: normal;
    z-index: 999;
    margin-top: -10%;
        
    .upperdiv{
        margin: auto;
        padding-top: 25px;
        margin-bottom: 28px;
        width: 90%;
        display: flex;
    .icon{
        color: #FFA000;
        
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 24px;
        height: 24px;
        width: 27px;
        display: inline-block;
        svg{
            margin-bottom: 10px !important;
        }
    }
    .maxCompareText{
       padding-left: 10px;
        max-width: 360px;
        width: 90%;
        font-family: "Roboto-Regular", Arial, sans-serif;	
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        display: inline-block;
        
    }
}
    .buttons{
        text-align: center;
        padding: 6px 15px;
        background-color: #0572d6;
        color: #ffffff;
        width: 71px;
        height: 34px;
        cursor: pointer;
        position: relative;
        margin:auto;
        border-radius: 3px;
        font-family: "Roboto-Regular", Arial, sans-serif;	
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        text-decoration: none;
        display: block !important;
        border-style: none;
    }
}
    
    @media (max-width: 595px), only screen and (max-device-width:595px) {
        .maxCompareContainer{
           margin-top: 75%;
           height: 165px;
           width: 100%;
        }
    
      }
