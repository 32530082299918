@import '../../../../../../styles/variables';

.topPerformingCardMain{
    width: 100%;
    .topPerformingAllCard{
        display: flex;
    }
    div[data-container="TopStockCommonTable"] > div{
        padding: 0px 10px 16px 10px;
        > section > div{
            padding: 5px 0px;
            > div {
                font-size: 12px !important;
                line-height: 16px !important;
            }
        }
    }
    .topPerformingCrousal{
        display: block;

        background: $color-white;
        max-width: 980px;
        overflow-x: auto;
        margin-top: 15px;
        padding: 0px;
        /* Shadows */
        background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
            /* Shadow covers */ linear-gradient(to right, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0)),
            linear-gradient(to left, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0));
        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;

        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        .topStockMobileContainer{
            display: flex;
            min-width: 980px;
            >div{
                background: none;
            }
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .topPerformingCrousal::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (min-width: 596px) and (max-width: 1023px) { 
    .topPerformingCardMain{
        .topPerformingCrousal{
            max-width: 1250px;
            padding: 0.5px 1px;
            .topStockMobileContainer{
                min-width: 1250px;
            }
        }
        div[data-container="TopStockCommonTable"] > div > div >div:first-child{
            width: 100%;
        }
        div[data-container="TopStockCommonTable"] > div > div >div:nth-child(2){
            width: 100%;
            display: none;
        }
    }
}