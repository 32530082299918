@import "../../styles/application.scss";
@import "../../styles/variables";

.f2TestCenterContainer {
  width: 100%;
  height: 100%;
  font-family: $Roboto-Regular;
  background-color: white;

  .apps-config-container {
    display: flex;

    >div {
      width: 50%;
      border: 1px solid #00485e;
      height: 350px;
      background-color: #fff
    }
  }

  .top-header {
    background-color: #00485e;
    border-color: #dadada;
    height: 60px;
    border-width: 0 0 1px;
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    padding: 0 15px 0 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 8px solid #fff;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 2px 2px #00000033;
  }

  .header {
    height: 50px;
    border: 1px solid #ddd;
    background-color: #00485e;
    border-top: 3px solid #ddd;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    padding: 0 15px;

    >div:first-child {
      width: 95%;
    }

    button {
      background-color: #00485e;
      border: none;
      cursor: pointer;
      border-radius: 25px;
      padding: 3px 20px;
      margin: 0 5px;
      color: #fff;
    }
  }

  .select-apps {
    padding: 20px;

    >ul {
      margin: 0;
      padding: 10px 0 10px 10px;
      list-style-type: none;
      overflow: auto;
      max-height: 230px;

      >li {
        padding: 4px 10px;
        margin: 5px;
        border-radius: 20px;
        color: #246a81;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px dashed #246a81;

        button {
          width: 100px;
          background-color: transparent;
          color: darkred;
          cursor: pointer;
          height: auto;
          border: none;
        }
      }
    }

    .selected-apps {
      li {
        span {
          width: 80%;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .search-apps {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 20px;
    outline: none;
    padding: 10px;
    line-height: 17px;
    cursor: pointer;
    text-align: left;
    background-color: #00485e;
    color: #fff;
  }

  .search-box {
    width: 400px;
    background: #fff;
    margin-top: 1px;

    .txt-search-app {
      width: 100%;
      border: none;
      outline: none;
      padding: 10px;
    }

    hr {
      margin: 0 0 3px 0;
      padding: 0;
      color: #000 !important;
    }

    >ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      border: 1px solid #00485e;
      max-height: 400px;
      overflow-y: auto;
      position: absolute;
      width: 46%;
      margin-left: 10px;
      background-color: #fff;
      overflow-x: hidden;
      z-index: 999;

      li {
        width: 100%;

        .app-category {
          background-color: #00485e;
          padding: 5px;
          color: #fff;

          ul {
            li {
              span {
                white-space: pre-wrap;
                word-break: break-all;
              }
            }
          }
        }

        >ul {
          padding: 0;
          margin: 0;
          list-style-type: none;
          background-color: #fff;
          padding: 5px 20px;

          >li {
            padding: 5px;
            cursor: pointer;
            color: #00485e;

            &:hover {
              background-color: #00485e;
              color: #ddd;
            }
          }
        }
      }
    }
  }


  .config-container {
    overflow: auto;
    max-height: 295px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      >li {
        border: 1px solid #ddd;
        margin: 10px;
        padding: 0;

        >div {
          background-color: #00485e;
          padding: 4px;
          color: #fff;
        }

        textarea {
          height: 150px;
          width: 100%;
          border: none;
          outline: none;
          padding: 20px;

          &:focus {
            background-color: #46899e;
            color: white;
            font-size: 18px;
          }
        }
      }
    }
  }

  .appView {
    border: 1px solid #ddd;
    margin-top: 20px;

    .appOuterContainer {
      margin: 0 auto;
      justify-content: center;
      padding: 50px 50px 500px 50px;

      .appContainer {
        border: 1px dashed #ddd;
        padding: 20px;
        margin: 20px auto;
        width: 1024px;
        display: flex;
        color: #d7d7d7;
        flex-direction: column;
        border: 1px dashed;
      }
    }

  }



  .btnSelected {
    background-color: #fff !important;
    color: #00485e !important;
  }

  .resolutions {
    position: absolute;
    right: 20px;
  }
}