@import '../../../styles//variables';

.tabs {
	.tab_list {
		background: none;
		color: #F7F7F7;
		display: flex;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		padding-left: 0px;
		margin-bottom: 20px;
		background-image: linear-gradient(to right, white, white),
            linear-gradient(to right, white, white),
            linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0)),
            linear-gradient(to left, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0));
        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
        background-attachment: local, local, scroll, scroll;
	}

	.tab_list_nonsticky {
		@extend .tab_list;		
		position: inherit !important;	 
		border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;  
	}

	.tab_list::-webkit-scrollbar {
		display: none;
	}
	.tab_list_item {
		font-family: $Roboto-Regular;
		font-size: 14px;
		color: #000000;
		text-align: center;
		line-height: 18px;
		padding: 11px 15px 11px 15px;
		cursor: pointer;
		display: block;
		list-style: none;
		white-space: nowrap;
	}
	.tab_list_item::before {
		display: block;
		height: 0;
		overflow: hidden;
		visibility: hidden;
		
	}
	.tab_list_active {
		font-family: $Roboto-Medium;
		font-size: 14px;
		font-weight: 500;
		color: #000000;
		text-align: center;
		line-height: 18px;
		border-bottom: 3px solid #70BBFF;
	}

	.tab {
		font-size: 14;
	}

	@media only screen and (max-width: 767px) and (min-width: 375px) {
		.tab_list {
			padding: 0px;
		}
	}
}