@import "../../../../../styles/variables";

.chartContainer {
    .lineChart {
        position: absolute;
        width: 100%;
        svg[class="ct-chart-line"] {
            g[class="ct-labels"] {
                span[class="ct-label ct-horizontal ct-end"] {
                    display: none !important;
                }

                foreignObject {
                    span[class="ct-label ct-vertical ct-start"] {
                        font-family: $Roboto-Regular;
                        font-size: 12px;
                        color: $color-black;
                        text-align: center;
                        line-height: 16px;
                    }

                    span[class="ct-label ct-horizontal ct-end hideExtraLabels"] {
                        display: none;
                    }
                }
            }
            
        }
    }

    .barChart {
        width: 100%;
        height: inherit;
        div {
            height: 300px;
            position: relative;
            pointer-events: none;
        }

        svg[class="ct-chart-bar"] {
            g[class="ct-grids"] {
                line[class="ct-grid ct-vertical"] {
                    stroke: rgba(0, 0, 0, 0.15);
                    stroke-dasharray: 0px;
                }
                line[class="ct-grid ct-vertical"]:first-of-type{
                    stroke: rgba(0, 0, 0, 0.30);
                }
            }

            g[class='ct-series ct-series-a'] {
                line[class='ct-bar'] {
                    stroke: $color-green;
                    stroke-width: 25px;
                }
            }

            g[class='ct-series ct-series-b'] {
                line[class='ct-bar'] {
                    stroke: $color-red-light;
                    stroke-width: 25px;
                }
            }

            g[class="ct-labels"] {
                span[class="ct-label ct-vertical ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    text-align: right;
                    line-height: 16px;
                }

                span[class="ct-label ct-horizontal ct-end"] {
                    font-family: $Roboto-Regular;
                    font-size: 12px;
                    color: $color-black;
                    text-align: center;
                    line-height: 16px;
                }

                foreignObject {
                    span[class="ct-label ct-vertical ct-start"] {
                        font-family: $Roboto-Regular;
                        font-size: 12px;
                        color: $color-black;
                        text-align: center;
                        line-height: 16px;
                    }

                    span[class="ct-label ct-horizontal ct-end hideExtraLabels"] {
                        display: none;
                    }
                }
            }
        }
    }
    .chartNoData {
        visibility: hidden;
    }
}

@media screen and (min-width: 375px) and (max-width: 595px) {
    svg[class="ct-chart-bar"] {
        g[class='ct-series ct-series-a'] {
            line[class='ct-bar'] {
                stroke-width: 12px !important;
            }
        }

        g[class='ct-series ct-series-b'] {
            line[class='ct-bar'] {
                stroke-width: 12px !important;
            }
        }

    }
}