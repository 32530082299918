@import '../../../styles/variables';
.QuoteCardParentContainer {
font-family: $Roboto-Regular;
font-size: 12px;
color: #666666;
line-height: 20px;

.quoteCardContainer {
    background: $color-white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    font-family: $Roboto-Medium;
    display: inline-block;
    word-break: normal;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width:100%;
    bottom: 1.27%;

    .quoteLckCardHeader {
        border-bottom: 1px solid #DBDBDB;
    }

    .circleIcon {
        padding: 10px 15px 10px;
    }

    .quoteCardHeader , .quoteLckCardHeader {
        padding: 5px 15px 0px 15px;
        position: relative;
        .quoteCardHeaderMain {
            font-size: 14px;
            color: #0572D6;
            line-height: 18px;
            font-weight: bold;
            max-width: 320px;
            width: 94%;
            white-space: normal;
            min-height: 36px;
        }

        .quoteCardHeaderDesc {
            font-size: 12px;
            color: #666666;
            line-height: 18px;
            font-family: $Roboto-Regular;
            width: 167px;
            overflow: hidden;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            height: 40px;
        }
    }

    .quoteCardModeChart{
        border: none;
    }
    .quoteCardModeChart img {
        height: 55px;
    }
    
    .quoteCardChart {
        margin-top: -20px;
        // padding-bottom: 20px;
        .quoteCardChartMain {
            font-size: 20px;
            color: $color-black;
            padding: 0 15px 0 15px;
            font-weight: 500;
            display: inline-block;
        }

        .quoteCardChartDesc {
            padding: 5px 15px 0 15px;
            font-family: $Roboto-Regular;
            font-size: 14px;
            display: inline-block;
        }
    }

    .red {
        color: $color-red;
    }
    .green {
        color: #008338;
    }
    .toolTip{
        float:right;
        position: absolute;
        right: 15px;
        top: 0px;
        padding-top: 6px;
    }
    .Al{
        color: #0572D6;
        width: 39px;
        height:26px;
        border-radius: 6px;
        margin-right: 6px;
        margin-left: 10px;
        background: rgba(47, 174, 255, 0.25) !important;
        border-color: transparent;   
    }
    .Alt{
        border-color: transparent;
        color: #0572D6;
        width: 167px;
        height:26px; 
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        background: rgba(47, 174, 255, 0.25) !important;   
    }
    .Eq{
        border-color: transparent;
        color: #0572D6;
        width: 72px;
        height:26px; 
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        background: rgba(47, 174, 255, 0.25) !important;   
    }
    .Et{
        border-color: transparent;
        color: #0572D6;
        width: 52px;
        height:26px; 
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        background: rgba(47, 174, 255, 0.25) !important;   
    }
    .Mo{
        border-color: transparent;
        color: #0572D6;
        width: 68px;
        height:26px; 
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        background: rgba(47, 174, 255, 0.25) !important;   
    }
    .Mu{
        border-color: transparent;
        color: #0572D6;
        width: 105px;
        height:26px; 
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        background: rgba(47, 174, 255, 0.25) !important;   
    }
    .Sc{
        border-color: transparent;
        color: #0572D6;
        width: 80px;
        height:26px; 
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        background: rgba(47, 174, 255, 0.25) !important;   
    }
    .searchInForm{
        width: 984px;
        height:600px;
        left:26px;
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        color: #666666;
        overflow: auto;
    }
    .pillsBar{
        position: absolute;
        display: flex;
        height: 26px;
        left: 15px;
        top: 17px;
        font-family: $Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;    
    }
    .All{
        width: 39px;
        height:26px; 
        color: #666666;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        margin-top: 6px;
        text-align: center;
        margin-left: 15px;
    }
    .AlternativeInvestments{
        width: 167px;
        height:26px; 
        color: #666666;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        margin-top: 6px;
        text-align: center;
        margin-left: 15px;
    }
    .SMA{
        width: 56px;
        height:26px; 
        color: #666666;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        margin-top: 6px;
        text-align: center;
        padding: 2px 5px 1px 4px;
    }
    .SMAL{
        width: 56px;
        height: 26px; 
        color: #666666;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        margin-top: 6px;
        text-align: center;
        padding: 2px 5px 1px 4px;
        position: relative;
    }
    .SMAL:hover .tooltiptext , .Modellocked:hover .tooltiptext{
        visibility: visible;
        font-family: $Roboto-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        cursor: pointer;
        background-color:#37474F;
        margin-bottom: 3px;
    }
    .SMAL .tooltiptext, .Modellocked .tooltiptext{
        visibility: hidden;
        width: 155px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 3px;
        padding: 10px;
        
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -60px; 
    }
    .ETF, .MF{
        width: 91px;
        height:22px; 
        color: #666666;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-top: 6px;
        text-align: center;
        margin-left: 15px;
    }
    .adjustWidth {
        width: 135px
    }
    .Models{
        width: 122px;
        height:30px; 
        color: #666666;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-top: 6px;
        text-align: center;
        padding: 5px 8px 3px 8px;
    }
    .Modellocked{
        width: 135px;
        height: 26px; 
        color: #666666;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        margin-top: 6px;
        text-align: center;
        position: relative;
        padding: 3px 8px 5px 8px;
    }
    .MutualFunds{
        width: 105px;
        height:26px; 
        color: #666666;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 6px;
        margin-top: 6px;
        text-align: center;
        margin-left: 15px;
    }
    .altInvPrice{
        font-family: $Roboto-Regular;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: left;
        padding-top: 20px;
    }
    .stockHighlightIPadDivLeft {
        padding: 0 10px 0 10px;
    }
    .stockHighlightIPadDivLeftOverideForCompare{
        padding: 0px !important;
        margin-left: -5px !important;
        margin-right: -5px !important;
        margin-bottom: 10px;
    }
	.stockHighlightIPadDivLeft>div:first-child,.stockHighlightIPadDivLeft>div:nth-child(2){
        display: flex;
        justify-content: space-between;
        width:100%;
        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 16px;
        padding: 7px 0px 7px 2px;
        border-bottom: 1px solid rgba(0,0,0,0.10);
    }
    .stockHighlightIPadDivLeft>div:last-child{
        display: flex;
        justify-content: space-between;
        width:100%;
        font-family: $Roboto-Regular;
        font-size: 12px;
        line-height: 16px;
        padding: 7px 0px 7px 2px;
        border-bottom: 0;
    }
    .stockHighlightIPadDivLeftOverideForCompare>div:first-child,.stockHighlightIPadDivLeftOverideForCompare>div:nth-child(n+1){
        padding: 4px 0px 5px 0px;
    }
    .stockHighlightIPadDivLeftOverideForCompare>div:last-child{
        padding: 3px 0px 0px 0px;
        margin-bottom: 16px;
    }
    .stockHighlightDataDiv:last-child{
        border-bottom:none;
    }
    .MOC, .TPMOC{
        width: 63%;
        height:22px; 
        color: #666666;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 6px;
        margin-top: 8px;
        text-align: center;
        margin-left: 1px;
        font-style: normal;
        margin-bottom: 20px;
    }
    .TPMOC:hover .tooltiptext , .MOC:hover .tooltiptext{
        visibility: visible;
        font-family: $Roboto-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        cursor: pointer;
        background-color:#37474F;
        margin-bottom: 3px;
    }
    .TPMOC .tooltiptext, .MOC .tooltiptext{
        visibility: hidden;
        width: 155px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 3px;
        padding: 10px;
        
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 75%;
        left: 50%;
        margin-left: -60px; 
    }

}
}
@media (max-width: 596px) {
    .quoteCardParentContainer {
        display: none;
    } 
    
    .quoteCardContainer {
        .quoteCardHeader{
            .quoteCardHeaderMain {
               width: 94% !important;
        }
    }
}
@media (max-width: 376px) {
    .quoteCardContainer {
        .MOC, .TPMOC{
           width: 73% !important;
        }
    }
}
}